const lightPalette = {
    serviall: {
        main: "#da291c", // in css --mui-palette-myfuture-main (Pantone 485 C)
        primary: "#e2271d",
        secondary: "#101820", // (Pantone Black 6 C)
        background: "#F5F5FB",
        darkGray: "#464646",
        gray1: "#7f7f7f",
        gray2: "#c8c8c8",
        lightGray: "#e3e3e3",
        darkRed1: "#a31c15",
        darkRed2: "#c9231a",
        midRed1: "#f02a1f",
        midRed2: "#ff544b",
        gradient1: "linear-gradient(310deg, rgba(255,1,51,1) 0%, rgba(255,58,116,1) 100%)",
        currencyInputHover: "#000000",
        currencyInputFocus: "#1976D2",
        currencyInputBackground: "#ffffff",
        selectDate: "#ffffff",
        datagridGroupBackground: "#FFFFFF",
        menuBackground: "#ffffff",
    }
}

export default lightPalette;