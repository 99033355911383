import { Box, Grid, Typography } from "@mui/material";
import "./ServiallStep.css";

/**
 * React component that renders a single step of the service
 * @param {string} n_step - the number of the step
 * @param {string} text - the text of the step
 * @param {string} icon - the icon of the step
 * @param {boolean} center - if true, the text is centered, otherwise it is aligned to the left
 * @returns a component with a step
 */

const ServiallStep = ({ n_step, text, icon, center }) => {
    return (
        <Grid display={"flex"} gap={0.5} flexWrap={"wrap"}>
            <Box display={"flex"} gap={2} width={{xs: "100%", sm: "80%", tablet: "100%", md:"80%"}}>
                {n_step &&
                    <Grid item className="serviall-step-container">
                            <Box className="serviall-step-number-circle-border" flexShrink={0}>
                                <Typography className="serviall-step-number-text">
                                    {n_step}
                                </Typography>
                            </Box>
                    </Grid>
                }
            
                <Grid item sx={{ justifyContent: !center && "left"}} className={"serviall-step-container"}>
                    <Typography className="serviall-step-text">
                        {text}
                    </Typography>
                </Grid>
            </Box>
            <Grid item display={"flex"} justifyContent={"center"}  alignItems={"center"} width={{xs: "100%", sm: "15%", tablet: "100%", md: "15%"}}>
                <Typography className="serviall-step-icon">
                    {icon}
                </Typography>
            </Grid>
        </Grid>
    )
}

ServiallStep.displayName = "ServiallStep";

export default ServiallStep;