import { useSelector } from "react-redux";
import { setFilter } from "../features/displaySlice";
import DraggableDataGrid from "./DraggableDataGrid";
import { Stack, Checkbox, Typography, Tooltip } from "@mui/material";

/**
 * EdicionItemsDatagrid is a React component for displaying a data grid with draggable columns
 * and filtering capabilities. It is designed to be used within a Redux-connected component and integrates
 * with Material-UI components.
 *
 * @component
 * @param {Object} props - The props object containing the necessary parameters.
 * @param {Array} props.rows - An array of data rows to be displayed in the grid.
 * @param {function} props.t - The translation function for internationalization.
 * @param {boolean} props.loading - A boolean indicating whether the component is in a loading state.
 * @param {function} props.enableCheckbox - A function that determines whether checkboxes should be enabled.
 * @param {boolean} props.noOffer - A boolean indicating whether the "No Correct Match" checkbox is checked.
 * @param {function} props.handleNoMatchCheckbox - A callback function for handling changes to the "No Correct Match" checkbox.
 * @param {number} props.datagridType - An integer indicating the type of datagrid.
 * @param {boolean} props.READ_ONLY - A boolean indicating whether the datagrid is in read-only mode.
 * @param {Array} props.datagridCols - An array of column configurations for the data grid.
 * @returns {JSX.Element} - A JSX element representing the editable data grid component.
 */
const EdicionItemsDatagrid = ({ rows, t, loading, enableCheckbox, noOffer, handleNoMatchCheckbox, datagridType, READ_ONLY, datagridCols }) => {

    const displayState = useSelector((state) => state.display.itemsLicitacionModoEdicion);
    const filters = displayState.filters;


    const initialState = {
        sku_cliente: "",
        desc_cliente: "",
        marca_cliente: "",
        pn_cliente: "",
        um: "",
        consumo_anual: "",
        similitud: "",
        criticidad_sa: "",
        proveedor: "",
        sku_proveedor: "",
        item_match: "",
        desc_match: "",
        marca_match: "",
        pn_match: "",
        um_match: "",
        cantidad_empaque: "",
        factor_equivalencia_cte: "",
        plazo_entrega: "",
        costo_estimado: "",
        moneda_costo: "",
        precio_venta_sugerido: "",
        moneda_precio_venta: "",
        oportunidad_valorizada: "",
        moneda_oportunidad_valorizada: ""
    }

    const rowKeyGetter = (row) => {
        return row.id;
    }




    return (
        <>

            {!READ_ONLY && datagridType === 0 &&
                <Tooltip placement="top-end" arrow title={!enableCheckbox() ? <span style={{ whiteSpace: "pre-line", fontSize: "var(--serviall-h6)" }}>{t("ItemsLicitation:NoOfferTooltip")}</span> : ""}>

                    <Stack width={"100%"} display={"flex"} justifyContent={"flex-end"} alignItems={"center"} direction={"row"} marginBottom={4}>
                        <Checkbox disabled={loading || !enableCheckbox()} checked={noOffer} onChange={handleNoMatchCheckbox} />
                        <Typography className="itemsLicitacion-no-match-text" sx={{ color: !enableCheckbox() ? "var(--mui-palette-serviall-gray1);" : "var(--mui-palette-serviall-currencyInputHover);" }}>
                            {t("ItemsLicitation:NoCorrectMatchButton")}
                        </Typography>
                    </Stack>
                </Tooltip>
            }
            {
                datagridCols.length > 0 &&
                <DraggableDataGrid
                    className="serviall-datagrid"
                    cols={datagridCols}
                    rows={rows}
                    headerRowHeight={90}
                    rowClass={(row) => {
                        let className = row.igual ?
                            "itemsLicitacion-datagrid-row-igual-selected"
                            :
                            row.alternativo && "itemsLicitacion-datagrid-row-alternativo-selected"

                        if (row.highlight_repeated) {
                            className = " itemsLicitacion-datagrid-row-repeated_item"
                        }

                        if (row.extra_item) {
                            className = " itemsLicitacion-datagrid-row-extra_item"
                        }
                        return className;
                    }}
                    rowKeyGetter={rowKeyGetter}
                    initialState={initialState}
                    filters={filters}
                    setFiltersAction={setFilter}
                    page={"itemsLicitacionModoEdicion"}
                    state={displayState}
                />
            }
        </>
    )
}

export default EdicionItemsDatagrid;