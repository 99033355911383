import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Card, CardContent, Typography, Tooltip } from '@mui/material'
import "./HomeCard.css";


/**
 * A React component that renders the cards for the Home page.
 * @typedef HomeCard
 * @param {*} props with the following properties:
 * @param {string} props.width - The width of the card
 * @param {string} props.primaryColor - The color of the card
 * @param {string} props.iconColor - The color of the card's icon
 * @param {string} props.secondaryColor - The secondary color of the card
 * @param {string} props.kpi - The kpi value for the card
 * @param {string} props.primaryText - The primary text value for the card
 * @param {string or null} props.secondaryText - The secondary text value for the card
 * @returns A page with the Agregar Proveedor components
 */

function HomeCard(props) {

    return (
        <Card  sx={{width: props.width ? props.width : "300px", borderRadius: "16px", backgroundColor: props.primaryColor }} raised={true}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Box></Box>
                    <Button sx={{ minHeight: 0, minWidth: 0, padding: "5px", color: "black" }}>
                        <Tooltip placement='top-start' arrow title={<span style={{whiteSpace: "pre-line", fontSize: "var(--serviall-h6)"}}>{props.tooltipText ? props.tooltipText : ""}</span>}>
                            <InfoOutlinedIcon fontSize='small' style={{color: props.iconColor ? props.iconColor : "black"}} />
                        </Tooltip>
                    </Button>
                </Box>
                <CardContent sx={{ paddingTop: "0px", display: "flex", flexDirection: "row", gap: "25px" }}>
                    <Box sx={{ backgroundColor: props.secondaryColor, height: "40px", display: "flex", flexDirection: "column", justifyContent: "center", paddingX: "30px", borderRadius: "16px" }}>
                        <Typography sx={{ fontSize: "17px", fontWeight: 'bold', color: 'white' }} component="div" variant="h5">
                            {props.kpi}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Box sx={{ paddingRight: "50px" }}>
                            <Typography className='homeCard-text' sx={{ fontSize: "15px" }} component="div" variant="h5">
                                {props.primaryText}
                            </Typography>
                            <Typography className='homeCard-text' sx={{ fontWeight: 'bold', fontSize: "15px" }} component="div" variant="h5">
                                {(props.secondaryText) ? props.secondaryText : ""}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Box>
        </Card>
    )
}

HomeCard.displayName = "HomeCard";

export default HomeCard