import { createSlice } from "@reduxjs/toolkit";

// navigationSlice

// En este slice guardar información de navegación del usuario, esto es donde se encuentra en la aplicación,
// qué pagina esta activa, que ruta tiene el url, etc. 
// Esto con el fin de que cuando el usuario actualice la página se mantenga su estado actual.

export const navigationSlice = createSlice({
    name: "navigation",
    initialState: {
        currentUrl: "/",
        currentPage: "home",
        configPage: "",
        email: "",
        id: -1,
        alert_state: {
            open: false,
            severity: "info",
            message: ""
        },
        loggingOut: false
    },
    reducers: {
        setUrl: (state, action) => {
            state.currentUrl = action.payload;
        },
        setPage: (state, action) => {
            localStorage.setItem("page", action.payload);
            state.currentPage = action.payload;
        },
        setConfigPage: (state, action) => {
            localStorage.setItem("configPage", action.payload);
            state.configPage = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setId: (state, action) => {
            state.id = action.payload;
        },

        /** 
        * Changes the alert status on the redux store, this makes a dialog appear sending a message to the user.
        * @param {Object} state - The current redux state.
        * @param {Object} parameterNameHere - Object that contains the data to change the state
        * {
        *   open: Boolean
        *   severity: "info"|"error"
        *   message: String
        * }
        */
        setAlert: (state, action) => {
            state.alert_state = action.payload;
        },
        resetAlert: (state) => {
            state.alert_state.open = false;
        },
        setLoggingOut: (state,action) => {
            state.loggingOut = action.payload
        }
    }
})

export const { setUrl, setPage, setConfigPage, setEmail, setId, setAlert, resetAlert, setLoggingOut} = navigationSlice.actions;

export default navigationSlice.reducer;