import { Box, Button, CircularProgress, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import PermissionsTableRow from "../../components/PermissionsTableRow";
import "./ConfiguracionPermisos.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import serviallAxios from "../../axiosConfig";
import { TIMEOUTS } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { setRowsPerPageConfiguracionPermisos } from "../../features/displaySlice";
import DatagridPagination from "../../components/DatagridPagination";
import { setAlert } from "../../features/navigationSlice";
import { CustomAlertMessage } from "../../utils";



/**
 * A React component that renders the 'Permisos' page.
 * @typedef ConfiguracionPermisos
 * @returns A page with the Permisos page components
 */

const ConfiguracionPermisos = () => {

    const dispatch = useDispatch();
    const displayData = useSelector((state) => state.display.configuracionPermisos);
    const rowsPerPage = displayData.rowsPerPage;
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    // const [rowsPerPage, setRowsPerPage] = useState(5);
    const { t } = useTranslation();
    const [newGroup, setNewGroup] = useState("")
    const handleNewGroupChange = (e) => {
        setNewGroup(e.target.value)
    }

    /**
     * A React effect hook that fetches the list of permission groups from the server.
     * It stores the fetched data in the 'groups' state and sets the 'loading' state to false
     * after the data is fetched or if an error occurs.
     * @returns {void}
     */
    useEffect(() => {
        serviallAxios.get("/permissions/groups", {
            timeout: TIMEOUTS.small
        })
            .then(res => {
                // If the request is successful, store data in the redux store, not in cookies
                setGroups(res.data.groups);
            })
            .catch(e => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: e.response ? e.response.data.detail : CustomAlertMessage(e.code)

                }));
            }).finally(() => {
                setLoading(false);
            })
    }, [])

    /**
     * A React effect hook that fetches the permissions data associated with a selected group from the server.
     * It stores the fetched data in the 'rows' state and sets the 'loading' state to false
     * after the data is fetched or if an error occurs.
     * @returns {void}
     */
    useEffect(() => {
        if (selectedGroup) {
            setLoading(true);
            // Get user session data
            const payload = {
                group: selectedGroup
            }
            serviallAxios.post("/permissions/group_component_permissions", payload, {
                timeout: TIMEOUTS.small
            })
                .then(res => {
                    // If the request is successful, store data in the redux store, not in cookie
                    setRows(res.data.component_permissions_list);
                })
                .catch(e => {
                    dispatch(setAlert({
                        open: true,
                        severity: "error",
                        message: e.response ? e.response.data.detail : CustomAlertMessage(e.code)

                    }));
                }).finally(() => {
                    setLoading(false);
                })
        }
    }, [selectedGroup])

    // Avoid a layout jump when reaching the last page with empty rows.

    /**
     * Calculate the number of empty rows to avoid a layout jump when reaching the last page with empty rows.
     * @returns {number} The number of empty rows.
     */
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;



    /**
     * Event handler for changing the number of rows per page in the pagination.
     * It dispatches an action to update the rowsPerPage value in the Redux store and resets the current page to 0.
     * @param {object} event - The event object.
     * @returns {void}
     */
    const handleChangeRowsPerPage = (event) => {
        dispatch(setRowsPerPageConfiguracionPermisos(parseInt(event.target.value, 10)))
        setPage(0);
    };

    /**
     * Event handler for updating the permission_id and access_level of a row in the 'rows' state.
     * @param {number} idx - The index of the row to update.
     * @param {string} permission_id - The new permission ID value.
     * @param {string} permission_name - The new access level value.
     * @returns {void}
     */
    const handlePermissionChange = (idx, permission_id, permission_name) => {
        let newRows = rows;
        newRows[idx].permission_id = permission_id;
        newRows[idx].access_level = permission_name;
        setRows(newRows);
    }

    /**
     * Event handler for saving the permissions changes to the server.
     * It sends a POST request to update the group component permissions based on the modified 'rows' data.
     * The function sets the 'loading' state to true while the request is being processed and sets it back to false
     * after the request completes or encounters an error.
     * @returns {void}
     */
    const handleSavePermissions = () => {
        setLoading(true);
        let payload = []
        rows.forEach(row => {
            payload.push({
                "group_id": row.group_id,
                "entity_id": row.entity_id,
                "permission_id": row.permission_id
            })
        })

        serviallAxios.post("/permissions/update_group_component_permission", payload, {
            timeout: TIMEOUTS.medium
        })
            .then(res => {
                // TODO: send success message
                console.log(res.data);
            })
            .catch(e => {
                // If the request fails, remove token and redirect to login
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: e.response ? e.response.data.detail : CustomAlertMessage(e.code)

                }));
            }).finally(() => {
                setLoading(false);
            })
    }

    const fakeHeaders = [
        t("ConfiguracionPermisos:HeaderTitle1"),
        t("ConfiguracionPermisos:HeaderTitle2"),
        t("ConfiguracionPermisos:HeaderTitle3"),
        t("ConfiguracionPermisos:HeaderTitle4"),
        t("ConfiguracionPermisos:HeaderTitle5"),
        t("ConfiguracionPermisos:HeaderTitle6"),
        t("ConfiguracionPermisos:HeaderTitle7"),
        t("ConfiguracionPermisos:HeaderTitle8")
    ]

    /**
     * A functional component that renders the table header for the permissions table.
     * @param {Object} props - The component props containing 'headers' - an array of header texts.
     * @returns {JSX.Element} The table header JSX element.
     */
    const PermissionsTableHeader = ({ headers }) => {
        return (
            <>
                {headers.map((headerText, idx) => <TableCell className={`serviall-datagrid-header${(idx)%2 === 0 ? '1' : '2'}`} key={`permissions-header-${idx}`}>{headerText}</TableCell>)}
            </>
        )
    }

    /**
     * Handles a change in the currently displayed page in a paginated data view.
     *
     * This function is called when the user interacts with the pagination controls.
     * It takes the new page value and updates the component's state with the new page value.
     *
     * @function handlePageChange
     * @memberof ComponentName
     * @param {Object} e - The event object representing the pagination interaction.
     * @param {number} newPage - The new page value to be displayed.
     * @returns {void}
     */
    const handlePageChange = (e, newPage) => {
        setPage(newPage);
    
    }

    const pageRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    return (
        <>
            <Box marginBottom={4}>
                <Typography className="serviall-page-title1">{t("ConfiguracionPermisos:Title")}</Typography>
                <Typography className="serviall-page-title2">{t("ConfiguracionPermisos:Caption")}</Typography>
            </Box>
            <Box className="configuracion-permisos-section-container">
                <Typography className="configuracion-permisos-section-text">
                    {t("ConfiguracionPermisos:SubTitle1")}
                </Typography>
                <Stack sx={{ alignItems: "center" }} direction={"row"} flexWrap={"wrap"} justifyContent={"space-between"} gap={{xs:4}}>
                    <Typography>{t("ConfiguracionPermisos:SubCaption")}</Typography>
                    <Select
                    sx={{width: "300px"}}
                    renderValue={value => value || <Typography sx={{ fontSize: "14px", color: "gray" }}>{t("MantenedoresVersiones:SelectPlaceholder")}</Typography>}
                    className="serviall-input"
                        value={selectedGroup}
                        onChange={(e) => { setSelectedGroup(e.target.value) }}
                    >
                        {
                            groups.map((group, idx) => <MenuItem key={group + idx} value={group}>{group}</MenuItem>)
                        }
                    </Select>
                </Stack>
                <Box sx={{ height: "20px" }} />
                {
                    selectedGroup ?
                        loading ?
                            //NOTA: Considerar utilizar componente skeleton de MUI
                            <Stack className="configuracion-permisos-loading-container" direction={"column"} spacing={4}>
                                <Typography className="configuracion-permisos-loading-label">
                                    {t("ConfiguracionPermisos:Loading")}
                                </Typography>
                                <CircularProgress size={50} />
                            </Stack>
                            :
                            <>
                            <TableContainer component={Paper}>
                                <Table
                                    className="configuracion-permisos-component-table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            {/* Debe ser generado a partir de los permisos */}
                                            <PermissionsTableHeader headers={fakeHeaders} />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(rowsPerPage > 0
                                            ? pageRows
                                            : rows
                                        ).map((fake_row, idx) => (
                                            <PermissionsTableRow
                                                key={fake_row.name + idx}
                                                idx={idx + page * rowsPerPage}
                                                is_active={fake_row.is_active}
                                                handlePermissionChange={handlePermissionChange}
                                                name={fake_row.name}
                                                desc={fake_row.desc}
                                                url={fake_row.url}
                                                access_level={fake_row.access_level}
                                            />
                                        ))}

                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 71 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <DatagridPagination 
                            rows={rows} 
                            page={page}
                            handlePageChange={handlePageChange}
                            rowsPerPage={rowsPerPage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}/>
                            </>
                        :
                        <Box>{t("ConfiguracionPermisos:Help")}</Box>
                }
            </Box>
            
            <Box className="configuracion-permisos-save-container">
                {/* NOTA: Considerar utilizar componente snackbar de MUI para avisar al usuario de cambios exitosos o errores*/}
                <Button onClick={handleSavePermissions} className="serviall-button configuracion-permisos-save-button" disabled={!selectedGroup || loading}>
                    {t("ConfiguracionPermisos:ButtonTitle1")}
                </Button>
            </Box>
        </>
    )
}

ConfiguracionPermisos.displayName = "ConfiguracionPermisos";

export default ConfiguracionPermisos;