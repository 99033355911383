import { useDrag, useDrop } from "react-dnd"

import { headerRenderer } from "react-data-grid"
import PropTypes from 'prop-types';
import 'rsuite/dist/rsuite.min.css'
import { useTranslation } from "react-i18next";
import { DateRangePicker } from 'rsuite';
import "./DraggableHeaderRenderer.css"

DraggableHeaderRenderer.propTypes = {
  onColumnsReorder: PropTypes.func,
  column: PropTypes.array,
}

/**
 * A component to render a draggable header
 * @param {function} onColumnsReorder - A function to handle the reordering of columns
 * @param {object} column - The column object
 * @param {function} onFilterChange - A function to handle the filter change
 * @param {boolean} filterIsVisible - A boolean to show or hide the filter
 * @param {function} handleResetFilters - A function to handle the reset of filters
 * @param {array} responsibles - An array of responsibles
 * @params props - Other props to pass to the header renderer
 * @returns 
 */


export function DraggableHeaderRenderer({
  onColumnsReorder,
  column,
  onFilterChange,
  filterIsVisible,
  responsibles,
  ...props
}) {
  const { t } = useTranslation()
  const [{ isDragging }, drag] = useDrag({
    type: "COLUMN_DRAG",
    item: { key: column.key },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const [{ isOver }, drop] = useDrop({
    accept: "COLUMN_DRAG",
    drop({ key }) {
      onColumnsReorder(key, column.key)
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  })
  let headerContent;
  if (column.key !== "acciones" && column.key !== "actions") {
    if (column.key === "status") {
      headerContent = headerContent = <select value={column.filterValue} onChange={e => onFilterChange("status_str", e.target.value)} name={column.key} id={column.key} className={`draggable-input ${filterIsVisible ? "visible" : "hidden"}`} >
        <option value="">{t("Filtros:SelectTitle")}</option>
        <option value="Done">Done</option>
        <option value="Pending">Pending</option>
        <option value="CM">CM</option>
      </select>
    }
    else if (column.key === "estado_revision") {
      headerContent = headerContent = <select value={column.filterValue} onChange={e => onFilterChange(column.key, e.target.value)} name={column.key} id={column.key} className={`draggable-input ${filterIsVisible ? "visible" : "hidden"}`} >
      <option value="">{t("Filtros:SelectTitle")}</option>
      <option value="OK">Ok</option>
      <option value="Pend">Pend</option>
    </select>
    }
    else if (column.key.includes("fecha")) {

      let defaultDateRange = null;
      if (column.filterValue) {
        let [day1, month1, year1] = column.filterValue[0].split("/");
        let [day2, month2, year2] = column.filterValue[1].split("/");
        defaultDateRange = [new Date(year1, month1, day1), new Date(year2, month2, day2)];
      }

      headerContent = <DateRangePicker
        defaultValue={defaultDateRange}
        format="dd/MM/yyyy"
        placeholder={t("Filtros:FilterDatePlaceholder")}
        size="sm"
        placement="topEnd"
        className={`draggable-input ${filterIsVisible ? "visible" : "hidden"}`}
        showOneCalendar
        onChange={(value) => onFilterChange(column.key, value)}
      />
    }
    else if (column.key.includes("responsable")) {
      headerContent =
        <select value={column.filterValue} onChange={e => onFilterChange(column.key, e.target.value)} name={column.key} id={column.key} className={`draggable-input ${filterIsVisible ? "visible" : "hidden"}`} >
          <option selected value="">{t("Filtros:SelectTitle")}</option>

          {responsibles.map(responsible => {
            return <option value={responsible[0]}>{responsible[0]}</option>
          })}
        </select>
    }
    else {
      headerContent = <input type="text" id={column.key} value={column.filterValue} className={`draggable-input ${filterIsVisible ? "visible" : "hidden"}`} placeholder={t("Filtros:Placeholder")} onChange={(e) => onFilterChange(column.key, e.target.value)} />
    }
  }

  if (column.no_filter) {
    headerContent = null
  }


  return (
    <div
      ref={ref => {
        drag(ref)
        drop(ref)
      }}
      style={{
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isOver ? "#ececec" : undefined,
        cursor: "move",
      }}
    >
      {headerRenderer({ column, ...props })}
      {headerContent}



    </div>
  )
}

DraggableHeaderRenderer.displayName = "DraggableHeaderRenderer";