
import { Stack, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

/**
 * KpiBoxSummary is a React component that renders a Key Performance Indicator (KPI) box with an optional tooltip.
 *
 * @component
 * @param {Object} props - The props object containing the KPI box data.
 * @param {number} props.value - The numerical value to display in the KPI box.
 * @param {string} props.text - The text to display below the numerical value in the KPI box.
 * @param {string} props.size - The width of the KPI box.
 * @param {string} [props.tooltipText] - Optional. The tooltip text to display when hovering over the KPI box.
 * @param {string} [props.variant] - Optional. The variant of the KPI box (e.g., "tiny") to change its style.
 * @param {number} [props.percentage] - Optional. The percentage value to display alongside the numerical value.
 * @param {number} [props.avance] - Optional. A special flag for "tiny" variant indicating whether the KPI box represents an advancement.
 * @param {number} [props.percentValue] - Optional. The percentage value to display alongside the numerical value in "tiny" variant.
 * @returns {JSX.Element} - A JSX element representing the KPI box component.
 */

const KpiBoxSummary = ({value, text, size, tooltipText, variant, percentage, avance, percentValue}) => {
    return (
        <Stack width={size} className="verDetalleOportunidad-KpiBox-container" direction={"column"}>
            {
                tooltipText &&
                <Tooltip className="verDetalleOportunidad-KpiBox-tooltip" title={<span style={{whiteSpace: "pre-line", fontSize: "var(--serviall-h6)"}}>{tooltipText}</span>} placement="top">
                    <InfoOutlinedIcon />
                </Tooltip>
            }
            {
                (variant === "tiny" && avance) ?
                    <>
                        <Typography className="verDetalleOportunidad-KpiBox-number-tiny"> {!percentage ? value : value ? value+"%": "-"}</Typography>
                        <Typography sx={{fontWeight: 'bold'}} className="verDetalleOportunidad-KpiBox-number-tiny"> ({percentValue ? percentValue+"%": "-"})</Typography>
                        <Typography  className="verDetalleOportunidad-KpiBox-text-tiny">{text}</Typography>
                    </>
                    :
                    <>
                        <Typography sx={{fontWeight: 'bold'}} className="verDetalleOportunidad-KpiBox-number">{!percentage ? value : value ? value+"%": "-"}</Typography>
                        <Typography sx={{fontWeight: 'bold'}} className="verDetalleOportunidad-KpiBox-text">{text}</Typography>
                    </>
            }
        </Stack>
    )
}

export default KpiBoxSummary