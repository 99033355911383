import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { Provider } from 'react-redux';
import store from './app/store';
import "./i18n";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Experimental_CssVarsProvider } from '@mui/material'
import cssTheme from './themes/CssTheme';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';


ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      >
        <Provider store={store}>
          <Experimental_CssVarsProvider theme={cssTheme}>
            <App />
          </Experimental_CssVarsProvider>
        </Provider>
      </GoogleOAuthProvider>
    </LocalizationProvider>
  // </React.StrictMode>
)
