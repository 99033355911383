import { Box, Divider, Typography, Paper } from '@mui/material'
import React from 'react'
import KpiBoxSummary from './KpiBoxSummary'



/**
 * LicitationSummary is a React component for displaying a summary of key performance indicators (KPIs) related to a licitation.
 *
 * @component
 * @param {Object} props - The props object containing the data for the licitation summary.
 * @param {function} props.t - The translation function for internationalization.
 * @param {Object} props.kpis - An object containing key performance indicators (KPIs) data.
 * @param {string} props.kpiWidth - The width of the KPI boxes.
 * @returns {JSX.Element} - A JSX element representing the licitation summary component.
 */
const LicitationSummary = ({ t, kpis, kpiWidth }) => {

    return (
        <Paper display={"flex"} flexDirection={"row"} sx={{ width: '60%', height: '174px' }} style={{ padding: "0.5rem 0.5rem 0.5rem" }}>
            <Box display={"flex"} justifyContent={"space-around"} >

                <div style={{ width: '55%', display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }} >
                    <div style={{ textAlign: "center", paddingBottom: '1rem' }}>
                        <Typography sx={{fontWeight: 'bold'}} variant="">{t("VerDetallePage:GridTitle1")}</Typography>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                        <div>
                            <KpiBoxSummary value={Object.keys(kpis).length > 0 ? kpis.rows_with_match : "-"} size={kpiWidth} text={t("VerDetallePage:ReviewedLinesAlternative")} variant={'tiny'} tooltipText={t("VistaAgregada:Tooltip1")} avance={true} percentValue={Object.keys(kpis).length > 0 ? kpis.rows_with_match_percentage : "-"}/>
                        </div>
                        <div>
                            <KpiBoxSummary value={Object.keys(kpis).length > 0 ? kpis.rows_with_no_offers : "-"} size={kpiWidth} variant={'tiny'} text={t("VerDetallePage:ReviewedLinesNoOffer")} tooltipText={t("VistaAgregada:Tooltip2")} avance={true} percentValue={Object.keys(kpis).length > 0 ? kpis.rows_with_no_offers_percentage : "-"}/>
                        </div>
                        <div>
                            <KpiBoxSummary value={Object.keys(kpis).length > 0 ? kpis.remaining_lines : "-"} size={kpiWidth} variant={'tiny'} text={t("VerDetallePage:PendingLines")} tooltipText={t("VistaAgregada:Tooltip3")} avance={true} percentValue={Object.keys(kpis).length > 0 ? kpis.remaining_lines_percentage : "-"}/>
                        </div>
                    </div>
                </div>

                <Divider sx={{ bgcolor: "black", margin: '5px' }} orientation="vertical" variant="middle" flexItem />

                <div style={{ width: '45%', display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }} >
                    <div style={{ textAlign: "center", paddingBottom: '1rem' }}>
                        <Typography sx={{fontWeight: 'bold'}} variant="">{t("VerDetallePage:GridTitle4")}</Typography>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }} >

                        <div>
                            <KpiBoxSummary value={Object.keys(kpis).length > 0 ? kpis.precision_bn : "-"} size={kpiWidth} variant={"tiny"} text={t("VerDetallePage:Precision")} tooltipText={t("VistaAgregada:Tooltip4")} percentage={true} />
                        </div>
                        <div>
                            <KpiBoxSummary value={Object.keys(kpis).length > 0 ? kpis.calibration : "-"} size={kpiWidth} variant={"tiny"} text={t("VerDetallePage:RequiresCalibration")} tooltipText={t("VistaAgregada:Tooltip5")} percentage={true} />
                        </div>
                    </div>
                </div>
            </Box>
        </Paper>
    )
}

export default LicitationSummary