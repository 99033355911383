import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import "./EditMastersItemDialog.css";
import ServiallLoading from "./ServiallLoading";
import ServiallInput from "./ServiallInput";
import { useTranslation } from "react-i18next";

/**
 * A React component that render the edit actions for Maestros Clientes / proveedores
 * @typedef EditMastersItemDialog
 * @param {boolean} dialogOpen - Wether the dialog is open
 * @param {boolean} loading - Wether the dialog is loading
 * @param {string} variant - The variant of the dialog (clientes || proveedores)
 * @param {object} formValues - The values of the form
 * @param {function} handleChange - The function to handle the change of the input
 * @param {function} handleClose - The function to handle the close of the dialog
 * @param {function} handleSave - The function to handle the save of the form
 * @returns A component with the form to edit the item.
 */

const EditMastersItemDialog = ({ dialogOpen, loading, variant, formValues, handleChange, handleClose, handleSave }) => {
    // variant values = clientes || proveedores
    const INPUT_SPACING = 3
    const {t} = useTranslation()
    let fields;
    if (variant === "proveedores"){
        fields = <Stack direction={"column"} spacing={INPUT_SPACING}>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
            {/* NOTA: llamar al componente de esta forma hace que no pierda el focus cuando se esta escribiendo */}
             <ServiallInput
                id="sku_proveedor"
                label={t("Common:EditMasterDialogHeader1")}
                placeholder={t("Common:EditMasterDialogHeader1")}
                value={formValues.sku_proveedor}
                onChange={handleChange}
                disabled={true}
                inputClassName="serviall-input"/>
            <ServiallInput
            label={t("Common:EditMasterDialogHeader2")}
            id="homologo_sa"
            placeholder={t("Common:EditMasterDialogHeader2")}
            value={formValues.homologo_sa}
            onChange={handleChange}
            inputClassName="serviall-input"/>

        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
            <ServiallInput
                label={t("Common:EditMasterDialogHeader3")}
                id="es_desc"
                placeholder={t("Common:EditMasterDialogHeader3")}
                value={formValues.es_desc}
                onChange={handleChange}
                inputClassName="serviall-input"/>
            <ServiallInput
                label={t("Common:EditMasterDialogHeader4")}
                id="en_desc"
                placeholder={t("Common:EditMasterDialogHeader4")}
                value={formValues.en_desc}
                onChange={handleChange}
                inputClassName="serviall-input"/>

        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
             <ServiallInput
               label={t("Common:EditMasterDialogHeader6")}
               id= "marca"
               placeholder= {t("Common:EditMasterDialogHeader6")}
               value= {formValues.marca}
               onChange={handleChange}
                inputClassName="serviall-input"/>
            <ServiallInput
               label={t("Common:EditMasterDialogHeader7")}
               id= "pn"
               placeholder= {t("Common:EditMasterDialogHeader7")}
               value= {formValues.pn}
               onChange= {handleChange}
                inputClassName="serviall-input"/>
        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>

            <ServiallInput
               label={t("Common:EditMasterDialogHeader8")}
               id= "costo_sa"
               placeholder= {t("Common:EditMasterDialogHeader8")}
               value= {formValues.costo_sa}
               onChange= {handleChange}
                inputClassName="serviall-input"/>
           
            <ServiallInput
               label={t("Common:EditMasterDialogHeader9")}
               id= "moneda"
               placeholder= {t("Common:EditMasterDialogHeader9")}
               value= {formValues.moneda}
               onChange= {handleChange}
                inputClassName="serviall-input"/>
        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>

            <ServiallInput
                label={t("Common:EditMasterDialogHeader10")}
                id= "factor_importacion"
                placeholder= {t("Common:EditMasterDialogHeader10")}
                value= {formValues.factor_importacion}
                onChange= {handleChange}
                inputClassName="serviall-input"/>
           
            <ServiallInput
                label={t("Common:EditMasterDialogHeader11")}
                id="um"
                placeholder={t("Common:EditMasterDialogHeader11")}
                value={formValues.um}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
            <ServiallInput
                label={t("Common:EditMasterDialogHeader12")}
                id="cantidad"
                placeholder={t("Common:EditMasterDialogHeader12")}
                value={formValues.cantidad}
                onChange={handleChange}
                inputClassName="serviall-input"
            />

            <ServiallInput
                label={t("Common:EditMasterDialogHeader13")}
                id="plazo_entrega"
                placeholder={t("Common:EditMasterDialogHeader13")}
                value={formValues.plazo_entrega}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
            <ServiallInput
                label={t("Common:EditMasterDialogHeader14")}
                id="sitio_web"
                placeholder={t("Common:EditMasterDialogHeader14")}
                value={formValues.sitio_web}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
        </Stack>
    </Stack>
    }else if (variant === "clientes") {
        fields = <Stack direction={"column"} spacing={INPUT_SPACING}>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
            <ServiallInput
                label={t("Common:EditMasterDialogHeader15")}
                id="sku_cliente"
                placeholder={t("Common:EditMasterDialogHeader15")}
                value={formValues.sku_cliente}
                disabled={true}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
            {/* <ServiallInput
                label="Sku Serviall"
                id="sku_serviall"
                placeholder="Sku Serviall"
                value={formValues.sku_serviall}
                onChange={handleChange}
                inputClassName="serviall-input"
            /> */}
            <ServiallInput
                label={t("Common:EditMasterDialogHeader2")}
                id="homologo_sa"
                placeholder={t("Common:EditMasterDialogHeader2")}
                value={formValues.homologo_sa}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
            <ServiallInput
                label={t("Common:EditMasterDialogHeader3")}
                id="desc_es"
                placeholder={t("Common:EditMasterDialogHeader3")}
                value={formValues.desc_es}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
            <ServiallInput
                label={t("Common:EditMasterDialogHeader4")}
                id="desc_en"
                placeholder={t("Common:EditMasterDialogHeader4")}
                value={formValues.desc_en}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
            <ServiallInput
                label="Marca"
                id="marca"
                placeholder={t("Common:EditMasterDialogHeader6")}
                value={formValues.marca}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
            <ServiallInput
                label={t("Common:EditMasterDialogHeader7")}
                id="pn"
                placeholder="P/N"
                value={formValues.pn}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>

        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
            <ServiallInput
                label={t("Common:EditMasterDialogHeader11")}
                id="um"
                placeholder={t("Common:EditMasterDialogHeader11")}
                value={formValues.um}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
            <ServiallInput
                label={t("Common:EditMasterDialogHeader13")}
                id="plazo_entrega"
                placeholder={t("Common:EditMasterDialogHeader13")}
                value={formValues.plazo_entrega}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
            <ServiallInput
                label={t("Common:EditMasterDialogHeader16")}
                id="apariciones_creacion"
                placeholder={t("Common:EditMasterDialogHeader16")}
                value={formValues.apariciones_creacion}
                onChange={handleChange}
                inputClassName="serviall-input"
                disabled={true}
            />
            <ServiallInput
                label={t("Common:EditMasterDialogHeader17")}
                id="apariciones_ultimos_dias"
                placeholder={t("Common:EditMasterDialogHeader17")}
                value={formValues.apariciones_ultimos_dias}
                onChange={handleChange}
                inputClassName="serviall-input"
                disabled={true}
            />
        </Stack>

    </Stack>
    }else if (variant === "aux_provider"){
        fields = <Stack direction={"column"} spacing={INPUT_SPACING}>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
            <ServiallInput
                label={t("Common:EditMasterDialogHeader1")}
                id="sku_proveedor"
                disabled={true}
                placeholder={t("Common:EditMasterDialogHeader1")}
                value={formValues.sku_proveedor}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
            <ServiallInput
                label={t("Common:EditMasterDialogHeader3")}
                id="es_desc"
                placeholder={t("Common:EditMasterDialogHeader3")}
                value={formValues.es_desc}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
            <ServiallInput
                label={t("Common:EditMasterDialogHeader4")}
                id="en_desc"
                placeholder={t("Common:EditMasterDialogHeader4")}
                value={formValues.en_desc}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
            <ServiallInput
                label={t("Common:EditMasterDialogHeader11")}
                id="UM"
                placeholder={t("Common:EditMasterDialogHeader11")}
                value={formValues["UM"]}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
            <ServiallInput
                label={t("Common:EditMasterDialogHeader18")}
                id="Nombre"
                placeholder={t("Common:EditMasterDialogHeader18")}
                value={formValues.Nombre}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
            <ServiallInput
                label={t("Common:EditMasterDialogHeader19")}
                id="Codigo Sap"
                placeholder={t("Common:EditMasterDialogHeader19")}
                value={formValues["Codigo Sap"]}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
            <ServiallInput
                label={t("Common:EditMasterDialogHeader20")}
                id="sku_serviall"
                placeholder={t("Common:EditMasterDialogHeader20")}
                value={formValues.sku_serviall}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
            <ServiallInput
                label={t("Common:EditMasterDialogHeader21")}
                id="Costo estimado"
                placeholder={t("Common:EditMasterDialogHeader21")}
                value={formValues["Costo estimado"]}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
            <ServiallInput
                label={t("Common:EditMasterDialogHeader22")}
                id="Marca/Fabricante"
                placeholder={t("Common:EditMasterDialogHeader22")}
                value={formValues["Marca/Fabricante"]}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
            <ServiallInput
                label={t("Common:EditMasterDialogHeader9")}
                id="Moneda del costo"
                placeholder={t("Common:EditMasterDialogHeader9")}
                value={formValues["Moneda del costo"]}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
            <ServiallInput
                label={t("Common:EditMasterDialogHeader7")}
                id="Numero de Parte"
                placeholder={t("Common:EditMasterDialogHeader7")}
                value={formValues["Numero de Parte"]}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
            <ServiallInput
                label={t("Common:EditMasterDialogHeader23")}
                id="Formato del empaque"
                placeholder={t("Common:EditMasterDialogHeader23")}
                value={formValues["Formato del empaque"]}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
        </Stack>
        <Stack direction={"row"} spacing={INPUT_SPACING}>
            <ServiallInput
                label={t("Common:EditMasterDialogHeader24")}
                id="URL del item-proveedor"
                placeholder={t("Common:EditMasterDialogHeader24")}
                value={formValues["URL del item-proveedor"]}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
            <ServiallInput
                label={t("Common:EditMasterDialogHeader12")}
                id="Cantidad por empaque cerrado"
                placeholder={t("Common:EditMasterDialogHeader12")}
                value={formValues["Cantidad por empaque cerrado"]}
                onChange={handleChange}
                inputClassName="serviall-input"
            />
        </Stack>
    </Stack>
    }

            


    return (
        <Dialog
            open={dialogOpen}
        >
            <DialogTitle>
                <Typography className="EditMasters-dialog-title">
                    {t("Common:EditTitle")} {variant}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {
                    loading ?
                        <ServiallLoading
                            text={t("Common:LoadingItem")}
                        />
                        :
                        fields
                }
            </DialogContent>
            {
                !loading &&
                <DialogActions>
                    <Button
                        className="serviall-button-success"
                        onClick={handleSave}
                    >
                        {t("Common:AcceptBtn")}
                    </Button>
                    <Button
                        className="serviall-button-error"
                        onClick={handleClose}
                    >
                        {t("Common:CancelBtn")}
                    </Button>
                </DialogActions>
            }
        </Dialog>
    )
}

EditMastersItemDialog.displayName = "EditMastersItemDialog";

export default EditMastersItemDialog;