import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, FormControl, Typography, Select, MenuItem, IconButton, TextField, Autocomplete, Tooltip } from "@mui/material";
import { useState, useEffect, React } from "react";
import "./AddAuxItemDialog.css";
import serviallAxios from "../axiosConfig";
import ServiallLoading from "./ServiallLoading";
import { useDispatch, useSelector } from "react-redux";
import { setHistoryById, setRows } from "../features/opportunitySlice";
import { setAlert } from "../features/navigationSlice";
import { useTranslation } from "react-i18next";
import CurrencyInput from 'react-currency-input-field';
import { TIMEOUTS } from "../utils";
import ServiallInput from "./ServiallInput";
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import { CustomAlertMessage } from "../utils";
/**
 * A React component that renders the form to add an auxiliar item.
 * @typedef AddAuxItemDialog
 * @param pageState: The redux object that contains the state of the page.
 * @param makeRow: Function helper to make a row for the datagrid.
 * @param noMatchReset: Function to reset an item with no matches.
 * @returns A component with a form.
 */

const AddAuxItemDialog = ({ pageState, makeRow, noMatchReset }) => {

    const current_user_id = useSelector((state) => state.navigation.id);
    const dispatch = useDispatch();
    const { t } = useTranslation()

    const INPUT_SPACING = 4
    const INITIAL_STATE = {
        provider_rut: "",
        sku_provider: "",
        es_desc: "",
        en_desc: "",
        um: "",
        costo_estimado: "",
        marca_fabricante: "",
        moneda_costo: "",
        numero_parte: "",
        formato_empaque: "",
        url_item_proveedor: "",
        cantidad_empaque_cerrado: "",
        criticidad_sa: "",
        provider_name: "",
    }

    const [dialogOpen, setDialogOpen] = useState(false);
    const [formValues, setFormValues] = useState({ ...INITIAL_STATE });
    const [loading, setLoading] = useState(false);
    const [providersInfo, setProvidersInfo] = useState([])
    const [type, setType] = useState("")
    const [searchItems, setSearchItems] = useState([])
    const [selectSearchItem, setSelectSearchItem] = useState({})
    /**
     * Handle change event for form inputs and update the form values accordingly.
     * This function is typically used in conjunction with Material-UI (MUI) form components.
     *
     * @param {Event} e - The change event triggered by the input element.
     * @returns {void}
     */

    const handleChange = (e) => {
        let newFormValues = { ...formValues };
        /* MUI select doesn´t show id property */
        if (e.target.name === "provider_rut") {
            newFormValues[e.target.name] = e.target.value;
        }
        else {
            newFormValues[e.target.id] = e.target.value;
        }
        setFormValues(newFormValues);
    }

    const handleChangeProvider = (e, type) => {
        let newFormValues = { ...formValues };
        if (type === "provider_name") {
            if (!e.target.outerText) {
                newFormValues.provider_name = ""
                setFormValues(newFormValues);
                return;
            }
            const [_, name] = e.target.outerText.split("-")
            newFormValues.provider_name = name
            setFormValues(newFormValues);
        }
        else {
            if (!e.target.outerText) {
                newFormValues.provider_rut = ""
                setFormValues(newFormValues);
                return;
            }
            const [rut, _] = e.target.outerText.split("-")
            newFormValues.provider_rut = rut
            setFormValues(newFormValues);
        }

    }
    /**
     * Handle cost change event and update the corresponding field in the form values.
     *
     * @param {string | number} value - The new cost value to be updated in the form.
     * @param {string} name - The name of the field to be updated in the form.
     * @returns {void}
     */

    const handleCostChange = (value, name) => {
        let newFormValues = { ...formValues };
        newFormValues[name] = value;

        setFormValues(newFormValues)
    }

    /**
     * Sets the Dialog to open
     *
     * @returns {void}
     */

    const handleOpen = () => {
        setDialogOpen(true);
    }

    /**
     * Sets the Dialog to close and resets FormValues
     *
     * @returns {void}
     */
    const handleClose = () => {
        setDialogOpen(false);
        setFormValues({ ...INITIAL_STATE })
        setSearchItems([])
        setSelectSearchItem({})
    }

    /**
     * Handle save state. Stores the new element in database and add it to redux
     * store
     *
     * @returns {void}
     */
    const handleSave = () => {
        // setLoading(true);

        // Check if the item_sku is already on the current rows
        let currentRows = JSON.parse(JSON.stringify(pageState.rows));
        console.log(formValues)
        let itemExists = currentRows.find((row) => row.proveedor === providersInfo.find(provider => provider.rut === formValues.provider_rut).name && row.sku_proveedor === formValues.sku_provider);

        if (itemExists) {
            itemExists.highlight_repeated = true;

            dispatch(setAlert({
                open: true,
                severity: "warning",
                message: t("AddAuxItemDialog:ItemExists")
            }))

            dispatch(setRows(currentRows));
            handleClose();

            return;
        }

        let dbRow = {
            provider_rut: formValues.provider_rut,
            sku_provider: formValues.sku_provider,
            es_desc: formValues.es_desc,
            en_desc: formValues.en_desc,
            "UM": formValues.um,
            "Costo estimado": formValues.costo_estimado,
            "Marca/Fabricante": formValues.marca_fabricante,
            "Moneda del costo": formValues.moneda_costo,
            "Número de Parte": formValues.numero_parte,
            "Formato de empaque": formValues.formato_empaque,
            "URL del item-proveedor": formValues.url_item_proveedor,
            "Cantidad por empaque cerrado": formValues.cantidad_empaque_cerrado,
            user_id: current_user_id
        }

        serviallAxios.post("opportunities/aux_table", JSON.stringify(dbRow), {
            timeout: TIMEOUTS.small
        })
            .then((res) => {
                dispatch(setAlert({
                    open: true,
                    severity: "info",
                    message: t("AddAuxItemDialog:ItemAdded")
                }));
                // Add item to table
                let newRows = JSON.parse(JSON.stringify(pageState.rows));
                let oportunidad_valorizada = ""
                if (newRows[0]["consumo_anual"] && formValues["costo_estimado"]) {
                    oportunidad_valorizada = (parseFloat(newRows[0]["consumo_anual"]) * parseFloat(formValues["costo_estimado"])).toFixed(1)
                }
                else if (!newRows[0]["consumo_anual"] && formValues["costo_estimado"]) {
                    oportunidad_valorizada = formValues["costo_estimado"]
                }
                else {
                    oportunidad_valorizada = 0
                }
                const newRow = makeRow(
                    pageState.rows.length + 1,
                    newRows[0]["sku_cliente"],
                    newRows[0]["desc_cliente"],
                    newRows[0]["desc_complementaria"],
                    newRows[0]["marca_cliente"],
                    newRows[0]["pn_cliente"],
                    newRows[0]["um"],
                    "CNI",
                    formValues["es_desc"],
                    formValues["marca_fabricante"],
                    formValues["numero_parte"],
                    "",
                    providersInfo.find(provider => provider.rut === formValues.provider_rut).name,
                    formValues["sku_provider"], // Sku provider ?
                    formValues["costo_estimado"],
                    formValues["moneda_costo"],
                    "",
                    "",
                    oportunidad_valorizada,
                    "",
                    false,
                    false,
                    formValues["um"],
                    newRows[0]["consumo_anual"],
                    formValues["cantidad_empaque_cerrado"],
                    "",
                    "",
                    "",
                    true, // No match field
                    true, // Extra item field for different styling
                    false,
                    false,
                    "Calibrado de auxiliar"
                )
                newRows.splice(Math.round(newRows.length / 2), 0, newRow);
                // Set no_match to true since an item had to be added
                newRows.forEach((row) => {
                    row.no_match = true;
                })
                dispatch(setRows(newRows));
                dispatch(setHistoryById({
                    idx: pageState.current.page - 1,
                    rows: newRows
                }))
                noMatchReset(false);
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
            .finally(() => {
                setLoading(false);
                handleClose();
            })
    }

    useEffect(() => {
        setProvidersInfo([])
        serviallAxios.get("/masters/providers_rut", {
            timeout: TIMEOUTS.small
        })
            .then(res => {
                res.data.providers_data.forEach(provider => {
                    const providerObj = {
                        rut: provider[0],
                        name: provider[1]
                    }
                    setProvidersInfo(prevState => [...prevState, providerObj])
                })
            })
            .catch(e => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: e.response ? e.response.data.detail : CustomAlertMessage(e.code)
                }));
            })
    }, [])
    const handleSearchChange = (e) => {
        const [provider_name, sku_provider] = e.target.value.split("__")
        setSelectSearchItem(searchItems.find((item) => item["Proveedor"] === provider_name && item["SKU proveedor"] === sku_provider))
    }
    useEffect(() => {
        setFormValues(INITIAL_STATE)
        setSearchItems([])
        setSelectSearchItem({})
    }, [type])
    const handleSearch = () => {
        dispatch(setAlert({
            open: true,
            severity: "info",
            message: t("AddAuxItemDialog:SearchStart")
        }))
        let params;
        if (type === "both") {
            params = {
                ...formValues.provider_name && { proveedor: formValues.provider_name },
                ...formValues.sku_provider && { sku: formValues.sku_provider },
                ...formValues.es_desc && { descripcion: formValues.es_desc },
                ...formValues.marca_fabricante && { marca: formValues.marca_fabricante },
                ...formValues.numero_parte && { pn: formValues.numero_parte },
                ...formValues.criticidad_sa && { criticidad: formValues.criticidad_sa },
                is_serviall: "Both"
            }
        }
        else if (type === "serviall") {
            params = {
                ...formValues.sku_provider && { sku: formValues.sku_provider },
                ...formValues.es_desc && { descripcion: formValues.es_desc },
                ...formValues.marca_fabricante && { marca: formValues.marca_fabricante },
                ...formValues.numero_parte && { pn: formValues.numero_parte },
                ...formValues.criticidad_sa && { criticidad: formValues.criticidad_sa },
                is_serviall: "True"
            }
        }
        else {
            params = {
                ...formValues.provider_name && { proveedor: formValues.provider_name },
                ...formValues.sku_provider && { sku: formValues.sku_provider },
                ...formValues.es_desc && { descripcion: formValues.es_desc },
                ...formValues.marca_fabricante && { marca: formValues.marca_fabricante },
                ...formValues.numero_parte && { pn: formValues.numero_parte },
                is_serviall: "False"
            }
        }
        serviallAxios.get(process.env.REACT_APP_ML_BACKEND_URL + "/licitations/item", { params }, {
            timeout: TIMEOUTS.medium
        })
            .then(res => {
                if (res.data.item === "Item no encontrado") {
                    dispatch(setAlert({
                        open: true,
                        severity: "error",
                        message: t("ItemsLicitation:ItemNotFound")
                    }));
                    return;
                }
                else {
                    dispatch(setAlert({
                        open: true,
                        severity: "success",
                        message: t("AddAuxItemDialog:SearchSuccess")
                    }))
                    setSearchItems(res.data.item)
                }
            }).catch(error => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: error.response ? error.response.data.detail : CustomAlertMessage(error.code)
                }));
            })

    }

    useEffect(() => {
        setFormValues({ ...INITIAL_STATE })
    }, [type])

    const handleAltSave = () => {
        const newItem = { ...selectSearchItem };
        let oportunidad_valorizada = "";
        let newRows = JSON.parse(JSON.stringify(pageState.rows));
        if (newRows[0]["consumo_anual"] && newItem["Costo estimado"]) {
            oportunidad_valorizada = (parseFloat(newRows[0]["consumo_anual"]) * parseFloat(newItem["Costo estimado"])).toFixed(1)
        }
        else if (!newRows[0]["consumo_anual"] && newItem["Costo estimado"]) {
            oportunidad_valorizada = newItem["Costo estimado"]
        }
        else {
            oportunidad_valorizada = 0
        }

        const newRow = makeRow(
            pageState.rows.length + 1,
            newRows[0]["sku_cliente"],
            newRows[0]["desc_cliente"],
            newRows[0]["desc_complementaria"],
            newRows[0]["marca_cliente"],
            newRows[0]["pn_cliente"],
            newRows[0]["um"],
            type === "serviall" ? newItem["SKU proveedor"] : "CNI",
            newItem["Desc Match"],
            newItem["Marca Match"],
            newItem["P/N Match"],
            newItem["Criticidad SA"],
            newItem["Proveedor"],
            type === "serviall" ? "" : newItem["SKU proveedor"], //
            newItem["Costo estimado"],
            newItem["Moneda del costo"],
            newItem["Precio Venta sugerido"],
            newItem["Moneda del precio venta"],
            oportunidad_valorizada,
            newItem["Moneda de oportunidad valorizada"],
            false,
            false,
            newItem["UM"],
            newRows[0]["consumo_anual"],
            newItem["Cantidad por empaque"],
            newItem["Factor de equivalencia cte"],
            newItem["Plazo de entrega"],
            "",
            true, //no match
            true, //extra field 
            false,
            false,
            "Calibrado de maestra"
        )

        let itemExists = type === "serviall" ? newRows.find((row) => newRow.proveedor === row.proveedor && newRow.item_match === row.item_match) : newRows.find((row) => newRow.proveedor === row.proveedor && newRow.sku_proveedor === row.sku_proveedor);

        if (!itemExists) {
            dispatch(setAlert({
                open: true,
                severity: "info",
                message: t("ItemsLicitation:ItemFound")
            }));

            newRows.splice(Math.round(newRows.length / 2), 0, newRow);
            // Set no_match to true since an item had to be added
            newRows.forEach((row) => {
                row.no_match = true;
            })
            dispatch(setRows(newRows));
            dispatch(setHistoryById({
                idx: pageState.current.page - 1,
                rows: newRows
            }))
            noMatchReset(false);
        } else {
            dispatch(setAlert({
                open: true,
                severity: "warning",
                message: t("ItemsLicitation:ItemExists")
            }));
            itemExists.highlight_repeated = true;
            dispatch(setRows(newRows));
        }
    }

    const filterOptions = (options, inputValue) => {
        const substrings = inputValue ? inputValue.split(" ") : [""]
        if (substrings.length === 1 && substrings[0] === "") return options
        return options.filter(item => {
            if (substrings[substrings.length - 1] === "") substrings.pop()
            return (
                substrings.some(substring => item.rut.toString().toLowerCase().includes(substring.toLowerCase())) || substrings.some(substring => item.name.toString().toLowerCase().includes(substring.toLowerCase()))
            )
        })
    }

    let dialogContent;

    if (type === "both") {
        dialogContent =
            <>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={INPUT_SPACING} justifyContent={"space-between"}  >

                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} width={{ xs: "100%", tablet: "45%" }} position={"relative"}  >
                        <Box>
                            <Typography className="serviall-input-label">{t("AddAuxItemDialog:Input17")}</Typography>
                        </Box>

                        <FormControl size="small" overflow={"scroll"} >
                            <Autocomplete
                                componentsProps={{ popper: { style: { width: 'fit-content', maxWidth: "90vw" } } }}
                                getOptionLabel={(option) => { return (option.rut ? `${option.rut}-${option.name}` : option) }}
                                filterOptions={(options, { inputValue }) => filterOptions(options, inputValue)}
                                className='nuevaOportunidad-mui-input-container'
                                id='provider_name'
                                options={providersInfo}
                                freeSolo
                                forcePopupIcon
                                onChange={(e) => handleChangeProvider(e, "provider_name")}
                                renderInput={(params) => <TextField className="serviall-input" placeholder={t("AddAuxItemDialog:Input17")} {...params} />} name='provider_name' variant="standard" fullWidth />
                        </FormControl>
                    </Box>
                    <ServiallInput
                        id={"sku_provider"}
                        label={t("AddAuxItemDialog:Input2")}
                        value={formValues.sku_provider}
                        placeholder={t("AddAuxItemDialog:Placeholder2")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={INPUT_SPACING} justifyContent={"space-between"} >
                    <ServiallInput
                        id={"es_desc"}
                        label={t("AddAuxItemDialog:Input16")}
                        value={formValues.es_desc}
                        placeholder={t("AddAuxItemDialog:Placeholder16")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />

                    <ServiallInput
                        id={"marca_fabricante"}
                        label={t("AddAuxItemDialog:Input9")}
                        value={formValues.marca_fabricante}
                        placeholder={t("AddAuxItemDialog:Placeholder9")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={INPUT_SPACING} justifyContent={"space-between"} >
                    <ServiallInput
                        id={"criticidad_sa"}
                        label={t("AddAuxItemDialog:Input15")}
                        value={formValues.criticidad_sa}
                        placeholder={t("AddAuxItemDialog:Placeholder15")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />

                    <ServiallInput
                        id={"numero_parte"}
                        label={t("AddAuxItemDialog:Input11")}
                        value={formValues.numero_parte}
                        placeholder={t("AddAuxItemDialog:Placeholder11")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={INPUT_SPACING} justifyContent={"space-between"}>

                    <Box display={"flex"} alignItems={"flex-end"} width={{ xs: "100%", tablet: "45%" }} justifyContent={"flex-end"}>
                        <Button
                            sx={{ height: "fit-content" }}
                            className="serviall-button"
                            onClick={handleSearch}>
                            {t("AddAuxItemDialog:SearchButton")}
                        </Button>
                    </Box>
                </Stack>

                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 1.5 }} width={"100%"} position={"relative"} >
                    <Box>
                        <Typography className="serviall-input-label">{t("AddAuxItemDialog:Input18")}</Typography>
                    </Box>

                    <FormControl size="small" overflow={"scroll"} >
                        <Select
                            id="items_options"
                            name="items_options"
                            defaultValue={""}
                            displayEmpty
                            renderValue={value => value || <Typography sx={{ fontSize: "14px", color: "gray" }}>{t("AddAuxItemDialog:Placeholder18")}</Typography>}
                            onChange={handleSearchChange}
                            className="serviall-input"
                            MenuProps={{ disablePortal: true }}
                            variant="standard"
                            disableUnderline
                            disabled={searchItems.length === 0}
                            fullWidth
                        >
                            {searchItems.map((item, idx) => {
                                return (
                                    <MenuItem divider sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column", overflowX: "auto" }} maxWidth={{ xs: "100%", sm: "55vw" }} key={idx} value={`${item.Proveedor}__${item["SKU proveedor"]}`}>
                                        <Tooltip placement="top-end" arrow
                                            title={
                                                <span style={{ whiteSpace: "pre-line", fontSize: "var(--serviall-h6)" }}>
                                                    {t("AddAuxItemDialog:Input17")}: {item["Proveedor"]}
                                                    <br />
                                                    {t("AddAuxItemDialog:Input2")}: {item["SKU proveedor"]}
                                                    <br />
                                                    {t("AddAuxItemDialog:Input9")}: {item["Marca Match"]}
                                                    <br />
                                                    {t("AddAuxItemDialog:Input16")}: {item["Desc Match"]}
                                                    <br />
                                                    {t("AddAuxItemDialog:Input11")}: {item["P/N Match"]}
                                                    <br />
                                                    {t("AddAuxItemDialog:Input8")}: {item["Costo estimado"]} {item["Moneda del costo"]}

                                                </span>
                                            }>
                                            <Box>
                                                <Typography maxWidth={{ xs: "100%", sm: "55vw" }}>{t("AddAuxItemDialog:Input17")}: {item["Proveedor"]}</Typography>
                                                <Typography maxWidth={{ xs: "100%", sm: "55vw" }}>{t("AddAuxItemDialog:Input2")}: {item["SKU proveedor"]}</Typography>
                                                <Typography maxWidth={{ xs: "100%", sm: "55vw" }}  >{t("AddAuxItemDialog:Input9")}: {item["Marca Match"]}</Typography>
                                                <Typography maxWidth={{ xs: "100%", sm: "55vw" }}>{t("AddAuxItemDialog:Input16")}: {item["Desc Match"]}</Typography>
                                            </Box>
                                        </Tooltip>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
            </>
    }
    else if (type === "serviall") {

        dialogContent =
            <>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={INPUT_SPACING} justifyContent={"space-between"} >

                    <ServiallInput
                        id={"sku_provider"}
                        label={t("AddAuxItemDialog:Input2")}
                        value={formValues.sku_provider}
                        placeholder={t("AddAuxItemDialog:Placeholder2")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                    <ServiallInput
                        id={"marca_fabricante"}
                        label={t("AddAuxItemDialog:Input9")}
                        value={formValues.marca_fabricante}
                        placeholder={t("AddAuxItemDialog:Placeholder9")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={INPUT_SPACING} justifyContent={"space-between"} >
                    <ServiallInput
                        id={"es_desc"}
                        label={t("AddAuxItemDialog:Input16")}
                        value={formValues.es_desc}
                        placeholder={t("AddAuxItemDialog:Placeholder16")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                    <ServiallInput
                        id={"numero_parte"}
                        label={t("AddAuxItemDialog:Input11")}
                        value={formValues.numero_parte}
                        placeholder={t("AddAuxItemDialog:Placeholder11")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={INPUT_SPACING} justifyContent={"space-between"} >
                    <ServiallInput
                        id={"criticidad_sa"}
                        label={t("AddAuxItemDialog:Input15")}
                        value={formValues.criticidad_sa}
                        placeholder={t("AddAuxItemDialog:Placeholder15")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                    <Box display={"flex"} alignItems={"flex-end"} width={{ xs: "100%", tablet: "45%" }} justifyContent={"flex-end"}>
                        <Button
                            sx={{ height: "fit-content" }}
                            className="serviall-button"
                            onClick={handleSearch}>
                            {t("AddAuxItemDialog:SearchButton")}
                        </Button>
                    </Box>
                </Stack>

                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 1.5 }} width={"100%"} position={"relative"} >
                    <Box>
                        <Typography className="serviall-input-label">{t("AddAuxItemDialog:Input18")}</Typography>
                    </Box>
                    <FormControl size="small" overflow={"scroll"} >
                        <Select
                            id="items_options"
                            name="items_options"
                            defaultValue={""}
                            displayEmpty
                            renderValue={value => value || <Typography sx={{ fontSize: "14px", color: "gray" }}>{t("AddAuxItemDialog:Placeholder18")}</Typography>}
                            onChange={handleSearchChange}
                            className="serviall-input"
                            MenuProps={{ disablePortal: true }}
                            disabled={searchItems.length === 0}
                            variant="standard"
                            disableUnderline
                            fullWidth
                        >
                            {searchItems.map((item, idx) => {
                                return (
                                    <MenuItem divider sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column", overflowX: "auto" }} maxWidth={{ xs: "100%", sm: "55vw" }} key={idx} value={`${item.Proveedor}__${item["SKU proveedor"]}`}>
                                        <Tooltip placement="top-end"
                                            title={
                                                <span style={{ whiteSpace: "pre-line", fontSize: "var(--serviall-h6)" }}>
                                                    {t("AddAuxItemDialog:Input17")}: {item["Proveedor"]}
                                                    <br />
                                                    {t("AddAuxItemDialog:Input2")}: {item["SKU proveedor"]}
                                                    <br />
                                                    {t("AddAuxItemDialog:Input9")}: {item["Marca Match"]}
                                                    <br />
                                                    {t("AddAuxItemDialog:Input16")}: {item["Desc Match"]}
                                                    <br />
                                                    {t("AddAuxItemDialog:Input11")}: {item["P/N Match"]}
                                                    <br />
                                                    {t("AddAuxItemDialog:Input8")}: {item["Costo estimado"]} {item["Moneda del costo"]}
                                                    <br />
                                                    {t("AddAuxItemDialog:Input15")}: {item["Criticidad SA"]}

                                                </span>
                                            }>
                                            <Box>
                                                <Typography maxWidth={{ xs: "100%", sm: "55vw" }}>{t("AddAuxItemDialog:Input17")}: {item["Proveedor"]}</Typography>
                                                <Typography maxWidth={{ xs: "100%", sm: "55vw" }}>{t("AddAuxItemDialog:Input2")}: {item["SKU proveedor"]}</Typography>
                                                <Typography maxWidth={{ xs: "100%", sm: "55vw" }}  >{t("AddAuxItemDialog:Input9")}: {item["Marca Match"]}</Typography>
                                                <Typography maxWidth={{ xs: "100%", sm: "55vw" }}>{t("AddAuxItemDialog:Input16")}: {item["Desc Match"]}</Typography>
                                            </Box>
                                        </Tooltip>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
            </>
    }
    else if (type === "providers") {
        dialogContent =
            <>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={INPUT_SPACING} justifyContent={"space-between"}  >

                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} width={{ xs: "100%", tablet: "45%" }} position={"relative"}  >
                        <Box>
                            <Typography className="serviall-input-label">{t("AddAuxItemDialog:Input17")}</Typography>
                        </Box>

                        <FormControl size="small" overflow={"scroll"} >
                            <Autocomplete
                                componentsProps={{ popper: { style: { width: 'fit-content', maxWidth: "90vw" } } }}
                                getOptionLabel={(option) => { return (option.rut ? `${option.rut}-${option.name}` : option) }}
                                filterOptions={(options, { inputValue }) => filterOptions(options, inputValue)}
                                className='nuevaOportunidad-mui-input-container'
                                id='provider_name'
                                options={providersInfo}
                                freeSolo
                                forcePopupIcon
                                onChange={(e) => handleChangeProvider(e, "provider_name")}
                                renderInput={(params) => <TextField className="serviall-input" placeholder={t("AddAuxItemDialog:Input17")} {...params} />} name='provider_name' variant="standard" fullWidth />
                        </FormControl>
                    </Box>
                    <ServiallInput
                        id={"sku_provider"}
                        label={t("AddAuxItemDialog:Input2")}
                        value={formValues.sku_provider}
                        placeholder={t("AddAuxItemDialog:Placeholder2")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={INPUT_SPACING} justifyContent={"space-between"} >
                    <ServiallInput
                        id={"es_desc"}
                        label={t("AddAuxItemDialog:Input16")}
                        value={formValues.es_desc}
                        placeholder={t("AddAuxItemDialog:Placeholder16")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />

                    <ServiallInput
                        id={"marca_fabricante"}
                        label={t("AddAuxItemDialog:Input9")}
                        value={formValues.marca_fabricante}
                        placeholder={t("AddAuxItemDialog:Placeholder9")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={INPUT_SPACING} justifyContent={"space-between"}>
                    <ServiallInput
                        id={"numero_parte"}
                        label={t("AddAuxItemDialog:Input11")}
                        value={formValues.numero_parte}
                        placeholder={t("AddAuxItemDialog:Placeholder11")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                    <Box display={"flex"} alignItems={"flex-end"} width={{ xs: "100%", tablet: "45%" }} justifyContent={"flex-end"}>
                        <Button
                            sx={{ height: "fit-content" }}
                            className="serviall-button"
                            onClick={handleSearch}>
                            {t("AddAuxItemDialog:SearchButton")}
                        </Button>
                    </Box>
                </Stack>

                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 1.5 }} width={"100%"} position={"relative"} >
                    <Box>
                        <Typography className="serviall-input-label">{t("AddAuxItemDialog:Input18")}</Typography>
                    </Box>

                    <FormControl size="small" overflow={"scroll"} >
                        <Select
                            id="items_options"
                            name="items_options"
                            defaultValue={""}
                            displayEmpty
                            renderValue={value => value || <Typography sx={{ fontSize: "14px", color: "gray" }}>{t("AddAuxItemDialog:Placeholder18")}</Typography>}
                            onChange={handleSearchChange}
                            className="serviall-input"
                            MenuProps={{ disablePortal: true }}
                            variant="standard"
                            disableUnderline
                            disabled={searchItems.length === 0}
                            fullWidth
                        >
                            {searchItems.map((item, idx) => {
                                return (
                                    <MenuItem divider sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column", overflowX: "auto" }} maxWidth={{ xs: "100%", sm: "55vw" }} key={idx} value={`${item.Proveedor}__${item["SKU proveedor"]}`}>
                                        <Tooltip placement="top-end" arrow
                                            title={
                                                <span style={{ whiteSpace: "pre-line", fontSize: "var(--serviall-h6)" }}>
                                                    {t("AddAuxItemDialog:Input17")}: {item["Proveedor"]}
                                                    <br />
                                                    {t("AddAuxItemDialog:Input2")}: {item["SKU proveedor"]}
                                                    <br />
                                                    {t("AddAuxItemDialog:Input9")}: {item["Marca Match"]}
                                                    <br />
                                                    {t("AddAuxItemDialog:Input16")}: {item["Desc Match"]}
                                                    <br />
                                                    {t("AddAuxItemDialog:Input11")}: {item["P/N Match"]}
                                                    <br />
                                                    {t("AddAuxItemDialog:Input8")}: {item["Costo estimado"]} {item["Moneda del costo"]}

                                                </span>
                                            }>
                                            <Box>
                                                <Typography maxWidth={{ xs: "100%", sm: "55vw" }}>{t("AddAuxItemDialog:Input17")}: {item["Proveedor"]}</Typography>
                                                <Typography maxWidth={{ xs: "100%", sm: "55vw" }}>{t("AddAuxItemDialog:Input2")}: {item["SKU proveedor"]}</Typography>
                                                <Typography maxWidth={{ xs: "100%", sm: "55vw" }}  >{t("AddAuxItemDialog:Input9")}: {item["Marca Match"]}</Typography>
                                                <Typography maxWidth={{ xs: "100%", sm: "55vw" }}>{t("AddAuxItemDialog:Input16")}: {item["Desc Match"]}</Typography>
                                            </Box>
                                        </Tooltip>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
            </>
    }
    else if (type === "manual") {
        dialogContent =
            <>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={INPUT_SPACING}>

                    {/* NOTA: llamar al componente de esta forma hace que no pierda el focus cuando se esta escribiendo */}
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 1.5 }} width={{ xs: "100%", tablet: "45%" }} position={"relative"} >
                        <Box>
                            <Typography className="serviall-input-label">{t("AddAuxItemDialog:Input1")}</Typography>
                        </Box>

                        <FormControl size="small" overflow={"scroll"} >
                            <Autocomplete
                                componentsProps={{ popper: { style: { width: 'fit-content', maxWidth: "90vw" } } }}
                                getOptionLabel={(option) => { return (option.rut ? `${option.rut}-${option.name}` : option) }}
                                filterOptions={(options, { inputValue }) => filterOptions(options, inputValue)}
                                className='nuevaOportunidad-mui-input-container'
                                id='provider_rut'
                                options={providersInfo}
                                freeSolo
                                forcePopupIcon
                                onChange={(e) => handleChangeProvider(e, "provider_rut")}
                                renderInput={(params) => <TextField className="serviall-input" placeholder={t("AddAuxItemDialog:Placeholder1")} {...params} />} name='provider_name' variant="standard" fullWidth />
                        </FormControl>
                        {
                            !formValues.provider_rut && <Typography className="serviall-input-error" position={"absolute"} bottom={"-20px"} right={"5px"}><ErrorIcon fontSize="inherit" /> {t("AddAuxItemDialog:Caption")}</Typography>
                        }
                    </Box>
                    <ServiallInput
                        id={"sku_provider"}
                        label={t("AddAuxItemDialog:Input2")}
                        value={formValues.sku_provider}
                        placeholder={t("AddAuxItemDialog:Placeholder2")}
                        errorText={!formValues.sku_provider && t("AddAuxItemDialog:Caption")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={INPUT_SPACING}>
                    <ServiallInput
                        id={"es_desc"}
                        label={t("AddAuxItemDialog:Input3")}
                        value={formValues.es_desc}
                        placeholder={t("AddAuxItemDialog:Placeholder3")}
                        errorText={!formValues.es_desc && t("AddAuxItemDialog:Caption")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />

                    <ServiallInput
                        id={"en_desc"}
                        label={t("AddAuxItemDialog:Input4")}
                        value={formValues.en_desc}
                        placeholder={t("AddAuxItemDialog:Placeholder4")}
                        errorText={!formValues.en_desc && t("AddAuxItemDialog:Caption")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={INPUT_SPACING}>
                    <ServiallInput
                        id={"um"}
                        label={t("AddAuxItemDialog:Input6")}
                        value={formValues.um}
                        placeholder={t("AddAuxItemDialog:Placeholder6")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 1.5 }} width={{ xs: "100%", tablet: "45%" }} position={"relative"} >
                        <Box>
                            <Typography className="serviall-input-label">{t("AddAuxItemDialog:Input8")}</Typography>
                        </Box>

                        <CurrencyInput
                            id="costo_estimado"
                            name="costo_estimado"
                            placeholder="Costo estimado"
                            groupSeparator="."
                            decimalSeparator=","
                            decimalsLimit={5}
                            value={formValues.costo_estimado}
                            prefix="$"
                            className="serviall-input"
                            onValueChange={(value, name) => handleCostChange(value, name)}
                        />
                    </Box>

                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={INPUT_SPACING}>
                    <ServiallInput
                        id={"marca_fabricante"}
                        label={t("AddAuxItemDialog:Input9")}
                        value={formValues.marca_fabricante}
                        placeholder={t("AddAuxItemDialog:Placeholder9")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                    <ServiallInput
                        id={"moneda_costo"}
                        label={t("AddAuxItemDialog:Input10")}
                        value={formValues.moneda_costo}
                        placeholder={t("AddAuxItemDialog:Placeholder10")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />

                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={INPUT_SPACING}>
                    <ServiallInput
                        id={"numero_parte"}
                        label={t("AddAuxItemDialog:Input11")}
                        value={formValues.numero_parte}
                        placeholder={t("AddAuxItemDialog:Placeholder11")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                    <ServiallInput
                        id={"formato_empaque"}
                        label={t("AddAuxItemDialog:Input12")}
                        value={formValues.formato_empaque}
                        placeholder={t("AddAuxItemDialog:Placeholder12")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={INPUT_SPACING}>
                    <ServiallInput
                        id={"url_item_proveedor"}
                        label={t("AddAuxItemDialog:Input13")}
                        value={formValues.url_item_proveedor}
                        placeholder={t("AddAuxItemDialog:Placeholder13")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                    <ServiallInput
                        id={"cantidad_empaque_cerrado"}
                        label={t("AddAuxItemDialog:Input14")}
                        value={formValues.cantidad_empaque_cerrado}
                        placeholder={t("AddAuxItemDialog:Placeholder14")}
                        onChange={handleChange}
                        inputClassName="serviall-input" />
                </Stack>
            </>
    }
    let dialogActions;
    if (type === "manual") {
        dialogActions =
            <Button
                className="serviall-button"
                disabled={!formValues.provider_rut || !formValues.sku_provider || !formValues.es_desc || !formValues.en_desc}
                onClick={handleSave}
            >
                {t("AddAuxItemDialog:AcceptButton")}
            </Button>
    }
    else {
        dialogActions =
            <Button
                onClick={handleAltSave}
                className="serviall-button"
                disabled={Object.keys(selectSearchItem).length === 0}
            >
                {t("AddAuxItemDialog:AcceptButton")}
            </Button>
    }
    return (
        <>
            <Tooltip title={<span style={{ whiteSpace: "pre-line", fontSize: "var(--serviall-h6)" }}>{t("AddAuxItemDialog:Tooltip")}</span>} sx={{ zIndex: 99, position: "absolute" }}>
                <Button onClick={handleOpen} sx={{ width: "fit-content" }} className="serviall-button">
                    {t("AddAuxItemDialog:AddButton")}
                </Button>
            </Tooltip>
            <Dialog open={dialogOpen}>
                <DialogTitle sx={{ paddingX: "4rem" }} display="flex">
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        className="dialog-close-button-icon"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography justifySelf={"flex-start"} className="AddAuxItemDialog-dialog-title" marginBottom={4} >
                        {t("AddAuxItemDialog:Title")}
                    </Typography>
                    {
                        loading ?
                            <ServiallLoading
                                text={"Cargando objeto"}
                            />
                            :
                            <Stack direction={"column"} spacing={INPUT_SPACING} className="addAuxItemDialog-container">
                                <FormControl size="small" overflow={"scroll"} >
                                    <Select
                                        id="type"
                                        name="type"
                                        value={type}
                                        displayEmpty
                                        renderValue={value => value || <Typography sx={{ fontSize: "14px", color: "gray" }}>{t("AddAuxItemDialog:SelectOptionPlaceholder")}</Typography>}
                                        onChange={(e) => setType(e.target.value)}
                                        className="serviall-input"
                                        MenuProps={{ disablePortal: true }}
                                        variant="standard"
                                        disableUnderline
                                        fullWidth
                                    >
                                        <MenuItem key={"both"} value={"both"}>{t("AddAuxItemDialog:BothMasters")}</MenuItem>
                                        <MenuItem key={"serviall"} value={"serviall"}>{t("AddAuxItemDialog:ServiallMasters")}</MenuItem>
                                        <MenuItem key={"providers"} value={"providers"}>{t("AddAuxItemDialog:ProvidersMasters")}</MenuItem>
                                        <MenuItem key={"manual"} value={"manual"}>{t("AddAuxItemDialog:ManualInput")}</MenuItem>
                                    </Select>
                                </FormControl>
                                {dialogContent}
                            </Stack>
                    }
                </DialogContent>
                {
                    !loading &&
                    <DialogActions>
                        {dialogActions}
                    </DialogActions>
                }
            </Dialog>
        </>
    )
}

AddAuxItemDialog.displayName = "AddAuxItemDialog";

export default AddAuxItemDialog;