import { createSlice } from "@reduxjs/toolkit";

// settingsSlice
// Aca dejar configuraciones del usuario solo con respecto a la página.
// Estados considerados por ahora: tema actual (light/dark), idioma (ingles/español).

export const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        theme: "light",
        language: "es"
    },
    reducers: {
        changeTheme: (state) => {
            if (state.theme === "dark") {
                state.theme = "light"
            } else {
                state.theme = "dark"
            }
        },
        changeLanguage: (state, action) => {
            state.language = action.payload;
        }
    }
})

export const { changeTheme, changeLanguage } = settingsSlice.actions;

export default settingsSlice.reducer;