import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { ReactComponent as ArchivoLogo } from "../assets/icons/archivo.svg";
import { ReactComponent as ArchivoErrorLogo } from "../assets/icons/error.svg";
import { ReactComponent as AccountLogo } from "../assets/icons/account.svg";
import CloseIcon from '@mui/icons-material/Close';
import "./ServiallDialog.css";
import { useTranslation } from "react-i18next";

/**
 * A React Component that renders a modal to show messages about the application services.
 * @param {boolean} open - Whether the modal is open or not
 * @param {string} status - The status of the modal
 * @param {string} loading_msg - The message to show while the modal is loading
 * @param {string} error_type - The type of error
 * @param {string} error_code - The error code
 * @param {string} error_msg - The error message
 * @param {function} handleClose - The function to call when the modal is closed
 * @returns A modal component
 */

// TODO: agregar translations
const ServiallDialog = ({ open, status, loading_msg, error_type, error_code, error_msg, handleClose }) => {
    const errorCodeExplain = (code) => {
        switch (code) {
            case 401:
                return "Error de usuario o contraseña"
            case 422:
                return "Error al subir archivo"
            case "ERR_NETWORK":
                return "Error de red, intentalo denuevo más tarde"
            case "ERR_CONNECTION_REFUSED":
                return "Error de red, intentalo denuevo más tarde"
            case "ERR_BAD_REQUEST":
                return "Error de formato excel"
            case "ECONNABORTED":
                return "Error de timeout, se ha excedido el tiempo límite"
            case "ERR_ALREADY_LOGGED":
                return "Ya has iniciado sesión con otro usuario dentro del mismo navegador. Refresca la página, por favor."
            default:
                return code
        }
    }

    const { t } = useTranslation()
    return (
        <Dialog
            open={open}
            onClose={status !== "loading" ? handleClose : null}
            fullWidth={true}
            className={"serviall-dialog-container"}
        >
            {
                status === "loading" &&
                <>
                    <DialogTitle>
                        <Box sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                            {
                                loading_msg
                            }
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress />
                        </Box>
                    </DialogContent>
                </>
            }
            {
                status === "success" &&
                <>
                    <DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            className="dialog-close-button-icon"
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Stack direction={"column"} alignItems={"center"} spacing={4}>
                            <ArchivoLogo className="serviall-dialog-file-icon" />
                            <Typography fontSize={"larger"} textAlign={"center"}>
                                {t("Common:ServiallDialogContent1")} <br />  {t("Common:ServiallDialogContent2")}
                            </Typography>
                        </Stack>
                    </DialogContent>
                </>
            }
            {
                status === "processing" &&
                <>
                    <DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            className="dialog-close-button-icon"
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Stack direction={"column"} alignItems={"center"} spacing={4}>
                            <ArchivoLogo className="serviall-dialog-file-icon" />
                            <Typography fontSize={"larger"} textAlign={"center"}>
                                {t("Common:ServiallDialogContent3")} <br />  {t("Common:ServiallDialogContent4")}
                            </Typography>
                        </Stack>
                    </DialogContent>
                </>
            }
            {
                status === "error" &&
                <>
                    <DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            className="dialog-close-button-icon"
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Stack direction={"column"} alignItems={"center"} spacing={4}>
                            {error_type === "file-error" && <ArchivoErrorLogo className="serviall-dialog-file-icon" />}
                            {error_type === "login-error" && <AccountLogo fill="red" className="serviall-dialog-file-icon" />}
                            <Typography fontSize={"larger"} textAlign={"center"}>
                                {errorCodeExplain(error_code)}
                            </Typography>
                            {
                                error_msg &&
                                <Typography fontSize={"larger"} textAlign={"center"}>
                                    {t("Common:Detail")} {error_msg}
                                </Typography>
                            }
                        </Stack>
                    </DialogContent>
                </>
            }
            {
                status === "check_data" &&
                <>
                    <DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            className="dialog-close-button-icon"
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Stack direction={"column"} alignItems={"center"} spacing={4}>
                            <ArchivoLogo className="serviall-dialog-file-icon" />
                            <Typography fontSize={"larger"} textAlign={"center"}>
                                {t("Common:ServiallDialogContent5")}
                            </Typography>
                        </Stack>
                    </DialogContent>
                </>
            }
        </Dialog>
    )
}

ServiallDialog.displayName = "ServiallDialog";

export default ServiallDialog;