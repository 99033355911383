import { useEffect, useState, useRef} from "react";
import { useSelector } from "react-redux";
import { Box, Stack, Button, Typography, Grid, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import ServiallInput from "../../components/ServiallInput";
import Calendar from 'react-calendar';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import "./CalendarioPage.css";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import serviallAxios from "../../axiosConfig";
import ServiallLoading from "../../components/ServiallLoading";
import { TIMEOUTS } from "../../utils";
import { useDispatch } from "react-redux";
import { setAlert } from "../../features/navigationSlice";
import { CustomAlertMessage } from "../../utils";

/**
 * A React component that renders the 'Calendario' page.
 * @typedef CalendarioPage
 * @returns A page with the Calendario components
 */

const CalendarioPage = () => {
    const { t } = useTranslation();
    const lang = useSelector((state) => state.settings.language);
    const [selectedDate, setSelectedDate] = useState(null);
    const [activeStartDate, setActiveStartDate] = useState(new Date());
    const [filteredLicitations, setFilteredLicitations] = useState([]);
    const [inputFilteredLicitations, setInputFilteredLicitations] = useState([])
    const [filterType, setFilterType] = useState("cierre");
    const [searchQuery, setSearchQuery] = useState("");
    const [isSearching, setIsSearching] = useState(false)
    const [loading, setLoading] = useState(false)
    const closeBtn = useRef(null)
    const dispatch = useDispatch()

    /**
     * Changes the active month in the calendar.
     * @param {number} amount - The number of months to change. Positive values move forward, negative values move backward.
     * @returns {void}
     */
    const changeMonth = (amount) => {
        let newDate = new Date(activeStartDate.setMonth(activeStartDate.getMonth() + amount));
        setSelectedDate(null);
        setActiveStartDate(newDate);
    }
    /**
     * Sets the active start date to the current date.
     * @returns {void}
     */
    const selectToday = () => {
        setActiveStartDate(new Date());
        setSelectedDate(new Date());
    }

    /**
     * Handles the click event on a date tile in the calendar.
     * @param {Date} date - The selected date.
     * @returns {void}
     */
    const onDateClick = (date) => {
        setSelectedDate(date);
        setInputFilteredLicitations([])
    }

    /**
     * Determines if a tile should be disabled (not selectable) in the calendar.
     *
     * This function is used to decide whether a specific date tile in the calendar should be disabled (not selectable) based on the current month of the active start date. If the month of the provided date does not match the month of the active start date, the tile will be disabled.
     *
     * @param {object} params - The parameters object containing the date and view information.
     * @param {Date} params.date - The date associated with the tile.
     * 
     * @returns {boolean} True if the tile should be disabled, false otherwise.
     */
    const tileDisabled = ({ date, view }) => {
        return date.getMonth() !== activeStartDate.getMonth();
    }

    // Add selected tile content via state
    /**
     * Adds content to the selected tile in the calendar.
     * @param {object} params - The parameters object containing the date and view information.
     * @returns {JSX.Element} The JSX content to be added to the tile.
     */
    const tileContent = ({ date, view }) => {
        if (selectedDate) {
            if (selectedDate.getDate() === date.getDate()) {
                return (<Typography fontSize={"small"}>{date.toLocaleString(lang, { weekday: "long" }).toUpperCase()}</Typography>);
            }
        }
    }

    /**
     * Adds a custom CSS class to the selected tile in the calendar.
     * @param {object} params - The parameters object containing the date and view information.
     * @returns {string} The CSS class name to be applied to the tile.
     */
    const tileClassName = ({ date, view }) => {
        let baseClass = "calendarioPage-calendar-tile"
        if (selectedDate) {
            if (selectedDate.getDate() === date.getDate()) {
                baseClass += " calendarioPage-calendar-tile-selected"
            }
        }
        return baseClass;
    }

    
    /**
     * Removes the "active" class from all date buttons in the component.
     * @returns {void}
     */
    const handleRemoveActiveClass = () => {
        const handleDateButtons = document.querySelectorAll(".calendarioPage-actions-button")
        handleDateButtons.forEach((el) => {
            el.classList.remove("active")
        })
    }
    
    /**
     * Handles the click event on a date filter button. Filters elements bassed on selected month and year
     * @param {object} e - The click event object.
     * @returns {void}
     */
    const handleActiveButton = (e) => {
        setSelectedDate(null)
        handleRemoveActiveClass()
        e.target.classList.add("active")
        setFilterType(e.target.id)
        setSelectedDate(null)
    }

    /**
     * Handles the change event on the search input field.
     * @param {object} e - The change event object.
     * @returns {void}
     */
    const handleSearchTextChange = (e) => {
        setSearchQuery(e.target.value);
    }

    /**
     * Handles the key down event on the search input field. Executes the search when the Enter key is pressed.
     * @param {object} e - The key down event object.
     * @returns {void}
     */
    const handleEnterPress = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    }

    /**
     * Executes the search based on the provided search query, active start date, and filter type.
     * @returns {void}
     */
    const handleSearch = () => {
        setSelectedDate(null)
        setLoading(true)
        setIsSearching(true)
        const newInputFilteredLicitations =[...filteredLicitations]
        if (!searchQuery){
            setLoading(false)
            setInputFilteredLicitations(newInputFilteredLicitations)
            return;
        }
        else{
            const substrings = searchQuery.split(" ").filter(el => el !== "")

            setInputFilteredLicitations(newInputFilteredLicitations.filter((licitation) => {
                return (
                    substrings.some(substring => licitation[1].toLowerCase().includes(substring.toLowerCase())) || substrings.some(substring => licitation[5].toLowerCase().includes(substring.toLowerCase())) || substrings.some(substring => licitation[6].toLowerCase().includes(substring.toLowerCase()))
            )}))
            setLoading(false)
        }

        
    }  
    
    /**
     * A React useEffect hook that handles the logic for inserting dots into day tiles in the calendar.
     *
     * This effect is responsible for dynamically inserting dots into day tiles of the calendar based on the provided occurrences data. It creates and appends dot elements to the appropriate day tiles to visually highlight days with occurrences. It also manages the removal of previously added dots and updates the filtered licitations based on the selected filter type and active start date.
     *
     * The effect is triggered when the `activeStartDate` or `filterType` dependencies change, typically when the user interacts with the calendar or changes the filter type.
     *
     * @param {NodeList} tilesList - A list of day tiles in the calendar.
     * @param {Object} occurrences - An object containing occurrences data for specific days.
     * @returns {void}
     */
    useEffect(() => {
        if (!selectedDate){

            const insertIntoHtml = (tilesList, ocurrencies) => {
                const highlightTiles = []
                tilesList.forEach((dayTile) =>{
                    const day = dayTile.children[0].textContent
                    const licitationDot = document.createElement("div")
                    licitationDot.classList.add(`react-calendar-dot`, `${filterType === "creacion" ? 'red-dot' : 'blue-dot'}` )
                    const licitationDot2 = document.createElement("div")
                    licitationDot2.classList.add(`react-calendar-dot2`, `${filterType === "creacion" ? 'red-dot' : 'blue-dot'}`)
                    const licitationDot3 = document.createElement("div")
                    licitationDot3.classList.add(`react-calendar-dot3`, `${filterType === "creacion" ? 'red-dot' : 'blue-dot'}`)
                    let container = document.createElement("div")
                    container.classList.add("react-calendar-dots-container")
                    if (day in ocurrencies){
                        highlightTiles.push({
                            day: ocurrencies[day]
                        })
                        switch (ocurrencies[day]){
                            
                            case 1:
                                container.append(licitationDot)
                                dayTile.append(container)
                                break;
                            case 2: 
                                container.append(licitationDot)
                                container.append(licitationDot2)
                                dayTile.append(container)
                                break;
                            default:
                                container.append(licitationDot)
                                container.append(licitationDot2)
                                container.append(licitationDot3)
                                dayTile.append(container)
                                break;

                        }
                    }
                })
            }

            /* Clear active days */
            const removeActiveDays = () =>{
                const allContainers = document.querySelectorAll(".react-calendar-dots-container")
                allContainers.forEach((container) =>{
                    container.parentElement.removeChild(container)
                })

            }

            setLoading(true)
            setIsSearching(false)
            const currentMonth = ('0' + (activeStartDate.getMonth() + 1)).slice(-2)
            serviallAxios.get(`opportunities/filter_licitations_last_month/${filterType}/${currentMonth}`,{
                timeout: TIMEOUTS.long
            })
                .then((res) => {
                    setFilteredLicitations(res.data.latest_licitations)
                    setInputFilteredLicitations(res.data.latest_licitations)
                    const allTiles = document.querySelectorAll(".calendarioPage-calendar-tile")
                    removeActiveDays(allTiles)
                    insertIntoHtml(allTiles, res.data.dates_frequency)
                    setSelectedDate(null)
                })
                .catch((err) => {
                    dispatch(setAlert({
                        open: true,
                        severity: "error",
                        message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                    }));
                })
                .finally(() => {
                    setLoading(false)
                })
               
            } 
    }, [activeStartDate, filterType])

    /**
     * A React useEffect hook that fetches filtered licitations based on the selected date and filter type.
     *
     * The function is triggered whenever the `selectedDate` state changes.
     *
     * @returns {void}
     */
    useEffect(() => {
        if (!selectedDate) return
        setLoading(true)
        setIsSearching(false)
        const year = selectedDate.getFullYear().toString();
        const month = ('0' + (selectedDate.getMonth() + 1)).slice(-2)
        const day = ('0' + (selectedDate.getDate())).slice(-2)
        serviallAxios.get(`opportunities/filter_licitations_date/${day}/${month}/${year}/${filterType}`, {
            timeout: TIMEOUTS.medium
        })
            .then((res) => {
                setInputFilteredLicitations(res.data.opportunities_data)
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
            .finally(() => {
                setLoading(false)
            })

    }, [selectedDate])
    

    /**
     * A React useEffect hook that sets the "active" class to the ref `closeBtn` element once when the component mounts.
     *
     * The function runs only once after the component is mounted due to an empty dependency array ([]).
     * This ensures that the effect is applied only on the initial render and not on subsequent updates.
     *
     * @returns {void}
     */
    useEffect(() =>{
        closeBtn.current.classList.add("active")
    }, [])
    // Helper component for calendar
    const CalendarNavigation = () => {
        return (
            <Box sx={{ display: "flex", justifyContent: "space-between"}} flexDirection={{xs: "column", sm: "row"}} gap={{xs: 3}}>
                <Stack direction={{xs: "row"}} justifyContent={{xs: "space-between"}} gap={{sm: 1}}>
                    <Box display={"flex"} flexDirection={"row"} >
                        <Button onClick={() => { changeMonth(-1) }} className="calendarioPage-navigation-button calendarioPage-navigation-buttonGroup-left">
                            <KeyboardArrowLeftIcon />
                        </Button>
                        <Button onClick={() => { changeMonth(1) }} className="calendarioPage-navigation-button calendarioPage-navigation-buttonGroup-right">
                            <KeyboardArrowRightIcon />
                        </Button>
                    </Box>
                    <Button className="calendarioPage-navigation-button" onClick={selectToday}>
                        {t('CalendarioPage:TodayBtn')}
                    </Button>
                </Stack>
                <Stack direction={"row"} justifyContent={"flex-end"} gap={{xs: 2}}>
                <FormControl className="calendarioPage-navigation-button">
                        <Select
                            id="demo-simple-select-autowidth"
                            autoWidth
                            style={{ height: "100%", color: "var(--mui-palette-serviall-selectDate)" }}
                            defaultValue={activeStartDate.getMonth()}
                            onChange={(e) => {
                                const difference = e.target.value - activeStartDate.getMonth()
                                changeMonth(difference)
                            }}
                        >
                            <MenuItem value={0}>{t("CalendarioPage:Month1")}</MenuItem>
                            <MenuItem value={1}>{t("CalendarioPage:Month2")}</MenuItem>
                            <MenuItem value={2}>{t("CalendarioPage:Month3")}</MenuItem>
                            <MenuItem value={3}>{t("CalendarioPage:Month4")}</MenuItem>
                            <MenuItem value={4}>{t("CalendarioPage:Month5")}</MenuItem>
                            <MenuItem value={5}>{t("CalendarioPage:Month6")}</MenuItem>
                            <MenuItem value={6}>{t("CalendarioPage:Month7")}</MenuItem>
                            <MenuItem value={7}>{t("CalendarioPage:Month8")}</MenuItem>
                            <MenuItem value={8}>{t("CalendarioPage:Month9")}</MenuItem>
                            <MenuItem value={9}>{t("CalendarioPage:Month10")}</MenuItem>
                            <MenuItem value={10}>{t("CalendarioPage:Month11")}</MenuItem>
                            <MenuItem value={11}>{t("CalendarioPage:Month12")}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className="calendarioPage-navigation-button">
                        <Select
                            id="demo-simple-select-autowidth"
                            autoWidth
                            style={{ height: "100%", color: "var(--mui-palette-serviall-selectDate)" }}
                            defaultValue={activeStartDate.getFullYear()}
                            onChange={(e) => {
                                const difference = e.target.value - activeStartDate.getFullYear()
                                changeMonth(12 * difference)
                            }}
                        >
                            <MenuItem value={2019}>2019</MenuItem>
                            <MenuItem value={2020}>2020</MenuItem>
                            <MenuItem value={2021}>2021</MenuItem>
                            <MenuItem value={2022}>2022</MenuItem>
                            <MenuItem value={2023}>2023</MenuItem>
                            <MenuItem value={2024}>2024</MenuItem>
                            <MenuItem value={2025}>2025</MenuItem>
                            <MenuItem value={2026}>2026</MenuItem>
                        </Select>
                    </FormControl>

                </Stack>
            </Box>
        )
    }

    // Selected licitation info component
    // TODO: make this component via the requested prop once the bd model is done
    const LicitationDisplay = ({ licitationData }) => {
        console.log('licitationDATA', licitationData)
        const creation_date = new Date(licitationData[14]).toLocaleDateString('es-GB', { year: "numeric", month: "2-digit", day: "2-digit" }).toString()
        const end_date = new Date(licitationData[4]).toLocaleDateString('es-GB', { year: "numeric", month: "2-digit", day: "2-digit" }).toString()
        if (licitationData) {
            return (
                <Stack direction={"column"} width={"90%"} spacing={3} sx={{ overflowWrap: "break-word", marginBottom: "10px" }} className="calendarPage-filtered-licitations-container">
                    <Stack direction={"column"} spacing={1}>
                        <Typography> <ArrowRightIcon className="serviall-color-main" />ID {licitationData[2]}</Typography>
                        <Typography><b>{t('CalendarioPage:DescClient')}</b> {licitationData[5]}</Typography>
                        <Typography>{licitationData[6]}</Typography>
                    </Stack>

                    <Stack container direction={"row"} marginBottom={2} spacing={2} justifyContent={"space-between"}>
                        <Stack direction={"column"} width={"50%"}>
                            <Typography className="calendarioPage-typography-semibold">{t('CalendarioPage:DescOpType')}</Typography>
                            <Typography>{licitationData[3]}</Typography>
                        </Stack>
                        <Stack direction={"column"} width={"50%"}>
                            <Typography className="calendarioPage-typography-semibold">{t('CalendarioPage:DescCreationDate')}</Typography>
                            <Typography>{creation_date}</Typography>
                        </Stack>
                    </Stack>

                    <Stack direction={"row"} marginBottom={2} spacing={2} justifyContent={"space-between"}>
                        <Stack direction={"column"} width={"50%"}>
                            <Typography className="calendarioPage-typography-semibold">{t('CalendarioPage:DescLines')}</Typography>
                            <Typography>{licitationData[12]}</Typography>
                        </Stack>
                        <Stack direction={"column"} width={"50%"}>
                            <Typography className="calendarioPage-typography-semibold">{t('CalendarioPage:DescValue')}</Typography>
                            <Typography>{licitationData[21].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} marginBottom={2} spacing={2} justifyContent={"space-between"}>
                        <Stack direction={"column"} width={"50%"}>
                            <Typography className="calendarioPage-typography-semibold">{t('CalendarioPage:DescExec')}</Typography>
                            <Typography>{licitationData[19]}</Typography>
                        </Stack>
                        <Stack direction={"column"} width={"50%"}>
                            <Typography className="calendarioPage-typography-semibold">{t('CalendarioPage:DescCloseDate')}</Typography>
                            <Typography>{end_date}</Typography>
                        </Stack>

                    </Stack>
                    <Stack direction={"row"} marginBottom={2} spacing={2} justifyContent={"space-between"}>
                        <Stack direction={"column"} width={"50%"}>
                            <Typography className="calendarioPage-typography-semibold">{t('CalendarioPage:DescUnits')}</Typography>
                            <Typography>{licitationData[22]}</Typography>
                        </Stack>
                        <Stack direction={"column"} width={"50%"}>
                            <Typography className="calendarioPage-typography-semibold">{t('CalendarioPage:DescEffMatch')}</Typography>
                            <Typography>{licitationData[20]} %</Typography>
                        </Stack>

                    </Stack>
                    <Divider sx={{ borderBottomWidth: 3, backgroundColor: "#8f8f8f" }} />
                </Stack>
            )

        } else {
            return (
                <Box className="calendarioPage-no-date-selected-container">
                    <Typography className="calendarioPage-no-date-selected-typography">
                        {t('CalendarioPage:NoDesc')}
                    </Typography>
                </Box>
            )
        }
    }

    return (
        <Stack direction={"column"} spacing={4}>
            <Stack direction={"column"} spacing={4}>
                <Box>
                    <Typography className="serviall-page-title1">{t('CalendarioPage:Title')}</Typography>
                    <Typography className="serviall-page-title2">{t('CalendarioPage:Caption')}</Typography>
                </Box>
                <Box display={"flex"} flexDirection={{xs: "column", md:"row"}} gap={"1rem"} justifyContent={"space-between"} alignItems={"flex-end"}>
                    <ServiallInput
                        inputClassName={"serviall-input calendarioPage-search-input"}
                        placeholder={t('CalendarioPage:SearchPlaceholder')}
                        width={"400px"}
                        rightIcon={
                            <SearchIcon
                                sx={{ cursor: "pointer" }}
                                onClick={handleSearch}
                            />
                        }
                        onChange={handleSearchTextChange}
                        onKeyDown={handleEnterPress}
                    />
                    <Stack display={"flex"} flexDirection={{xs:"column", sm:"row"}} gap={"1rem"} width={{md: "60%"}}>
                        <Box width={{xs: "100%", sm:"45%", lg: "100%"}}>
                            <Button id="creacion" className="serviall-button calendarioPage-actions-button" sx={{height:"100%"}} onClick={(e) => handleActiveButton(e)} fullWidth>
                                {t('CalendarioPage:CreationDateBtn')}
                            </Button>
                        </Box>
                        <Box width={{xs: "100%", sm:"45%", lg: "100%"}} >
                            <Button ref={closeBtn} id="cierre" className="serviall-button calendarioPage-actions-button" sx={{height:"100%"}} onClick={(e) => handleActiveButton(e)} fullWidth>
                                {t('CalendarioPage:CloseDateBtn')}
                            </Button>
                        </Box>
                    </Stack>
                </Box>
                
                <Box>
                    <Typography className="serviall-page-title2">{filterType === "cierre" ? t("CalendarioPage:FilterTypeEndDateMsg") : t("CalendarioPage:FilterTypeCreationDateMsg")}</Typography>
                </Box>
            </Stack>
            <Grid display={"flex"} flexDirection={{xs: "column", md: "row"}}>
                <Grid item className="calendarioPage-calendar-container" width={{xs: "100%", md: "66%"}}>
                    <Stack direction={"column"} gap={4}>
                        <CalendarNavigation />
                        <Calendar
                            className={"calendarioPage-calendar"}
                            tileClassName={tileClassName}
                            onChange={onDateClick}
                            value={selectedDate}
                            activeStartDate={activeStartDate}
                            showNavigation={false}
                            locale={lang}
                            next2Label={null}
                            prev2Label={null}
                            tileContent={tileContent}
                            tileDisabled={tileDisabled}
                            view="month"
                        />
                    </Stack>
                </Grid>
                <Grid className="calendarioPage-grid-separator" />
                <Grid item width={{xs: "100%", md: "40%"}} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} style={{height: "430px", overflowY:"auto", paddingLeft:"10px"}}>
                    {selectedDate || filterType || isSearching ?
                        loading ? <ServiallLoading text={t("CalendarioPage:Loading")} /> :
                            inputFilteredLicitations.length > 0 ? inputFilteredLicitations.map((licitation) => {
                                return (
                                    <LicitationDisplay licitationData={licitation} />
                                )
                            }) :
                                <Box className="calendarioPage-no-date-selected-container">
                                    <Typography className="calendarioPage-no-date-selected-typography">
                                        {t('CalendarioPage:NoItems')}
                                    </Typography>
                                </Box>
                        :
                        <Box className="calendarioPage-no-date-selected-container">
                            <Typography className="calendarioPage-no-date-selected-typography">
                                {t('CalendarioPage:NoDesc')}
                            </Typography>
                        </Box>
                    }
                </Grid>
            </Grid>
        </Stack>
    )
}


CalendarioPage.displayName = 'CalendarioPage';

export default CalendarioPage;