import { Box } from "@mui/system";
import "./ServiallInput.css";
import { Typography } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';

/**
 * A React component for creating a serviall input.
 * @param {string} id - The id of the input.
 * @param {string} inputClassName - The class name of the input.
 * @param {string} placeholder - The placeholder of the input.
 * @param {string} defaultValue - The default value of the input.
 * @param {string} errorText - The error text of the input.
 * @param {string} type - The type of the input.
 * @param {string} maxLength - The max length of the input.
 * @param {string} label - The label of the input.
 * @param {string} caption - The caption of the input.
 * @param {string} margin - The margin of the input.
 * @param {string} value - The value of the input.
 * @param {function} onFocus - The function to call when the input is focused.
 * @param {function} onChange - The function to call when the input is changed.
 * @param {function} onKeyDown - The function to call when the input is key down.
 * @param {boolean} disabled - The disabled state of the input.
 * @param {string} width - The width of the input.
 * @param {string} rightIcon - The right icon of the input.
 * @param {string} style - The style of the input.
 * @param {boolean} fullWidth - The full width of the input.
 * @returns An input element.
 */

const ServiallInput = ({
    id,
    inputClassName,
    placeholder,
    defaultValue,
    errorText,
    type,
    maxLength,
    label,
    caption,
    margin,
    value,
    onFocus,
    onChange,
    onKeyDown,
    disabled,
    width,
    rightIcon,
    style,
    fullWidth }) => {
    return (
        <Box sx={{ margin: margin, display: "flex", flexDirection: "column", justifyContent:"space-between", gap: 1.5}} width={{xs: "100%", tablet: fullWidth ? "100%" : "45%" ,lg: fullWidth ? "100%" : width}} position={"relative"} >
            <Box>
                {label && <Typography className="serviall-input-label">{label}</Typography>}
                {caption && <Typography className="serviall-input-caption">{caption}</Typography>}
            </Box>
            
            <Box sx={{ display: "flex", alignItems: "center"}}>
                <input
                    id={id}
                    className={inputClassName}
                    placeholder={placeholder}
                    type={type}
                    label={label}
                    defaultValue={defaultValue}
                    value={value}
                    onFocus={onFocus}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    autoComplete="off"
                    disabled={disabled}
                    style={style}
                    maxLength={maxLength}
                />
                {rightIcon &&
                    <Box className={"serviall-icon-right"}>
                        {rightIcon}
                    </Box>
                }
            </Box>
            {errorText && <Typography className="serviall-input-error" position={"absolute"} bottom={"-20px"} right={"5px"}><ErrorIcon fontSize="inherit" /> {errorText}</Typography>}
        </Box>
    )
}

ServiallInput.displayName = "ServiallInput";

export default ServiallInput;