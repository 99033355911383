import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'
import { Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setActiveCols } from '../features/displaySlice';
import { init } from 'i18next';

/**
 * getStyles Function
 *
 * Generates and returns an inline style object for controlling the font weight of an element based on conditions.
 *
 * @param {string} name - The name to be used for comparison.
 * @param {Array} personName - An array of person names to compare against.
 * @param {Object} theme - The theme object containing typography styles.
 *
*/

function getStyles(name, personName, theme) {
  return {
    backgroundColor:
      personName.indexOf(name) === -1
        ? "var(--mui-palette-background-default)"
        : "#D2D2D2",
    fontWeight:
      personName.indexOf(name) === -1
        ? "400"
        : "600",
  };
}

/**
 * 
 * @component
 * @param {Function} props.setDatagridCols - A function to set the selected columns for the data grid.
 * @param {Array} props.initialState - An array of objects representing the initial state of available columns.
 * @returns {JSX.Element} ColumnSelector component
 *
 */

const ColumnSelector = ({ setDatagridCols, initialState, page, render = false }) => {

  const dispatch = useDispatch();
  const theme = useTheme();
  const selectedColsRedux = useSelector((state) => state.display[page].activeCols)
  const [selectedColumns, setSelectedColumns] = useState(selectedColsRedux.length > 0 ? selectedColsRedux : initialState.map(obj => obj.key))
  const lang = useSelector((state) => state.settings.language);
  const { t } = useTranslation()

  /**
  *
  * Event handler function for handling changes in the selection of columns.
  *
  * @param {Object} event - The event object representing the change event.
  * @returns {void}
  *
  */
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedColumns(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  /**
   * useEffect for Setting Initial Keys
   *
   * An effect that sets the initial keys by mapping the 'name' property from the 'initialState' array
   * and attaches an error event listener to hide specific warnings.
   *
   * @effect
   * @returns {void}
   *
   */
  useEffect(() => {
    if (selectedColsRedux.length < 27) {
      setSelectedColumns(selectedColsRedux)
    } else {
      let val = initialState.map(obj => {
        if (
          obj.key != "desc_complementaria" &&
          obj.key != "marca_cliente" &&
          obj.key != "pn_cliente" &&
          obj.key != "um" &&
          obj.key != "consumo_anual" &&
          obj.key != "pn_match" &&
          obj.key != "criticidad_sa" &&
          obj.key != "um_match" &&
          obj.key != "cantidad_empaque" &&
          obj.key != "factor_equivalencia_cte" &&
          obj.key != "plazo_entrega" &&
          obj.key != "seleccion" &&
          obj.key != "match_proveedor" &&
          obj.key != "sku_proveedor" &&
          obj.key != "no_match"
        ) {
          return obj.key
        }
      })
      val = val.filter(e => e != undefined)
      setSelectedColumns(val);
    }
    /* Hide 'ResizeObserver loop completed with undelivered notifications.' warning */
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.' || e.message === 'Script error.') {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    })
  }, [initialState])

  useEffect(() => {
    setSelectedColumns(selectedColsRedux.length > 0 ? selectedColsRedux : initialState.map(obj => obj.key))
  }, [render])
  /**
  * useEffect for Resetting Selected Columns
  *
  * An effect that resets the 'selectedColumns' state to an empty array when the 'lang' property changes.
  *
  * @effect
  * @returns {void}
  *
  */
  useEffect(() => {
    setSelectedColumns(selectedColsRedux.length > 0 ? selectedColsRedux : initialState.map(obj => obj.key))
  }, [lang])

  /**
   * useEffect for Filtering and Updating Data Grid Columns
   *
   * An effect that filters the 'initialState' based on the selected columns and updates the data grid columns using 'setDatagridCols'.
   *
   * @effect
   * @returns {void}
   *
   */
  useEffect(() => {
    const newArray = initialState.filter((obj) => selectedColumns.includes(obj.key))

    if (page) {
      dispatch(setActiveCols({
        page: page,
        value: selectedColumns
      }))
    }

    setDatagridCols(newArray)
  }, [selectedColumns])

  return (
    <Box className="serviall-column-selector">
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"space-between"} width={{ xs: "100%", sm: "300px" }}>
        <Typography width={"100%"} className='serviall-page-title2'>{t("Common:ShowColumns")}</Typography>

        <FormControl sx={{ width: "100%" }} width={"100%"}>
          <Select
            notched={false}
            color='error'
            label=""
            displayEmpty
            className='serviall-input'
            sx={{ height: "51.6px" }}
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={selectedColumns}
            renderValue={(p) => p.length}
            onChange={handleChange}
          >
            {initialState.map((state) => (
              <MenuItem
                key={state.key}
                value={state.key}
                style={getStyles(state.key, selectedColumns, theme)}
              >
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

    </Box>

  )
}

export default ColumnSelector
