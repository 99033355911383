import { createSlice } from "@reduxjs/toolkit";

// permissionSlice
// En este slice manejar estados respecto a permisos sobre usuarios y/o componentes.

export const permissionsSlice = createSlice({
    name: "permission",
    initialState: {
        group: "",
        componentPermissions: {
            // Poner la lista de componentes que cambiarán dependiendo de la permisiologia
            // permissions: read, write, admin, none
            Dashboard: {
                ToggleButton: {
                    optionInicio: "hidden",
                    optionNuevaOportunidad: "hidden",
                    optionNuevaOportunidadMasiva: "hidden",
                    optionGestorOportunidades: "hidden",
                    optionCalendario: "hidden",
                    optionMaestroProveedores: "hidden",
                    optionMaestroClientes: "hidden",
                }
            },
            HomePage: {
                Box: {
                    homeCardContainer: "hidden"
                }
            },
            NuevaOportunidadPage: {
                Box: {
                    inputContainer: "hidden",
                    selectContainer: "hidden",
                    uploadButtonContainer: "hidden"
                }
            },
            GestorOportunidadesPageDatagrid: {
                ServiallActionsMenu: {
                    writeActions: "hidden"
                },
                DraggableDataGrid: {
                    serviallDataGrid: "hidden"
                }
            },
            GestorOportunidadesPage: {
                Button: {
                    downloadExcelButton: "hidden"
                }
            },
            MaestroProveedoresPage: {
                Typography: {
                    addProviderLink: "hidden"
                }
            },
            MaestroClientesPage: {
                Typography: {
                    addClientLink: "hidden"
                }
            },
            ConfiguracionPage: {
                AccordionConfigSelector: {
                    optionMantenedores: "hidden"
                },
                SubConfigPageSelector: {
                    optionPermisos: "hidden"
                },
                AccordionItem: {
                    optionMantenedoresAuxItems: "hidden",
                    optionMantenedoresVersiones: "hidden"
                }
            }
        },
        groupPermissions: {
            // Poner lista de grupos de usuarios a los que tendrá acceso el usuario
            groupX: "none"
        }
    },
    reducers: {
        setGroup: (state, action) => {
            state.group = action.payload;
        },
        setComponentPermissions: (state, action) => {
            state.componentPermissions = action.payload;
        },
        setComponentPermission: (state, action) => {
            state.componentPermissions[action.payload.component] = action.payload.value;
        },
        setGroupPermissions: (state, action) => {
            state.groupPermissions = action.payload;
        },
        setGroupPermission: (state, action) => {
            state.groupPermissions[action.payload.group] = action.payload.value;
        }
    }
})

export const {
    setGroup,
    setComponentPermissions,
    setComponentPermission,
    setGroupPermissions,
    setGroupPermission
} = permissionsSlice.actions;

export default permissionsSlice.reducer;