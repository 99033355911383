import { useState, useMemo, useCallback, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DataGrid from 'react-data-grid';
import { DraggableHeaderRenderer } from './DraggableHeaderRenderer';
import 'react-data-grid/lib/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import DatagridPagination from './DatagridPagination';
import { addColWidth } from '../features/displaySlice';

/**
 * Function that convert an string to a Date format string.
 * @param {string} str - the string to convert into a Date.
 * @returns a Date
 */
function stringToDate(str) {
  if (str) {
    const [dd, mm, yyyy] = str.split('/');
    return new Date(yyyy, mm - 1, dd);
  }

}

/**
 * A component to render a datagrid.
 * @param {array} cols - the columns of the datagrid.
 * @param {array} rows - the rows of the datagrid.
 * @param {number} headerRowHeight - the height of the header row.
 * @param {string} rowClass - the class of the row.
 * @param {function} rowKeyGetter - the function to get the row key.
 * @param {object} initialState - the initial state of the filters.
 * @param {array} responsibles - the responsibles of the datagrid.
 * @returns a datagrid.
 */
function DraggableDataGrid({ cols, rows, headerRowHeight, rowClass, rowKeyGetter, responsibles, filters, setFiltersAction, page, pageNum, rowsPerPage, handleChangeRowsPerPage, handlePageChange, count }) {

  // Constants
  const COL_BASE_WIDTH = 200;
  const ROW_OFFSET = 35;

  const dispatch = useDispatch();
  const storedColWidths = useSelector((state) => state.display[page].widths);
  const [columns, setColumns] = useState(cols);
  const [sortColumns, setSortColumns] = useState([]);

  /**
   * Handle the change event for sorting columns in the datagrid.
   *
   * @param {array} sortColumns - The array of columns with sorting information.
   * @returns {void}
   */
  const onSortColumnsChange = useCallback((sortColumns) => {
    setSortColumns(sortColumns.slice(-1));
  }, []);

  /**
   * A hook that runs after the component renders and sets the columns when cols prop changes.
   *
   * @returns {void}
   */
  useEffect(() => {
    setColumns(cols)
  }, [cols])

  /**
   * Handle the change event for the filters and update the filters object.
   *
   * @param {string} key - The key of the filter being updated.
   * @param {string|array} value - The new value of the filter.
   * @returns {void}
   */
  const handleFilterChange = (key, value) => {
    if (key.includes("fecha")) {
      if (value) {
        dispatch(setFiltersAction({
          page: page,
          key: key,
          value: [value[0].toLocaleDateString('es-GB', { year: "numeric", month: "2-digit", day: "2-digit" }), value[1].toLocaleDateString('es-GB', { year: "numeric", month: "2-digit", day: "2-digit" })]
        }));
        // setFilters((prevState) => { return ({ ...prevState, [key]: [value[0].toLocaleDateString('es-GB', { year: "numeric", month: "2-digit", day: "2-digit" }), value[1].toLocaleDateString('es-GB', { year: "numeric", month: "2-digit", day: "2-digit" }),] }) })
        return

      } else {
        dispatch(setFiltersAction({
          page: page,
          key: key,
          value: ""
        }));
        // setFilters((prevState) => { return ({ ...prevState, [key]: "" }) })
        return

      }
    }
    dispatch(setFiltersAction({
      page: page,
      key: key,
      value: value
    }));
    // setFilters((prevState) => { return ({ ...prevState, [key]: value }) })
  }


  const draggableColumns = useMemo(() => {


    /**
     * Custom header renderer for draggable datagrid columns.
     *
     * @param {object} props - The properties passed to the header renderer.
     * @returns {ReactElement} The custom header renderer for draggable columns.
     */
    function headerRenderer(props) {
      return <DraggableHeaderRenderer  {...props} onColumnsReorder={handleColumnsReorder} onFilterChange={handleFilterChange} filterIsVisible={true} responsibles={responsibles ? responsibles : []}>
      </DraggableHeaderRenderer>;
    }

    /**
     * Handle the reordering of draggable columns in the datagrid.
     *
     * @param {string} sourceKey - The key of the column being dragged.
     * @param {string} targetKey - The key of the column being dragged to.
     * @returns {void}
     */
    function handleColumnsReorder(sourceKey, targetKey) {
      const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
      const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
      const reorderedColumns = [...columns];

      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );

      setColumns(reorderedColumns);
    }

    return columns.map((c) => {
      if (c.key === 'id') return c;
      if (c.key === "status") {
        c.filterValue = filters["status_str"];
      } else {
        c.filterValue = filters[c.key];
      }
      return (
        { ...c, headerRenderer })
    });
  }, [columns, filters]);

  /**
   * Generate and memoize sorted rows based on the sorting columns in the datagrid.
   *
   * @returns {array} An array of sorted rows.
   */
  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) return rows;
    const { columnKey, direction } = sortColumns[0];

    let sortedRows = [...rows];
    sortedRows = sortedRows.sort((a, b) => {
      if (columnKey === "fecha_cierre" || columnKey === "fecha_creacion" || columnKey === "fecha_actualizacion" || columnKey === "fecha_edicion") {
        let aa = a[columnKey].split('/').reverse().join(),
          bb = b[columnKey].split('/').reverse().join();
        return aa < bb ? -1 : (aa > bb ? 1 : 0);
      }
      else if (typeof a[columnKey] === "number") {
        return a[columnKey] - b[columnKey];

      }
      return a[columnKey]?.localeCompare(b[columnKey])
    });

    return direction === 'DESC' ? sortedRows.reverse() : sortedRows;
  }, [rows, sortColumns]);

  /**
   * Generate and memoize filtered rows based on the filters in the datagrid.
   *
   * @returns {array} An array of filtered rows.
   */
  const filteredRows = sortedRows.filter((row) => {
    let accept = true;
    Object.keys(filters).forEach((key) => {
      if (row[key]) {
        if (key.includes("fecha")) {
          const row_date = stringToDate(row[key])
          const from_date = stringToDate(filters[key][0])
          const to_date = stringToDate(filters[key][1])
          accept *= filters[key] ? row_date >= from_date && row_date <= to_date : true
        } else if (key.includes("estado_revision") && row[key].props) {
          accept *= (filters[key] ? row[key].props.value.toString().toLowerCase().includes(filters[key].toLowerCase()) : true);
        }
        else {
          accept *= (filters[key] ? row[key].toString().toLowerCase().includes(filters[key].toLowerCase()) : true);
        }
      }
      else {
        /* If row[key] is empty and filters[key] has values, then exclude the row */
        if (filters[key]) {
          accept = false
        }
      }
    })
    return Boolean(accept)
  })

  /**
   * Calculate the width of each column in the datagrid based on the content of the rows.
   *
   * @returns {object} An object containing the calculated width for each column.
   */
  const getColLengths = () => {
    if (!rows) return;
    let colWidths = {}
    cols.forEach((c) => {
      colWidths[c.key] = 0;
    })
    // Get max row length
    rows.forEach(row => {
      Object.keys(row).forEach(key => {
        if (!key.includes("select") && key !== "actions") {
          if (row[key]) {
            /* Put a maximum width, so rows don't extend too much */
            if (row[key].toString().length >= 10) {
              colWidths[key] = 3;
            }
            else {
              if (row[key].toString().length > colWidths[key]) colWidths[key] = row[key].toString().length;
            }

          }
        }
      })
    })

    // Get col widths based on row size
    // Multiply each value by a constant to put them on the datagrid
    Object.keys(colWidths).forEach(key => {
      if (key === 'itemsLicitationIndex' || key === "redirect") {
        colWidths[key] = 0
      }
      else {
        colWidths[key] *= ROW_OFFSET;
        if (colWidths[key] < COL_BASE_WIDTH) {
          colWidths[key] = COL_BASE_WIDTH;
        }
      }
    })

    return colWidths;
  }


  const colLengths = getColLengths(rows);

  /**
   * Generate and memoize columns with auto-calculated widths based on the content of the rows.
   *
   * @returns {array} An array of columns with calculated widths.
   */
  const autoWidthCols = draggableColumns.map((c, idx) => {
    if (storedColWidths[idx]) {
      c.width = storedColWidths[idx];
    } else {
      c.width = colLengths[c.key];
    }
    return c
  })

  const pageRows = pageNum !== undefined && rowsPerPage !== undefined ? filteredRows.slice(pageNum * rowsPerPage, (pageNum + 1) * rowsPerPage) : filteredRows
  const removeDuplicates = pageRows.filter((v, i, a) => v.id ? a.findIndex(v2 => (v2.id === v.id)) === i : v)

  return (
    <DndProvider backend={HTML5Backend}>
      <div className='serviall-datagrid-container'>
        <DataGrid
          className='serviall-datagrid'
          columns={autoWidthCols}
          rows={removeDuplicates}
          sortColumns={sortColumns}
          onSortColumnsChange={onSortColumnsChange}
          toggleFilters={true}
          headerRowHeight={headerRowHeight}
          rowClass={rowClass}
          rowKeyGetter={rowKeyGetter}
          onColumnResize={(id, width) => {
            dispatch(addColWidth({
              page: page,
              id: id,
              width: width
            }))
          }}
        />
        {
          (rowsPerPage !== undefined && pageNum !== undefined) &&
          <DatagridPagination
            rows={filteredRows}
            page={pageNum}
            count={count}
            handlePageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage} />
        }
      </div>
    </DndProvider>
  )
}

DraggableDataGrid.displayName = 'DraggableDataGrid';

export default DraggableDataGrid
