import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, ListItem, List, ListItemText, Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ListAltIcon from '@mui/icons-material/ListAlt';

/** 
* Icon that when clicked shows info on a dialog.
* @param {Object} item_data - Object with data to be rendered in the dialog.
* @return {React.Component} The react component.
*/
const ItemsAdicionalesDialog = ({ item_data }) => {

    const [open, setOpen] = useState(false);
    const {t} = useTranslation()
    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "center", marginTop:"5px", cursor: "pointer" }}>
                <ListAltIcon onClick={() => { setOpen(true) }} color="serviall-color-main" />
            </Box>
            <Dialog
                open={open}
                onClose={() => { setOpen(false) }}
            >
                <DialogTitle>
                    <Typography className="serviall-h5 serviall-page-title2-alt">
                        {t("Common:ObjectDetail")}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <List dense={true}>
                        {
                            Object.keys(item_data).map((key) =>
                                <ListItem>
                                    <ListItemText
                                        primary={`${key}:${item_data[key]}`}
                                    />
                                </ListItem>
                            )
                        }
                    </List>
                </DialogContent>
                <DialogActions style={{ marginBottom: "6px" }}>
                    <Button
                        onClick={() => setOpen(false)}
                        className="serviall-button-error"
                    >
                        {t("Common:CloseBtn")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ItemsAdicionalesDialog;