import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import "./ServiallLoading.css";

/**
 * A React component to display a loading screen while the app is loading.
 * @param {string} text - The text to display in the loading screen.
 * @returns A component to display a loading screen while the app is loading.
 */

const ServiallLoading = ({ text }) => {
    return (
        <Box className="serviall-loading-container" >
            <Stack direction={"column"} alignItems={"center"} spacing={2}>
                <Typography textAlign={{xs: "center"}} className="serviall-h4">{text}</Typography>
                <CircularProgress />
            </Stack>
        </Box>
    )
}

ServiallLoading.displayName = "ServiallLoading";

export default ServiallLoading;