import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import "./EditMastersItemDialog.css";
import ServiallLoading from "./ServiallLoading";
import { useTranslation } from 'react-i18next';
/**
 * A React component that renders a form to allow delete items in MAestro Clientes / Proveedores.
 * @param {boolean} dialogOpen - Determines if the dialog is open or not.
 * @param {boolean} loading - Determines if the dialog is loading or not.
 * @param {string} variant - Determines if the dialog is for clientes or proveedores.
 * @param {object} formValues - The values of the form.
 * @param {function} handleClose - Function to close the dialog.
 * @param {function} handleSave - Function to save the form.
 * @returns a form component
 */

const DeleteMastersItemDialog = ({ dialogOpen, loading, variant, formValues, handleClose, handleSave }) => {
    // variant values = clientes || proveedores
    const INPUT_SPACING = 3
    const {t} = useTranslation()
    let fields =
        variant === "proveedores" ?
            <Stack direction={"column"} spacing={INPUT_SPACING}>
                {t("Common:DeleteMessage1")} {formValues.sku_proveedor}?
            </Stack>
            :
            <Stack direction={"column"} spacing={INPUT_SPACING}>
                {t("Common:DeleteMessage1")} {formValues.sku_client}?
            </Stack>


    return (
        <Dialog
            open={dialogOpen}
        >
            <DialogTitle>
                <Typography className="EditMasters-dialog-title">
                {t("Common:DeleteMessage2")} {variant}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {
                    loading ?
                        <ServiallLoading
                            text={"Cargando objeto"}
                        />
                        :
                        fields
                }
            </DialogContent>
            {
                !loading &&
                <DialogActions>
                    <Button
                        className="serviall-button-success"
                        onClick={handleSave}
                    >
                        {t("Common:AcceptBtn")}
                    </Button>
                    <Button
                        className="serviall-button-error"
                        onClick={handleClose}
                    >
                       {t("Common:CancelBtn")}
                    </Button>
                </DialogActions>
            }
        </Dialog>
    )
}

DeleteMastersItemDialog.displayName = "DeleteMastersItemDialog";

export default DeleteMastersItemDialog;