import "./MaestroProveedoresPage.css";
import { Box } from "@mui/system";
import { Button, Stack, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as PlusIcon } from '../../assets/icons/mas.svg';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArchivoLogo } from "../../assets/icons/archivo.svg";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ListIcon from '@mui/icons-material/List';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import ServiallActionsMenu from "../../components/ServiallActionsMenu";
import 'react-data-grid/lib/styles.css';
import MaestroProveedoresPageDatagrid from "./MaestroProveedoresDatagridConfig";
import { useEffect, useState } from "react";
import serviallAxios from "../../axiosConfig";
import ServiallLoading from "../../components/ServiallLoading";
import { TIMEOUTS } from "../../utils";
import { setAlert } from "../../features/navigationSlice";
import ResetFiltersBtn from "../../components/ResetFiltersBtn";
import ActiveInfoDialog from "../../components/ActiveInfoDialog";
import { CustomAlertMessage } from "../../utils";


/**
 * A React component that renders the 'Maestro de proveedores' page.
 * @typedef MaestroProveedoresPage
 * @returns A page with the Maestro de proveedores page and components
 */

const MaestroProveedoresPage = () => {
    const maestroProveedoresPermissions = useSelector((state) => state.permissions.componentPermissions.MaestroProveedoresPage);
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const lang = useSelector((state) => state.settings.language);
    const [redirectRoute, setRedirectRoute] = useState("");
    const [redirectState, setRedirectState] = useState({});
    const [open, setOpen] = useState(false)
    const[actualProvider, setActualProvider] = useState({
        rut: "",
        name: "",
        website: ""
    });  

    useEffect(() => {
        fetchProvidersDatagridRows();
    }, [loading])

    useEffect(() => {
        setLoading(true)
    }, [lang])

    useEffect(() => {
        if(redirectRoute.length > 0){
            navigate(redirectRoute, {state: redirectState})
        }
    }, [redirectRoute])

    /**
     * A function that transforms the data to a format suitable for the datagrid.
     * @function
     * @param {string|number} id - The unique identifier for the row.
     * @param {string} id_proveedor - The ID of the provider.
     * @param {string} proveedor - The name of the provider.
     * @param {string} sitio_web - The provider's website.
     * @param {string} fecha_actualizacion - The date of the last update.
     * @param {string|number} sku_cargados - The number of loaded SKUs.
     * @param {string|number} calidad_carga - The quality of the load.
     * @param {string|number} sku_match - The number of matched SKUs.
     * @param {string|number} apariciones - The number of appearances.
     * @param {string|ReactNode} actions - The actions available for the row.
     * @returns {Object} An object with the transformed row data.
     */
    const makeData = (
        id,
        id_proveedor,
        proveedor,
        sitio_web,
        fecha_actualizacion,
        sku_cargados,
        calidad_carga,
        sku_match,
        apariciones,
        actions
    ) => {
        return { id, id_proveedor, proveedor, sitio_web, fecha_actualizacion, sku_cargados, calidad_carga, sku_match, apariciones, actions }
    }

    /**
     * A function that fetches data for the datagrid rows on the 'Maestro de proveedores' page.
     */
    const fetchProvidersDatagridRows = () => {
        if (loading === true){
            serviallAxios.get("/masters/providers", {
                timeout: TIMEOUTS.medium
            })
            .then(res => {
                // data has shape 
                // id, rut, name, website, version, created_at, updated_at
                let rows = res.data.clients_data.map((row, idx) => makeData(idx, row[1], row[2], row[3], row[7] ? (new Date(row[7]).toLocaleDateString('es-GB', {year: "numeric", month: "2-digit", day: "2-digit"})).toString() : "", row[10].toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."), row[11], row[12], row[13], makeRowActions({
                    rut: row[1],
                    name: row[2],
                    website: row[3],
                    updated_at: row[7] ? (new Date(row[7]).toLocaleDateString('es-GB', {year: "numeric", month: "2-digit", day: "2-digit"})).toString() : "",
                    current_version: row[5],
                })))
                setRows(rows);
            })
            .catch(err => {
                console.log(err )
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
            .finally(() => { setLoading(false) })
            
        }
    }

    /**
     * A function that handles the navigation to the 'Nuevo Proveedor' page.
     */
    const handleNuevoProveedor = () => {
        setRedirectRoute("/dashboard/nuevo_proveedor");
    }

    /**
     * A function that handles the download of all providers' data as an Excel file.
     */

    const handleDownload = () => {
        
        dispatch(setAlert({
            open: true,
            severity: "info",
            message: t("Common:Download")
        }))
        
        serviallAxios.get("masters/all_providers_sheet", { 
            timeout: TIMEOUTS.small,
            responseType: "blob" 
        }).then((res) => {
            const href = URL.createObjectURL(res.data);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", "all_providers.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
        .catch((err) => {
            
            dispatch(setAlert({
                open: true,
                severity: "error",
                message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
            }))
        })
    }

    /**
     * A function that handles the download of a specific provider's data as an Excel file.
     * @function
     * @param {string} rut - The ID of the provider for which data should be downloaded.
     */
    const handleDownloadSpecificProvider = (rut) => {

        dispatch(setAlert({
            open: true,
            severity: "info",
            message: t("Common:Download")
        }))

        serviallAxios.get(`masters/provider_sheet/${rut}`, { 
            timeout: TIMEOUTS.small,
            responseType: "blob" 
        }).then((res) => {
            const href = URL.createObjectURL(res.data);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", `proveedor_${rut}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
        .catch((err) => {

            dispatch(setAlert({
                open: true,
                severity: "error",
                message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
            }))
        })
    }

    const handleClose = () => {
        setOpen(false)
    }
    const checkIsProcessed = (params) => {
        serviallAxios.get(`masters/masters_provider_processed/${params.rut}`, { 
            timeout: TIMEOUTS.small,
        }).then((res) => {
            if (!res.data.is_processing){
                setRedirectRoute("/dashboard/detalle_homologo_proveedor/" + params.rut);
                setRedirectState(params);
            }
            else{
                const newProvider = {
                    rut: params.rut,
                    name: params.name,
                    website: params.website
                }
                setActualProvider(newProvider)
                setOpen(true)
            }
        })
        .catch((err) => {
            dispatch(setAlert({
                open: true,
                severity: "error",
                message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
            }));
        })
    }

   

    /**
     * A function that creates actions menu for a row in the datagrid.
     * @param {Object} params - The parameters required to create actions for a row.
     * @returns {ReactNode} A component containing the actions menu for the row.
     */
    const makeRowActions = (params) => {
        const actions = [
            {
                key: "verDetalle" + params.id,
                icon: <ArchivoLogo fill="currentColor" className="maestroProveedores-action-icon" />,
                text: t("MaestroProveedoresPage:DataGridAction1"),
                onClick: () => {
                    setRedirectRoute("/dashboard/detalle_maestros_proveedor/");
                    setRedirectState(params);
                }
            },
            {
                key: "verDetalleHomologo" + params.id,
                icon: <HandymanOutlinedIcon />,
                text: t("MaestroProveedoresPage:DataGridAction3"),
                onClick: () => {
                    checkIsProcessed(params)
                    
                }
            },
            {
                key: "descargarExcel1" + params.id,
                icon: <SystemUpdateAltIcon />,
                text: t("MaestroProveedoresPage:DataGridAction2"),
                onClick: () => {handleDownloadSpecificProvider(params.rut)}
            }
        ]

        if (params) {
            return <ServiallActionsMenu
                menu={<ListIcon className='maestroProveedores-datagrid-action-icon' />}
                actions={actions}
            />
        }
        else {
            return ""
        }
    }

    return (
        <Box>
           <ActiveInfoDialog open={open} field1={actualProvider.rut} field2={actualProvider.name} field3={actualProvider.website} handleClose={handleClose} type={"active_master"}/>
            <Stack direction={"column"} marginBottom={4}>
                <Typography className="serviall-page-title1">{t("MaestroProveedoresPage:Title")} </Typography>
                <Box display={"flex"} flexDirection={{xs: "column", sm: "row"}} sx={{overflowWrap:"break-word"}}>
                    <Typography className="serviall-page-title2">
                        {t("MaestroProveedoresPage:Caption2")}
                    </Typography>
                </Box>
            </Stack>
            <Box display={"flex"} flexDirection={{xs :"column", sm: "row"}} gap={{xs: 2}} justifyContent={{sm: "space-between"}} marginBottom={4}>
                {maestroProveedoresPermissions.Typography.addProviderLink === "hidden" ?
                    <Typography></Typography>
                    :
                    <Typography
                        id="addProviderLink"
                        className='maestroProveedores-actions-nuevoProveedor 
                                   MaestroProveedoresPage-Typography-permi 
                                   desc-Add_master_provider_page_link'
                        onClick={handleNuevoProveedor}
                    >
                        {/* NOTE: the following typography causes a warning: p cannot appear as descendant of p */}
                        <Typography className="serviall-main-color">
                            <PlusIcon fill="currentColor" className="maestroProveedores-plus-icon" />
                        </Typography>
                        &nbsp; {t("MaestroProveedoresPage:Subtitle")}
                    </Typography>
                }
                <Box display={"flex"} gap={4}>
                    <Typography
                            id="downloadExcelButton"
                            className="GestorOportunidadesPage-Button-permi
                                desc-Download-excel-button
                                serviall-page-caption
                                gestorOportunidades-download-button"
                            onClick={() => handleDownload()}
                        >
                            {t("MaestroProveedoresPage:Download")} <SystemUpdateAltIcon />
                    </Typography>
                    <ResetFiltersBtn page={"maestroProveedores"}/>
                </Box>

            </Box>
            {
                loading ?
                    <ServiallLoading text={t("MaestroProveedoresPage:LoadingStatus")} />
                    :
                    rows.length > 0 ?
                    <>
                    <MaestroProveedoresPageDatagrid
                            rows={rows}
                        />
                    </>
                        :
                        <Box className="serviall-datagrid-loading-container">
                            <Typography className="serviall-h4">{t("MaestroProveedoresPage:NoProviders")}</Typography>
                        </Box>
            }
        </Box >
    )
}

MaestroProveedoresPage.displayName = "MaestroProveedoresPage";

export default MaestroProveedoresPage;