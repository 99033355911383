import { useTranslation } from "react-i18next";
import "./ConfiguracionMantenedores.css";

/**
 * A React component that renders the 'Mantenedores' page.
 * @typedef ConfiguracionMantenedores
 * @returns A page with the Mantenedores page components
 */
const ConfiguracionMantenedores = () => {
    const {t} = useTranslation()
    return <div>{t("MantenedoresMenu:Title")}</div>
}

ConfiguracionMantenedores.displayName = "ConfiguracionMantenedores";

export default ConfiguracionMantenedores;