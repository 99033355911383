import React, { useState, useEffect } from 'react'
import { Box, Typography, Stack, CircularProgress, Button, DialogTitle, DialogContent, DialogActions, Dialog, MenuList, MenuItem, Switch, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ServiallInput from '../../components/ServiallInput'
import serviallAxios from "../../axiosConfig";
import "./ConfiguracionGrupos.css"
import { TIMEOUTS } from '../../utils';
import EditIcon from "@mui/icons-material/Edit"
import { useDispatch, useSelector } from 'react-redux';
import DraggableDataGrid from '../../components/DraggableDataGrid';
import { setFilter, setRowsPerPageConfiguracionGrupos } from '../../features/displaySlice';
import ResetFiltersBtn from '../../components/ResetFiltersBtn';
import { setAlert } from '../../features/navigationSlice';
import { CustomAlertMessage } from '../../utils';
import CloseIcon from '@mui/icons-material/Close';

/**
 * React component for configuring permission groups.
 *
 * This component allows users to view, add, edit, and delete permission groups.
 * It provides an interface for managing groups, and utilizes Redux for state management.
 * Fetches group data from the server using Axios requests.
 *
 * @component
 * @returns {JSX.Element}
 */

const ConfiguracionGrupos = () => {
    const { t } = useTranslation()
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingDialog, setLoadingDialog] = useState(false)
    const [wasFocused, setWasFocused] = useState(false)
    const [newGroup, setNewGroup] = useState({
        group_name: "",
        group_desc: ""
    })
    const dispatch = useDispatch();
    const displayData = useSelector((state) => state.display.configuracionGrupos);

    const [page, setPage] = useState(0);
    const rowsPerPage = displayData.rowsPerPage;
    const [actualGroup, setActualGroup] = useState({
        id: "",
        name: "",
        description: ""
    })
    const [dialogOpen, setDialogOpen] = useState(false)

    const [users, setUsers] = useState([])


    /**
     * Handles the change of the current page in the data grid pagination.
     * Updates the current page index with the newly selected page.
     *
     * @function handlePageChange
     * @param {Object} e - The event object representing the change event.
     * @param {number} newPage - The index of the newly selected page.
     * @returns {void}
     */
    const handlePageChange = (e, newPage) => {
        setPage(newPage);

    }


    /**
     * Handles the change of rows per page in the data grid.
     * Dispatches an action to update the rows per page value in the Redux store.
     * Resets the current page index to 0 to ensure consistent pagination behavior.
     *
     * @function handleChangeRowsPerPage
     * @param {Object} e - The event object representing the change event.
     * @returns {void}
     */
    const handleChangeRowsPerPage = (e) => {
        dispatch(setRowsPerPageConfiguracionGrupos(parseInt(e.target.value)));
        setPage(0);
    }
    const initialState = {
        nombre: "",
        descripcion: ""
    }
    const cols = [
        {
            key: 'nombre',
            name: t("ConfiguracionGrupos:Col1"),
            width: 150,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'descripcion',
            name: t("ConfiguracionGrupos:Col2"),
            width: 150,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'acciones',
            name: t("ConfiguracionGrupos:Col3"),
            width: 150,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
    ]

    /**
     * Retrieves a unique key for a given row in the data grid.
     * This key is typically used for identifying rows when rendering or updating.
     *
     * @function rowKeyGetter
     * @param {Object} row - The data object representing a row in the data grid.
     * @returns {string|number} The unique key for the provided row.
     */
    const rowKeyGetter = (row) => {
        return row.id;
    }

    const displayState = useSelector((state) => state.display.configuracionGrupos);
    const filters = displayState.filters;

    /**
     * Fetches information about permission groups from the server using an Axios GET request.
     * Updates the component's state with the fetched data and manages loading state accordingly.
     *
     * This function sets loading to true before making the request and sets it back to false
     * after the request completes (whether successful or not).
     *
     * @function fetchInfo
     * @returns {void}
    */
    const fetchInfo = () => {
        setLoading(true)
        serviallAxios.get("/permissions/groups_info", {
            timeout: TIMEOUTS.small
        })
            .then(res => {
                const newRows = []
                res.data.groups.forEach((data) => {
                    const newObj = {
                        nombre: data[1],
                        descripcion: data[2],
                        acciones: <EditIcon className='configuracion-grupos-icon' size={12} onClick={() => handleEdit(data[0], data[1], data[2])} />
                    }
                    newRows.push(newObj)
                })
                setRows(newRows)
            })
            .catch(e => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: e.response ? e.response.data.detail : CustomAlertMessage(e.code)
                }));
            }).finally(() => {
                setLoading(false);
            })
    }


    /**
     * React useEffect hook that fetches information about permission groups from the server when the component mounts.
     * It triggers the 'fetchInfo' function to retrieve and update the component's state with fetched data.
     *
     * The effect only runs once, immediately after the component is mounted, due to an empty dependency array.
     * This ensures that the data is fetched only on the initial render.
     *
     * @function useEffect
     * @returns {void}
     */
    useEffect(() => {
        fetchInfo()
    }, [])


    /**
     * Handle the editing of a group by setting its ID, name, and description, and then fetching
     * group users' information to populate the dialog.
     *
     * @param {number} id - The ID of the group to edit.
     * @param {string} name - The updated name for the group.
     * @param {string} description - The updated description for the group.
     */
    const handleEdit = (id, name, description) => {
        const currentGroup = {
            id: id,
            name: name,
            description: description
        }
        setActualGroup(currentGroup)
        setDialogOpen(true)
        setLoadingDialog(true)
        const params = {
            group_id: id
        }
        serviallAxios.get("/permissions/get_group_users", { params }, {
            timeout: TIMEOUTS.small
        })
            .then(res => {
                const currentUsers = []
                res.data.related_users.forEach(related_user => {
                    const currentUser = {
                        id: related_user[0],
                        email: related_user[1],
                        active: true
                    }
                    currentUsers.push(currentUser)
                })
                res.data.non_related_users.forEach(non_related_user => {
                    const currentUser = {
                        id: non_related_user[0],
                        email: non_related_user[1],
                        active: false
                    }
                    currentUsers.push(currentUser)
                })
                setLoadingDialog(false)
                setUsers(currentUsers)
            })
            .catch(e => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: e.response ? e.response.data.detail : CustomAlertMessage(e.code)
                }));
                setLoadingDialog(false)
            })


    }


    /**
     * Handles input changes and updates the 'newGroup' state accordingly.
     *
     * If the input element's ID is 'group_name', it sets the 'wasFocused' state to true.
     * Then, it updates the 'newGroup' state by creating a shallow copy and modifying the property
     * corresponding to the changed input.
     *
     * @function handleChange
     * @param {Object} e - The event object representing the input change event.
     * @returns {void}
     */
    const handleChange = (e) => {
        if (e.target.id === "group_name") {
            setWasFocused(true)
        }
        const auxGroup = { ...newGroup }
        auxGroup[e.target.id] = e.target.value
        setNewGroup(auxGroup)
    }

    /**
     * Handles the submission of a new group's data to the server.
     * Constructs a payload with 'group_name' and 'group_desc' from the 'newGroup' state.
     * Sends a POST request to create a new group with the provided payload.
     * Upon successful response, triggers a data refresh by calling 'fetchInfo' and clears the 'newGroup' state.
     * If an error occurs during the request, logs the error.
     * Finally, sets 'wasFocused' state to false.
     *
     * @function handleSubmit
     * @returns {void}
     */
    const handleSubmit = () => {
        const payload = {
            group_name: newGroup.group_name,
            group_desc: newGroup.group_desc
        }
        serviallAxios.post("/permissions/groups", payload, {
            timeout: TIMEOUTS.small
        })
            .then(res => {
                fetchInfo()
                setNewGroup({ group_name: "", group_desc: "" })
            })
            .catch(e => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: e.response ? e.response.data.detail : CustomAlertMessage(e.code)
                }));
            }).finally(() => {
                setWasFocused(false)
            })
    }

    /**
     * Handle the activation/deactivation of a user within the current group.
     *
     * @param {number} id - The ID of the user to be activated/deactivated.
     */
    const handleActiveUser = (id) => {
        const currentUsers = [...users]
        const currentUser = currentUsers.find((obj) => obj.id === id)
        currentUser.active = !currentUser.active
        setUsers(currentUsers)
    }

    /**
     * Handle the alteration of a group by sending a PUT request to update group permissions.
     * It also displays appropriate alerts based on the operation's success or failure.
     */
    const handleAlterGroup = () => {
        dispatch(setAlert({
            open: true,
            severity: "info",
            message: t("ConfiguracionGrupos:PutProcess")
        }))
        const new_users = users.filter((user) => user.active === true).map(({ id }) => id)
        const remove_users = users.filter((user) => user.active === false).map(({ id }) => id)

        const payload = {
            new_users: new_users,
            remove_users: remove_users,
            group_id: actualGroup.id,
            description: actualGroup.description ? actualGroup.description : ""
        }
        serviallAxios.put("/permissions/update_group", payload, {
            timeout: TIMEOUTS.medium
        })
            .then((res) => {
                dispatch(setAlert({
                    open: true,
                    severity: "success",
                    message: t("ConfiguracionGrupos:PutSuccess")
                }))
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }))
            })
            .finally(() => {
                setDialogOpen(false)
                setUsers([])
                setActualGroup({})
                fetchInfo()

            })


    }

    /**
     * Handle the change of the group's description.
     *
     * @param {Object} e - The event object containing the target element.
     */
    const handleChangeDescription = (e) => {
        const currentGroup = { ...actualGroup }
        currentGroup[e.target.id] = e.target.value
        setActualGroup(currentGroup)
    }

    const label = { inputProps: { 'aria-label': 'Group user switch' } };

    return (
        <>
            <Box marginBottom={4}>
                <Typography className="serviall-page-title1">{t("ConfiguracionGrupos:Title")}</Typography>
                <Typography className="serviall-page-title2">{t("ConfiguracionGrupos:Description")}</Typography>
            </Box>

            <Box display={"flex"} flexDirection={"column"} gap={4} marginBottom={4}>
                <Typography className="configuracion-grupos-section">{t("ConfiguracionGrupos:Caption1")}</Typography>
                <ServiallInput
                    onChange={handleChange}
                    value={newGroup.group_name}
                    id={"group_name"}
                    label={t("ConfiguracionGrupos:InputTitle1")}
                    placeholder={t("ConfiguracionGrupos:InputPlaceholder1")}
                    type="text"
                    maxLength={16}
                    inputClassName="serviall-input"
                    errorText={wasFocused && !newGroup.group_name && t("ConfiguracionGrupos:InputAlert")}
                />
                <ServiallInput
                    onChange={handleChange}
                    value={newGroup.group_desc}
                    id={"group_desc"}
                    width={"350px"}
                    label={t("ConfiguracionGrupos:InputTitle2")}
                    placeholder={t("ConfiguracionGrupos:InputPlaceholder2")}
                    type="text"
                    maxLength={16}
                    inputClassName="serviall-input"
                />

                <Button disabled={!newGroup.group_name} onClick={handleSubmit} className="serviall-button configuracion-permisos-save-button" sx={{ width: "fit-content", marginTop: "1rem" }}>
                    {t("ConfiguracionPermisos:ButtonTitle1")}
                </Button>
            </Box>

            <Box>
                {
                    loading ?

                        <Stack className="configuracion-permisos-loading-container" direction={"column"} spacing={3}>
                            <Typography className="configuracion-permisos-loading-label">
                                {t("ConfiguracionGrupos:Loading")}
                            </Typography>
                            <CircularProgress size={50} />
                        </Stack>
                        :
                        <>
                            <Typography marginBottom={2} className="configuracion-grupos-section">{t("ConfiguracionGrupos:Caption2")}</Typography>
                            <Box marginBottom={"2rem"} width={"100%"} display={"flex"} justifyContent={"flex-end"} >
                                <ResetFiltersBtn page={"configuracionGrupos"} />

                            </Box>
                            <Box className="serviall-datagrid-container">
                                <DraggableDataGrid
                                    cols={cols}
                                    rows={rows}
                                    rowKeyGetter={rowKeyGetter}
                                    className="serviall-datagrid"
                                    headerRowHeight={90}
                                    initialState={initialState}
                                    filters={filters}
                                    setFiltersAction={setFilter}
                                    page={"configuracionGrupos"}
                                    pageNum={page}
                                    handlePageChange={handlePageChange}
                                    rowsPerPage={rowsPerPage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                />

                            </Box>
                            <Dialog
                                open={dialogOpen}
                                onClose={() => { setDialogOpen(false) }}
                            >
                                <DialogTitle>
                                    <Typography className="versions-dialog-title">
                                        {t("ConfiguracionGrupos:DialogTitle")}: {actualGroup.name}
                                    </Typography>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => setDialogOpen(false)}
                                        className="dialog-close-button-icon"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Stack direction={"column"} marginBottom={4}>

                                        <ServiallInput
                                            onChange={handleChangeDescription}
                                            value={actualGroup.description}
                                            id={"description"}
                                            label={t("ConfiguracionGrupos:DialogInput1")}
                                            placeholder={t("ConfiguracionGrupos:DialogPlaceholder1")}
                                            type="text"
                                            fullWidth={true}
                                            inputClassName="serviall-input" />
                                    </Stack>

                                    <Stack direction={"column"} spacing={1} width={"100%"}>

                                        <Stack direction={"column"} gap={1.5}>
                                            <Box>
                                                <Box display={"flex"} gap={"1rem"} alignItems={"center"} flexWrap={"wrap"}>
                                                    <Typography className='serviall-input-label'>
                                                        {t("ConfiguracionGrupos:DialogInput2")}
                                                    </Typography>
                                                </Box>

                                                <Typography className='serviall-input-caption'>
                                                    {t("ConfiguracionGrupos:DialogPlaceholder2")}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                        <Box >
                                            {loadingDialog ?
                                                <Stack className="configuracion-permisos-loading-container" direction={"column"} spacing={3}>
                                                    <Typography className="configuracion-permisos-loading-label">
                                                        {t("ConfiguracionGrupos:Loading2")}
                                                    </Typography>
                                                    <CircularProgress size={50} />
                                                </Stack> :
                                                <MenuList>
                                                    {users.map((obj) => {
                                                        return (
                                                            <MenuItem sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                <Typography>{obj.email}</Typography>
                                                                <Switch {...label} onChange={() => handleActiveUser(obj.id)} checked={obj.active} />
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </MenuList>
                                            }
                                        </Box>
                                    </Stack>
                                </DialogContent>
                                <DialogActions>
                                    <Button className="serviall-button-error" onClick={handleAlterGroup}>
                                        {t("ConfiguracionGrupos:SaveBtn")}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>


                }
            </Box>



        </>
    )

}

export default ConfiguracionGrupos