import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux';
import { resetFilters } from '../features/displaySlice';
import { Button } from 'rsuite';

/**
 * ResetFiltersBtn is a React component that renders a button for resetting filters associated with a specific page.
 *
 * @component
 * @param {string} page - The page identifier for which filters should be reset.
 * @returns {JSX.Element} - A JSX element representing the reset filters button.
 */
const ResetFiltersBtn = ({page}) => {

    const {t} = useTranslation()
    const dispatch = useDispatch()
    return (
    <Button
        className="serviall-button-reset"
        onClick={() => dispatch(resetFilters({
            page: page
        }))}
    >
        {t("Common:ResetBtn")}
    </Button>
    )
}

export default ResetFiltersBtn