import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography, FormControl, Select, ListSubheader, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * SelectLanguage is a React component for selecting the language.
 *
 * @component
 * @param {Object} props - The props object.
 * @param {string} props.primaryText - The primary text displayed as a label.
 * @param {string} props.secondaryText - The secondary text displayed below the label.
 * @param {Object} props.formValues - An object containing form values, including the selected language.
 * @param {function} props.handleFormChange - A function to handle changes in form input.
 * @param {boolean} props.fullWidth - A boolean indicating whether the component should occupy full width.
 * @returns {JSX.Element} - The JSX element representing the language selection component.
 */
const SelectLanguage = ({ primaryText, secondaryText, formValues, handleFormChange, fullWidth }) => {
    const nuevaOportunidadPermissions = useSelector((state) => state.permissions.componentPermissions.NuevaOportunidadPage);

    const { t } = useTranslation()
    return (
        <>
            {nuevaOportunidadPermissions.Box.selectContainer !== "hidden" &&
                <Box
                    id="selectContainer"
                    className='nuevaOportunidad-Box-permi desc-selectInput_container'
                    sx={{ display: "flex", flexDirection: "column" }} width={{ xs: "100%", tablet: fullWidth ? "100%" : "45%", lg: fullWidth ? "100%" : "45%" }} justifyContent={"space-between"} gap={1.5}>
                    <Box>
                        <Typography className='serviall-input-label'>
                            {primaryText}
                        </Typography>
                        <Typography className='serviall-input-caption'>
                            {secondaryText}
                        </Typography>
                    </Box>
                    <FormControl className='nuevaOportunidad-mui-input-container'>
                        <Select
                            name='file_lang'
                            variant="standard"
                            disableUnderline
                            placeholder={"Idioma"}
                            value={formValues.file_lang}
                            onChange={handleFormChange}

                        >   <ListSubheader className='nuevaOportunidad-select-options-title'>
                                <Typography className='serviall-h6'>{t("Common:LanguageInputDescription")}</Typography>
                            </ListSubheader>
                            <MenuItem className='nuevaOportunidad-menuItem' value={"auto"} divider>
                                <Box className="nuevaOportunidad-select-item-container">
                                    <Typography className='serviall-small'>
                                        {t("Common:LanguageInputOption4")}
                                    </Typography>
                                </Box>
                            </MenuItem>
                            <MenuItem className='nuevaOportunidad-menuItem' value={"es"} divider>
                                <Box className="nuevaOportunidad-select-item-container">
                                    <Typography className='serviall-small'>
                                        {t("Common:LanguageInputOption1")}
                                    </Typography>
                                </Box>
                            </MenuItem>
                            <MenuItem className='nuevaOportunidad-menuItem' divider value={"en"}>
                                <Box className="nuevaOportunidad-select-item-container">
                                    <Typography className='serviall-small'>
                                        {t("Common:LanguageInputOption2")}
                                    </Typography>
                                </Box>
                            </MenuItem>
                            <MenuItem divider value={"es.en"}>
                                <Box className="nuevaOportunidad-select-item-container">
                                    <Typography className='serviall-small'>
                                        {t("Common:LanguageInputOption3")}
                                    </Typography>
                                </Box>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            }
        </>

    )
}

export default SelectLanguage