import { Autocomplete, Box, Button, Checkbox, Stack, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import serviallAxios from "../../axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../features/navigationSlice";
import DraggableDataGrid from "../../components/DraggableDataGrid";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ServiallLoading from "../../components/ServiallLoading";
import EditMastersItemDialog from "../../components/EditMastersItemDialog";
import DeleteMastersItemDialog from "../../components/DeleteMastersItemDialog";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { TIMEOUTS } from "../../utils";
import { setFilter } from "../../features/displaySlice";
import ResetFiltersBtn from "../../components/ResetFiltersBtn";
import "./ConfiguracionMantenedoresAuxItems.css"
import { setRowsPerPageConfiguracionAuxItems } from "../../features/displaySlice";
import { CustomAlertMessage } from "../../utils";

const ConfiguracionMantenedoresAuxItems = () => {

    /* COLUMNS DECLARATION */
    const RESIZABLE_COLS = false;


    const IS_VALID_COL = {
        key: 'selected',
        no_filter: true,
        name: i18next.t("MantenedoresItemsAuxiliares:Header1"),
        resizable: RESIZABLE_COLS,
        headerCellClass: "serviall-datagrid-header1",
        sortable: false,
        filterable: false
    }

    
    const PROVIDER_ITEM_COLS = [
        {
            key: 'upload_user',
            name: "Subido por",
            headerCellClass: "serviall-datagrid-header2",
            sortable: true,
            resizable: true
        },
        {
            key: 'provider_rut',
            name: i18next.t("MantenedoresItemsAuxiliares:Header2"),
            headerCellClass: "serviall-datagrid-header1",
            sortable: true,
            resizable: true
        },
        {
            key: 'sku_provider',
            name: i18next.t("MantenedoresItemsAuxiliares:Header3"),
            headerCellClass: "serviall-datagrid-header2",
            sortable: true,
            resizable: true
        },
        {
            key: 'es_desc',
            name: i18next.t("MantenedoresItemsAuxiliares:Header4"),
            headerCellClass: "serviall-datagrid-header1",
            sortable: true,
            resizable: true
        },
        {
            key: 'en_desc',
            name: i18next.t("MantenedoresItemsAuxiliares:Header5"),
            headerCellClass: "serviall-datagrid-header2",
            sortable: true,
            resizable: true
        },
        {
            key: 'creation_date',
            name: "Fecha creación",
            headerCellClass: "serviall-datagrid-header1",
            sortable: true,
            resizable: true
        },
        {
            key: 'UM',
            name: i18next.t("MantenedoresItemsAuxiliares:Header6"),
            sortable: true,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true
        },
        {
            key: 'Nombre',
            name: i18next.t("MantenedoresItemsAuxiliares:Header7"),
            headerCellClass: "serviall-datagrid-header1",
            sortable: true,
            resizable: true
        },
        {
            key: 'Codigo Sap',
            name: i18next.t("MantenedoresItemsAuxiliares:Header8"),
            headerCellClass: "serviall-datagrid-header2",
            sortable: true,
            resizable: true
        },
        {
            key: 'sku_serviall',
            name: i18next.t("MantenedoresItemsAuxiliares:Header9"),
            headerCellClass: "serviall-datagrid-header1",
            sortable: true,
            resizable: true
        },
        {
            key: 'Costo estimado',
            name: i18next.t("MantenedoresItemsAuxiliares:Header10"),
            headerCellClass: "serviall-datagrid-header2",
            sortable: true,
            resizable: true
        },
        {
            key: 'Marca/Fabricante',
            name: i18next.t("MantenedoresItemsAuxiliares:Header11"),
            headerCellClass: "serviall-datagrid-header1",
            sortable: true,
            resizable: true
        },
        {
            key: 'Moneda del costo',
            name: i18next.t("MantenedoresItemsAuxiliares:Header12"),
            headerCellClass: "serviall-datagrid-header2",
            sortable: true,
            resizable: true
        },
        {
            key: 'Numero de Parte',
            name: i18next.t("MantenedoresItemsAuxiliares:Header13"),
            headerCellClass: "serviall-datagrid-header1",
            sortable: true,
            resizable: true
        },
        {
            key: 'Formato del empaque',
            name: i18next.t("MantenedoresItemsAuxiliares:Header14"),
            headerCellClass: "serviall-datagrid-header2",
            sortable: true,
            resizable: true
        },
        {
            key: 'URL del item-proveedor',
            name: i18next.t("MantenedoresItemsAuxiliares:Header15"),
            headerCellClass: "serviall-datagrid-header1",
            sortable: true,
            resizable: true
        },
        {
            key: 'Cantidad por empaque cerrado',
            name: i18next.t("MantenedoresItemsAuxiliares:Header16"),
            headerCellClass: "serviall-datagrid-header2",
            sortable: true,
            resizable: true
        },
        {
            key: 'acciones',
            reset: true,
            name: "Acciones",
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
        },
    ]
    const dispatch = useDispatch();
    const displayState = useSelector((state) => state.display.configuracionAuxItems);
    const filters = displayState.filters;

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const [providerText, setProviderText] = useState("");
    const [providerList, setProviderList] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);

    const [rows, setRows] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [initialSelectedRows, setInitialSelectedRows] = useState([]);
    const { t } = useTranslation();
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [dialogLoading, setDialogLoading] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [actualDeleteId, setActualDeleteId] = useState({});

    const [page, setPage] = useState(0);
    const rowsPerPage = displayState.rowsPerPage;

    /**
     * Handles the change of the current page in the data grid.
     * Updates the 'page' state with the new page number.
     *
     * @function handlePageChange
     * @param {Event} e - The event object representing the page change event.
     * @param {number} newPage - The new page number to be set.
     * @returns {void}
     */
    const handlePageChange = (e, newPage) => {
        setPage(newPage);
    
    }


    /**
     * Handles the change of the number of rows per page in the data grid.
     * Updates the 'rowsPerPage' state and resets the 'page' state to 0.
     *
     * @function handleChangeRowsPerPage
     * @param {Event} e - The event object representing the rows per page change event.
     * @returns {void}
     */
    const handleChangeRowsPerPage = (e) => {
        dispatch(setRowsPerPageConfiguracionAuxItems(parseInt(e.target.value)));
        setPage(0);
    }

    const initialState = {
        upload_user: "",
        provider_rut: "",
        sku_provider: "",
        es_desc: "",
        en_desc: "",
        creation_date: "",
        UM: "",
        Nombre: "",
        "Codigo Sap": "",
        sku_serviall: "",
        "Costo estimado": "",
        "Marca/Fabricante": "",
        "Moneda del costo": "",
        "Numero de Parte": "",
        "Formato del empaque": "",
        "URL del item-proveedor": "",
        "Cantidad por empaque cerrado": "",
    }

    const [editForm, setEditForm] = useState(initialState);

    /**
     * Handles the 'Editar' button click on a data grid row and opens the edit dialog.
     * @param {Object} params - The parameters of the data grid row.
     * @returns {void}
     */
    const handleEdit = (params) => {
        setEditDialogOpen(true);
        setEditForm(params);
    }

    /**
     * Handles changes in the fields of the edit dialog form.
     * @param {Object} e - The input change event.
     * @returns {void}
     */
    const handleEditFormChange = (e) => {
        let newFormValues = { ...editForm };
        newFormValues[e.target.id] = e.target.value;
        setEditForm(newFormValues);
    }

    /**
     * Closes the edit dialog.
     * @returns {void}
     */
    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
    }

    /**
     * Saves the changes made in the edit dialog to the backend.
     * @returns {void}
     */
    const handleSaveEdit = () => {

        if (editForm.db_id === -1) {
            console.log("Edit id error");
            setEditDialogOpen(false);
            return
        }

        setDialogLoading(true);

        let item_data = {
            "UM": editForm.UM ? editForm.UM : "",
            "Nombre": editForm["Nombre"] ? editForm["Nombre"] : "",
            "Codigo Sap": editForm["Codigo Sap"] ? editForm["Codigo Sap"] : "",
            "sku_serviall": editForm.sku_serviall ? editForm.sku_serviall : "",
            "Costo estimado": editForm["Costo estimado"] ? editForm["Costo estimado"] : "",
            "Marca/Fabricante": editForm["Marca/Fabricante"] ? editForm["Marca/Fabricante"] : "",
            "Moneda del costo": editForm["Moneda del costo"] ? editForm["Moneda del costo"] : "",
            "Numero de Parte": editForm["Numero de Parte"] ? editForm["Numero de Parte"] : "",
            "Formato del empaque": editForm["Formato del empaque"] ? editForm["Formato del empaque"] : "",
            "URL del item-proveedor": editForm["URL del item-proveedor"] ? editForm["URL del item-proveedor"] : "",
            "Cantidad por empaque cerrado": editForm["Cantidad por empaque cerrado"] ? editForm["Cantidad por empaque cerrado"] : "",
        };

        let payload = {
            item_id: editForm.db_id,
            sku_provider: editForm.sku_proveedor,
            es_desc: editForm.es_desc,
            en_desc: editForm.en_desc,
            item_data: JSON.stringify(item_data)
        }

        serviallAxios.put("/masters/aux_provider_items", payload, {
            timeout: TIMEOUTS.medium
        })
            .then((res) => {
                dispatch(setAlert({
                    open: true,
                    severity: "info",
                    message: t("Common:UpdateSuccesful")
                }))
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }))
            })
            .finally(() => {
                setDialogLoading(false);
                setEditDialogOpen(false);
                fetchData()
            })
    }

    /* Delete Functions */

    /**
     * Handles the 'Eliminar' button click on a data grid row and opens the delete dialog.
     * @param {string} sku_proveedor - The SKU of the provider.
     * @param {number} db_id - The database ID of the row.
     * @returns {void}
     */
    const handleClickOpen = (sku_proveedor, db_id) => {
        setDeleteDialogOpen(true);
        setActualDeleteId({ "sku_proveedor": sku_proveedor, "db_id": db_id })
    };

    /**
     * Closes the delete dialog.
     * @returns {void}
     */
    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

     /**
     * Deletes the selected data grid row from the backend.
     * @returns {void}
     */
    const handleDelete = () => {
        let payload = {
            provider_item_id: actualDeleteId.db_id
        }
        serviallAxios.delete("/masters/aux_provider_items", {
            params: payload,
            timeout: TIMEOUTS.small
        }).then((res) => {
            dispatch(setAlert({
                open: true,
                severity: "info",
                message: t("Common:DeleteSuccesful")
            }))
        })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }))
            })
            .finally(() => {
                setDialogLoading(false);
                setDeleteDialogOpen(false);
                fetchData();
            })

    }
    
    /**
     * A React useEffect hook that fetches data from the server and initializes the provider list.
     * The function sends a GET request to "opportunities/provider_aux_items" endpoint using serviallAxios.
     * Upon successful response, it processes the data and extracts the 'rut' and 'name' fields to create an array of providers.
     * The array of providers is then set as the providerList state using setProviderList.
     * @param {Function} serviallAxios - The Axios instance used to make the HTTP request.
     * @param {number} TIMEOUTS.medium - The timeout value for the HTTP request.
     * @param {Function} setProviderList - The state setter function to update the providerList state.
     */
    useEffect(() => {
        serviallAxios.get("opportunities/provider_aux_items", {
            timeout: TIMEOUTS.medium
        })
            .then((res) => {
                let providersData = res.data.result.map((row) => {
                    return {
                        rut: row[0],
                        name: row[1]
                    }
                })
                setProviderList(providersData);
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
            .finally(() => {

            })
    }, [])

    /**
     * Function that generates the actions stack for a row in the data grid.
     * It returns a JSX element containing icons for editing and deleting the row.
     * @param {Object} params - The parameters object for the row containing 'sku_proveedor' and 'db_id' fields.
     * @returns {JSX.Element} - A JSX element containing edit and delete icons wrapped in a Stack component.
     */
    const makeRowActions = (params) => {
        return (
            <Stack sx={{ justifyContent: "center", marginTop: "5px" }} direction={"row"} spacing={1}>
                <EditIcon
                    fontSize="small"
                    className="detalleHomologoProveedor-datagrid-header-icon"
                    onClick={() => { handleEdit(params) }}
                />
                <DeleteIcon
                    fontSize="small"
                    className="detalleHomologoProveedor-datagrid-header-icon"
                    onClick={() => { handleClickOpen(params.sku_proveedor, params.db_id) }}
                />
            </Stack>
        )
    }

    /**
     * Fetches data from the backend and populates the data grid.
     * @returns {void}
     */
    const fetchData = () => {
        serviallAxios.get(`opportunities/provider_aux_items/${selectedProvider.rut}`, {
            timeout: TIMEOUTS.medium
        })
            .then((res) => {
                let valid_items = [];
                let newRows = res.data.result.map((entry, idx) => {
                    let item_data = JSON.parse(entry[5]);
                    if (typeof item_data === "string") {
                        item_data = JSON.parse(item_data)
                    }
                    if (entry[6]) valid_items.push(idx);
                    let date_no_hour = entry[8].split("T")[0];
                    return {
                        id: idx,
                        acciones: makeRowActions(
                            {
                                sku_proveedor: entry[2],
                                es_desc: entry[3],
                                en_desc: entry[4],
                                UM: "UM" in item_data ? item_data["UM"] : "",
                                "Nombre": "Nombre" in item_data ? item_data["Nombre"] : "",
                                "Codigo Sap": "Codigo Sap" in item_data ? item_data["Codigo Sap"] : "",
                                sku_serviall: "sku_serviall" in item_data ? item_data["sku_serviall"] : "",
                                "Costo estimado": "Costo estimado" in item_data ? item_data["Costo estimado"] : "",
                                "Marca/Fabricante": "Marca/Fabricante" in item_data ? item_data["Marca/Fabricante"] : "",
                                "Moneda del costo": "Moneda del costo" in item_data ? item_data["Moneda del costo"] : "",
                                "Numero de Parte": "Numero de Parte" in item_data ? item_data["Numero de Parte"] : "",
                                "Formato del empaque": "Formato del empaque" in item_data ? item_data["Formato del empaque"] : "",
                                "URL del item-proveedor": "URL del item-proveedor" in item_data ? item_data["URL del item-proveedor"] : "",
                                "Cantidad por empaque cerrado": "Cantidad por empaque cerrado" in item_data ? item_data["Cantidad por empaque cerrado"] : "",
                                db_id: entry[0]
                            }),
                        upload_user: entry[10],
                        db_id: entry[0],
                        is_valid: entry[7],
                        selected: makeRowCheckbox(idx, entry[7]),
                        provider_rut: entry[1],
                        sku_provider: entry[2],
                        es_desc: entry[3],
                        en_desc: entry[4],
                        creation_date: date_no_hour.split("-").reverse().join("/"),
                        ...item_data
                    }
                })
                // let initialSelectedRows = {}
                // newRows.forEach((row, idx) => {
                //     initialSelectedRows[idx] = Boolean(row.is_valid);
                // })
                setSelectedRows(valid_items);
                setInitialSelectedRows(valid_items);
                setRows(newRows);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    /**
     * A React useEffect hook that fetches data when the selectedProvider changes.
     * If the selectedProvider is truthy, it sets the loading state to true and calls the fetchData function.
     * @param {Function} fetchData - The function used to fetch data based on the selectedProvider value.
     * @param {Object|null} selectedProvider - The selected provider object (truthy) or null (falsy).
     */
    useEffect(() => {
        if (selectedProvider) {
            setLoading(true);
            fetchData()
        }
    }, [selectedProvider])

    /**
     * Handles the 'Guardar cambios' button click and saves the selected rows to the backend.
     * @returns {void}
     */
    const handleSaveAuxItems = () => {
        setSaving(true);
        let db_rows = []
        rows.forEach((row) => {
            if (selectedRows.includes(row.id)) {
                db_rows.push({
                    id: row.db_id,
                    is_valid: 1
                })
            } else {
                db_rows.push({
                    id: row.db_id,
                    is_valid: 0
                })
            }
        })
        let payload = {
            ids_json: JSON.stringify(db_rows)
        }
        serviallAxios.put("opportunities/aux_items", payload, {
            timeout: TIMEOUTS.medium
        })
            .then((res) => {
                setInitialSelectedRows(selectedRows);
                dispatch(setAlert({
                    open: true,
                    severity: "info",
                    message: t("Common:ObjectsSuccesful")
                }))
            }
            )
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }))
            }
            )
            .finally(() => {
                setSaving(false);
            })
    }

     /**
     * Checks if there are any changes made in the data grid rows.
     * @returns {boolean} True if there are changes, otherwise false.
     */
    const checkIfChanges = () => {
        if (JSON.stringify(initialSelectedRows.sort()) !== JSON.stringify(selectedRows.sort())) {
            return true;
        }
        return false;
    }

    /**
     * Handles the checkbox toggle event for a data grid row.
     * @param {Object} e - The checkbox change event.
     * @param {number} idx - The index of the row.
     * @returns {void}
     */
    const onCheckboxToggle = (e, idx) => {
        if (e.target.checked) {
            setSelectedRows((state) => {
                let newState = [...state]
                newState.push(idx);
                return newState
            });
        } else {
            setSelectedRows((state) => {
                let newState = [...state]
                newState = newState.filter((selected_id) => selected_id !== idx);
                return newState
            });
        }
    }

    /**
     * Generates a custom checkbox element for a data grid row.
     * @param {number} idx - The index of the row.
     * @param {boolean} defaultChecked - The default checked status for the checkbox.
     * @returns {JSX.Element} The checkbox element.
     */
    const makeRowCheckbox = (idx, defaultChecked) => {
        return (
            <Box display={"flex"} justifyContent={"center"}>
                <Checkbox
                    onChange={(e) => onCheckboxToggle(e, idx)}
                    // checked={selectedRows[idx]}
                    defaultChecked={defaultChecked}
                />
            </Box>
        )
    }

    const cols = [IS_VALID_COL, ...PROVIDER_ITEM_COLS];

    /**
     * Returns the unique key for the given data grid row.
     * The 'id' property of the row is used as the unique key.
     * @param {Object} row - The data grid row object.
     * @returns {string|number} The unique key for the row.
     */
    const rowKeyGetter = (row) => {
        return row.id;
    }

    return (
        <Stack direction={"column"}>
            <EditMastersItemDialog
                dialogOpen={editDialogOpen}
                loading={dialogLoading}
                variant={"aux_provider"}
                formValues={editForm}
                handleChange={handleEditFormChange}
                handleClose={handleEditDialogClose}
                handleSave={handleSaveEdit}
            />
            <DeleteMastersItemDialog
                dialogOpen={deleteDialogOpen}
                loading={dialogLoading}
                variant={"proveedores"}
                formValues={actualDeleteId}
                handleClose={handleDeleteDialogClose}
                handleSave={handleDelete} />
            <Typography className="serviall-page-title1">
                {t("MantenedoresItemsAuxiliares:Title")}
            </Typography>
            <Typography className="serviall-page-title2" marginBottom={4}>
                {t("MantenedoresItemsAuxiliares:Description")}
            </Typography>
            <Box display={"flex"} justifyContent={"space-between"} className="MantenedoresItemsAuxiliares-form-container" marginBottom={4}>
                <Autocomplete
                    size="small"
                    value={selectedProvider}
                    onChange={(e, newValue) => {
                            setSelectedProvider(newValue);
                    }}
                    disablePortal
                    inputValue={providerText}
                    onInputChange={(e, newInputValue) => {
                        if (newInputValue.name) {
                            setProviderText(newInputValue.name)
                        } else {
                            setProviderText(newInputValue)
                        }
                    }}
                    options={providerList}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} placeholder={t("MantenedoresItemsAuxiliares:InputLabel")}/>}
                    renderOption={(props, option) => <li {...props}><Typography>{option.name}</Typography></li>}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option) => option.name}
            
                />
                <ResetFiltersBtn page={"configuracionAuxItems"}/>
            </Box>
            {/* Custom autcomplete to handle objects instead of strings as options */}
            
            {selectedProvider ?
                loading ?
                    <ServiallLoading
                        text={t("MantenedoresItemsAuxiliares:LoadingStatus")}
                    />
                    :
                    <Box className="serviall-datagrid-container">
                        <DraggableDataGrid
                            cols={cols}
                            rows={rows}
                            className="serviall-datagrid"
                            rowKeyGetter={rowKeyGetter}
                            headerRowHeight={90}
                            initialState={initialState}
                            filters={filters}
                            setFiltersAction={setFilter}
                            page={"configuracionAuxItems"}
                            pageNum={page}
                            rowsPerPage={rowsPerPage}
                            handlePageChange={handlePageChange}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}


                        />
                    </Box>
                :
                <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", height: "300px" }}>
                    <Typography className="serviall-h4">{t("MantenedoresItemsAuxiliares:NoSelected")}</Typography>

                </Box>

            }
            {
                checkIfChanges() > 0 &&
                <Box display={"flex"} justifyContent={"right"}>
                    <Button onClick={handleSaveAuxItems} className="serviall-button" disabled={saving}>
                        Guardar cambios
                    </Button>
                </Box>
            }
        </Stack>
    )
}

export default ConfiguracionMantenedoresAuxItems;