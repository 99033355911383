import { Checkbox, Radio, TableCell, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./PermissionsTableRow.css";
import { useDispatch } from "react-redux";
import { setPage } from "../features/navigationSlice";

/**
 * A React component to display a row of a permissions table.
 * @param {string} key - key for the row element.
 * @param {number} idx - index of the row.
 * @param {boolean} is_active - whether the row is active or not.
 * @param {function} handlePermissionChange - function to handle the change of the permission.
 * @param {string} name - name of the permission.
 * @param {string} desc - description of the permission.
 * @param {string} url - url of the permission.
 * @param {string} access_level - access level of the permission.
 * @returns A component to display a row of a permissions table.
 */

const PermissionsTableRow = ({ key, idx, is_active, handlePermissionChange, name, desc, url, access_level }) => {

    const dispatch = useDispatch();

    const [accessLevel, setAccessLevel] = useState(access_level)

    const navigate = useNavigate();

    // TODO: si estos permisos no son fijos, puede ser necesario hacer que se obtengan de la base de datos y cargarlos a la pagina 
    // cuando se inicie sesión, tal vez guardarlo en la store de redux.
    const perm_name_to_id = {
        "admin": 1,
        "read": 2,
        "write": 3,
        "hidden": 4,
        "not_admin": 5
    }

    /**
     * Event handler for the change of the access level in the PermissionsTableRow component.
     *
     * @param {Object} e - The event object from the access level change.
     * @returns {void}
     */
    const handleAccessLevelChange = (e) => {
        handlePermissionChange(idx, perm_name_to_id[e.target.value], e.target.value);
        setAccessLevel(e.target.value);
    }
    /**
     * Event handler for clicking a link in the PermissionsTableRow component.
     *
     * @param {string} url - The URL of the link clicked.
     * @returns {void}
    */
    const handleLinkClick = (url) => {
        dispatch(setPage(url.split("/").at(-1)));
        navigate(url);
    }

    return (
        <React.Fragment key={key}>
            <TableRow>
                <TableCell>
                    <Checkbox size="small" defaultChecked={Boolean(is_active)} />
                </TableCell>
                <TableCell>
                    <Typography>
                        {name}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        {desc}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Box sx={{ cursor: "pointer" }} onClick={() => { handleLinkClick(url); }}>
                        <Typography className="permissions-table-row-link">
                            {url.split("/").at(-1)}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell>
                    <Radio
                        size="small"
                        checked={accessLevel === 'hidden'}
                        onChange={handleAccessLevelChange}
                        value="hidden"
                    />
                </TableCell>
                <TableCell>
                    <Radio
                        size="small"
                        checked={accessLevel === 'read'}
                        onChange={handleAccessLevelChange}
                        value="read"
                    />
                </TableCell>
                <TableCell>
                    <Radio
                        size="small"
                        checked={accessLevel === 'write'}
                        onChange={handleAccessLevelChange}
                        value="write"
                    />
                </TableCell>
                <TableCell>
                    <Radio
                        size="small"
                        checked={accessLevel === 'admin'}
                        onChange={handleAccessLevelChange}
                        value="admin"
                    />
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

PermissionsTableRow.displayName = "PermissionsTableRow";

export default PermissionsTableRow;