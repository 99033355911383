import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Select } from "@mui/material";
import { changeLanguage } from "../features/settingsSlice";

/**
 * A React component that allows the user to switch between the languages.
 * @typedef LangSwitcher
 * @returns A component that allows the user to switch between the languages.
 */

const LangSwitcher = () => {

    const dispatch = useDispatch();
    const lang = useSelector((state) => state.settings.language);

    /**
     * Event handler for the language change in the LangSwitcher component.
     *
     * @param {Object} e - The event object from the language change.
     * @returns {void}
     */
    const handleLangChange = (e) => {
        dispatch(changeLanguage(e.target.value));
    }

    return (
        <Select
            variant="standard"
            defaultValue={"es"}
            value={lang}
            onChange={handleLangChange}
        >
            <MenuItem value={"en"}>en 🇺🇸</MenuItem>
            <MenuItem value={"es"}>es 🇨🇱</MenuItem>
        </Select>
    )
}

export default LangSwitcher;