import { createSlice } from "@reduxjs/toolkit";

// notifications slice

/*
    En este slice se guarda toda la información referente a notificaciones, de las cuales se hace fetch cada  5 minutos y se muestran 
    en el navbar
*/

export const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        notificationArray : [],
        notSeenCounter : 0,
    },
    reducers: {
        setNotificationArray: (state, action) => {
            state.notificationArray = action.payload;
        },
        setCounter: (state, action) => {
            state.notSeenCounter = action.payload;
        },
        setIdsArray: (state, action) => {
            state.idsArray = action.payload
        },
        setSeenNotification: (state,action) => {
            const updatedNotificationArray = [...state.notificationArray]
            updatedNotificationArray.find(x=> x.id === action.payload).is_read = true 
            state.notificationArray = updatedNotificationArray
            state.notSeenCounter -= 1
        }
    }
})

export const { setNotificationArray, setCounter, setIdsArray, setSeenNotification } = notificationSlice.actions;

export default notificationSlice.reducer;