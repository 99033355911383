import DataGrid from 'react-data-grid';

/** 
* Component to render a simple datagrid with extra client items on ItemsLicitationPage, does not support column dragging 
* nor filters.
* @param {Object[]} rows - List of rows to render.
* @param {Function} t - Function to handle translations
* @return {ReturnValueDataTypeHere} Brief description of the returning value here.
*/
const ItemsAdicionalesDatagrid = ({ rows, t }) => {

    const MIN_COL_WIDTH = 120;
    const datagridCols = [
        {
            key: 'es_desc',
            name: "Descripcion español",
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'en_desc',
            name: "Descripcion ingles",
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'item_data',
            no_filter: true,
            name: "Detalle objeto",
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'igual_select',
            no_filter: true,
            name: t("ItemsLicitation:Header25"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header2",
            cellClass: "itemsLicitacion-datagrid-checkbox-cell",
            resizable: true,
        },
        {
            key: 'alternativo_select',
            no_filter: true,
            name: t("ItemsLicitation:Header26"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            cellClass: "itemsLicitacion-datagrid-checkbox-cell",
            resizable: true,
        }
    ]

    /**
     * Generates a unique key for a row in a data grid.
     *
     * @function
     * @param {Object} row - The data row for which a key needs to be generated.
     * @returns {string|number} - A unique key for the given row.
     */
    const rowKeyGetter = (row) => {
        return row.id;
    }

    return (
        <DataGrid
            className='serviall-datagrid'
            columns={datagridCols}
            rows={rows}
            rowClass={(row) => {
                let className = row.igual ?
                    "itemsLicitacion-datagrid-row-igual-selected"
                    :
                    row.alternativo && "itemsLicitacion-datagrid-row-alternativo-selected"

                if (row.highlight_repeated) {
                    className = " itemsLicitacion-datagrid-row-repeated_item"
                }

                return className;
            }}
            rowKeyGetter={rowKeyGetter}
        />
    )
}

export default ItemsAdicionalesDatagrid;