import {Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import ServiallInput from "../../components/ServiallInput";
import { Stack } from "@mui/system";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ServiallUploadButton from "../../components/ServiallUploadButton";
import "./AgregarProveedorPage.css";
import ServiallStep from "../../components/ServiallStep";
import serviallAxios from "../../axiosConfig";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ServiallDialog from "../../components/ServiallDialog";
import { TIMEOUTS } from "../../utils";
import { setAlert } from "../../features/navigationSlice";
import { useNavigate } from "react-router-dom";
import { CustomAlertMessage } from "../../utils";

/**
 * A React component that renders the 'Agregar Proveedor' page.
 * @typedef AgregarProveedorPage
 * @returns A page with the Agregar Proveedor component
 */

const AgregarProveedorPage = () => {

    const email = useSelector((state) => state.navigation.email);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);

    const navigate = useNavigate();

    // Dialog states
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogStatus, setDialogStatus] = useState("");
    const [uploadErrorCode, setUploadErrorCode] = useState("");
    const [uploadErrorMsg, setUploadErrorMsg] = useState("");

    const [formValues, setFormValues] = useState({
        provider_id: "",
        provider_name: "",
        url: "",
    })

    const [wasFocused, setWasFocused] = useState({
        provider_id: false,
        provider_name: false,
        url: false
    })

    const [errors, setErrors] = useState({
        provider_id: t("AgregarProveedorPage:EmptyField"),
        provider_name: t("AgregarProveedorPage:EmptyField"),
        url: t("AgregarProveedorPage:EmptyField"),
    })

    /**
     * Function to handle changes in the input fields of the form.
     *
     * @param {SyntheticEvent} e - The event object.
     * @returns {void}
     */
    const handleFormInputChange = (e) => {
        let newFormValues = { ...formValues };
        let newErrors = { ...errors }
        if (e.target.id) {
            newFormValues[e.target.id] = e.target.value;
        } else {
            newFormValues[e.target.name] = e.target.value;
        }
        // Don't change the client_id field if the value has periods or hyphens
        if (e.target.id === "provider_id") {
            if (!/^[a-zA-Z0-9]*$/.test(e.target.value)) {
                return;
            }
        }
        
        setFormValues(newFormValues);
        if (!e.target.value) {
            newErrors[e.target.id] = "Campo vacio";
        }
        else {
            newErrors[e.target.id] = "";
        }
        setErrors(newErrors);
    }

    /**
     * Function to handle focus events on input fields.
     *
     * @param {SyntheticEvent} e - The event object.
     * @returns {void}
     */
    const handleFormInputFocus = (e) => {
        let newWasFocused = { ...wasFocused };
        newWasFocused[e.target.id] = true
        setWasFocused(newWasFocused);
    }

    /**
     * Function to handle changes in the selected file for upload.
     *
     * @param {SyntheticEvent} e - The event object.
     * @returns {void}
     */
    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    }

    /**
     * Function to handle closing the dialog.
     *
     * @returns {void}
     */
    const handleDialogClose = () => {
        setDialogOpen(false);
        setUploadErrorCode("");
        setUploadErrorMsg("");
    }

    /**
     * Function to reset the selected file.
     *
     * @returns {void}
     */
    const resetFile = () => {
        setFile(null);
    }

    /**
     * Function to handle downloading the template file.
     *
     * @returns {void}
     */
    const handleDownloadTemplate = () => {

        dispatch(setAlert({
            open: true,
            severity: "info",
            message: t("Common:Download")
        }))

        serviallAxios.get("masters/providers_template", {
            timeout: TIMEOUTS.small,
            responseType: "blob"
        })
            .then((res) => {
                const href = URL.createObjectURL(res.data);
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "master_providers_template.xlsx");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => {
                console.log("Error is ", err)
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));

            })
    }

    /**
     * Function to handle saving the data.
     *
     * @returns {void}
     */
    const handleSave = () => {
        if (file) {
            setLoading(true);
            setDialogOpen(true);
            setDialogStatus("loading");
            let payload = new FormData();
            payload.append("file", file);
            payload.append("provider_name", formValues.provider_name);
            payload.append("provider_rut", formValues.provider_id);
            payload.append("provider_web_site", formValues.url);
            serviallAxios.post("/masters/providers",
                payload,
                {
                    timeout: TIMEOUTS.medium,
                    headers: {
                        "Content-Type": 'multipart/form-data'
                    }
                })
                .then((res) => {
                    payload = {
                        s3_path: res.data.object_name,
                        provider_name: formValues.provider_name,
                        prov_rut: formValues.provider_id,
                        user_email: email,
                    }
                    serviallAxios.post(process.env.REACT_APP_ML_BACKEND_URL + "/masters/providers-ml", payload,
                        {
                            timeout: TIMEOUTS.huge
                        })
                        .then((res) => {
                            setDialogStatus("processing");
                            
                            // Reset form data
                            setFormValues({
                                provider_id: "",
                                provider_name: "",
                                url: ""
                            });
                            setWasFocused({
                                provider_id: false,
                                provider_name: false,
                                url: false
                            });
                            setErrors({
                                provider_id: t("AgregarProveedorPage:EmptyField"),
                                provider_name: t("AgregarProveedorPage:EmptyField"),
                                url: t("AgregarProveedorPage:EmptyField")
                            });
                            setFile(null);
                        })
                        .catch((err) => {
                            if (err.response) {
                                setUploadErrorCode(err.response.status);
                                setUploadErrorMsg(err.response.data.detail);
                            } else {
                                setUploadErrorCode(err.code);
                            }
                            setDialogStatus("error");
                        })
                        .finally(() => setLoading(false))
                })
                .catch((err) => {
                    if (err.response) {
                        setUploadErrorCode(err.response.status);
                        setUploadErrorMsg(err.response.data.detail);
                    } else {
                        setUploadErrorCode(err.code);
                    }
                    setDialogStatus("error");
                })
                .finally(() => setLoading(false))
        }
    }

    const provider_id_caption = t("AgregarProveedorPage:InputFieldDesc1")

    const provider_name_caption = t("AgregarProveedorPage:InputFieldDesc2")

    const url_caption = t("AgregarProveedorPage:InputFieldDesc3")

    return (
        <Stack direction={"column"} spacing={4}>
            <Stack direction={"column"}>
                <Typography className="serviall-page-title1">{t("AgregarProveedorPage:Title")}</Typography>
                <Box className="agregarProveedor-subtitle-container">
                    <Typography className="serviall-page-title2">
                        {t("AgregarProveedorPage:Caption2")}
                    </Typography>
                </Box>
            </Stack>
            <Typography className="serviall-page-caption">
                {t("AgregarProveedorPage:Description")}
            </Typography>
            <Grid display="flex" justifyContent={{ xs: "space-between" }} flexDirection={{ xs: "column", tablet: "row" }} gap={{ xs: 3, tablet: 0 }} >
                <Grid display="flex" flexDirection={"column"} alignItems={"center"} width={{ xs: "100%", tablet: "50%" }} gap={{ xs: 3, tablet: 7 }}>
                    <ServiallInput
                        id={"provider_id"}
                        width={"100%"}
                        label={t("AgregarProveedorPage:InputFieldTitle1")}
                        placeholder={t("AgregarProveedorPage:Placeholder1")}
                        caption={provider_id_caption}
                        errorText={errors.provider_id && wasFocused.provider_id ? errors.provider_id : null}
                        type="text"
                        maxLength={16}
                        value={formValues.provider_id}
                        onFocus={handleFormInputFocus}
                        onChange={handleFormInputChange}
                        fullWidth={true}
                        inputClassName="serviall-input"

                    />
                    <ServiallInput
                        id={"provider_name"}
                        width={"100%"}
                        label={t("AgregarProveedorPage:InputFieldTitle2")}
                        placeholder={t("AgregarProveedorPage:Placeholder2")}
                        caption={provider_name_caption}
                        errorText={errors.provider_name && wasFocused.provider_name ? errors.provider_name : null}
                        type="text"
                        maxLength={40}
                        value={formValues.provider_name}
                        onFocus={handleFormInputFocus}
                        onChange={handleFormInputChange}
                        fullWidth={true}
                        inputClassName="serviall-input"

                    />
                    <ServiallInput
                        id={"url"}
                        width={"100%"}
                        label={t("AgregarProveedorPage:InputFieldTitle3")}
                        placeholder={t("AgregarProveedorPage:Placeholder3")}
                        caption={url_caption}
                        type="text"
                        maxLength={40}
                        value={formValues.url}
                        onFocus={handleFormInputFocus}
                        onChange={handleFormInputChange}
                        fullWidth={true}
                        inputClassName="serviall-input"

                    />
                </Grid>
                <Grid className="agregarProveedor-grid-separator" />
                <Grid className="agregarProveedor-grid-content" width={{ xs: "100%", tablet: "50%" }}>
                    <Stack direction={"column"} gap={6.5}>
                        <ServiallStep
                            n_step={1}
                            text={t("AgregarProveedorPage:StepDesc1")}
                            icon={<SystemUpdateAltIcon onClick={handleDownloadTemplate} fontSize="large" />}
                        />
                        <ServiallStep
                            n_step={2}
                            text={t("AgregarProveedorPage:StepDesc2")}
                        />


                        <ServiallStep
                            text={
                                <Stack direction={"column"} spacing={1}>
                                    <ServiallUploadButton
                                        primaryText={t("AgregarProveedorPage:InputFieldTitle4")}
                                        secondaryText={t("AgregarProveedorPage:InputFieldDesc4")}
                                        tertiaryText={t("AgregarProveedorPage:InputFieldFormat4")}
                                        statusText={t("AgregarClientePage:InputFieldSubDesc4")}
                                        filename={file && file.name}
                                        handleFileChange={handleFileChange}
                                        resetFile={resetFile}
                                        disabled={false}
                                    />
                                </Stack>


                            }
                        />
                    </Stack>
                    <Box display={"flex"} flexDirection={"row-reverse"} marginRight={{ xs: 0, md: "4rem" }} marginTop={4} width={"100%"}>
                        <Button onClick={handleSave} disabled={loading || !file || errors.provider_id || errors.provider_name} sx={{ textTransform: "none", padding: "10px 30px 5px 30px" }} className="serviall-button">
                            {t("AgregarProveedorPage:Save")}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Typography className="serviall-back-text" onClick={() => navigate(-1)}>
                <ArrowBackIcon /> {t("Common:GoBackButton")}
            </Typography>
            <ServiallDialog
                open={dialogOpen}
                status={dialogStatus}
                loading_msg={"Cargando archivo..."}
                error_code={uploadErrorCode}
                error_msg={uploadErrorMsg}
                error_type={"file-error"}
                handleClose={handleDialogClose}
            />
        </Stack>
    )
}

AgregarProveedorPage.displayName = "AgregarProveedorPage";

export default AgregarProveedorPage;