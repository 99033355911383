import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import ServiallInput from "../../components/ServiallInput";
import { Stack } from "@mui/system";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ServiallUploadButton from "../../components/ServiallUploadButton";
import "./AgregarClientePage.css";
import ServiallStep from "../../components/ServiallStep";
import serviallAxios from "../../axiosConfig";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ServiallDialog from "../../components/ServiallDialog";
import { TIMEOUTS } from "../../utils";
import { setAlert } from "../../features/navigationSlice";
import { useNavigate } from "react-router-dom";
import { CustomAlertMessage } from "../../utils";

/**
 * A React component that renders the 'Agregar Cliente' page.
 * @typedef AgregarClientePage
 * @returns A page with the Agregar Cliente components
 */
const AgregarClientePage = () => {

    const email = useSelector((state) => state.navigation.email);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const id = useSelector((state) => state.navigation.id);
    const navigate = useNavigate();

    // Dialog states
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogStatus, setDialogStatus] = useState("");
    const [uploadErrorCode, setUploadErrorCode] = useState("");
    const [uploadErrorMsg, setUploadErrorMsg] = useState("");

    const [formValues, setFormValues] = useState({
        client_id: "",
        client_name: "",
        url: "",

    })
    const [wasFocused, setWasFocused] = useState({
        client_id: false,
        client_name: false,
        url: false
    })

    const [errors, setErrors] = useState({
        client_id: t("AgregarClientePage:EmptyField"),
        client_name: t("AgregarClientePage:EmptyField"),
        url: t("AgregarClientePage:EmptyField")
    })


    /**
     * Function to handle input change in the form fields.
     *
     * @param {Object} e - The event object.
     */
    const handleFormInputChange = (e) => {

        let newFormValues = { ...formValues };
        let newErrors = { ...errors }
        if (e.target.id) {
            newFormValues[e.target.id] = e.target.value;
        } else {
            newFormValues[e.target.name] = e.target.value;
        }

        // Don't change the client_id field if the value has periods or hyphens
        if (e.target.id === "client_id") {
            if (!/^[a-zA-Z0-9]*$/.test(e.target.value)) {
                return;
            }
        }
        setFormValues(newFormValues);

        if (!e.target.value) {
            newErrors[e.target.id] = t("AgregarClientePage:EmptyField");
        }
        else {
            newErrors[e.target.id] = "";
        }

        setErrors(newErrors);
    }

    /**
     * Function to handle input focus in the form fields.
     *
     * @param {Object} e - The event object.
     */
    const handleFormInputFocus = (e) => {
        let newWasFocused = { ...wasFocused };
        newWasFocused[e.target.id] = true
        setWasFocused(newWasFocused);
    }

    /**
     * Function to handle file change in the file input field.
     *
     * @param {Object} e - The event object.
     */
    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    }

    /**
     * Function to handle the closing of the dialog.
     */
    const handleDialogClose = () => {
        setDialogOpen(false);
        setUploadErrorCode("");
        setUploadErrorMsg("");
    }

    /**
     * Function to reset the selected file in the file input field.
     */
    const resetFile = () => {
        setFile(null);
    }

    /**
     * Function to handle the download of the master clients template.
     */
    const handleDownloadTemplate = () => {

        dispatch(setAlert({
            open: true,
            severity: "info",
            message: t("Common:Download")
        }))

        serviallAxios.get("masters/clients_template", {
            timeout: TIMEOUTS.small,
            responseType: "blob"
        })
            .then((res) => {
                const href = URL.createObjectURL(res.data);
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "master_clients_template.xlsx");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }))
            })
    }

    /**
     * Function to handle the save action for adding a new client.
     */
    const handleSave = () => {
        if (file) {
            setLoading(true);
            setDialogOpen(true);
            setDialogStatus("loading");
            let payload = new FormData();
            payload.append("file", file);
            payload.append("client_name", formValues.client_name);
            payload.append("client_rut", formValues.client_id);
            payload.append("client_web_site", formValues.url);
            if (formValues.url) payload.append("client_web_site", formValues.url);
            serviallAxios.post("/masters/clients",
                payload,
                {
                    timeout: TIMEOUTS.medium,
                    headers: {
                        "Content-Type": 'multipart/form-data'
                    }
                })
                .then((res) => {
                    payload = {
                        s3_path: res.data.object_name,
                        client_name: formValues.client_name,
                        client_rut: formValues.client_id,
                        user_email: email,
                        user_id: id
                    }
                    serviallAxios.post(process.env.REACT_APP_ML_BACKEND_URL + "/masters/clients-ml", payload,
                        {
                            timeout: TIMEOUTS.xlong
                        })
                        .then((res) => {
                            setDialogStatus("success");

                            // Reset form data 
                            setFormValues({
                                client_id: "",
                                client_name: "",
                                url: ""
                            });
                            setWasFocused({
                                client_id: false,
                                client_name: false,
                                url: false
                            });
                            setErrors({
                                client_id: t("AgregarClientePage:EmptyField"),
                                client_name: t("AgregarClientePage:EmptyField"),
                                url: t("AgregarClientePage:EmptyField")
                            });
                            setFile(null);
                        })
                        .catch((err) => {
                            if (err.response) {
                                setUploadErrorCode(err.response.status);
                                setUploadErrorMsg(err.response.data.detail);
                            } else {
                                setUploadErrorCode(err.code);
                            }
                            setDialogStatus("error");
                        })
                        .finally(() => { setLoading(false) })
                })
                .catch((err) => {
                    if (err.response) {
                        setUploadErrorCode(err.response.status);
                        setUploadErrorMsg(err.response.data.detail);
                    } else {
                        setUploadErrorCode(err.code);
                    }
                    setDialogStatus("error");
                })
                .finally(() => setLoading(false))
        }
    }

    const provider_id_caption = t("AgregarClientePage:InputFieldDesc1")

    const provider_name_caption = t("AgregarClientePage:InputFieldDesc2")

    const url_caption = t("AgregarClientePage:InputFieldDesc3")

    return (
        <Stack direction={"column"} spacing={4}>
            <Stack direction={"column"}>
                <Typography className="serviall-page-title1">{t("AgregarClientePage:Title")}</Typography>
                <Box className="agregarCliente-subtitle-container">
                    <Typography className="serviall-page-title2">
                        {t("AgregarClientePage:Caption2")}
                    </Typography>
                </Box>
            </Stack>
            <Typography className="serviall-page-caption">
                {t("AgregarClientePage:Description")}
            </Typography>
            <Grid display="flex" justifyContent={{ xs: "space-between" }} flexDirection={{ xs: "column", tablet: "row" }} gap={{ xs: 3, tablet: 0 }}>
                <Grid display="flex" flexDirection={"column"} alignItems={"center"} width={{ xs: "100%", tablet: "50%" }} gap={{ xs: 3, tablet: 7 }}>
                    <ServiallInput
                        id={"client_id"}
                        width={"100%"}
                        label={t("AgregarClientePage:InputFieldTitle1")}
                        placeholder={t("AgregarClientePage:Placeholder1")}
                        caption={provider_id_caption}
                        errorText={errors.client_id && wasFocused.client_id ? errors.client_id : null}
                        type="text"
                        maxLength={16}
                        inputClassName="serviall-input"
                        value={formValues.client_id}
                        onFocus={handleFormInputFocus}
                        onChange={handleFormInputChange}
                        fullWidth={true}
                    />
                    <ServiallInput
                        id={"client_name"}
                        width={"100%"}
                        label={t("AgregarClientePage:InputFieldTitle2")}
                        placeholder={t("AgregarClientePage:Placeholder2")}
                        caption={provider_name_caption}
                        errorText={errors.client_name && wasFocused.client_name ? errors.client_name : null}
                        type="text"
                        maxLength={40}
                        value={formValues.client_name}
                        onFocus={handleFormInputFocus}
                        onChange={handleFormInputChange}
                        fullWidth={true}
                        inputClassName="serviall-input"
                    />
                    <ServiallInput
                        id={"url"}
                        width={"100%"}
                        label={t("AgregarClientePage:InputFieldTitle3")}
                        placeholder={t("AgregarClientePage:Placeholder3")}
                        caption={url_caption}
                        type="text"
                        maxLength={40}
                        value={formValues.url}
                        onFocus={handleFormInputFocus}
                        onChange={handleFormInputChange}
                        fullWidth={true}
                        inputClassName="serviall-input"
                    />
                </Grid>
                <Grid className="agregarCliente-grid-separator" />
                <Grid className="agregarCliente-grid-content" width={{ xs: "100%", tablet: "50%" }}>
                    
                    <Stack direction={"column"} gap={6.5}>
                        <ServiallStep
                            n_step={1}
                            text={t("AgregarClientePage:StepDesc1")}
                            icon={<SystemUpdateAltIcon onClick={handleDownloadTemplate} fontSize="large" />}
                        />
                        <ServiallStep
                            n_step={2}
                            text={t("AgregarClientePage:StepDesc2")}
                        />
                        <ServiallStep
                            text={
                                <Stack direction={"column"} spacing={1}>
                                    <ServiallUploadButton
                                        primaryText={t("AgregarClientePage:InputFieldTitle4")}
                                        secondaryText={t("AgregarClientePage:InputFieldDesc4")}
                                        tertiaryText={t("AgregarClientePage:InputFieldFormat4")}
                                        statusText={t("AgregarClientePage:InputFieldSubDesc4")}
                                        filename={file && file.name}
                                        handleFileChange={handleFileChange}
                                        resetFile={resetFile}
                                        disabled={false}
                                    />
                                    
                                </Stack>
                            }
                        />


                    </Stack>
                    <Box display={"flex"} flexDirection={"row-reverse"} marginRight={{ xs: 0, md: "4rem" }} marginTop={4} width={"100%"}>
                        <Button onClick={handleSave} disabled={loading || !file || errors.client_id || errors.client_name} sx={{ textTransform: "none", padding: "10px 30px 5px 30px" }} className="serviall-button">
                            {t("AgregarClientePage:Save")}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Typography className="serviall-back-text" onClick={() => navigate(-1)}>
                <ArrowBackIcon /> {t("Common:GoBackButton")}
            </Typography>
            <ServiallDialog
                open={dialogOpen}
                status={dialogStatus}
                loading_msg={"Cargando archivo..."}
                error_code={uploadErrorCode}
                error_msg={uploadErrorMsg}
                error_type={"file-error"}
                handleClose={handleDialogClose}
            />
        </Stack>
    )
}

AgregarClientePage.displayName = "AgregarClientePage";


export default AgregarClientePage;