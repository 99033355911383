import { Box} from "@mui/material";
import { useTranslation } from "react-i18next";
import "./MaestroProveedoresPage.css";
import 'react-data-grid/lib/styles.css';
import DraggableDataGrid from "../../components/DraggableDataGrid";
import { useSelector, useDispatch} from "react-redux";
import { setFilter } from "../../features/displaySlice";
import { setRowsPerPageMaestroProveedores } from "../../features/displaySlice";
import { useState } from "react";

/**
 * A React component that renders the datagrid for the Maestro Proveedores page.
 * @typedef MaestroProveedoresPageDatagrid
 * @returns A component with the datagrid for the Maestro Proveedores Page.
 */

const MaestroProveedoresPageDatagrid = ({ rows }) => {

    const displayState = useSelector((state) => state.display.maestroProveedores);
    const filters = displayState.filters;
    const { t } = useTranslation();

    const initialState = {
        id_proveedor: "",
        proveedor: "",
        sitio_web: "",
        fecha_actualizacion: "",
        sku_cargados: "",
        calidad_carga: "",
    }
    const colWidth = 162;
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const rowsPerPage = displayState.rowsPerPage;
    
    /**
     * Handles the change of the current page number in a paginated view.
     *
     * @param {Object} e - The event object triggered by the page change.
     * @param {number} newPage - The new page number to be set.
     * @returns {void}
     */
    const handlePageChange = (e, newPage) => {
        setPage(newPage);
    
    }

    /**
     * Handles the change of the number of rows displayed per page.
     *
     * @param {Object} e - The event object triggered by the rows-per-page change.
     * @returns {void}
     */
    const handleChangeRowsPerPage = (e) => {
        dispatch(setRowsPerPageMaestroProveedores(parseInt(e.target.value)));
        setPage(0);
    }
    
    /**
     * A function that retrieves the unique key for a row in the datagrid.
     * @function
     * @param {Object} row - The row object for which the key needs to be retrieved.
     * @returns {string|number} The unique key for the specified row.
     */
    const rowKeyGetter = (row) => {
        return row.id;
    }
    const dataGridCols = [
        {
            key: 'id_proveedor',
            name: t("MaestroProveedoresPage:DataGridCol1"),
            width: colWidth - 30,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'proveedor',
            name: t("MaestroProveedoresPage:DataGridCol2"),
            width: colWidth - 30,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'sitio_web',
            name: t("MaestroProveedoresPage:DataGridCol3"),
            width: colWidth,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'fecha_actualizacion',
            name: t("MaestroProveedoresPage:DataGridCol4"),
            width: colWidth + 20,
            headerCellClass: "serviall-datagrid-header2",
            type: "date",
            resizable: true,
            sortable: true,
        },
        {
            key: 'sku_cargados',
            name: t("MaestroProveedoresPage:DataGridCol5"),
            width: colWidth + 50,
            headerCellClass: "serviall-datagrid-header1",
            type: "number",
            resizable: true,
            sortable: true,
        },
        {
            key: 'calidad_carga',
            name: t("MaestroProveedoresPage:DataGridCol6"),
            width: colWidth + 70,
            headerCellClass: "serviall-datagrid-header2",
            type: "number",
            resizable: true,
            sortable: true,
        },
        {
            key: 'actions',
            reset: true,
            name: t("MaestroProveedoresPage:DataGridCol9"),
            width: colWidth,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
        },
    ]

    // Fill with empty rowsfor style purposes only
    if (rows.length < 10) {
        let emptyRow = {
            id_proveedor: "",
            proveedor: "",
            sitio_web: "",
            fecha_actualizacion: "",
            sku_cargados: "",
            calidad_carga: "",
            actions: ""
        }

        for (let i = rows.length; i < 12; i++) {
            rows.push({ id: i, ...emptyRow });
        }
    }

    return (
        <Box className="serviall-datagrid-container">
            <DraggableDataGrid
                className="serviall-datagrid"
                cols={dataGridCols}
                rows={rows}
                rowKeyGetter={rowKeyGetter}
                headerRowHeight={90}
                initialState={initialState}
                filters={filters}
                setFiltersAction={setFilter}
                page={"maestroProveedores"}
                pageNum={page}
                rowsPerPage={rowsPerPage}
                handlePageChange={handlePageChange}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Box>
    )
}

MaestroProveedoresPageDatagrid.displayName = "MaestroProveedoresPageDatagrid";

export default MaestroProveedoresPageDatagrid;