import saveAs from 'file-saver';
import XLSX from 'sheetjs-style';
import i18next from "i18next";

export const TIMEOUTS = {
    backendDefault: parseInt(process.env.REACT_APP_BACKEND_DEFAULT_TIMEOUT_MS) || 30000,
    mlBackendDefault: parseInt(process.env.REACT_APP_ML_BACKEND_DEFAULT_TIMEOUT_MS) || 1800000,
    small: parseInt(process.env.REACT_APP_SMALL_TIMEOUT) || 30000,
    medium: parseInt(process.env.REACT_APP_MEDIUM_TIMEOUT) || 60000,
    long: parseInt(process.env.REACT_APP_LONG_TIMEOUT) || 120000,
    xlong: parseInt(process.env.REACT_APP_XLONG_TIMEOUT) || 300000,
    huge: parseInt(process.env.REACT_APP_HUGE_TIMEOUT) || 600000,
    xhuge: parseInt(process.env.REACT_APP_XHUGE_TIMEOUT) || 900000
}

console.log(TIMEOUTS);

export const MATCH_FAMILIES = [
    'ACTIVO FIJO', 'ALIMENTOS', 'ALLIOT Y SOLO MEL', 'ALMACENAMIENTO Y EMB',
    'ARTICULOS MARKETING', 'ASEO Y LIMPIEZA GRAL', 'CAJAS Y BOLSOS HERRA', 'COMUNICACION E ILUMI',
    'CONSTR Y FABRICACION', 'ELECTRICIDAD', 'EPP', 'EQUIPOS AUTONOMOS',
    'FIJACION Y CERRAJERI', 'GASFITERIA', 'HERR DE ESPECIALIDAD', 'HERR ELECT E INALAMB',
    'HERR HIDRAULICAS', 'HERR MANUALES', 'HERR MED, INS Y TRA', 'HERR NEUMATICAS',
    'INSUMOS AUTOMOVIL', 'IZAJE Y HERR LEVANTE', 'LIBRERIA', 'MUEBLES Y EXTERIOR',
    'PINTURAS Y COMPLEMEN', 'QUIM. ESPECIALIDAD', 'ROPA CORPORATIVA', 'SEGURIDAD INDUSTRIAL',
    'SELLANTES, ADH E IMP', 'SERVICIOS', 'SERVICIOS EXTERNOS', 'SOLDADURAS Y CONSUMI'
]

export const ITEM_DATA_FIELDS = [
    "Marca/Fabricante",
    "Número de parte",
    "UM",
    "Cantidad por empaque",
    "Factor equivalencia cte",
    "Plazo de entrega",
    "Costo estimado",
    "Moneda del costo",
    "Precio Venta sugerido",
    "Moneda del precio venta",
    "Oportunidad valorizada",
    "Moneda de oportunidad valorizada"
]

/** 
* Download an excel file in the browser.
* @param {String} filename - Name of the downloaded file.
* @param {String} excelData - JSON string of the data to save.
*/
export const make_excel = (filename, excelData) => {
    const filetype = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const extension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'Page1': ws }, SheetNames: ["Page1"] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const fileData = new Blob([excelBuffer], { type: filetype });
    saveAs(fileData, filename + extension);
}

/** 
* Make a folder path required to access an S3 resource.
* @param {Object} data - Licitation data to create the necessary routes for the AWS S3 service for instance.
* data has the shape:
* {
*   op_id: String - the opportunity id
*   rut_cliente: String - the client identifier
*   fecha_creacion: String - the creation date, has shape "day/month/year" or "year-month-day"
*   lang: String - licitation language
*   families: String[] - list of provider families
* }
* @return {String} The s3 folder path.
*/
export const makeS3FolderPath = (data) => {

    if (data.fecha_creacion.includes("/")) {
        let [day, month, year] = data.fecha_creacion.split("/");
        let s3_folder = `licitations/${process.env.REACT_APP_S3_FOLDER}/${data.rut_cliente.toUpperCase()}/${data.op_id}_${year}-${month}-${day}`;

        console.log("LICITATIONS", data.fecha_creacion)
        console.log("LICITATIONS", year, month, day)
        console.log("LICITATIONS", s3_folder)
        return s3_folder
    }

    if (data.fecha_creacion.includes("-")) {

        let [year, month, day] = data.fecha_creacion.split("-");
        let s3_folder = `licitations/${process.env.REACT_APP_S3_FOLDER}/${data.rut_cliente.toUpperCase()}/${data.op_id}_${year}-${month}-${day}`;
        console.log("LICITATIONS", data.fecha_creacion)
        console.log("LICITATIONS", year, month, day)
        console.log("LICITATIONS", s3_folder)

        return s3_folder
    }
}

/** 
* Make a base payload used when calling the ML backend.
* @param {Object} data - Licitation data to create the necessary payload to be used in the ml route.
* data has the shape:
* {
*   op_id: String - the opportunity id
*   rut_cliente: String - the client identifier
*   fecha_creacion: String - the creation date, has shape "day/month/year" or "year-month-day"
*   lang: String - licitation language
*   families: String[] - list of provider families
* }
* @return {Object} The object required to use the ML resource. It has the shape:
* {
*    s3_path: String
*    id_opportunity: String
*    client_rut: String
*    family: String[]
* }
*/
export const makeMlPayload = (data) => {
    var payload = {}
    payload["s3_path"] = makeS3FolderPath(data) + "/licitation_latest.xlsx";
    payload["id_opportunity"] = data.op_id;

    if (data.fecha_creacion.includes("/")) {

        let [day, month, year] = data.fecha_creacion.split("/");
        let s3_basename = `licitations/${process.env.REACT_APP_S3_FOLDER}`;
        let s3_folder = `${data.rut_cliente.toUpperCase()}/${data.op_id}_${data.rut_cliente.toUpperCase()}_${year}-${month}-${day}`;
        let serviall_s3_fullname = `${s3_basename}/${s3_folder}/serviall_${data.lang}_${data.op_id}_${data.rut_cliente.toUpperCase()}_${year}-${month}-${day}.txt`;

        console.log(serviall_s3_fullname)

        if (data.families && data.families.length > 0) {
            payload["family"] = data.families;
        }
        payload["client_rut"] = data.rut_cliente.toUpperCase();
        return payload
    }

    if (data.fecha_creacion.includes("-")) {

        let [year, month, day] = data.fecha_creacion.split("-");
        let s3_basename = `licitations/${process.env.REACT_APP_S3_FOLDER}`;
        let s3_folder = `${data.rut_cliente.toUpperCase()}/${data.op_id}_${data.rut_cliente.toUpperCase()}_${year}-${month}-${day}`;
        let serviall_s3_fullname = `${s3_basename}/${s3_folder}/serviall_${data.lang}_${data.op_id}_${data.rut_cliente.toUpperCase()}_${year}-${month}-${day}.txt`;

        console.log(serviall_s3_fullname)

        if (data.families && data.families.length > 0) {
            payload["family"] = data.families;
        }
        payload["client_rut"] = data.rut_cliente.toUpperCase();

        return payload
    }

}

/** 
* Transform data to an object that can be used on a datagrid.
* @summary Transform data to an object that can be used on a datagrid. This data transformation is used in the edit 
* mode of ItemsLicitation.jsx datagrid. The list of parameters depend on received data from backend, most of them are 
* strings. 
* @return {Object} The row to be rendered.
*/
export const makeRowEditMode = (
    id,
    sku_cliente,
    desc_cliente,
    desc_complementaria,
    marca_cliente,
    pn_cliente,
    um,
    item_match,
    desc_match,
    marca_match,
    pn_match,
    criticidad_sa,
    proveedor,
    sku_proveedor,
    costo_estimado,
    moneda_costo,
    precio_venta_sugerido,
    moneda_precio_venta,
    oportunidad_valorizada,
    moneda_oportunidad_valorizada,
    igual,
    alternativo,
    um_match,
    consumo_anual,
    cantidad_empaque,
    factor_equivalencia_cte,
    plazo_entrega,
    similitud,
    no_match = false,
    extra_item = false,
    highlight_repeated = false, // Parameter for styling only
    from_client_items = false,
    auxiliar= ""
) => {
    return {
        id,
        sku_cliente,
        desc_cliente,
        desc_complementaria,
        marca_cliente,
        pn_cliente,
        um,
        item_match,
        desc_match,
        marca_match,
        pn_match,
        criticidad_sa,
        proveedor,
        sku_proveedor,
        costo_estimado,
        moneda_costo,
        precio_venta_sugerido,
        moneda_precio_venta,
        oportunidad_valorizada,
        moneda_oportunidad_valorizada,
        igual,
        alternativo,
        um_match,
        consumo_anual,
        cantidad_empaque,
        factor_equivalencia_cte,
        plazo_entrega,
        similitud,
        no_match,
        extra_item,
        highlight_repeated,
        from_client_items,
        auxiliar
    }
}

/** 
* Transform data to an object that can be used on a datagrid.
* @summary Transform data to an object that can be used on a datagrid. This data transformation is used in the 
* aggregated mode of ItemsLicitation.jsx datagrid. The list of parameters depend on received data from backend, most of 
* them are strings. 
* @return {Object} The row to be rendered.
*/
export const makeRowAggregatedMode = ({
    seleccion,
    redirect,
    index,
    itemsLicitationIndex,
    estado_revision,
    sku_cliente,
    desc_cliente,
    desc_complementaria,
    marca_cliente,
    pn_cliente,
    um,
    consumo_anual,
    num_alternativa,
    match_proveedor,
    tipo_match,
    no_match,
    item_match,
    sku_proveedor,
    desc_match,
    marca_match,
    pn_match,
    criticidad_sa,
    um_match,
    cantidad_empaque,
    factor_equivalencia_cte,
    plazo_entrega,
    costo_estimado,
    precio_venta_sugerido,
    oportunidad_valorizada
}) => {
    return {
        seleccion,
        redirect,
        index,
        itemsLicitationIndex,
        estado_revision: estado_revision,
        sku_cliente,
        desc_cliente,
        desc_complementaria,
        marca_cliente,
        pn_cliente,
        um,
        consumo_anual,
        num_alternativa,
        match_proveedor,
        tipo_match,
        no_match,
        item_match,
        sku_proveedor,
        desc_match,
        marca_match,
        pn_match,
        criticidad_sa,
        um_match,
        cantidad_empaque,
        factor_equivalencia_cte,
        plazo_entrega,
        costo_estimado,
        precio_venta_sugerido,
        oportunidad_valorizada
    }
}

/** 
* Transform data to an object that can be used on a datagrid.
* @summary Transform data to an object that can be used on a datagrid. This data transformation is used in the 
* extra client items datagrid of ItemsLicitation.jsx. The list of parameters depend on received data from backend, most of 
* them are strings. 
* @return {Object} The row to be rendered.
*/
export const makeExtraItemRows = ({
    extra_client_item = true,
    es_desc,
    en_desc,
    item_data,
    igual,
    alternativo,
    no_match = false,
}) => {
    return {
        extra_client_item,
        es_desc,
        en_desc,
        item_data,
        igual,
        alternativo,
        no_match
    }
}

export const CustomAlertMessage = (code) => {
    
    switch (code){
        case 401:
            return i18next.t("Common:401")
        case 403:
            return i18next.t("Common:403")
        case 404:
            return i18next.t("Common:404")
        case 408:
            return i18next.t("Common:408")
        case 409:
            return i18next.t("Common:409")
        case 413:
            return i18next.t("Common:413")
        case 422:
            return i18next.t("Common:422")
        case 429:
            return i18next.t("Common:429")
        case 431:
            return i18next.t("Common:431")
        case "ERR_NETWORK":
            return i18next.t("Common:ERR_NETWORK")
        case "ECONNABORTED":
            return i18next.t("Common:ECONNABORTED")
        default: 
            return i18next.t("Common:default")    
    }
}