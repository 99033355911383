import React, { useState, useEffect } from 'react'
import { Box, Typography, Stack, CircularProgress, Button, DialogTitle, DialogContent, DialogActions, Dialog, MenuList, MenuItem, Switch, IconButton, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ServiallInput from '../../components/ServiallInput'
import serviallAxios from "../../axiosConfig";
import "./ConfiguracionUsuarios.css"
import { TIMEOUTS } from '../../utils';
import EditIcon from "@mui/icons-material/Edit"
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useDispatch, useSelector } from 'react-redux';
import DraggableDataGrid from '../../components/DraggableDataGrid';
import { setFilter, setRowsPerPageConfiguracionGrupos } from '../../features/displaySlice';
import ResetFiltersBtn from '../../components/ResetFiltersBtn';
import { setAlert } from '../../features/navigationSlice';
import { CustomAlertMessage } from '../../utils';
import CloseIcon from '@mui/icons-material/Close';

/**
 * React component for configuring permission groups.
 *
 * This component allows users to view, add, edit, and delete permission groups.
 * It provides an interface for managing groups, and utilizes Redux for state management.
 * Fetches group data from the server using Axios requests.
 *
 * @component
 * @returns {JSX.Element}
 */

const ConfiguracionGrupos = () => {
    const { t } = useTranslation()
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingDialog, setLoadingDialog] = useState(false)
    const [wasFocused, setWasFocused] = useState(false)
    const [newGroup, setNewGroup] = useState({
        group_name: "",
        group_desc: ""
    })

    const [newAccount, setNewAccount] = useState({
        create_name: "",
        create_lastname: "",
        create_password: "",
        create_password_confirm: "",
        create_email: ""
    })

    const isEmailValid = (email) => {
        const validateRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (validateRegex.test(email)) {
            return true
        } else {
            return false
        }

    }



    const [createErrors, setCreateErrors] = useState({
        create_password: t("ConfiguracionCuenta:EmptyFieldError"),
        create_email: t("ConfiguracionCuenta:EmptyFieldError"),
        create_password_confirm: t("ConfiguracionCuenta:EmptyFieldError"),
    })
    const dispatch = useDispatch();
    const displayData = useSelector((state) => state.display.configuracionGrupos);

    const [page, setPage] = useState(0);
    const rowsPerPage = displayData.rowsPerPage;
    const [actualGroup, setActualGroup] = useState({
        id: "",
        descripcion: "",
        name: ""
    })
    const [actualUser, setActualUser] = useState({
        id: "",
        name: "",
        name: "",
        lastname: ""
    })

    const [createWasFocused, setCreateWasFocused] = useState({
        create_name: false,
        create_password: false,
        create_email: false,
        create_lastname: false,
        create_password_confirm: false,
    })

    const [userId, setUserId] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [userLastname, setUserLastname] = useState('');

    const [dialogOpen, setDialogOpen] = useState(false)

    const [users, setUsers] = useState([])


    /**
     * Handles the change of the current page in the data grid pagination.
     * Updates the current page index with the newly selected page.
     *
     * @function handlePageChange
     * @param {Object} e - The event object representing the change event.
     * @param {number} newPage - The index of the newly selected page.
     * @returns {void}
     */
    const handlePageChange = (e, newPage) => {
        setPage(newPage);

    }


    /**
     * Handles the change of rows per page in the data grid.
     * Dispatches an action to update the rows per page value in the Redux store.
     * Resets the current page index to 0 to ensure consistent pagination behavior.
     *
     * @function handleChangeRowsPerPage
     * @param {Object} e - The event object representing the change event.
     * @returns {void}
     */
    const handleChangeRowsPerPage = (e) => {
        dispatch(setRowsPerPageConfiguracionGrupos(parseInt(e.target.value)));
        setPage(0);
    }
    const initialState = {
        nombre: "",
        descripcion: ""
    }
    const cols = [
        {
            key: 'email',
            name: t("ConfiguracionUsuarios:Col1"),
            width: 150,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'fullname',
            name: t("ConfiguracionUsuarios:Col2"),
            width: 150,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'createdat',
            name: t("ConfiguracionUsuarios:Col4"),
            width: 150,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'acciones',
            name: t("ConfiguracionUsuarios:Col3"),
            width: 150,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
    ]

    /**
     * Retrieves a unique key for a given row in the data grid.
     * This key is typically used for identifying rows when rendering or updating.
     *
     * @function rowKeyGetter
     * @param {Object} row - The data object representing a row in the data grid.
     * @returns {string|number} The unique key for the provided row.
     */
    const rowKeyGetter = (row) => {
        return row.id;
    }

    const displayState = useSelector((state) => state.display.configuracionGrupos);
    const filters = displayState.filters;

    /**
     * Fetches information about permission groups from the server using an Axios GET request.
     * Updates the component's state with the fetched data and manages loading state accordingly.
     *
     * This function sets loading to true before making the request and sets it back to false
     * after the request completes (whether successful or not).
     *
     * @function fetchInfo
     * @returns {void}
    */
    const fetchInfo = () => {
        setLoading(true)
        serviallAxios.get("/users/get_users", {
            timeout: TIMEOUTS.small
        })
            .then(res => {
                const newRows = []
                res.data.users.forEach((data) => {
                    const newObj = {
                        email: data[1],
                        fullname: data[2] + " " + data[3],
                        createdat: data[4],
                        acciones: <>
                            <EditIcon className='configuracion-grupos-icon' size={12} onClick={() => handleEdit(data[0], data[1], data[2], data[3])} />
                        </>
                    }

                    newRows.push(newObj)
                })
                setRows(newRows)
            })
            .catch(e => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: e.response ? e.response.data.detail : CustomAlertMessage(e.code)
                }));
            }).finally(() => {
                setLoading(false);
            })
    }


    /**
     * React useEffect hook that fetches information about permission groups from the server when the component mounts.
     * It triggers the 'fetchInfo' function to retrieve and update the component's state with fetched data.
     *
     * The effect only runs once, immediately after the component is mounted, due to an empty dependency array.
     * This ensures that the data is fetched only on the initial render.
     *
     * @function useEffect
     * @returns {void}
     */
    useEffect(() => {
        fetchInfo()
    }, [])

    /**
     * Handles the change event on the input fields of the create account form.
     * Updates the form values and error messages based on the user's input.
     *
     * @param {object} e - The change event object.
     * @returns {void}
     */
    const handleCreateFormInputChange = (e) => {
        let newFormValues = { ...newAccount }
        let newErrors = { ...createErrors }
        newFormValues[e.target.id] = e.target.value;
        setNewAccount(newFormValues)
        if (!e.target.value) {
            newErrors[e.target.id] = t("ConfiguracionCuenta:EmptyFieldError");
        }
        else if (e.target.id === "create_email") {
            if (!isEmailValid(e.target.value)) {
                newErrors[e.target.id] = t("ConfiguracionCuenta:EmailError");
            } else {
                newErrors[e.target.id] = "";
            }
        }
        else {
            newErrors[e.target.id] = "";
        }
        setCreateErrors(newErrors);
    }

    /**
     * Handles input field focus events.
     * Updates the wasFocused state to indicate that an input field has been focused.
     * @param {Object} e - The input focus event.
     * @returns {void}
     */
    const handleCreateFormInputFocus = (e) => {
        let newWasFocused = { ...createWasFocused };
        newWasFocused[e.target.id] = true
        setCreateWasFocused(newWasFocused);
    }


    /**
     * Handle the editing of a group by setting its ID, name, and description, and then fetching
     * group users' information to populate the dialog.
     *
     * @param {number} id - The ID of the group to edit.
     * @param {string} name - The updated name for the group.
     * @param {string} description - The updated description for the group.
     */
    const handleEdit = (id, email, name, lastname) => {
        const currentUser = {
            id: id,
            email: email,
            name: name,
            lastname: lastname
        }
        setUserId(id)
        setUserEmail(email)
        setUserName(name)
        setUserLastname(lastname)

        setActualUser(currentUser)
        setDialogOpen(true)
        setLoadingDialog(true)
        const params = {
            user_id: id
        }

    }


    /**
     * Handles input changes and updates the 'newGroup' state accordingly.
     *
     * If the input element's ID is 'group_name', it sets the 'wasFocused' state to true.
     * Then, it updates the 'newGroup' state by creating a shallow copy and modifying the property
     * corresponding to the changed input.
     *
     * @function handleChange
     * @param {Object} e - The event object representing the input change event.
     * @returns {void}
     */
    const handleChange = (e) => {
        if (e.target.id === "group_name") {
            setWasFocused(true)
        }
        const auxGroup = { ...newGroup }
        auxGroup[e.target.id] = e.target.value
        setNewGroup(auxGroup)
    }

    /**
     * Handles the submission of a new group's data to the server.
     * Constructs a payload with 'group_name' and 'group_desc' from the 'newGroup' state.
     * Sends a POST request to create a new group with the provided payload.
     * Upon successful response, triggers a data refresh by calling 'fetchInfo' and clears the 'newGroup' state.
     * If an error occurs during the request, logs the error.
     * Finally, sets 'wasFocused' state to false.
     *
     * @function handleSubmit
     * @returns {void}
     */
    const handleSubmit = () => {
        const payload = {
            group_name: newGroup.group_name,
            group_desc: newGroup.group_desc
        }
        serviallAxios.post("/permissions/groups", payload, {
            timeout: TIMEOUTS.small
        })
            .then(res => {
                fetchInfo()
                setNewGroup({ group_name: "", group_desc: "" })
            })
            .catch(e => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: e.response ? e.response.data.detail : CustomAlertMessage(e.code)
                }));
            }).finally(() => {
                setWasFocused(false)
            })
    }

    /**
     * Handles the submission of user creation data.
     * Sends a POST request to create a new user account.
     * Displays an alert on success.
     * Displays an alert in case of an error.
     *
     * @returns {void}
     */
    const handleSubmitCreate = () => {
        const payload = {
            email: newAccount.create_email,
            first_name: newAccount.create_name,
            last_name: newAccount.create_lastname,
            password: newAccount.create_password,
            password_confirmation: newAccount.create_password_confirm
        }

        serviallAxios.post("/auth/register", payload, {
            timeout: TIMEOUTS.medium
        })
            .then(res => {
                dispatch(setAlert({
                    open: true,
                    severity: "success",
                    message: t("ConfiguracionCuenta:AlertSuccesful")
                }))
            })
            .catch(e => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: e.response ? e.response.data.detail : CustomAlertMessage(e.code)
                }))
            })
    }

    /**
     * Handle the alteration of a group by sending a PUT request to update group permissions.
     * It also displays appropriate alerts based on the operation's success or failure.
     */
    const handleAlterUser = () => {
        dispatch(setAlert({
            open: true,
            severity: "info",
            message: t("ConfiguracionUsuarios:PutProcess")
        }))
        //const new_users = users.filter((user) => user.active === true).map(({ id }) => id)
        //const remove_users = users.filter((user) => user.active === false).map(({ id }) => id)

        const payload = {
            email: userEmail,
            first_name: userName,
            last_name: userLastname
        }
        serviallAxios.put("/users/update_user", payload, {
            timeout: TIMEOUTS.medium
        })
            .then((res) => {
                dispatch(setAlert({
                    open: true,
                    severity: "success",
                    message: t("ConfiguracionUsuarios:PutSuccess")
                }))
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }))
            })
            .finally(() => {
                setDialogOpen(false)
                setUsers([])
                setUserEmail('')
                setUserName('')
                setUserLastname('')
                setActualGroup({})
                fetchInfo()

            })


    }


    /**
     * Handle the alteration of a group by sending a PUT request to update group permissions.
     * It also displays appropriate alerts based on the operation's success or failure.
     */
    const handleResetPasswordUser = (email) => {
        dispatch(setAlert({
            open: true,
            severity: "info",
            message: t("ConfiguracionUsuarios:PutProcess")
        }))
        //const new_users = users.filter((user) => user.active === true).map(({ id }) => id)
        //const remove_users = users.filter((user) => user.active === false).map(({ id }) => id)

        const payload = {
            email
        }
        serviallAxios.put("/users/reset_password_user", payload, {
            timeout: TIMEOUTS.medium
        })
            .then((res) => {
                dispatch(setAlert({
                    open: true,
                    severity: "success",
                    message: t("ConfiguracionUsuarios:PutSuccess")
                }))
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }))
            })
            .finally(() => {
                setDialogOpen(false)
                setUsers([])
                setUserEmail('')
                setUserName('')
                setUserLastname('')
                setActualGroup({})
                fetchInfo()

            })


    }

    /**
     * Handle the change of the group's description.
     *
     * @param {Object} e - The event object containing the target element.
     */
    const handleChangeDescription = (e) => {
        const currentGroup = { ...actualGroup }
        currentGroup[e.target.id] = e.target.value
        setActualGroup(currentGroup)
    }

    const label = { inputProps: { 'aria-label': 'Group user switch' } };

    return (
        <>
            <Box marginBottom={4}>
                <Typography className="serviall-page-title1">{t("ConfiguracionUsuarios:Title")}</Typography>
                <Typography className="serviall-page-title2">{t("ConfiguracionUsuarios:Description")}</Typography>
            </Box>


            <Typography className="configuracion-grupos-section">{t("ConfiguracionCuenta:Caption2")}</Typography>
            <Box display={"flex"} flexDirection={{ xs: "column", tablet: "row" }} width={"100%"} gap={{ xs: 4 }} marginBottom={4}>
                <ServiallInput
                    id={"create_name"}
                    label={t("ConfiguracionCuenta:NameInputLabel")}
                    placeholder={t("ConfiguracionCuenta:CreateNameInputPlaceholder")}
                    type="text"
                    value={newAccount.create_name}
                    onFocus={handleCreateFormInputFocus}
                    onChange={handleCreateFormInputChange}
                    inputClassName="serviall-input"
                />
                <ServiallInput
                    id="create_lastname"
                    label={t("ConfiguracionCuenta:LastnameInputLabel")}
                    placeholder={t("ConfiguracionCuenta:CreateLastnameInputPlaceholder")}
                    type="text"
                    value={newAccount.create_lastname}
                    onFocus={handleCreateFormInputFocus}
                    onChange={handleCreateFormInputChange}
                    inputClassName="serviall-input"
                />

            </Box>

            <Box display={"flex"} flexDirection={{ xs: "column", tablet: "row" }} width={"100%"} gap={{ xs: 4 }} marginBottom={4}>
                <ServiallInput
                    id="create_password"
                    label={t("ConfiguracionCuenta:PasswordInputLabel")}
                    placeholder={t("ConfiguracionCuenta:CreatePasswordInputPlaceholder")}
                    errorText={(newAccount.create_password !== newAccount.create_password_confirm && t("ConfiguracionCuenta:PasswordError"))
                        || (createWasFocused.create_password && !newAccount.create_password && t("ConfiguracionCuenta:EmptyFieldError"))}
                    type="password"
                    value={newAccount.create_password}
                    onFocus={handleCreateFormInputFocus}
                    onChange={handleCreateFormInputChange}
                    inputClassName="serviall-input"
                />
                <ServiallInput
                    id="create_password_confirm"
                    label={t("ConfiguracionCuenta:PasswordConfirmInputLabel")}
                    placeholder={t("ConfiguracionCuenta:CreatePasswordConfirmInputPlaceholder")}
                    errorText={(newAccount.create_password !== newAccount.create_password_confirm && t("ConfiguracionCuenta:PasswordError"))
                        || (createWasFocused.create_password_confirm && !newAccount.create_password_confirm && t("ConfiguracionCuenta:EmptyFieldError"))}
                    type="password"
                    value={newAccount.create_password_confirm}
                    onFocus={handleCreateFormInputFocus}
                    onChange={handleCreateFormInputChange}
                    inputClassName="serviall-input"
                />
            </Box>
            <Box display={"flex"} flexDirection={{ xs: "column", tablet: "row" }} width={"100%"} gap={{ xs: 4 }} marginBottom={4}>
                <ServiallInput
                    id="create_email"
                    label={t("ConfiguracionCuenta:MailInputLabel")}
                    placeholder={t("ConfiguracionCuenta:CreateMailInputPlaceholder")}
                    errorText={createErrors.create_email && createWasFocused.create_email ? createErrors.create_email : null}
                    type="email"
                    value={newAccount.create_email}
                    onFocus={handleCreateFormInputFocus}
                    onChange={handleCreateFormInputChange}
                    inputClassName="serviall-input"
                />
            </Box>
            <Box display={"flex"} justifyContent={"flex-end"} marginTop={"2rem"} width={"100%"}>
                <Button
                    className="serviall-button configuracion-cuenta-save-button"
                    disabled={newAccount.create_password !== newAccount.create_password_confirm
                        || !isEmailValid(newAccount.create_email) || !newAccount.create_password}
                    onClick={handleSubmitCreate}
                >
                    {t("ConfiguracionCuenta:Save")}
                </Button>
            </Box>


            <Box>
                {
                    loading ?

                        <Stack className="configuracion-permisos-loading-container" direction={"column"} spacing={3}>
                            <Typography className="configuracion-permisos-loading-label">
                                {t("ConfiguracionUsuarios:Loading")}
                            </Typography>
                            <CircularProgress size={50} />
                        </Stack>
                        :
                        <>
                            <Typography marginBottom={2} className="configuracion-grupos-section">{t("ConfiguracionUsuarios:Caption2")}</Typography>
                            <Box marginBottom={"2rem"} width={"100%"} display={"flex"} justifyContent={"flex-end"} >
                                <ResetFiltersBtn page={"configuracionGrupos"} />

                            </Box>
                            <Box className="serviall-datagrid-container">
                                <DraggableDataGrid
                                    cols={cols}
                                    rows={rows}
                                    rowKeyGetter={rowKeyGetter}
                                    className="serviall-datagrid"
                                    headerRowHeight={90}
                                    initialState={initialState}
                                    filters={filters}
                                    setFiltersAction={setFilter}
                                    page={"configuracionGrupos"}
                                    pageNum={page}
                                    handlePageChange={handlePageChange}
                                    rowsPerPage={rowsPerPage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                />

                            </Box>
                            <Dialog
                                open={dialogOpen}
                                onClose={() => { setDialogOpen(false) }}
                            >
                                <DialogTitle>
                                    <Typography className="versions-dialog-title">
                                        {t("ConfiguracionUsuarios:DialogTitle")}: {actualGroup.name}
                                    </Typography>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => setDialogOpen(false)}
                                        className="dialog-close-button-icon"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Stack direction={"column"} marginBottom={4}>

                                        <ServiallInput
                                            value={userEmail}
                                            id={"email"}
                                            label={t("ConfiguracionUsuarios:DialogInput1")}
                                            placeholder={t("ConfiguracionUsuarios:DialogPlaceholder1")}
                                            type="text"
                                            fullWidth={true}
                                            inputClassName="serviall-input"
                                            disabled={true}
                                        />

                                        <ServiallInput
                                            onChange={(e) => setUserName(e.target.value)}
                                            value={userName}
                                            id={"name"}
                                            label={t("ConfiguracionUsuarios:DialogInput2")}
                                            placeholder={t("ConfiguracionUsuarios:DialogPlaceholder2")}
                                            type="text"
                                            fullWidth={true}
                                            inputClassName="serviall-input" />

                                        <ServiallInput
                                            onChange={(e) => setUserLastname(e.target.value)}
                                            value={userLastname}
                                            id={"lastname"}
                                            label={t("ConfiguracionUsuarios:DialogInput3")}
                                            placeholder={t("ConfiguracionUsuarios:DialogPlaceholder3")}
                                            type="text"
                                            fullWidth={true}
                                            inputClassName="serviall-input" />
                                    </Stack>


                                </DialogContent>
                                <DialogActions>
                                    <Button className="serviall-button-error" onClick={() => handleResetPasswordUser(userEmail)}>
                                        {t("ConfiguracionUsuarios:ResetPasswordBtn")}
                                    </Button>
                                    <Button className="serviall-button-error" onClick={handleAlterUser}>
                                        {t("ConfiguracionUsuarios:SaveBtn")}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>


                }
            </Box>



        </>
    )

}

export default ConfiguracionGrupos