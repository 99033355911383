import { Alert, Snackbar } from "@mui/material"
import { useDispatch } from "react-redux"
import { resetAlert } from "../features/navigationSlice";

/**
 * A React component to display a snackbar with a message and severity
 * @param {boolean} snackbarOpen - Whether the snackbar is open
 * @param {string} severity - The severity of the snackbar
 * @param {string} message - The message to display in the snackbar
 * @param {number} duration - The duration of the snackbar in milliseconds
 * @returns a React component to display a snackbar with a message and severity
 */

const ServiallSnackbar = ({ snackbarOpen, severity, message, duration }) => {
    const dispatch = useDispatch();

    return (
        <Snackbar
            open={snackbarOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={duration || 15000}
            onClose={() => dispatch(resetAlert())}
        >
            <Alert
                onClose={() => dispatch(resetAlert())}
                severity={severity}
                sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}

ServiallSnackbar.displayName = 'ServiallSnackbar';

export default ServiallSnackbar;