import { Box, Grid, Paper, Stack, Typography, Button, Tooltip } from "@mui/material";
import "./VerDetalleMaestrosPage.css"
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ServiallStep from "../../components/ServiallStep";
import CircleIcon from '@mui/icons-material/Circle';
import UploadButton from "../../components/ServiallUploadButton";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import serviallAxios from "../../axiosConfig";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { TIMEOUTS } from "../../utils";
import { useDispatch } from "react-redux";
import { setAlert } from "../../features/navigationSlice";
import ServiallDialog from "../../components/ServiallDialog";
import { useSelector } from "react-redux";
import { CustomAlertMessage } from "../../utils";

/**
 * A React component that renders the 'Detalles' of the Maestros page.
 * @typedef VerDetalleMaestrosPage
 * @returns A page with the component to display details for masters pages
 */

const VerDetalleMaestrosPage = ({ variant }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation()
    // TODO: una vez creadas las translations esto desaparece
    const type_string_singular = variant === "client" ? "cliente" : "proveedor"
    const type_string_plural = variant === "client" ? "clientes" : "proveedores"

    const email = useSelector((state) => state.navigation.email);
    const id = useSelector((state) => state.navigation.id);
    const [masterData] = useState(location.state);
    const [file, setFile] = useState(null);
    const [masterDetails, setMasterDetails] = useState({})

    // Dialog states
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogStatus, setDialogStatus] = useState("");
    const [uploadErrorCode, setUploadErrorCode] = useState("");
    const [uploadErrorMsg, setUploadErrorMsg] = useState("");

    /**
     * Handle the change event when a new file is selected for upload.
     * It updates the 'file' state with the selected file.
     * 
     * @param {Object} e - The event object representing the file change event.
     * @returns {void}
     */
    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    }

    /**
    * Reset the selected file by setting the 'file' state to null.
    * 
    * @returns {void}
    */
    const resetFile = () => {
        setFile(null);
    }

    /**
     * Handle the download of the template for the selected master type (cliente or proveedor).
     * It shows a success alert with a download message and then makes a GET request to the server
     * to download the template file in Excel format. The file is saved with a name based on the masterData.
     * 
     * @returns {void}
     */
    const handleDownloadTemplate = () => {

        dispatch(setAlert({
            open: true,
            severity: "info",
            message: t("Common:Download")
        }))

        const url = variant === "client" ? `masters/clients_template/${masterData.rut}` : `masters/providers_template/${masterData.rut}`;
        serviallAxios.get(url, {
            timeout: TIMEOUTS.small,
            responseType: "blob"
        })
            .then((res) => {
                if (res.data.size < 100) {
                    // This case is when an answer is returned but not a file, but since a responsetype blob is set,
                    // the return value will be made into a file

                    dispatch(setAlert({
                        open: true,
                        severity: "error",
                        message: t("DetalleMaestroPage:NotFound")
                    }))

                    return
                }
                const href = URL.createObjectURL(res.data);
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", `${masterData.name}_v${masterData.current_version}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => {
                if (variant === "client" && err.response && err.response.request.status === 404){
                    dispatch(setAlert({
                        open: true,
                        severity: "info",
                        message: t("DetalleMaestroPage:NoItems")
                    }))
                }
                else{
                    dispatch(setAlert({
                        open: true,
                        severity: "error",
                        message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                    }))
                }
            })
    }

    /**
     * Closes the dialog and resets error-related state values.
     *
     * This function is used to close a dialog window and clear any error-related state values
     * associated with uploading or processing data.
     *
     * @returns {void}
     */
    const handleDialogClose = () => {
        setDialogOpen(false);
        setUploadErrorCode("");
        setUploadErrorMsg("");
    }

    /**
     * Function to handle the save action for adding a new client.
     */
    const handleSave = () => {
        if (file) {
            setLoading(true);
            setDialogOpen(true);
            setDialogStatus("loading");
            let payload = new FormData();
            payload.append("file", file);
            payload.append(`${variant}_name`, masterData.name);
            payload.append(`${variant}_rut`, masterData.rut);
            payload.append(`${variant}_web_site`, masterData.websiste);
            serviallAxios.post(`/masters/${variant}s`,
                payload,
                {
                    timeout: TIMEOUTS.medium,
                    headers: {
                        "Content-Type": 'multipart/form-data'
                    }
                })
                .then((res) => {
                    if (variant === "client"){
                        payload = {
                            s3_path: res.data.object_name,
                            client_name: masterData.name,
                            client_rut: masterData.rut,
                            user_email: email,
                            user_id: id
                        }
                    }
                    else{
                        payload={
                            s3_path: res.data.object_name,
                            provider_name: masterData.name,
                            prov_rut: masterData.rut,
                            user_email: email,
                        }
                    }
                    

                    serviallAxios.post(process.env.REACT_APP_ML_BACKEND_URL + `/masters/${variant}s-ml`, payload,
                        {
                            timeout: TIMEOUTS.xlong
                        })
                        .then((res) => {
                            if (variant === 'provider'){
                                setDialogStatus("processing");
                            }
                            if (variant === "client"){
                                setDialogStatus("success");
                            }
                            setFile(null);
                        })
                        .catch((err) => {
                            if (err.response) {
                                setUploadErrorCode(err.response.status);
                                setUploadErrorMsg(err.response.data.detail);
                            } else {
                                setUploadErrorCode(err.code);
                            }
                            setDialogStatus("error");
                        })
                        .finally(() => { setLoading(false) })
                })
                .catch((err) => {
                    if (err.response) {
                        setUploadErrorCode(err.response.status);
                        setUploadErrorMsg(err.response.data.detail);
                    } else {
                        setUploadErrorCode(err.code);
                    }
                    setDialogStatus("error");
                })
                .finally(() => setLoading(false))
        }
    }

    /** 
    * Useeffect for the the case when user goes to the link by changing the url manually to this page.
    * @return {void}
    */
    useEffect(() => {
        if (!masterData) {
            navigate("/dashboard/home");
        }
    }, [masterData])

    /**
     * useEffect hook to fetch and update master details based on the selected variant.
     * 
     * This effect is triggered when the component mounts, and it fetches master data
     * based on the provided variant and masterData.rut. The fetched data is used to update
     * the state of master details using the setMasterDetails function.
     * 
     * @param {string} variant - The variant indicating whether the data is for a "client" or not.
     * @param {object} masterData - The master data object containing the "rut" identifier.
     * @param {function} setMasterDetails - The state update function for master details.
     */
    useEffect(() => {
        if (masterData) {
            let url;
            if (variant === "client") {
                url = `masters/master_client_data/${masterData.rut}`
            }
            else {
                url = `masters/master_provider_data/${masterData.rut}`
            }
            serviallAxios.get(url, {
                timeout: TIMEOUTS.small,
            })
                .then((res) => {
                    setMasterDetails(res.data.data)
                })
                .catch((err) => {
                    dispatch(setAlert({
                        open: true,
                        severity: "error",
                        message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                    }));
                })
        }
    }, [])

    return (
        <Stack spacing={4} direction={"column"}>
            <Box display={"flex"} flexDirection={{ xs: "column", sm: "row" }} gap={{ sm: "0.5rem" }} alignItems={{ sm: "center" }}>
                <Typography className="serviall-page-title1">
                    {t("DetalleMaestroPage:Title")}
                </Typography>
                <Typography className="serviall-page-title2">
                    &nbsp; {i18next.t("DetalleMaestroPage:Heading", { master: type_string_plural })}
                </Typography>
            </Box>
            <Grid display={"flex"} flexDirection={{ xs: "column", tablet: "row" }}>
                <Grid item className="VerDetalleMaestros-grid-left-content VerDetalleMaestrsos-grid-separator" width={{ xs: "100%", tablet: "50%" }}>
                    <Stack direction={"column"} spacing={4}>
                        {masterData &&
                            <Stack direction={"column"} spacing={0.5}>
                                <Typography className="VerDetalleMaestros-property-title">
                                    <ArrowRightIcon className="serviall-color-main" />{masterData.rut}
                                </Typography>
                                <Typography className="VerDetalleMaestros-property-value">
                                    {masterData.name}
                                </Typography>
                                <Typography className="VerDetalleMaestros-property-value">
                                    {masterData.website}
                                </Typography>
                            </Stack>
                        }
                        <Grid display={"flex"} flexDirection={{ xs: "column", sm: "row" }} gap={{ xs: 3 }}>
                            <Grid item xs={"100%"} md={5} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                <Typography className="VerDetalleMaestros-property-title">
                                    {t("DetalleMaestroPage:Sku")}
                                </Typography>
                                <Typography className="VerDetalleMaestros-property-value">
                                {masterDetails && masterDetails["Total sku cargados"] !== undefined ? masterDetails["Total sku cargados"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""}
                                </Typography>
                            </Grid>
                            <Grid item xs={"100%"} md={6} >
                                <Typography className="VerDetalleMaestros-property-title">
                                    {t("DetalleMaestroPage:LastUpdate")}
                                </Typography>
                                {masterData &&
                                    <Typography className="VerDetalleMaestros-property-value">
                                        {masterData.updated_at}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Stack direction={"column"}>
                            <Typography className="VerDetalleMaestros-property-title">
                                {t("DetalleMaestroPage:QualityLevel")}
                            </Typography>
                            <Typography className="VerDetalleMaestros-property-value">
                                {masterDetails && masterDetails["Nivel de calidad de carga"]}
                            </Typography>
                        </Stack>
                        <Typography className="serviall-gray1" style={{ whiteSpace: "pre-line" }}>
                            {t("DetalleMaestroPage:Description")}
                        </Typography>
                        <Paper sx={{ padding: "10px" }} elevation={2}> {/* tal vez poner un box con estilos, ahi ver que calza mejor*/}
                            <Grid container>
                                <Grid className="grid-separator VerDetalleMaestros-grid-left-content-box" width={{ xs: "100%", md: "50%" }}>
                                    <Stack direction={"column"}>
                                        <Typography className="VerDetalleMaestros-property-title">
                                            {t("DetalleMaestroPage:CardTitle1")}
                                        </Typography>
                                        <Typography className="VerDetalleMaestros-property-value">
                                            {t("DetalleMaestroPage:CardDesc11")} {masterDetails && masterDetails["Sku con match 90"]}
                                        </Typography>
                                        <Typography className="VerDetalleMaestros-property-value">
                                            {t("DetalleMaestroPage:CardDesc12")} {masterDetails && masterDetails["Sku con match 365"]}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid className="VerDetalleMaestros-grid-left-content-box" width={{ xs: "100%", md: "50%" }}>
                                    <Stack direction={"column"}>
                                        <Tooltip placement="top-end" 
                                        title={
                                            <span style={{whiteSpace: "pre-line", fontSize: "var(--serviall-h6)"}}>
                                            <p>{t("DetalleMaestroPage:TooltipApariciones")}</p>
                                            </span>
                                }>
                                        <Typography className="VerDetalleMaestros-property-title">

                                            {t("DetalleMaestroPage:CardTitle2")}
                                        </Typography>
                                        <Typography className="VerDetalleMaestros-property-value">
                                            {t("DetalleMaestroPage:CardDesc21")} {masterDetails && masterDetails["Cantidad de apariciones 90"]}
                                        </Typography>
                                        <Typography className="VerDetalleMaestros-property-value">
                                            {t("DetalleMaestroPage:CardDesc22")} {masterDetails && masterDetails["Cantidad de apariciones 365"]}
                                        </Typography>
                                </Tooltip>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Stack>
                </Grid>
                <Grid marginTop={{ xs: "2rem", tablet: 0 }} sx={{ marginLeft: "1rem" }} width={{ xs: "100%", tablet: "50%" }}>
                    <Stack direction={"column"} spacing={8}>
                        <ServiallStep
                            n_step={1}
                            text={t("DetalleMaestroPage:Step1")}
                            icon={<SystemUpdateAltIcon onClick={handleDownloadTemplate} fontSize="large" />}
                        />
                        <ServiallStep
                            n_step={2}
                            text={i18next.t("DetalleMaestroPage:Step2", { master_singular: type_string_singular })}
                        />
                        <ServiallStep
                            text={
                                <UploadButton
                                    primaryText={t("DetalleMaestroPage:UploadTitle")}
                                    secondaryText={t("DetalleMaestroPage:UploadButton")}
                                    terciaryText={t("DetalleMaestroPage:UploadFormat")}
                                    filename={file && file.name}
                                    handleFileChange={handleFileChange}
                                    resetFile={resetFile}
                                    disabled={false}
                                />
                            }
                        />
                        <Stack className="VerDetalleMaestros-grid-right-help-list-container" direction={"column"} spacing={4}>
                            <Stack direction={"row"} spacing={1}>
                                <CircleIcon className="VerDetalleMaestros-grid-right-help-dot-icon serviall-color-main" />
                                <Typography className="serviall-gray1 VerDetalleMaestros-grid-right-help-text">
                                    {i18next.t("DetalleMaestroPage:Warning1", { master_singular: type_string_singular })}
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} spacing={1}>
                                <CircleIcon className="VerDetalleMaestros-grid-right-help-dot-icon serviall-color-main" />
                                <Typography className="serviall-gray1 VerDetalleMaestros-grid-right-help-text">
                                    {t("DetalleMaestroPage:Warning2")}
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} spacing={1}>
                                <CircleIcon className="VerDetalleMaestros-grid-right-help-dot-icon serviall-color-main" />
                                <Typography className="serviall-gray1 VerDetalleMaestros-grid-right-help-text">
                                    {t("DetalleMaestroPage:Warning3")}
                                </Typography>
                            </Stack>

                        </Stack>
                        <Box display={"flex"} flexDirection={"row-reverse"} marginRight={{ xs: 0, md: "4rem" }} marginTop="1rem" width={"100%"}>
                            <Button disabled={!file || loading} onClick={handleSave} sx={{ textTransform: "none", padding: "10px 30px 5px 30px" }} className="serviall-button">
                                {t("AgregarClientePage:Save")}
                            </Button>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
            <Typography className="serviall-back-text" onClick={() => navigate(-1)}>
                <ArrowBackIcon /> {t("DetalleMaestroPage:GoBackButton")}
            </Typography>

            <ServiallDialog
                open={dialogOpen}
                status={dialogStatus}
                loading_msg={"Cargando archivo..."}
                error_code={uploadErrorCode}
                error_msg={uploadErrorMsg}
                error_type={"file-error"}
                handleClose={handleDialogClose}
            />
        </Stack>
    )
}

VerDetalleMaestrosPage.displayName = "VerDetalleMaestrosPage";

export default VerDetalleMaestrosPage;