
import { Stack, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from "react-i18next";

/**
 * A helper component to render a Key Performance Indicator (KPI) box with optional tooltip.
 * 
 * @component
 * @param {Object} props - The props object containing the KPI box data.
 * @param {number} props.value - The value to display in the KPI box.
 * @param {string} props.text - The text to display below the value in the KPI box.
 * @param {string} props.size - The width of the KPI box.
 * @param {string} [props.tooltipText] - The tooltip text to display when hovering over the KPI box (optional).
 * @param {string} [props.variant] - The variant of the KPI box (e.g., "tiny") to change the style (optional).
 * @returns {JSX.Element} - The KPI box component.
 */
const KpiBox = ({ value, text, size, tooltipText, variant, percentage}) => {

    const {t} = useTranslation()
    
    return (
        <Stack width={size} className="verDetalleOportunidad-KpiBox-container" direction={"column"}>
            {
                tooltipText &&
                <Tooltip className="verDetalleOportunidad-KpiBox-tooltip" title={<span style={{whiteSpace: "pre-line", fontSize: "var(--serviall-h6)"}}>{t(tooltipText)}</span>} placement="top">
                    <InfoOutlinedIcon />
                </Tooltip>
            }
            {
                variant === "tiny" ?
                    <>
                        <Typography className="verDetalleOportunidad-KpiBox-number-tiny"> {!percentage ? value : value ? value+"%": "-"}</Typography>
                        <Typography className="verDetalleOportunidad-KpiBox-text-tiny">{text}</Typography>
                    </>
                    :
                    <>
                        <Typography className="verDetalleOportunidad-KpiBox-number">{!percentage ? value : value ? value+"%": "-"}</Typography>
                        <Typography className="verDetalleOportunidad-KpiBox-text">{text}</Typography>
                    </>
            }
        </Stack>
    )
}

export default KpiBox;