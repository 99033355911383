import DraggableDataGrid from "./DraggableDataGrid";
import { setFilter } from "../features/displaySlice";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { setRowsPerPageItemsLicitacionModoAgregado } from "../features/displaySlice";

/**
 * VistaAgregadaDatagrid is a React component that displays an editable data grid with draggable columns
 * and filtering for added items in a particular context.
 *
 * @component
 * @param {Object} props - The props object containing necessary parameters.
 * @param {Array} props.rows - An array of data rows to be displayed in the grid.
 * @param {Object} props.initialState - The initial state for the data grid.
 * @param {Array} props.datagridCols - An array of column configurations for the data grid.
 * @returns {JSX.Element} - A JSX element representing the editable data grid component for added items.
 */
const VistaAgregadaDatagrid = ({ rows, initialState, datagridCols}) => {

    const displayState = useSelector((state) => state.display.itemsLicitacionModoAgregado);
    const filters = displayState.filters;
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const rowsPerPage = displayState.rowsPerPage

    /**
     * Function to handle changes in the current page of the data grid.
     *
     * @param {SyntheticEvent} e - The event object.
     * @param {number} newPage - The new page index.
     * @returns {void}
     */
    const handlePageChange = (e, newPage) => {
        setPage(newPage);
    }


    /**
     * Function to handle changes in the number of rows displayed per page.
     *
     * @param {SyntheticEvent} e - The event object.
     * @returns {void}
     */
    const handleChangeRowsPerPage = (e) => {
        dispatch(setRowsPerPageItemsLicitacionModoAgregado(parseInt(e.target.value)));
        setPage(0);
    }

    /**
     * Function to get the unique key for a row in the data grid.
     *
     * @param {Object} row - The data row in the data grid.
     * @returns {string|number} The unique key for the row.
     */
    const rowKeyGetter = (row) => {
        return row.id;
    }
    
    
    

    

    
    return (
        <>
        {
            datagridCols.length > 0 &&

            <DraggableDataGrid
            className="serviall-datagrid"
            cols={datagridCols}
            rows={rows}
            rowKeyGetter={rowKeyGetter}
            headerRowHeight={90}
            initialState={initialState}
            filters={filters}
            setFiltersAction={setFilter}
            page={"itemsLicitacionModoAgregado"}
            pageNum={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        }
        </>
    )
}

export default VistaAgregadaDatagrid;