import { Box } from '@mui/material'
import HomeCard from "../../components/HomeCard";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";
import "./HomePage.css";
import {Stack, Typography, FormControl, Select, MenuItem, Button, Tooltip as MuiTooltip} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState, useEffect } from 'react';
import serviallAxios from '../../axiosConfig';
import { TIMEOUTS } from '../../utils';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../features/navigationSlice';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ServiallLoading from '../../components/ServiallLoading';
import { CustomAlertMessage } from '../../utils';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
  } from 'chart.js';
  import { Line, Doughnut} from 'react-chartjs-2';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

/**
 * A React component that renders the 'Home' page.
 * @typedef HomePage
 * @returns A component page with the Home component.
 */
function HomePage() {

    const { t } = useTranslation();
    const homePermissions = useSelector((state) => state.permissions.componentPermissions.HomePage);
    const theme = useSelector((state) => state.settings.theme);
    const themeVar = useTheme();
    const oportunityCardPrimaryColor = theme === "light" ? themeVar.colorSchemes.light.palette.serviall.main : themeVar.colorSchemes.dark.palette.serviall.main
    const oportunityCardSecondaryColor = theme === "light" ? themeVar.colorSchemes.light.palette.serviall.darkRed1 : themeVar.colorSchemes.dark.palette.serviall.darkRed1

    const checkSKUCardPrimaryColor = theme === "light" ? themeVar.colorSchemes.light.palette.serviall.gray1 : themeVar.colorSchemes.dark.palette.serviall.gray1
    const checkSKUCardSecondaryColor = theme === "light" ? themeVar.colorSchemes.light.palette.serviall.darkGray : themeVar.colorSchemes.dark.palette.serviall.darkGray

    const accuracyCardPrimaryColor = theme === "light" ? themeVar.colorSchemes.light.palette.common.onBackground : themeVar.colorSchemes.dark.palette.common.onBackground
    const accuracyCardSecondaryColor = theme === "light" ? themeVar.colorSchemes.light.palette.serviall.gray1 : themeVar.colorSchemes.dark.palette.serviall.gray1

    /* Graphs  */

    const initialState = {
        id_user: "All",
        client_rut: "All"
    }
    const [executives, setExecutives] = useState([])
    const [clients, setClients] = useState([])
    const [formValues, setFormValues] = useState(initialState)
    const [allClients, setAllClients] = useState([])
    const [datasets, setDatasets] = useState([])
    const [openOps, setOpenOps] = useState(null)
    const [skuRevision, setSkuRevision] = useState(null)
    const [precision, setPrecision] = useState(null)
    const [initialFetch, setInitialFetch] = useState(true)
    const [loading, setLoading] = useState(false)
    const [loadingClients, setLoadingClients] = useState(false)
    const [loadedCards, setLoadedCards] = useState(false)
    const dispatch = useDispatch();

    /**
     * A React useEffect hook that fetches executives' data from the server and updates the state with the fetched data.
     * The effect runs once after the component mounts.
     */
    useEffect(() => {
        serviallAxios.get("opportunities/executives", {
            timeout: TIMEOUTS.small
        })
            .then((res) => {
                let exec_names = res.data.body.map((row) => {
                    return {
                        id: row[0],
                        name: row[2]
                    }
                })
                setExecutives(exec_names)

            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
    }, [])
    /**
     * This hook is designed to be used with the `useEffect` function in a functional React component.
     * It will execute the provided `handleFormSubmit` function if the `initialFetch` flag is true
     * and the `executives` array has at least one item.
     *
     * @param {boolean} initialFetch - A boolean flag indicating whether the initial fetch has occurred.
     * @param {Array} executives - An array containing executives' data.
     * @param {function} handleFormSubmit - The function to be executed when the conditions are met.
     * 
     */
    useEffect(() => {
        if (initialFetch && executives.length > 0){
            handleFormSubmit(false, 0, true)
            setInitialFetch(false)
        }
    }, [executives, initialFetch])
    /**
     * A function that handles form input changes and updates the state with the new form values.
     * @param {Object} e - The event object containing information about the input change.
     */
    const handleFormChange = (e) => {
        let newFormValues = { ...formValues };
        if (e.target.id) {
            newFormValues[e.target.id] = e.target.value;
        }
        else{
            newFormValues[e.target.name] = e.target.value;
        }
        setFormValues(newFormValues);

        }
    
    
    /**
     * A function that handles date picker changes and updates the state with the new date value.
     * @param {Date} dateData - The selected date value from the date picker.
     * @param {string} id - The identifier for the date picker input.
     */
    const handleDateChange = (dateData, id) => {
        let newFormValues = { ...formValues };
        newFormValues[id] = dateData;
        setFormValues(newFormValues);
    }

    /**
     * A function that resets the form values and selected executive and clients arrays to their initial states.
     */
    const resetFilters = () =>{
        setFormValues(initialState)
        setClients(allClients)
        
        handleFormSubmit(false, 0, true)
        
    }
    
    /**
     * A function that checks whether the form data is valid.
     * @returns {boolean} - Returns true if the form data is valid; otherwise, returns false.
     */
    const checkFormValid = () => {
        if (!formValues.ending_time) return true
        
        if (formValues.initial_time <= formValues.ending_time){
            return true
        }
        return false

    }
    /**
     * A function that handles the form submission and sends a request to fetch metrics data based on the form values.
     * @param {boolean} [shortcut=false] - A flag indicating whether a shortcut date range is used.
     * @param {number} [monthsToSubstract=0] - The number of months to subtract from the current date when using a shortcut date range.
     */
    const handleFormSubmit = (shortcut=false, monthsToSubstract=0, resetSubmit=false) =>{
        setLoading(true)

        if (resetSubmit){
            const executive_ids = executivesToQueryParam()
            const params = {
                id_user: executive_ids
            }
            serviallAxios.get(`utilities/all_metrics`,{params}, {
                timeout: TIMEOUTS.long
            })
            .then((res) => {
                setDatasets(res.data.datasets)
                if(!loadedCards){
                    setOpenOps(res.data.metrics[0].open_ops)
                    setSkuRevision(res.data.metrics[1].sku_revision)
                    setPrecision(res.data.metrics[2].precision)
                    setLoadedCards(true)
                }
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
            .finally(() =>{
                setLoading(false)
            })
        }
        else{

            if (!checkFormValid()){
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: `Error: ${t("HomePage:ErrorMsg")}`
                }))
                return
            }

            //setOpenOps(null)
            //setSkuRevision(null)
            //setPrecision(null)
            /* Format dates to YYYY-MM-DD */
            let initial_time, ending_time;
            if (shortcut){
                const initial_date = new Date()
                initial_date.setMonth(initial_date.getMonth() - monthsToSubstract)
                initial_time = initial_date.toISOString().split("T")[0]
                ending_time = new Date().toISOString().split("T")[0]

            }
            else{
                initial_time = formValues.initial_time ? formValues.initial_time["$d"].toISOString().split('T')[0] : ""
                ending_time = formValues.ending_time ? formValues.ending_time["$d"].toISOString().split('T')[0] : ""
            }
            let executive_ids;
            if (formValues.id_user === "All"){
                executive_ids = executivesToQueryParam()
            }
            else{
                executive_ids = formValues.id_user
            }
            const params = {
                id_user: executive_ids
            }
            if (initial_time){
                params["initial_time"] = initial_time
            }
            if (ending_time){
                params["ending_time"] = ending_time
            }

            if (formValues.client_rut && formValues.client_rut !== "All"){
                params["client_rut"] = formValues.client_rut
            }
            else{
            }

            serviallAxios.get(`utilities/all_metrics`,{params}, {
                timeout: TIMEOUTS.long
            })
            .then((res) => {
                setDatasets(res.data.datasets)

                

            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
            .finally(() =>{
                setLoading(false)
            })
        }

    }  

    /**
     * Converts an array of executives into a formatted query parameter string.
     *
     * This function takes an array of executives and creates a comma-separated string
     * containing the IDs of each executive. The resulting string can be used as a query
     * parameter in API requests or other data operations that require a formatted list of IDs.
     *
     * @param {Array} executives - An array of objects representing executives, each having an 'id' property.
     * @returns {string} A formatted query parameter string containing the IDs of the executives.
     *
     */
    const executivesToQueryParam = () => {
        let formattedExecutives = "";
        executives.forEach((exec, idx) => {
            if (idx === executives.length - 1){
                formattedExecutives += exec.id
            }
            else{
                formattedExecutives += exec.id + ","
            }
        })
        return formattedExecutives
    }

    
    /**
     * A React effect hook that runs when the 'formValues.id_user' state changes.
     * This effect is responsible for fetching and updating the 'clients' state based on the selected 'id_user'.
     * If the 'id_user' is changed, the 'formValues.client_rut' state is reset, and a new request is sent to fetch clients associated with the updated 'id_user'.
     * @returns {void}
     */
    useEffect(() => {
        const refreshFormValues = {...formValues}
        refreshFormValues.client_rut = "All"
        setFormValues(refreshFormValues)
        if (formValues.id_user){
            setLoadingClients(true)
            let params = {
                executive_id: formValues.id_user
            }
            serviallAxios.get(`masters/clients_with_exec`,{params},{
                timeout: TIMEOUTS.small
            })
            .then((res) => {
                if (formValues.id_user === "All" && allClients.length === 0){
                    setAllClients(res.data.clients_data)
                }
                setClients(res.data.clients_data)
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
            .finally(() => {
                setLoadingClients(false)
            })
            
        }
        
    }, [formValues.id_user])

    return (
        <div>
            {homePermissions.Box.homeCardsContainer !== "hidden" &&
                <Box id="homeCardsContainer" 
                className="HomePage-Box-permi desc-cards_container"
                sx={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}
                flexDirection={{xs: "column", md: "row"}}
                gap={{xs: "2rem"}}
                >
                    <HomeCard kpi={openOps} primaryText={t("HomePage:Card1Title")} secondaryText={t("HomePage:Card1Caption")} primaryColor={oportunityCardPrimaryColor} secondaryColor={oportunityCardSecondaryColor} iconColor="black" tooltipText={t("HomePage:Tooltip1")} />
                    <HomeCard kpi={skuRevision} primaryText={t("HomePage:Card2Title")}  secondaryText={t("HomePage:Card2Caption")} primaryColor={checkSKUCardPrimaryColor} secondaryColor={checkSKUCardSecondaryColor} iconColor="var(--mui-palette-serviall-selectDate)" tooltipText={t("HomePage:Tooltip2")}   />
                    <HomeCard kpi={precision} primaryText={t("HomePage:Card3Title")}  primaryColor={accuracyCardPrimaryColor} secondaryColor={accuracyCardSecondaryColor} iconColor="var(--mui-palette-serviall-selectDate)" tooltipText={t("HomePage:Tooltip3")}  />
                </Box>
            }

            <Stack direction={{xs: "column-reverse", md: "row"}}  marginTop={"2rem"} gap={{xs: 2, md: 0}}>
                {loading ? 
                    <ServiallLoading text={t("HomePage:LoadingMsg")}/> 
                    :
                <Box display={"flex"} flexDirection={"column"} width={{xs: "100%", md: "82%"}} flexWrap={"wrap"} >
                    { datasets.length > 0 &&
                        <Box display={"flex"} justifyContent={"flex-end"} sx={{color: "black"}} paddingX={{xs: 0, md: 2, xl:3}}>
                        <Button onClick={() => handleFormSubmit(true,1)}>1M</Button>
                        <Button onClick={() => handleFormSubmit(true,3)}>3M</Button>
                        <Button onClick={() => handleFormSubmit(true,6)}>6M</Button>
                        <Button onClick={() => handleFormSubmit(true,12)}>1Y</Button>
                    </Box>
                    }
                    <Box width={"100%"} display={"flex"}  alignItems={"center"} flexWrap={"wrap"} gap={2}>
                        {datasets.map((dataset, idx) => {
                                return (
                                    <Box  display={"flex"} flexDirection={"column"} width={{ xs: "100%", tablet: "48%"}} sx={{border: "1px solid #C7C7C7", borderRadius: "18px", }} padding={2} className={"homePage-shadow"}>
                                        <Box marginBottom={2}>
                                            <Typography className='homePage-charts-title'>{t(dataset.dataset.title)}</Typography>
                                        </Box>
                                        <Box display={"flex"} justifyContent={'space-between'} >
                                            <Box display={"flex"} gap={4}>
                                                {dataset.dataset.accs.map((accumValue) => {
                                                    return (
                                                        <Box display={"flex"} flexDirection={"column"} className={"homePage-chart-kpiTotal"}>
                                                            <Typography className='homePage-chart-kpiText homePage-chart-red'>{t(accumValue[0])}</Typography>
                                                            <Box display={"flex"} alignItems={"center"} gap={1}>
                                                                <Typography className='homePage-chart-kpiNumber homePage-chart-red' sx={{fontWeight: 600}}>{accumValue[1]}</Typography>
                                                            </Box>
                                                        </Box>
                                                    )
                                                })}

                                                
                                            </Box>
                                            
                                            <MuiTooltip placement='top-start' arrow title={<span style={{whiteSpace: "pre-line", fontSize: "var(--serviall-h6)"}}>{t(dataset.dataset.tooltip)}</span>}>
                                                <InfoOutlinedIcon fontSize='small' style={{color: "red"}} />
                                            </MuiTooltip>
                                        </Box>
                                        <Box width={"100%"} height={{xs: "200px"}}>
                                            {idx !== 2 && idx !== 3 ?
                                            <Line
                                            datasetIdKey={idx}
                                            data={{
                                                labels: dataset.dataset.labels,
                                                datasets: dataset.dataset.datasets,
                                            }}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio:false,
                                                plugins:{
                                                    legend: {
                                                        display: false
                                                    }
                                                },
                                                scales: dataset.dataset.scales ? dataset.dataset.scales : "",
                                            }}
                                            />
                                            :
                                            dataset.dataset.datasets[0].data.length > 0  ? 
                                                <Doughnut
                                                datasetIdKey={idx}
                                                data={{
                                                    labels: dataset.dataset.labels,
                                                    datasets: dataset.dataset.datasets,
                                                }}
                                                options={{
                                                    responsive: true,
                                                    maintainAspectRatio:false,
                                                    plugins:{
                                                        legend: {
                                                            display: false
                                                        },
    
                                                    },
                                                }}
                                                />:
                                                <Box display={"flex"} height={"100%"} width={"100%"} justifyContent={"center"} alignItems={"center"}>
                                                    <Typography sx={{fontWeight:"600", fontSize: "20px"}}>No data</Typography>
                                                </Box>
                                            }
                                        </Box>
                                    </Box>
                                )
                            
                            })}
                    </Box>
                </Box>
                }

                <Box height={"fit-content"} display={"flex"} flexDirection={"column"} marginTop={4.5} padding={2} gap={4} sx={{border: "1px solid #C7C7C7", borderRadius: "18px" }}  width={{xs: "100%", md: "18%"}} className={"homePage-shadow"}>
                    <Box display={"flex"} flexDirection={"column"} gap={2}>
                        <Typography>{t("HomePage:InputTitle1")}</Typography>
                        <DatePicker
                            id="initial_time"
                            disabled={loading}
                            format='DD/MM/YYYY'
                            value={formValues.initial_time ? formValues.initial_time : ""}
                            onChange={(value) => handleDateChange(value, "initial_time")}
                            slotProps={{
                                textField: {
                                    className: "nuevaOportunidad-date-input",
                                    disabled: true,
                                }
                            }}
                        />
                        <DatePicker
                            id="ending_time"
                            disabled={loading}
                            format='DD/MM/YYYY'
                            value={formValues.ending_time ? formValues.ending_time : ""}
                            onChange={(value) => handleDateChange(value, "ending_time")}
                            slotProps={{
                                textField: {
                                    className: "nuevaOportunidad-date-input",
                                    disabled: true,
                                }
                                }}
                        />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={2} >
                        <Typography>{t("HomePage:InputTitle2")}</Typography>
                        <FormControl className='homePage-mui-input-container'>
                            <Select disabled={loading}  name='id_user' value={formValues.id_user} onChange={(e) => {
                                handleFormChange(e)
                            }} variant="standard" fullWidth disableUnderline={true}>
                                <MenuItem divider value={"All"}>
                                        <Typography className='serviall-small'>
                                            {t("HomePage:All")}
                                        </Typography>
                                    </MenuItem>
                                {
                                    executives && executives.map((exec) => {
                                        return (
                                            <MenuItem divider value={exec.id}>
                                                <Typography className='serviall-small'>
                                                    {exec.name}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={2}>
                        <Typography>{t("HomePage:InputTitle3")}</Typography>
                        <FormControl className='homePage-mui-input-container'>
                                <Select displayEmpty disabled={loading || loadingClients} name='client_rut' value={formValues.client_rut} onChange={(e) => {
                                    handleFormChange(e)
                                }} variant="standard" fullWidth disableUnderline={true}>
                                    <MenuItem divider value={"All"}>
                                        <Typography className='serviall-small'>
                                            {t("HomePage:All")}
                                        </Typography>
                                    </MenuItem>
                                    {
                                        clients && clients.map((client) => {
                                            return (
                                                <MenuItem divider value={client[0]}>
                                                    <Typography className='serviall-small'>
                                                        {client[0]} - {client[1]}
                                                    </Typography>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                        </FormControl>
                    </Box>
                                    
                    <Box display={"flex"} flexDirection={"column"} gap={2} >
                        <Button onClick={resetFilters} disabled={loading} className="serviall-button" sx={{width:"fit-content"}}>{t("HomePage:ClearFiltersBtn")}</Button>
                        <Button onClick={()=> handleFormSubmit(false, 0)} disabled={loading} className="serviall-button" sx={{width:"fit-content"}}>{t("HomePage:SubmitBtn")}</Button>
                    </Box>
                </Box>
            </Stack>

        </div>
    )
}

HomePage.displayName = "HomePage";

export default HomePage;