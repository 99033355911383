import React from 'react'
import { Dialog, DialogTitle, Typography, DialogContent, Stack,DialogActions, Button, TextField, FormControl, Autocomplete} from '@mui/material'
import { useTranslation } from 'react-i18next'


/**
 * MaintainersModelsDialog is a React component that displays a dialog for maintaining models with associated information.
 *
 * @component
 * @param {Object} props - The props object containing the data and functions for the dialog.
 * @param {boolean} props.dialogOpen - A boolean indicating whether the dialog is open.
 * @param {function} props.setDialogOpen - A function to control the open/close state of the dialog.
 * @param {Object} props.modelInfo - An object containing model information.
 * @param {function} props.handleChange - A callback function to handle changes in input fields.
 * @param {function} props.handleSubmit - A callback function to handle form submission.
 * @param {Array} props.relatedExecutors - An array of related executor options.
 * @param {string} props.title - The title of the dialog.
 * @returns {JSX.Element} - A JSX element representing the maintainers models dialog.
 */
const MaintainersModelsDialog = ({dialogOpen, setDialogOpen, modelInfo,  handleChange, handleSubmit, relatedExecutors, title, isDelete=false}) => {
    const {t} = useTranslation()
    return (
    <Dialog
        fullWidth
        open={dialogOpen}
        onClose={() => { setDialogOpen(false) }}
        >
        <DialogTitle>
            <Typography className="versions-dialog-title">
            {title}
            </Typography>
        </DialogTitle>
        <DialogContent>
            <Stack direction={"column"} spacing={1} className='maintainersModelosDialog-container'>
                <Typography>
                {t("MantenedoresModelos:DialogText1")}: {modelInfo.client_name}
                </Typography>
                <Typography>
                {t("MantenedoresModelos:DialogText2")}: {modelInfo.client_rut}
                </Typography>
                <Typography>
                {t("MantenedoresModelos:DialogText3")}: {modelInfo.lang === "es"? t("MantenedoresModelos:Spanish") : t("MantenedoresModelos:English")}
                </Typography>
                <Typography>
                {modelInfo.link && t("MantenedoresModelos:DialogText5") + ": " + modelInfo.link}
                </Typography>
                    <Stack direction={"column"} spacing={1} justifyContent={"space-between"} paddingTop={2}>
                    <Typography>{t("MantenedoresModelos:DialogText4")}</Typography>
                    <FormControl className='configuracionMantenedoresModelos-formControl'>
                    <Autocomplete disabled={isDelete} sx={{"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {border: "none"}}}  inputValue={modelInfo.executor_name} id='executor_name' onInputChange={(e, val) => handleChange("executor_name", val)} options={relatedExecutors} freeSolo forcePopupIcon renderInput={(params) => <TextField className="serviall-input" {...params} />} name='executor_name' variant="standard" fullWidth />
                    </FormControl>
                    </Stack>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button className="serviall-button-success" onClick={handleSubmit}>{isDelete ? t("MantenedoresModelos:DeleteBtn") : t("MantenedoresModelos:SaveBtn")}</Button>
            <Button className="serviall-button-error" onClick={() => setDialogOpen(false)}>{t("MantenedoresModelos:CancelBtn")} </Button>
        </DialogActions>
        </Dialog>
    )
    }

export default MaintainersModelsDialog