import { Box, CircularProgress, Stack, Typography, Paper, Grid } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import "./VerDetalleHomologosClientePage.css";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import serviallAxios from "../../axiosConfig";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EditMastersItemDialog from "../../components/EditMastersItemDialog";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../features/navigationSlice";
import DeleteMastersItemDialog from "../../components/DeleteMastersItemDialog";
import DraggableDataGrid from "../../components/DraggableDataGrid";
import { TIMEOUTS, make_excel } from "../../utils";
import { useTranslation } from "react-i18next";
import { setFilter, setRowsPerPageDetalleHomologoCliente } from "../../features/displaySlice";
import ResetFiltersBtn from "../../components/ResetFiltersBtn";
import ColumnSelector from "../../components/ColumnSelector";
import { CustomAlertMessage } from "../../utils";
import KpiBox from "../../components/KpiBox";
import ServiallInput from "../../components/ServiallInput";

/**
 * A React component that renders the 'Detalle Homologos' for the Client Master page.
 * @typedef VerDetalleHomologosClientePage
 * @returns A page with the datagrid with the clients items.
 */
const VerDetalleHomologosClientePage = () => {

    /* Initial State */
    const initialState = {
        sku_cliente: "",
        homologo_sa: "",
        desc_es: "",
        desc_en: "",
        codigo_sap: "",
        marca: "",
        pn: "",
        costo_sa: "",
        moneda: "",
        um: "",
        cantidad: "",
        sitio_web: "",
        apariciones_creacion: "",
        apariciones_ultimos_dias: "",
        fecha_creacion: "",
        fecha_edicion: "",
        plazo_entrega: ""
    }
    /* Constants */
    const displayData = useSelector((state) => state.display.detalleHomologoCliente);
    const filters = displayData.filters;
    const rowsPerPage = displayData.rowsPerPage;
    const navigate = useNavigate();
    const { client_id } = useParams();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editForm, setEditForm] = useState(initialState);
    const [dialogLoading, setDialogLoading] = useState(false);
    const dispatch = useDispatch();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [actualDeleteId, setActualDeleteId] = useState({})
    const location = useLocation();
    const masterData = location.state
    const [page, setPage] = useState(0);
    const { t } = useTranslation();
    const [lines, setLines] = useState(0)
    const [filteredLines, setFilteredLines] = useState(0)
    const [query, setQuery] = useState("")
    const [filledPages, setFilledPages] = useState([0])
    const [redirectPage, setRedirectPage] = useState(false)
    const [lastPage, setLastPage] = useState(0)

    /**
     * Open the delete dialog and set the actual delete ID for the selected item.
     * 
     * @function
     * @param {string} sku_client - The SKU of the client.
     * @param {string} db_id - The ID of the item in the database.
     * @returns {void}
     */
    const handleClickOpen = (sku_client, db_id) => {
        setDeleteDialogOpen(true);
        setActualDeleteId({ "sku_client": sku_client, "db_id": db_id })
    };

    /**
     * Close the delete dialog.
     * 
     * @function
     * @returns {void}
     */
    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    /**
     * Handle the delete action for the selected item.
     * This function sends a request to delete the item from the database,
     * shows a success message if the deletion is successful, or an error message if there's an error,
     * and then fetches updated data from the server.
     * 
     * @function
     * @returns {void}
     */
    const handleDelete = () => {
        let payload = {
            client_item_id: actualDeleteId.db_id
        }
        serviallAxios.delete("/masters/client_items", {
            timeout: TIMEOUTS.small,
            params: payload
        }).then((res) => {
            dispatch(setAlert({
                open: true,
                severity: "info",
                message: t("DetalleHomologoClientePage:DeleteSuccesful")
            }))
        })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }))
            })
            .finally(() => {
                setDialogLoading(false);
                setDeleteDialogOpen(false);
                fetchData(true, true);
                setPage(0)
                fetchLines()
            })

    }

    /**
     * Handle changes in the edit form and update the state accordingly.
     * 
     * @function
     * @param {Object} e - The event object representing the change in the input field.
     * @returns {void}
     */
    const handleEditFormChange = (e) => {
        let newFormValues = { ...editForm };
        newFormValues[e.target.id] = e.target.value;
        setEditForm(newFormValues);
    }

    /**
     * Close the edit dialog.
     * 
     * @function
     * @returns {void}
     */
    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
    }
    /**
     * Handle the save edit action for the edited item.
     * This function sends a request to update the item in the database,
     * shows a success message if the update is successful, or an error message if there's an error,
     * and then fetches updated data from the server.
     * 
     * @function
     * @returns {void}
     */
    const handleSaveEdit = () => {
        if (editForm.db_id === -1) {
            console.log("Edit id error");
            setEditDialogOpen(false);
            return
        }

        setDialogLoading(true);

        let item_data = {
            "UM": editForm.um ? editForm.um : "",
            "Nombre": masterData.name ? masterData.name : "",
            "Codigo Sap": editForm.codigo_sap ? editForm.codigo_sap : "",
            "Marca/Fabricante": editForm.marca ? editForm.marca : "",
            "Número de Parte": editForm.pn ? editForm.pn : "",
            "Apariciones Creacion": editForm.apariciones_creacion ? editForm.apariciones_creacion : "",
            "Apariciones ultimos dias": editForm.apariciones_ultimos_dias ? editForm.apariciones_ultimos_dias : "",
            "Plazo de entrega": editForm.plazo_entrega ? editForm.plazo_entrega : ""
        }

        let payload = {
            item_id: editForm.db_id,
            sku_client: editForm.sku_cliente,
            sku_serviall: editForm.homologo_sa,
            es_desc: editForm.desc_es,
            en_desc: editForm.desc_en,
            item_data: JSON.stringify(item_data)
        }
        serviallAxios.put("/masters/client_items", payload, {
            timeout: TIMEOUTS.small
        })
            .then((res) => {
                dispatch(setAlert({
                    open: true,
                    severity: "info",
                    message: t("DetalleHomologoClientePage:UpdateSuccesful")
                }))
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }))
            })
            .finally(() => {
                setDialogLoading(false);
                setEditDialogOpen(false);
                fetchData(false, false, true);

            })
    }
    /**
     * Open the edit dialog and set the parameters for the edited item.
     * 
     * @function
     * @param {Object} params - The parameters for the edited item.
     * @returns {void}
     */
    const handleEdit = (params) => {
        setEditDialogOpen(true);
        setEditForm(params);
    }

   /**
     * Fetches data from a server and updates the 'rows' state based on specific conditions.
     *
     * This function is responsible for fetching data from the server and updating the 'rows' state.
     * It checks if the 'initializeArray' flag is set, and if so, it initializes the 'rows' state with
     * the fetched data. If not, it updates the 'rows' state at the appropriate index. It also handles
     * loading states and error handling.
     *
     * @param {boolean} initializeArray - A flag indicating whether to initialize the 'rows' array.
     */
    const fetchData = (initializeArray, resetPages=false, handleEdit=false) => {
        setLoading(true)
        const rowsCpy = [...rows]
        let twoFetch = false
        if (filledPages.length === 2 && !initializeArray){
            if (Object.keys(rowsCpy[filledPages[0]]).length === 0 && Object.keys(rowsCpy[filledPages[1]]).length === 0){
                twoFetch = true
            }
        }
        const cpyFilledPages = resetPages ? [0] : [...filledPages]
        for(let k = 0; k < cpyFilledPages.length ; k ++){
            const getPage = (cpyFilledPages[k]/500)+1
            if (rowsCpy.length === 0 || Object.keys(rowsCpy[cpyFilledPages[k]]).length === 0 || initializeArray || handleEdit){
                serviallAxios.get("/masters/client_items", {params:{client_rut: client_id, user_query:query, page:getPage}}, {
                    timeout: TIMEOUTS.medium
                })
                    .then((res) => {
                        const filteredRows = res.data.client_items.map((db_row, idx) => {
                            let item_data = JSON.parse(db_row[6]);
                            // Because in db a clenup function is used so that database accepts ' and " characters, 
                            // another JSON parse is needed only when the first JSON parse returns a string
                            if (typeof item_data === "string") {
                                item_data = JSON.parse(item_data);
                            }
                            let creation_date = db_row[7] ? (new Date(db_row[7]).toLocaleDateString('es-GB', { year: "numeric", month: "2-digit", day: "2-digit" })).toString() : ""
                            let update_date = db_row[8] ? (new Date(db_row[8]).toLocaleDateString('es-GB', { year: "numeric", month: "2-digit", day: "2-digit" })).toString() : ""
                            return makeData(idx, db_row[2], db_row[3], db_row[4], db_row[5],  item_data["Marca/Fabricante"], item_data["Número de Parte"], item_data["UM"], db_row[10], db_row[11], creation_date, update_date, db_row[0], item_data["Codigo Sap"], item_data["Plazo de entrega"]);
                        })
                        setFilteredLines(res.data.total_filtered_items)
                        if (initializeArray){
                            const newArray = new Array(res.data.total_filtered_items).fill({})
                            Array.prototype.splice.apply(newArray, [0, filteredRows.length].concat(filteredRows))
                            setRows(newArray)
                            setFilledPages([0])
                            setLoading(false)
                        }
                        else{
                            if (getPage === 1){
                                Array.prototype.splice.apply(rowsCpy, [0, filteredRows.length].concat(filteredRows))
                            }
                            else{
                                Array.prototype.splice.apply(rowsCpy, [(getPage-1)*500, filteredRows.length].concat(filteredRows))
                            }
                            if(twoFetch){
                                if (k === 1){
                                    setRows(rowsCpy)
                                    setLoading(false)
                                }
                            }
                            else{
                                setRows(rowsCpy)
                                setLoading(false)
                            }
                        }
                        
                    })
                    .catch((error) => { 
                        dispatch(setAlert({
                            open: true,
                            severity: "error",
                            message: error.response ? error.response.data.detail : CustomAlertMessage(error.code)
                        }));
                        setLoading(false)
                     })
            }
            else{
                setTimeout(() => {
                    setLoading(false)
                  }, 2000);
            }
            
        }
        
        
        
    }

    /**
     * Fetches the total number of lines from a server and updates the 'lines' state.
     *
     * This function is responsible for fetching the total number of lines from the server and updating
     * the 'lines' state with the fetched value. It is typically used to retrieve the total number of
     * lines for a specific client.
     */
    const fetchLines = () => {
        serviallAxios.get("/masters/total_lines_client", {params:{client_rut: client_id}}, {
            timeout: TIMEOUTS.medium
        })
            .then((res) => {
                setLines(res.data.total)
            })
            .catch((error) => { 
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: error.response ? error.response.data.detail : CustomAlertMessage(error.code)
                }));
             })
    }

    /** 
    * Useeffect for the really specific bug of changing URL to the prod version and then go back to the dev version.
    * @return {void}
    */
    useEffect(() => {
        if (!masterData) {
            navigate("/dashboard/home");
        }
    }, [masterData])

    /**
     * Perform data fetching when the component mounts.
     * This effect calls the `fetchData` function to get the initial data for the datagrid.
     * 
     * @returns {void}
     */
    useEffect(() => {
        fetchData(true);
        fetchLines()
    }, [])


        /**
     * A React useEffect hook that monitors changes in the 'page' state variable and
     * triggers data fetching based on specific conditions.
     *
     * It checks if there are rows available and if the current page exceeds the available rows.
     * If so, it checks if the last row contains no data, and if filters are empty, it triggers data fetching.
     *
     * @param {number} page - The current page number.
     * @param {Array} rows - An array of data rows.
     * @param {number} rowsPerPage - The number of rows to display per page.
     * @param {object} filters - An object containing filter values.
     * @param {function} fetchData - A function to fetch data when needed.
     */
        useEffect(() => {
            if (rows.length > 0){
                if (!rows[rowsPerPage*(page+1)-1]){
                    if (Object.keys(rows[rows.length - 1]).length === 0) {
                        if (!Object.keys(filters).some(x => filters[x] !== "")){
                            fetchData(false)
                        }
                    }
                }
                else{
                    if (Object.keys(rows[rowsPerPage*(page+1)-1]).length === 0) {
                        if (!Object.keys(filters).some(x => filters[x] !== "")){
                            fetchData(false)
                        }
                    }
                }
            }
        }, [page])
        
        /**
        * A React useEffect hook that monitors changes in the 'filters' object
        * and performs actions based on the changes.
        *
        * If any value in the 'filters' object is not an empty string and the 'redirectPage'
        * state is not set to true, it sets 'page' to 0 and sets 'redirectPage' to true.
        * 
        * If all values in the 'filters' object are empty strings, it sets 'redirectPage' to false
        * and if 'redirectPage' was previously false, it also sets 'page' to 0 and fetches data.
        *
        * @param {object} filters - An object containing filter values.
        * @param {boolean} redirectPage - A state variable indicating whether to redirect the page.
        * @param {function} setPage - A function to update the 'page' state.
        * @param {function} setRedirectPage - A function to update the 'redirectPage' state.
        * @param {function} fetchData - A function to fetch data when needed.
        */
        useEffect(() => {
            if (rows.length > 0){
                if (Object.keys(filters).some(x => filters[x] !== "") && !redirectPage){
                    setLastPage(page)
                    setPage(0)
                    setRedirectPage(true)
                }
                else{
                    if(!Object.keys(filters).some(x => filters[x] !== "")){
                        setPage(lastPage)
                        fetchData(false)
                        setRedirectPage(false)
                    }
                }
            }
        }, [filters])
    

    /**
     * Get the row key for a row in the datagrid.
     * This function returns the ID of the row as the key.
     * 
     * @param {Object} row - The row object in the datagrid.
     * @returns {string} - The ID of the row.
     */
    const rowKeyGetter = (row) => {
        return row.id;
    }


    const initialCols = [
        {
            key: 'sku_cliente',
            name: t("DetalleHomologoClientePage:Header2"),
            width: 120,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        // {
        //     key: 'sku_serviall',
        //     name: t("DetalleHomologoClientePage:Header3"),
        //     width: 120,
        //     headerCellClass: "serviall-datagrid-header1",
        //     type: "text",
        //     resizable: true,
        //     sortable: true,
        // },
        {
            key: 'homologo_sa',
            name: t("DetalleHomologoClientePage:Header4"),
            width: 120,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'desc_es',
            name: t("DetalleHomologoClientePage:Header5"),
            width: 160,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'desc_en',
            name: t("DetalleHomologoClientePage:Header6"),
            width: 150,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'marca',
            name: t("DetalleHomologoClientePage:Header8"),
            width: 60,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'pn',
            name: t("DetalleHomologoClientePage:Header9"),
            width: 60,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'um',
            name: t("DetalleHomologoClientePage:Header11"),
            width: 120,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'apariciones_creacion',
            name: t("DetalleHomologoClientePage:Header13"),
            width: 290,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'apariciones_ultimos_dias',
            name: t("DetalleHomologoClientePage:Header14"),
            width: 270,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'fecha_creacion',
            name: t("DetalleHomologoClientePage:Header15"),
            width: 160,
            headerCellClass: "serviall-datagrid-header1",

            resizable: true,
            sortable: true,
        },
        {
            key: 'fecha_edicion',
            name: t("DetalleHomologoClientePage:Header16"),
            width: 160,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'acciones',
            reset: true,
            name: t("DetalleHomologoClientePage:Header1"),
            width: 30,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
        },
    ]

    const [datagridCols, setDatagridCols] = useState(initialCols)


    /**
     * Generate the action icons for a row in the datagrid.
     * This function returns JSX elements representing edit and delete icons,
     * and attaches click handlers to these icons for handling corresponding actions.
     * 
     * @param {Object} params - The parameters for the row.
     * @param {string} params.sku_cliente - The SKU of the client.
     * @param {string} params.db_id - The ID of the item in the database.
     * @returns {JSX.Element} - The JSX element representing the action icons.
     */
    const makeRowActions = (params) => {
        return (
            <>
                <Stack sx={{ justifyContent: "center", marginTop: "5px" }} direction={"row"} spacing={1}>
                    <EditIcon fontSize="small" className="detalleHomologoCliente-datagrid-header-icon" onClick={() => { handleEdit(params) }} />
                    <DeleteIcon fontSize="small" className="detalleHomologoCliente-datagrid-header-icon" onClick={() => { handleClickOpen(params.sku_cliente, params.db_id) }} />
                </Stack>
                <Stack >

                </Stack>
            </>
        )
    }

    /**
     * Generate data for a row in the datagrid.
     * This function takes various parameters representing the row data and creates an object with appropriate properties.
     * It also calls `makeRowActions` to generate the action icons for the row.
     * 
     * @param {number} id - The ID of the row.
     * @param {string} sku_cliente - The SKU of the client.
     * @param {string} sku_serviall - The SKU of the serviall.
     * @param {string} homologo_sa - The name of the homologo SA.
     * @param {string} desc_es - The long description.
     * @param {string} desc_en - The short description.
     * @param {string} marca - The brand of the item.
     * @param {string} pn - The part number of the item.
     * @param {string} um - The unit of measurement.
     * @param {string} apariciones_creacion - Appearances on creation.
     * @param {string} apariciones_ultimos_dias - Appearances in the last days.
     * @param {string} fecha_creacion - The creation date.
     * @param {string} fecha_edicion - The edition date.
     * @param {number} db_id - The ID of the item in the database.
     * @returns {Object} - An object representing the data for the row in the datagrid.
     */
    const makeData = (
        id,
        sku_cliente,
        // sku_serviall,
        homologo_sa,
        desc_es,
        desc_en,
        marca,
        pn,
        um,
        apariciones_creacion,
        apariciones_ultimos_dias,
        fecha_creacion,
        fecha_edicion,
        db_id,
        codigo_sap,
        plazo_entrega
    ) => {
        return {
            id, acciones: makeRowActions({
                id,
                sku_cliente,
                // sku_serviall,
                homologo_sa,
                desc_es,
                desc_en,
                marca,
                pn,
                um,
                apariciones_creacion,
                apariciones_ultimos_dias, db_id: db_id, codigo_sap: codigo_sap, plazo_entrega: plazo_entrega
            }), sku_cliente, homologo_sa, desc_es, desc_en, marca, pn, um, apariciones_creacion, apariciones_ultimos_dias, fecha_creacion, fecha_edicion, codigo_sap, plazo_entrega
        }
    }


        /**
     * Resets the 'rows' state based on the given 'newPage' and 'filters'.
     *
     * This function is responsible for resetting the 'rows' state based on the provided 'newPage'
     * and the state of 'filters'. If all filter values are empty, it resets 'rows' by filling it
     * with empty objects, depending on the 'newPage' value and pagination settings. It also updates
     * the 'filledPages' state accordingly.
     *
     * @param {number} newPage - The new page number for which to reset the 'rows' state.
     */
        const resetRows = (newPage) => {
            if (!Object.keys(filters).some(x => filters[x] !== "")){
                const newFilledPages = []
                const newRows = [...rows]
                if (rowsPerPage * (newPage+1) <= 500){
                    const newArray = new Array(newRows.length).fill({})
                    const newLeftIndex = parseInt(parseInt(rowsPerPage * newPage / 500)*500)
                    for (let k = 0; k < filledPages.length; k ++){
                        const leftIndex = parseInt(filledPages[k]/500)*500
                        if (Math.abs(leftIndex-newLeftIndex) === 500){
                            newFilledPages.push(leftIndex)
                            newFilledPages.push(newLeftIndex)
                        }
                        /* If its not consecutive, then remove from current array */
                        else{
                            const newArray = new Array( Math.min(500, Math.abs(newRows.length - leftIndex))).fill({})
                            Array.prototype.splice.apply(newRows, [leftIndex, Math.min(500, Math.abs(newRows.length - leftIndex))].concat(newArray))
                        }
                    }
                    if (newFilledPages.length === 0){
                        setFilledPages([0])
                        setRows(newArray)
                    }
                    else{
                        setFilledPages(newFilledPages)
                        setRows(newRows)
                    }
                
                }
                else{
                    const newFilledPages = []
                    const newLeftIndex = parseInt(parseInt(rowsPerPage * newPage / 500)*500)
                    for (let k = 0; k < filledPages.length; k ++){
                        const leftIndex = parseInt(filledPages[k]/500)*500
                        if (Math.abs(leftIndex-newLeftIndex) === 500){
                            newFilledPages.push(leftIndex)
                            newFilledPages.push(newLeftIndex)
                    }
                    /* If its not consecutive, then remove from current array */
                    else{
                        const newArray = new Array( Math.min(500, Math.abs(newRows.length - leftIndex))).fill({})
                        Array.prototype.splice.apply(newRows, [leftIndex, Math.min(500, Math.abs(newRows.length - leftIndex))].concat(newArray))
                    }
                }
                setRows(newRows)
                if (newFilledPages.length === 0){
                    newFilledPages.push(newLeftIndex - 500)
                    newFilledPages.push(newLeftIndex)
                    setFilledPages(newFilledPages)
                }
                else{
                    setFilledPages(newFilledPages)
                }
                }
            }
           
        }
    // Pagination handling

    /**
     * Handle the page change event in the datagrid pagination.
     * This function updates the state with the new page number.
     * 
     * @param {Object} e - The event object representing the page change.
     * @param {number} newPage - The new page number.
     * @returns {void}
     */
    const handlePageChange = (e, newPage) => {
        if (!rows[rowsPerPage*(newPage+1)-1]){
            if (Object.keys(rows[rows.length - 1]).length === 0){
                resetRows(newPage)
            }
        }
        else{ 
            if(Object.keys(rows[rowsPerPage*(newPage+1)-1]).length === 0){
            /* Remove First array from memory*/
                resetRows(newPage)

            }
        }
        setPage(newPage);
        
    }

    /**
     * Event handler for handling 'Enter' key presses.
     *
     * This function is an event handler that triggers a data fetch operation when the 'Enter' key is pressed.
     * It is typically used in input fields to initiate a data fetch when the user presses 'Enter'.
     *
     * @param {Event} e - The keyboard event object.
     */
    const onKeyDown = (e) => {
        if (e.key === "Enter"){
            setPage(0)
            setLastPage(0)
            fetchData(true, true)
        }
    }

    /**
     * Handle the "change rows per page" event in the datagrid pagination.
     * This function updates the state with the new number of rows per page and resets the current page to 0.
     * 
     * @function
     * @param {Object} e - The event object representing the "change rows per page" event.
     * @returns {void}
     */
    const handleChangeRowsPerPage = (e) => {
        dispatch(setRowsPerPageDetalleHomologoCliente(parseInt(e.target.value, 10)));
        fetchData(true, true)
        setPage(0)
    }


    // Export

    /**
      * Handle the export functionality to download data in Excel format.
      * This function triggers the download process when the data is available and not in the loading state.
      * It removes the "acciones" property from each row before exporting the data to Excel.
      * 
      * @returns {void}
      */
    const handleExport = () => {
        if (!loading && rows.length > 0) {
            dispatch(setAlert({
                open: true,
                severity: "info",
                message: t("Common:Download")
            }))
            let rowsToSave = JSON.parse(JSON.stringify(rows));
            // Filtrar los registros que tienen el campo 'sku_cliente'
            const filteredRows = rows.filter(row => row.sku_cliente);
            
            rowsToSave = filteredRows.map((row) => {
                return {
                    'Rut': client_id,
                    'Sku Cliente': row.sku_cliente,
                    'Homólogo SA': row.homologo_sa,
                    'Descripción Español': row.desc_es,
                    'Descripción Inglés': row.desc_en,
                    'Marca': row.marca,
                    'P/N': row.pn,
                    'UM del costo': row.um,
                    'Cantidad de apariciones desde creación': row.apariciones_creacion,
                    'Cantidad apariciones en los últimos 90 días': row.apariciones_ultimos_dias
                }
            })
            rowsToSave.forEach((row) => {
                delete row.acciones;
            })
            make_excel(`homologos_cliente_${masterData.name}`, rowsToSave);
        }
    }

    return (
        <Stack direction={"column"} spacing={4}>
            <Box display={"flex"} flexWrap={"wrap"} gap={4} justifyContent={"space-between"}>
                <Box display={"flex"} flexDirection={"column"} gap={4}>
                    <Typography className="serviall-page-title1">
                        {t("DetalleHomologoClientePage:Title")}
                    </Typography>
                    {masterData &&
                        <Stack direction={"column"} spacing={0.5}>
                            <Typography className="VerDetalleMaestros-property-title">
                                <ArrowRightIcon className="serviall-color-main" />{masterData.rut}
                            </Typography>
                            <Typography className="VerDetalleMaestros-property-value">
                                {masterData.name}
                            </Typography>
                        </Stack>
                    }
                </Box>
                <Paper className="verDetalleOportunidad-paper-container" elevation={6}>
                    <Grid display={"flex"}  flexWrap={"wrap"} width={"100%"} flexDirection={{ xs: "columnm", lg: "row" }} justifyContent={{ lg: "space-evenly" }} gap={{ xs: "1rem", lg: "0" }}>
                            <Stack display={"flex"} flexDirection={"column"} alignItems={"center"} marginRight={{ xs: 0, lg: "8px" }} marginBottom={{ xs: "1rem", lg: 0 }}>
                                <Typography className="verDetalleOportunidad-KpiBox-container-title">{t("DetalleHomologoClientePage:GridTitle")}</Typography>
                                <Grid display={"flex"} flexDirection={{ xs: "column", tablet: "row" }}>
                                        <KpiBox value={lines} size={100} text={t("DetalleHomologoClientePage:TotalLines")} tooltipText={"Info"}/>


                                </Grid>
                            </Stack>
                    </Grid>
                </Paper>
            </Box>

            <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"space-between"} gap={4}>
                <Typography
                            id="downloadExcelButton"
                            className="GestorOportunidadesPage-Button-permi
                                desc-Download-excel-button
                                serviall-page-caption
                                gestorOportunidades-download-button"
                            onClick={() => handleExport()}
                        >
                             {t("DetalleHomologoClientePage:ExportButton")} <SystemUpdateAltIcon />
                </Typography>
                <Box display={"flex"} flexDirection={"column"}>
                    <Box flexWrap={"wrap"} display={"flex"} gap={4}>
                        <ColumnSelector setDatagridCols={setDatagridCols} initialState={initialCols} page="detalleHomologoCliente" />
                        <ResetFiltersBtn page={"detalleHomologoCliente"} />
                    </Box>

                    <Box width={"100%"}>
                        <ServiallInput 
                            id={"user_query"} 
                            value= {query} 
                            onKeyDown={onKeyDown}
                            placeholder={t("DetalleHomologoClientePage:UserQueryPlaceholder")}
                            fullWidth={true}
                            onChange={(e) => setQuery(e.target.value)}
                            inputClassName="serviall-input"/>
                    </Box>

                </Box>
            </Stack>
            <Typography className="serviall-page-title2">{t("DetalleHomologoClientePage:Subtitle3")}</Typography>
            <Typography className="serviall-page-title2">{t("DetalleHomologoClientePage:Subtitle2")}{filteredLines}</Typography>
            {
                loading ?
                <Box className="serviall-datagrid-loading-container">
                        <Stack direction={"column"} alignItems={"center"} spacing={2}>
                            <Typography className="serviall-h4">{t("DetalleHomologoClientePage:LoadingStatus")}</Typography>
                            <CircularProgress />
                        </Stack>
                    </Box>
                    :
                    rows.length > 0 ?
                    <Box className="serviall-datagrid-container">
                            {datagridCols.length > 0 &&
                                <>
                                    <DraggableDataGrid
                                        className="serviall-datagrid"
                                        cols={datagridCols}
                                        rows={rows}
                                        headerRowHeight={90}
                                        rowKeyGetter={rowKeyGetter}
                                        initialState={initialState}
                                        filters={filters}
                                        setFiltersAction={setFilter}
                                        page={"detalleHomologoCliente"}
                                        pageNum={page}
                                        count={filteredLines}
                                        rowsPerPage={rowsPerPage}
                                        handlePageChange={handlePageChange}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </>
                            }

                        </Box>
                        :
                        <Box className="serviall-datagrid-loading-container">
                            <Typography className="serviall-h4">{t("DetalleHomologoClientePage:NoData")}</Typography>
                        </Box>

            }
            <Typography className="serviall-back-text" onClick={() => navigate(-1)}>
                <ArrowBackIcon /> {t("DetalleHomologoClientePage:GoBackButton")}
            </Typography>
            <EditMastersItemDialog
                dialogOpen={editDialogOpen}
                loading={dialogLoading}
                variant={"clientes"}
                formValues={editForm}
                handleChange={handleEditFormChange}
                handleClose={handleEditDialogClose}
                handleSave={handleSaveEdit}
            />
            <DeleteMastersItemDialog
                dialogOpen={deleteDialogOpen}
                loading={dialogLoading}
                variant={"clientes"}
                formValues={actualDeleteId}
                handleClose={handleDeleteDialogClose}
                handleSave={handleDelete} />
        </Stack>
    )
}


VerDetalleHomologosClientePage.displayName = "VerDetalleHomologosClientePage";

export default VerDetalleHomologosClientePage;
