import { Box} from "@mui/material";
import { useTranslation } from "react-i18next";
import "./MaestroClientesPage.css";
import 'react-data-grid/lib/styles.css';
import DraggableDataGrid from "../../components/DraggableDataGrid";
import {useSelector, useDispatch } from "react-redux";
import { setFilter, setRowsPerPageMaestroClientes} from "../../features/displaySlice";
import { useState } from "react";

/**
 * A React component that renders the datagrid for the Maestro Clientes page.
 * @typedef MaestroClientesPageDatagrid
 * @returns A datagrid component.
 */

const MaestroClientesPageDatagrid = ({ rows }) => {

    const displayData = useSelector((state) => state.display.maestroClientes);

    const filters = displayData.filters;
    const { t } = useTranslation();
    const initialState = {
        id_cliente: "",
        cliente: "",
        sitio_web: "",
        fecha_actualizacion: "",
        sku_cargados: "",
        calidad_carga: "",
    }

    const colWidth = 162;
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const rowsPerPage = displayData.rowsPerPage

    /**
     * Handles the change of the current page number in a paginated view.
     *
     * @param {Object} e - The event object triggered by the page change.
     * @param {number} newPage - The new page number to be set.
     * @returns {void}
     */
    const handlePageChange = (e, newPage) => {
        setPage(newPage);
    }


    /**
     * Handles the change of the number of rows displayed per page.
     *
     * @param {Object} e - The event object triggered by the rows-per-page change.
     * @returns {void}
     */
    const handleChangeRowsPerPage = (e) => {
        dispatch(setRowsPerPageMaestroClientes(parseInt(e.target.value)));
        setPage(0);
    }
    /**
     * Function to get the row key for the datagrid rows.
     * This function is used as a key getter for each row in the datagrid.
     * It returns the "id" property of the row as the key.
     *
     * @param {object} row - The row object in the datagrid.
     * @returns {string|number} The unique key for the row.
     */
    const rowKeyGetter = (row) => {
        return row.id;
    }
    
    const dataGridCols = [
        {
            key: 'id_cliente',
            name: t("MaestroClientesPage:DataGridCol1"),
            width: colWidth - 30,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'cliente',
            name: t("MaestroClientesPage:DataGridCol2"),
            width: colWidth - 30,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'sitio_web',
            name: t("MaestroClientesPage:DataGridCol3"),
            width: colWidth,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'fecha_actualizacion',
            name: t("MaestroClientesPage:DataGridCol4"),
            width: colWidth + 20,
            headerCellClass: "serviall-datagrid-header2",
            type: "date",
            resizable: true,
            sortable: true,
        },
        {
            key: 'sku_cargados',
            name: t("MaestroClientesPage:DataGridCol5"),
            width: colWidth + 50,
            headerCellClass: "serviall-datagrid-header1",
            type: "number",
            resizable: true,
            sortable: true,
        },
        {
            key: 'calidad_carga',
            name: t("MaestroClientesPage:DataGridCol6"),
            width: colWidth + 70,
            headerCellClass: "serviall-datagrid-header2",
            type: "number",
            resizable: true,
            sortable: true,
        },
        {
            key: 'actions',
            reset: true,
            name: t("MaestroClientesPage:DataGridCol9"),
            width: colWidth,
            headerCellClass: "serviall-datagrid-header1",
        },
    ]

    // Fill with empty rowsfor style purposes only
    if (rows.length < 10) {
        let emptyRow = {
            id_proveedor: "",
            proveedor: "",
            sitio_web: "",
            fecha_actualizacion: "",
            sku_cargados: "",
            calidad_carga: "",
            actions: ""
        }

        for (let i = rows.length; i < 12; i++) {
            rows.push({ id: i, ...emptyRow });
        }
    }

    return (
        <Box className="serviall-datagrid-container">
            <DraggableDataGrid
                className="serviall-datagrid"
                cols={dataGridCols}
                rows={rows}
                rowKeyGetter={rowKeyGetter}
                headerRowHeight={90}
                initialState={initialState}
                filters={filters}
                setFiltersAction={setFilter}
                page={"maestroClientes"}
                pageNum={page}
                rowsPerPage={rowsPerPage}
                handlePageChange={handlePageChange}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            
            
        </Box>
    )
}

MaestroClientesPageDatagrid.displayName = "MaestroClientesPageDatagrid";

export default MaestroClientesPageDatagrid;