import axios from "axios";


function getAccessToken() {
    return localStorage.getItem('access_token');
}

function getRefreshToken() {
    return localStorage.getItem('refresh_token');
}

function setAccessToken(newAccessToken) {
    localStorage.setItem('access_token', newAccessToken);
}


const serviallAxios = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_BACKEND_URL,
    timeout: process.env.REACT_APP_BACKEND_DEFAULT_TIMEOUT_MS || 240000, // 4 min timeout for normal backend
});


// Interceptor de request para agregar el access token al header
serviallAxios.interceptors.request.use(
    (config) => {
        const accessToken = getAccessToken();
        console.log('interceptors ran')
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);


// Interceptor de respuesta para manejo de refresh token
serviallAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = getRefreshToken();

            if (refreshToken) {
                try {
                    const refreshResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/refresh`, {
                        headers: {
                            Authorization: `Bearer ${refreshToken}`,
                        },
                    });
                    const newAccessToken = refreshResponse.data.access_token;
                    setAccessToken(newAccessToken);
                    originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                    return axios(originalRequest);
                } catch (refreshError) {
                    // Manejar errores de renovación de token
                    console.error('Error al renovar el token de acceso:', refreshError);
                    window.localStorage.removeItem("configPage");
                    window.localStorage.removeItem('access_token');
                    window.localStorage.removeItem('refresh_token');
                    // Puedes redirigir al usuario a la página de inicio de sesión o tomar otras medidas según tu aplicación
                    // redirigir a login
                    alert("Sesión expirada, por favor inicie sesión nuevamente.")
                    window.location.href = '/';
                    return Promise.reject(refreshError);
                }
            }
        }
        return Promise.reject(error);
    }
);


export default serviallAxios;