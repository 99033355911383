import React from 'react'
import { TablePagination, Box, Typography, Select, FormControl, MenuItem} from '@mui/material'
import { useTranslation } from 'react-i18next'

/**
 * A pagination component for a data grid using Material-UI components.
 *
 * @component
 * @param {Object} props - The props object containing necessary parameters.
 * @param {Array} props.rows - The array of data rows to be paginated.
 * @param {number} props.page - The current active page index.
 * @param {Function} props.handlePageChange - Callback function to handle page changes.
 * @param {number} props.rowsPerPage - The number of rows to be displayed per page.
 * @param {Function} props.handleChangeRowsPerPage - Callback function to handle rows per page changes.
 * @returns {JSX.Element} - A JSX element representing the pagination component.
 */
const DatagridPagination = ({rows, page, handlePageChange, rowsPerPage, handleChangeRowsPerPage, count}) => {
    const {t} = useTranslation()
    const totalPages = [...Array(Math.ceil((count ? count : rows.length) / rowsPerPage)).keys()];
    return (
        <Box display={"flex"} gap={2} justifyContent={"flex-end"} alignItems={"stretch"} className="datagridPagination" sx={{position: "relative"}} overflowx="auto" flexWrap="wrap">
            <TablePagination
            disableUnderline={true}
            count={count ? count : rows.length}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("Common:PaginationLabelRow")}
            labelDisplayedRows={({ from, to, count }) => {
                return '' + from + '-' + to + ' ' + t("Common:PaginationQtyPerPage") + ' ' + count
            }}
            showFirstButton
            showLastButton
            />
            <Box display={"flex"} gap={2} alignItems={"center"}> 
                <Box width={"2px"} py={"4"} style={{height: "30px", borderLeft: "2px solid var(--mui-palette-serviall-currencyInputHover)"}}></Box>
                <Box display={"flex"} gap={2}  alignItems={"center"}>
                    <p>{t("Common:GoToPage")}</p>
                    <FormControl className='homePage-mui-input-container'>
                        <Select  value={page}  variant="standard" onChange={(e) => handlePageChange("", e.target.value)} fullWidth disableUnderline={true}>
                            {
                                totalPages.map((pageNumber) => {
                                    return (
                                        <MenuItem divider value={pageNumber}>
                                            <Typography className='serviall-small'>
                                                {pageNumber+1}
                                            </Typography>
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </Box>

    )
}

export default DatagridPagination