import React, { useEffect, useState } from 'react'
import { Paper, Stack, Typography, Link, Box } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { GroupingState, IntegratedGrouping, PagingState, IntegratedPaging } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableGroupRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import serviallAxios from "../../axiosConfig"
import { TIMEOUTS } from '../../utils';
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from '@mui/icons-material/Add';
import "./ConfiguracionMantenedoresModelos.css"
import { setAlert } from '../../features/navigationSlice';
import { useDispatch } from 'react-redux';
import MaintainersModelsDialog from '../../components/MaintainersModelsDialog';
import { CustomAlertMessage } from '../../utils';
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * React component for configuring and managing models.
 * It displays a data grid containing models, allows editing, and provides pagination.
 * @typedef {function} ConfiguracionMantenedoresModelos
 * @returns {JSX.Element} - JSX element representing the configuration and management of models.
 */
const ConfiguracionMantenedoresModelos = () => {

  const dispatch = useDispatch()
  const { t } = useTranslation();

  const columns = [
    { name: "usuario", title: t("MantenedoresModelos:Col0"), className:"serviall-datagrid-header1"},
    { name: 'idioma', title: t("MantenedoresModelos:Col1")},
    { name: 'nombre', title: t("MantenedoresModelos:Col2")},
    { name: 'enlace', title: t("MantenedoresModelos:Col3")},
    { name: 'acciones', title: t("MantenedoresModelos:Col4")}
  ];


  const [rows, setRows] = useState([]);
  const [pageSizes] = useState([5, 10, 15, 0]);

  /* 
  * actualModel is used when editing a model
  *
  * actualAddModel is used when adding a model to an empty client entry
  */
  const [actualModel, setActualModel] = useState({
    client_name: "",
    lang: "",
    executor_name: "",
    link: "",
    client_rut: ""
  })
  const [actualAddModel, setActualAddModel] = useState({
    client_rut: "",
    lang: "",
    client_name: "",
    executor_name: ""
  })
  const [newModel, setNewModel] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogAddOpen, setDialogAddOpen] = useState(false)
  const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false)
  const [relatedExecutors, setRelatedExecutors] = useState([])


  /**
   * Checks if the given executor name contains the specified language code.
   *
   * This function validates whether the provided executor name includes the given language code.
   * If the executor name is falsy or doesn't include the language code, the function returns false; otherwise, it returns true.
   *
   * @function checkFormat
   * @param {string} new_executor_name - The executor name to be checked.
   * @param {string} lang - The language code to be checked within the executor name.
   * @returns {boolean} - Returns true if the executor name contains the language code; otherwise, returns false.
   */
  const checkFormat = (new_executor_name, lang) => {
    if (!new_executor_name) return false;
    if (!new_executor_name.includes(lang)) return false;
    return true
  }

  /**
  * Function to handle editing a model.
  * It sets the actualModel state and initializes the newModel state for editing.
  * @param {string} user - The user of the model.
  * @param {string} lang - The language of the model.
  * @param {string} name - The name of the model.
  * @param {string} link - The link to the model.
  * @param {string} client_rut - The client's RUT (Rol Único Tributario).
  */
  const handleEdit = (user, lang, name, link, client_rut) => {
    setActualModel({ client_name: user, lang: lang, executor_name: name, link: link, client_rut: client_rut })
    const initNewModel = {
      old_executor: name,
      client_rut: client_rut,
      is_active: true,
    }
    setNewModel(initNewModel)
    setDialogOpen(true)
  }

  const handleDelete = (user, lang, name, link, client_rut) => {
    setActualModel({ client_name: user, lang: lang, executor_name: name, link: link, client_rut: client_rut })
    const initNewModel = {
      old_executor: name,
      client_rut: client_rut,
      is_active: false,
    }
    setNewModel(initNewModel)
    setDialogDeleteOpen(true)
  }
  /**
   * Handles the addition of a new client information into the system.
   *
   * This function creates a new client model with the provided client RUT (Rol Único Tributario),
   * language, and client name. The executor name is initially set to an empty string. The newly
   * created model is then set as the actual add model, and the dialog for adding a client is opened.
   *
   * @param {string} client_rut - The client's RUT (Rol Único Tributario).
   * @param {string} lang - The language associated with the client.
   * @param {string} client_name - The name of the client.
   * @returns {void}
   */
  const handleAdd = (client_rut, lang, client_name) => {
    const newAddModel = {
      client_rut: client_rut,
      lang: lang,
      client_name: client_name,
      executor_name: ""
    }

    setActualAddModel(newAddModel)
    setDialogAddOpen(true)
  }

  /**
   * Handles the change of a specific property in the current add model.
   *
   * This function updates the specified property with the provided value in the current add model.
   * The function creates a new object based on the current add model, updates the specified property,
   * and then sets the new add model to reflect the changes.
   *
   * @param {string} id - The identifier of the property to be changed in the add model.
   * @param {*} val - The new value to be assigned to the specified property.
   * @returns {void}
   * 
   */
  const handleChangeAddModel = (id, val) => {
    const newAddModel = { ...actualAddModel }
    newAddModel[id] = val

    setActualAddModel(newAddModel)

  }

  /**
   * Function to handle changes in the input fields during editing.
   * It updates the value of the corresponding field in the actualModel state.
   * @param {string} value - The new value of the field.
   * @param {string} id - The id of the field being updated.
   */
  const handleChange = (id, value) => {
    const newActualModel = { ...actualModel }
    newActualModel[id] = value
    setActualModel(newActualModel)
  }

  /**
   * Function to handle submitting the edited model to the server for saving.
   * It sends a PUT request to the server with the updated model data.
   * After a successful request, it fetches the updated models from the server.
   */
  const handleSubmit = () => {
    if (!checkFormat(actualModel.executor_name, actualModel.lang)) {
      dispatch(setAlert({
        open: true,
        severity: "error",
        message: `Error: ${t("MantenedoresModelos:ErrorMsg")}`
      }))
      return
    }
    const payload = {
      ...newModel
    }
    payload["new_executor"] = actualModel.executor_name

    serviallAxios.put(process.env.REACT_APP_ML_BACKEND_URL + "/maintainers/models", payload, {
      timeout: TIMEOUTS.medium
    })
      .then((res) => {
        fetchModels()
      })
      .catch((err) => {
        dispatch(setAlert({
          open: true,
          severity: "error",
          message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
      }));
      })
      .finally(() => {
        setDialogOpen(false)
      })

  }

  const handleSubmitDelete = () => {
    const payload = {
      ...newModel
    }
    serviallAxios.put(process.env.REACT_APP_ML_BACKEND_URL + "/maintainers/models", payload, {
      timeout: TIMEOUTS.medium
    })
      .then((res) => {
        fetchModels()
      })
      .catch((err) => {
        dispatch(setAlert({
          open: true,
          severity: "error",
          message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
      }));
      })
      .finally(() => {
        setDialogDeleteOpen(false)
      })

  }
  /**
  * Handles the submission of adding a new executor model. Validates the input format,
  * dispatches an error alert if needed, and sends a POST request to create the executor.
  *
  */
  const handleSubmitAdd = () => {
    if (!checkFormat(actualAddModel.executor_name, actualAddModel.lang)) {
      dispatch(setAlert({
        open: true,
        severity: "error",
        message: `Error: ${t("MantenedoresModelos:ErrorMsg")}`
      }))
      return
    }
    const payload = {
      executor_name: actualAddModel.executor_name,
      client_rut: actualAddModel.client_rut
    }

    serviallAxios.post("/masters/executor", payload, {
      timeout: TIMEOUTS.small
    })
      .then((res) => {
        fetchModels()
      })
      .catch((err) => {
        dispatch(setAlert({
          open: true,
          severity: "error",
          message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
      }));
      })
      .finally(() => {
        setDialogAddOpen(false)
      })

  }

  /**
   * Retrieves and sets a list of related executor names based on a given language match.
   *
   * This function iterates through the rows and extracts executor names that include the specified language code.
   * If the provided rows array is empty, an empty array is returned.
   * The extracted executor names are stored in the 'relatedExecutors' state variable using the 'setRelatedExecutors' function.
   *
   * @returns {void}
   */
  const getRelatedExecutors = () => {
    serviallAxios.get(process.env.REACT_APP_ML_BACKEND_URL + "/maintainers/models").then(res => {
      const related = []
      for (let i = 0; i < res.data.models.length; i++) {
        const part = res.data.models[i].split('/')[1];
        related.push(part)
      }
      setRelatedExecutors(related)
    }).catch(error => {
      dispatch(setAlert({
        open: true,
        severity: "error",
        message: error.response ? error.response.data.detail : CustomAlertMessage(error.code)
    }));
    })

  }

  /**
   * Generates a set of edit actions UI elements for a client entry.
   *
   * This function returns a JSX component that displays edit and delete icons for a client entry.
   * When clicked, the edit icon invokes the handleEdit function with relevant parameters, while
   * the delete icon triggers the handleDelete function.
   *
   * @param {string} client_name - The name of the client.
   * @param {string} lang - The language associated with the client.
   * @param {string} executor_name - The executor's name associated with the client.
   * @param {string} link - The link associated with the client entry.
   * @param {string} client_rut - The client's RUT (Rol Único Tributario).
   * @returns {JSX.Element} A JSX element containing edit and delete icons for client actions.
   */
  const editActions = (client_name, lang, executor_name, link, client_rut) => {
    return (
      <Box display={"flex"} gap={2}>
        {/* <EditIcon className={"versions-icon"}
          onClick={() => handleEdit(client_name, lang, executor_name, link, client_rut)} /> */}

        <DeleteIcon className={"versions-icon"} 
          onClick={() => handleDelete(client_name, lang, executor_name, link, client_rut)}/>
      </Box>
    )
  }
  /**
   * Function to fetch models from the server.
   * It sends a GET request to the server to fetch model data.
   * The response data is processed and used to update the rows state.
   */

  const fetchModels = () => {
    serviallAxios.get(process.env.REACT_APP_ML_BACKEND_URL + "/maintainers/model_client",
      {
        timeout: TIMEOUTS.medium
      })
      .then((res) => {
        const newRows = []
        res.data.model_clients.forEach((sublist) => {
          let spanishModel = null;
          let englishModel = null;
        
          sublist.forEach((model) => {
            if (model.is_active === 1) {
              if (model.executor_name.includes("_es_")) {
                spanishModel = model;
              } else {
                englishModel = model;
              }
            }
          });
        
          newRows.push({
            usuario: sublist[0].client_name,
            idioma: t("MantenedoresModelos:Spanish"),
            nombre: spanishModel && spanishModel.executor_name,
            enlace: spanishModel ? <Link href={spanishModel.model_card}>{spanishModel.model_card}</Link> : null,
            acciones: spanishModel ? editActions(sublist[0].client_name, "es", spanishModel.executor_name, spanishModel.model_card, sublist[0].client_rut) : <AddIcon className='versions-icon' onClick={() => handleAdd(sublist[0].client_rut, "es", sublist[0].client_name)} />
          });
        
          newRows.push({
            usuario: sublist[0].client_name,
            idioma: t("MantenedoresModelos:English"),
            nombre: englishModel && englishModel.executor_name,
            enlace: englishModel ? <Link href={englishModel.model_card}>{englishModel.model_card}</Link> : null,
            acciones: englishModel ? editActions(sublist[0].client_name, "en", englishModel.executor_name, englishModel.model_card, sublist[0].client_rut) : <AddIcon className='versions-icon' onClick={() => handleAdd(sublist[0].client_rut, "en", sublist[0].client_name)} />
          });
        });
        setRows(newRows)
      })
      .catch((err) => {
        dispatch(setAlert({
          open: true,
          severity: "error",
          message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
      }));
      })
  }
  /**
   * React useEffect hook to fetch models from the server when the component mounts.
   */
  useEffect(() => {
    fetchModels()
  }, [])

  /**
   * Executes the 'getRelatedExecutors' function when there are changes to the 'actualAddModel' or 'actualModel'.
   *
   * This effect triggers the 'getRelatedExecutors' function when either the 'actualAddModel' or 'actualModel' dependency changes.
   * It ensures that the related executors are fetched and updated whenever there is a change in the context that requires it.
   *
   * @function useEffect
   * @memberof ComponentName
   * @param {function} getRelatedExecutors - The function to fetch related executors.
   * @param {any} actualAddModel - The current value of the 'actualAddModel' dependency.
   * @param {any} actualModel - The current value of the 'actualModel' dependency.
   * @returns {void}
   */
  useEffect(() => {
    getRelatedExecutors()
  }, [actualAddModel, actualModel])

  return (
    <Stack direction={"column"}>
      <Typography className="serviall-page-title1">
        {t("MantenedoresModelos:Title")}
      </Typography>
      <Typography className="serviall-page-title2" marginBottom={4}>
        {t("MantenedoresModelos:Description")}
      </Typography>
      <Paper className='configuracionMantenedoresModelos-grid' sx={{borderRadius: 2}}>
        <Grid
          rows={rows}
          columns={columns}
        
        >
          <PagingState
            defaultCurrentPage={0}
            defaultPageSize={5}
          />
          <GroupingState
            grouping={[{ columnName: 'usuario' }]}
          />
          <IntegratedGrouping />
          <IntegratedPaging />
          <Table />
          <TableHeaderRow />
          <TableGroupRow />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
      </Paper>

      <MaintainersModelsDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} modelInfo={actualModel} handleChange={handleChange} handleSubmit={handleSubmit} relatedExecutors={relatedExecutors} title={t("MantenedoresModelos:DialogTitle")} />
      <MaintainersModelsDialog dialogOpen={dialogAddOpen} setDialogOpen={setDialogAddOpen} modelInfo={actualAddModel} handleChange={handleChangeAddModel} handleSubmit={handleSubmitAdd} relatedExecutors={relatedExecutors} title={t("MantenedoresModelos:DialogAddTitle")} />
      <MaintainersModelsDialog dialogOpen={dialogDeleteOpen} setDialogOpen={setDialogDeleteOpen} modelInfo={actualModel} handleSubmit={handleSubmitDelete} title={t("MantenedoresModelos:DialogDeleteTitle")} isDelete={true} />
    </Stack>

  );
}

export default ConfiguracionMantenedoresModelos