import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next";

/**
 * A React component that renders an info dialog to tell the user when a licitation is being edited by a user.
 * @typedef ActiveInfoDialog
 * @param open: Bolean that tells when to open the dialog component, it is usually a state value.
 * @param lic_name: String that represents the name of the licitation being edited.
 * @param name: String that represents the name of the user editing the licitation.
 * @param email: String that represents the mail of the user editing the licitation.
 * @param handleClose: Function called when the component is closed, generally this should set the state of the open parameter to false.
 * @returns A dialog component.
 */

const ActiveInfoDialog = ({ open, field1, field2, field3, handleClose, type }) => {
    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                <Typography className="serviall-h4 serviall-color-main">
                    {t("Common:Alert")}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Stack direction={"column"} spacing={3}>
                    <Typography className="serviall-h5" sx={{ textAlign: "justify" }}>
                        {type === "active_user" ? t("Common:ActiveUserInfo") : t("Common:ActiveMasterInfo")}
                    </Typography>
                    <Box>
                        <Typography className="serviall-h5" sx={{ fontWeight: "700" }} >
                        {type === "active_user" ? t("Common:Opportunity") : "Rut"}: {field1}
                        </Typography>
                        <Typography className="serviall-h5" sx={{ fontWeight: "700" }} >
                        {type === "active_user" ? t("Common:User") : t("Common:Name") }: {field2}
                        </Typography>
                        <Typography className="serviall-h5" sx={{ fontWeight: "700" }}>
                        {type === "active_user" ? t("Common:Email") : t("Common:Website") }: {field3}
                        </Typography>
                    </Box>
                    <Typography className="serviall-h5" sx={{ textAlign: "justify" }}>
                    {type === "active_user" ? t("Common:ActiveUserBottomText") : t("Common:ActiveMasterBottomText") }
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className="serviall-button">
                    {t("Common:Accept")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ActiveInfoDialog;