import { Box, Button, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as PlusIcon } from '../../assets/icons/mas.svg';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArchivoLogo } from "../../assets/icons/archivo.svg";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ListIcon from '@mui/icons-material/List';
import ServiallActionsMenu from "../../components/ServiallActionsMenu";
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import "./MaestroClientesPage.css";
import MaestroClientesPageDatagrid from "./MaestroClientesDatagrid";
import { useEffect, useState } from "react";
import serviallAxios from "../../axiosConfig";
import ServiallLoading from "../../components/ServiallLoading";
import { TIMEOUTS } from "../../utils";
import { setAlert } from "../../features/navigationSlice";
import ResetFiltersBtn from "../../components/ResetFiltersBtn";
import ActiveInfoDialog from "../../components/ActiveInfoDialog";
import { CustomAlertMessage } from "../../utils";

/**
 * A React component that renders the 'Maestro Clientes' page.
 * @typedef MaestroClientesPage
 * @returns A page with the Maestro Clientes components
 */

const MaestroClientesPage = () => {

    const maestroClientesPermissions = useSelector((state) => state.permissions.componentPermissions.MaestroClientesPage);
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const lang = useSelector((state) => state.settings.language);
    const [redirectRoute, setRedirectRoute] = useState("");
    const [redirectState, setRedirectState] = useState({});
    const [open, setOpen] = useState(false)
    const [actualClient, setActualClient] = useState({
        rut: "",
        name: "",
        website: ""
    })
    /**
     * useEffect hook to fetch client datagrid rows when the component is mounted or the 'loading' state changes.
     * It is used to update the datagrid rows based on changes in the 'loading' state.
     */
    useEffect(() => {
        fetchClientDatagridRows();
    }, [loading])

    /**
     * useEffect hook to set the 'loading' state to 'true' when the 'lang' (language) state changes.
     * It is used to reload the page data when the language is changed.
     */
    useEffect(() => {
        setLoading(true)
    }, [lang])

    /**
     * useEffect hook to force page redirect when the state changes because of a weird bug that makes react router not change
     * the component (even though the url changes) when a download happened before, for some reason the "downloading file"
     * alert causes this behaviour.
     */
    useEffect(() => {
        if(redirectRoute.length > 0){
            navigate(redirectRoute, {state: redirectState})
        }
    }, [redirectRoute])

    /**
     * Function that creates a data object for a row in the datagrid.
     *
     * @param {number} id - The unique identifier for the row.
     * @param {string} id_cliente - The ID of the client.
     * @param {string} cliente - The name of the client.
     * @param {string} sitio_web - The website of the client.
     * @param {string} fecha_actualizacion - The date of the last update for the client.
     * @param {string} sku_cargados - The number of SKUs loaded for the client.
     * @param {string} calidad_carga - The quality of the data load for the client.
     * @param {string} sku_match - The number of matched SKUs for the client.
     * @param {string} apariciones - The number of appearances for the client.
     * @param {JSX.Element} actions - The JSX element containing the row actions.
     * @returns {object} The data object for the row in the datagrid.
     */
    const makeData = (
        id,
        id_cliente,
        cliente,
        sitio_web,
        fecha_actualizacion,
        sku_cargados,
        calidad_carga,
        sku_match,
        apariciones,
        actions
    ) => {
        return { id, id_cliente, cliente, sitio_web, fecha_actualizacion, sku_cargados, calidad_carga, sku_match, apariciones, actions }
    }

    /**
     * Function to fetch client datagrid rows from the server.
     * It makes an HTTP GET request to retrieve the data and updates the state with the fetched rows.
     */
    const fetchClientDatagridRows = () => {
        if (loading === true) {
            serviallAxios.get("/masters/clients", {
                timeout: TIMEOUTS.small
            })
                .then(res => {
                    // data has shape 
                    // id, rut, name, website, version, created_at, updated_at
                    let rows = res.data.clients_data.map((row, idx) => makeData(idx, row[1], row[2], row[3], row[7] ? (new Date(row[7]).toLocaleDateString('es-GB', { year: "numeric", month: "2-digit", day: "2-digit" })).toString() : "", row[10].toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."), row[11], row[12], row[13], makeRowActions({
                        rut: row[1],
                        name: row[2],
                        website: row[3],
                        updated_at: row[7] ? (new Date(row[7]).toLocaleDateString('es-GB', { year: "numeric", month: "2-digit", day: "2-digit" })).toString() : "",
                        current_version: row[5],
                        
                    })))
                    setRows(rows);
                })
                .catch(err => {
                    dispatch(setAlert({
                        open: true,
                        severity: "error",
                        message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                    }));
                })
                .finally(() => { setLoading(false) })
        }
    }

    /**
     * Function to handle the click event when the "Nuevo Cliente" link is clicked.
     * It navigates to the "nuevo_cliente" route.
     */
    const handleNuevoCliente = () => {
        setRedirectRoute("/dashboard/nuevo_cliente");
    }

    /**
     * Function to handle the click event when the "Download" button is clicked.
     * It makes an HTTP GET request to download a file containing data for all clients.
     */
    const handleDownload = () => {

        dispatch(setAlert({
            open: true,
            severity: "info",
            message: t("Common:Download")
        }))

        serviallAxios.get("masters/all_clients_sheet", {
            timeout: TIMEOUTS.small,
            responseType: "blob"
        }).then((res) => {
            const href = URL.createObjectURL(res.data);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", "all_clients.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
            .catch((err) => {

                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }))
            })
    }

    /**
     * Function to handle the click event when downloading data for a specific client.
     * It makes an HTTP GET request to download a file containing data for a specific client using the provided "rut" (ID).
     *
     * @param {string} rut - The ID of the client to download data for.
     */
    const handleDownloadSpecificClient = (rut) => {

        dispatch(setAlert({
            open: true,
            severity: "info",
            message: t("Common:Download")
        }))

        serviallAxios.get(`masters/client_sheet/${rut}`, {
            timeout: TIMEOUTS.small,
            responseType: "blob"
        }).then((res) => {
            const href = URL.createObjectURL(res.data);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", `cliente_${rut}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
            .catch((err) => {
                if (err.response && err.response.request.status === 404){
                    dispatch(setAlert({
                        open: true,
                        severity: "info",
                        message: t("MaestroClientesPage:NoItems")
                    }))
                }
                else{
                    dispatch(setAlert({
                        open: true,
                        severity: "error",
                        message: CustomAlertMessage(err.code)
                    }))
                }
               
            })
    }

    /**
     * Closes a dialog.
     *
     * @returns {void}
     */
    const handleClose = () => {
        setOpen(false)
    }

    /**
     * Checks if a client is already processed and handles the redirection or displaying a modal.
     *
     * @param {Object} params - Parameters containing information about the client.
     * @param {string} params.rut - The RUT of the client.
     * @param {string} params.name - The name of the client.
     * @param {string} params.website - The website of the client.
     * @returns {void}
     */
    const checkIsProcessed = (params) => {
        serviallAxios.get(`masters/masters_client_processed/${params.rut}`, { 
            timeout: TIMEOUTS.medium,
        }).then((res) => {
            if (!res.data.is_processing){
                setRedirectRoute("/dashboard/detalle_homologo_cliente/" + params.rut);
                    setRedirectState(params);
            }
            else{
                const newClient = {
                    rut: params.rut,
                    name: params.name,
                    website: params.website
                }
                setActualClient(newClient)
                setOpen(true)
            }
        })
        .catch((err) => {
            dispatch(setAlert({
                open: true,
                severity: "error",
                message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
            }));
        })
    }

    /**
    * Function to create row actions for a specific client.
    *
    * @param {object} params - The parameters object containing information about the client.
    * @returns {JSX.Element} The JSX element containing the row actions.
    */
    const makeRowActions = (params) => {
        const actions = [
            {
                key: "verDetalle" + params.id,
                icon: <ArchivoLogo fill="currentColor" className="maestroClientes-action-icon" />,
                text: t("MaestroClientesPage:DataGridAction1"),
                onClick: () => {
                    setRedirectRoute("/dashboard/detalle_maestros_cliente/");
                    setRedirectState(params);
                }
            },
            {
                key: "verDetalleHomologo" + params.id,
                icon: <HandymanOutlinedIcon />,
                text: t("MaestroClientesPage:DataGridAction3"),
                onClick: () => {
                    checkIsProcessed(params)
                }
            },
            {
                key: "descargarExcel1" + params.id,
                icon: <SystemUpdateAltIcon />,
                text: t("MaestroClientesPage:DataGridAction2"),
                onClick: () => { handleDownloadSpecificClient(params.rut) }
            }
        ]

        if (params) {
            return <ServiallActionsMenu
                menu={<ListIcon className='maestroClientes-datagrid-action-icon' />}
                actions={actions}
            />
        }
        else {
            return ""
        }
    }

    return (
        <Box>
            <ActiveInfoDialog open={open} field1={actualClient.rut} field2={actualClient.name} field3={actualClient.website} handleClose={handleClose} type={"active_master"}/>
            <Stack direction={"column"} marginBottom={4}>
                <Typography className="serviall-page-title1">{t("MaestroClientesPage:Title")}</Typography>
                <Box display={"flex"} flexDirection={{ xs: "column", sm: "row" }} sx={{ overflowWrap: "break-word" }}>
                    <Typography className="serviall-page-title2">
                        {t("MaestroClientesPage:Caption2")}
                    </Typography>
                </Box>
            </Stack>
            <Box display={"flex"} flexDirection={{ xs: "column", sm: "row" }} gap={{ xs: 2 }} marginBottom={4} justifyContent={{ sm: "space-between" }}>
                {maestroClientesPermissions.Typography.addClientLink === "hidden" ?
                    <Typography></Typography>
                    :
                    <Typography
                        id="addClientLink"
                        className='maestroClientes-actions-nuevoCliente 
                                   MaestroClientesPage-Typography-permi 
                                   desc-Add_master_client_page_link'
                        onClick={handleNuevoCliente}
                    >
                        <Typography className="serviall-main-color">
                            <PlusIcon fill="currentColor" className="maestroClientes-plus-icon" />
                        </Typography>
                        &nbsp; {t("MaestroClientesPage:Subtitle")}
                    </Typography>
                }
                <Box display={"flex"} gap={4}>
                    <Typography
                            id="downloadExcelButton"
                            className="GestorOportunidadesPage-Button-permi
                                desc-Download-excel-button
                                serviall-page-caption
                                gestorOportunidades-download-button"
                            onClick={() => handleDownload()}
                        >
                            {t("MaestroClientesPage:Download")} <SystemUpdateAltIcon />
                    </Typography>
                    <ResetFiltersBtn page={"maestroClientes"}/>
                </Box>
                
            </Box>
            {
                loading ?
                    <ServiallLoading text={t("MaestroClientesPage:LoadingStatus")} />
                    :
                    rows.length > 0 ?
                        <MaestroClientesPageDatagrid
                            rows={rows}
                        />
                        :
                        <Box className="serviall-datagrid-loading-container">
                            <Typography className="serviall-h4">{t("MaestroClientesPage:NoClients")}</Typography>
                        </Box>
            }
        </Box >
    )
}

MaestroClientesPage.displayName = "MaestroClientesPage";

export default MaestroClientesPage;