import { Divider, Menu, MenuItem, MenuList, Stack, Typography } from "@mui/material";
import "./ServiallActionsMenu.css";
import { useState } from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

/**
 * A React component to display a menu with actions.
 * @param {string} menu - The menu to display.
 * @param {array} actions - The actions to display.
 * @params {array} actionsSecondary - The actions to display in the secondary menu.
 * @params {array} actionsTertiary - The actions to display in the tertiary menu.
 * @returns 
 */

const ServiallActionsMenu = ({ menu, actions, actionsSecondary, actionsTertiary }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const {t} = useTranslation();

    /**
     * Event handler function to handle the click event on the component.
     *
     * @param {object} e - The click event object.
     */
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    /**
     * Event handler function to handle the close event on the component.
     * It sets the anchor element to null to close the menu.
     */
    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <>
            <Box sx={{ cursor: "pointer", width: "fit-content" }} onClick={handleClick}>
                {
                    menu ?
                        menu
                        :
                        "Menu"
                }
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <MenuList sx={{ width: "300px" }} dense>
                    {
                        actions ?
                            actions.map(action => {
                                return (
                                    action &&
                                    <MenuItem key={action.key} onClick={action.onClick} style={{ display: "flex", flexDirection: "column", position:"relative"}}>
                                        {action.key === "gestorOportunidadesDescargarOriginal" ?  <Divider style={{ width: "100%", position:"absolute", borderColor: "gray", paddingTop: "0.5rem", paddingBottom: "0.5rem", top: "-15px"}}/>   : ""}
                                        <Stack direction={"row"} spacing={2} style={{ width: "100%"}}>
                                            <Typography className="serviall-actions-menu-icon">
                                                {action.icon}
                                            </Typography>
                                            <Typography className="serviall-actions-menu-text">
                                                {action.text}
                                            </Typography>
                                        </Stack>
                                    </MenuItem>
                                )
                            })
                            :
                            <Typography>
                                {t("Common:NoActions")}
                            </Typography>
                    }
                    {actionsSecondary && <Divider sx={{ borderColor: "gray" }} />}
                    {
                        actionsSecondary &&
                        actionsSecondary.map(action =>
                            <MenuItem key={action.key} onClick={action.onClick}>
                                <Stack direction={"row"} spacing={2}>
                                    <Typography className="serviall-actions-menu-icon">
                                        {action.icon}
                                    </Typography>
                                    <Typography className="serviall-actions-menu-text">
                                        {action.text}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        )
                    }
                    {actionsTertiary && <Divider sx={{ borderColor: "gray" }} />}
                    {
                        actionsTertiary &&
                        actionsTertiary.map(action =>
                            <MenuItem key={action.key} onClick={action.onClick}>
                                <Stack direction={"row"} spacing={2}>
                                    <Typography className="serviall-actions-menu-icon">
                                        {action.icon}
                                    </Typography>
                                    <Typography className="serviall-actions-menu-text">
                                        {action.text}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        )
                    }
                </MenuList>
            </Menu>
        </>
    )
}

ServiallActionsMenu.displayName = "ServiallActionsMenu";

export default ServiallActionsMenu;