import {
    Box, Button, FormControl, Select, Typography, MenuItem, Stack, TextField, Tooltip, ListSubheader, Alert,
    Snackbar, Autocomplete, Chip
} from '@mui/material';
import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef } from "react";
import { useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ServiallUploadButton from '../../components/ServiallUploadButton';
import serviallAxios from '../../axiosConfig';
import ServiallDialog from '../../components/ServiallDialog';
import "./NuevaOportunidadMasivaPage.css";
import ServiallInput from '../../components/ServiallInput';
import { useNavigate } from 'react-router-dom';
import { TIMEOUTS } from '../../utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SelectLanguage from '../../components/SelectLanguage';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../features/navigationSlice';
import { CustomAlertMessage } from '../../utils';
import * as XLSX from 'xlsx';
/**
 * A React component that renders the 'Nueva Oportunidad' page.
 * @typedef NuevaOportunidadPage
 * @returns A component with the form to add a new opportunity.
 */

function NuevaOportunidadPage(props) {

    const { t } = useTranslation();
    const lang = useSelector((state) => state.settings.language)
    const user = useSelector((state) => { return { id: state.navigation.id, email: state.navigation.email } })

    const navigate = useNavigate();

    const nuevaOportunidadPermissions = useSelector((state) => state.permissions.componentPermissions.NuevaOportunidadPage);

    // Family selection states
    const [selectedFamily, setSelectedFamily] = useState("");
    const [selectedFamilies, setSelectedFamilies] = useState([]);
    const [executives, setExecutives] = useState([]);
    const [selectedExecutive, setSelectedExecutive] = useState()
    const [familyOptions, setFamilyOptions] = useState([]);

    const [file, setFile] = useState(null);
    const [formValues, setFormValues] = useState({
        client_id: "",
        client_name: "",
        opportunity_id: "",
        opportunity_title: "",
        opportunity_type: "",
        assigned_executive: "",
        deadline: "",
        //file_lang: lang,
        file_lang: "auto",
    })



    const [licitationIds, setLicitationIds] = useState([])
    const [s3Paths, setS3Paths] = useState([])
    const [clientIds, setClientIds] = useState([])
    const [opportunityIds, setOpportunityIds] = useState([])
    const [clientNames, setClientNames] = useState([])

    // Dialog states
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogStatus, setDialogStatus] = useState("");
    const [uploadErrorCode, setUploadErrorCode] = useState("");
    const [uploadErrorMsg, setUploadErrorMsg] = useState("");

    /* Retry requests  */
    const intervalRef = useRef(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const [startRequest, setStartRequest] = useState(false)

    // Snackbar states
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    /* Clients rut state */
    const [clientsRut, setClientsRut] = useState([])
    const [loadOpportunity, setLoadOpportunity] = useState(false)

    const dispatch = useDispatch();

    /**
     * Fetches necessary data from API endpoints and handles ML model processing based on conditions.
     *
     * This effect is triggered on initial component mount and when certain state values change.
     * It fetches family options and executive names, and initiates processing using a machine
     * learning model with the provided data.
     *
     * @effect
     * @returns {void}
     */
    useEffect(() => {
        //setMessageDialog(_messageDialog[0])
        /*serviallAxios.get("opportunities/family", {
            timeout: TIMEOUTS.small
        })
            .then((res) => {
                setFamilyOptions(["", ...res.data.family_list]);
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })*/

        serviallAxios.get("opportunities/executives", {
            timeout: TIMEOUTS.small
        })
            .then((res) => {
                let exec_names = res.data.body.map((row) => {
                    return {
                        id: row[0],
                        name: row[2]
                    }
                })
                setExecutives([{ id: null, name: "Sin ejecutivo" }, ...exec_names]);
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
    }, [])

    /**
     * Initiates ML model processing based on certain conditions.
     *
     * This effect is triggered when the `startRequest` or `isProcessing` state values change.
     * It prepares necessary data for ML processing and makes requests to a backend endpoint.
     * If successful, it updates the component state and redirects to a new route.
     *
     * @effect
     * @returns {void}
     */
    useEffect(() => {
        if (startRequest) {

            // Date in UTC format Y-M-D
            let date_string = new Date().toISOString().slice(0, 10);
            let count = 0
            intervalRef.current = setInterval(() => {
                count++;
                //licitationIds.forEach((licitationId, i) => {
                //setTimeout(() => {
                console.log("ejecutando request al backend ml ", count - 1)
                if (count >= clientIds.length) {
                    clearInterval(intervalRef.current)
                    intervalRef.current = null
                }
                console.log("corrio despues del if", count)
                //if (!isProcessing && s3Paths && licitationIds) {
                if (s3Paths && licitationIds && count <= clientIds.length) {
                    let ml_payload = {
                        s3_path: s3Paths[count - 1],
                        model_lg: formValues.file_lang.split("."),
                        client_rut: clientIds[count - 1],
                        id_opportunity: opportunityIds[count - 1],
                        client_name: clientNames[count - 1],
                        family: selectedFamilies,
                    }
                    console.log("ml_payload", count - 1, ml_payload)
                    //setIsProcessing(true)
                    serviallAxios.post(process.env.REACT_APP_ML_BACKEND_URL + "/licitations/licitation", ml_payload,
                        {
                            timeout: TIMEOUTS.xhuge
                        })

                        .then((res) => {
                            console.log("Licitations send post")
                            if (res.data.statusCode !== 429) {
                                setIsProcessing(false)
                                //setStartRequest(false)
                                //setLoadOpportunity(false)
                                /*if (count > clientIds.length) {
                                    clearInterval(intervalRef.current)
                                    intervalRef.current = null
                                }*/
                                setDialogStatus("success");
                                // navigate("/dashboard/items_licitacion", {
                                //         state: {
                                //             op_id: formValues.opportunity_id,
                                //             lic_id: licitationId,
                                //             rut_cliente: formValues.client_id,
                                //             nombre_cliente: formValues.client_name,
                                //             descripcion: formValues.opportunity_title,
                                //             tipo: formValues.opportunity_type,
                                //             fecha_creacion: date_string,
                                //             fecha_cierre: formValues.deadline,
                                //             responsable: formValues.assigned_executive,
                                //             families: selectedFamilies,
                                //             lang: formValues.file_lang,
                                //             s3_path: s3Path
                                //         }
                                //     });
                            }
                            else {
                                setIsProcessing(false)
                            }
                        })
                        .catch((err) => {
                            setIsProcessing(false)
                            setStartRequest(false)
                            setLoadOpportunity(false)
                            if (count > clientIds.length) {
                                clearInterval(intervalRef.current)
                                intervalRef.current = null
                            }
                            //deleteLicitation(clientIds[i], opportunityIds[i])
                            if (err.code === "ECONNABORTED") {
                                console.log("Timeout error")
                                setUploadErrorCode(err.code)
                                setUploadErrorMsg(err.message)
                            }
                            else {
                                if (err.response) {
                                    setUploadErrorCode(err.response.status);
                                    setUploadErrorMsg(err.response.data.detail);
                                } else {
                                    setUploadErrorCode(err.code);
                                }
                            }
                            setDialogStatus("error");
                        })
                }
                //}, 30000)
                //})

            }, 60000)
        }
        return () => clearInterval(intervalRef.current);
    }, [startRequest, isProcessing])


    /**
     * Handles the selection of a family from the list of options.
     * @param {Object} e - The event object.
     * @param {string} newValue - The selected family value.
     */
    const handleSelectFamily = (e, newValue) => {
        setSelectedFamily(newValue)
    }

    /**
     * Handles the deletion of a family from the selected families list.
     * @param {string} value - The family value to be deleted.
     */
    const handleFamilyDelete = (value) => {
        let newSelectedFamilies = [...selectedFamilies];
        newSelectedFamilies = newSelectedFamilies.filter((family) => family !== value);
        setSelectedFamilies(newSelectedFamilies);
    }


    /**
     * Handles changes in the form input fields and updates the form values accordingly.
     * @param {Object} e - The event object.
     */
    const handleFormChange = (e) => {
        console.log(e.target.value)

        let newFormValues = { ...formValues };
        if (e.target.id) {
            newFormValues[e.target.id] = e.target.value;
        } else {
            newFormValues[e.target.name] = e.target.value;
        }
        setFormValues(newFormValues);
    }

    /**
     * Handles changes in the client data fields and updates the form values accordingly.
     * @param {string} id - The client data field ID.
     * @param {string} val - The new value for the client data field.
     */
    const handleClientChange = (id, val) => {
        console.log("val", val)
        console.log("id", id)
        let newFormValues = { ...formValues }
        const parsedValue = val.split("-")
        newFormValues[id] = parsedValue[0]
        console.log("newFormValues", newFormValues)
        setFormValues(newFormValues)
    }

    /**
     * Handles changes in the deadline date and updates the form values accordingly.
     * @param {Object} dateData - The date object containing year, month, and day information.
     */
    const handleDateChange = (dateData) => {
        let newFormValues = { ...formValues };
        newFormValues["deadline"] = dateData;
        setFormValues(newFormValues);
    }

    /**
     * Resets the selected file to null.
     */
    const resetFile = () => {
        setFile(null);
    }

    /**
     * Handles changes in the file input and updates the selected file accordingly.
     * @param {Object} e - The event object.
     */
    const handleFileChange = (e) => {

        if (e.target.files) {
            setFile(e.target.files[0]);
            const reader = new FileReader();
            reader.onload = (e) => {

                const book = XLSX.read(e.target.result, { type: 'binary' });
                const sheet = book.Sheets[book.SheetNames[0]];
                // Convertir la sheet a un objeto de JavaScript
                const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                let opportunity_type = ""
                //tipo de oportunidad grande
                if (data[101].length > 0) {
                    opportunity_type = "contizacion_grande"
                    //tipo de oportunidad agil    
                } else {
                    opportunity_type = "contizacion_agil"
                }
                setSelectedExecutive(executives.find(x => x.id === user.id).name)
                setFormValues({
                    ...formValues,
                    //client_id: data[1][0],
                    //opportunity_id: data[1][2],
                    //client_name: data[1][1],
                    //opportunity_title: data[1][2],
                    opportunity_type,
                    assigned_executive: user.id
                })

                setDialogOpen(true)
                setDialogStatus("check_data")
            };
            reader.readAsArrayBuffer(e.target.files[0]);

        }
    }

    /**
     * Checks if the form has any errors or missing values.
     * @returns {boolean} - True if there are errors, false otherwise.
     */
    const formHasErrors = () => {
        let hasErrors = false;
        Object.keys(formValues).forEach((key) => {
            if (!formValues[key] && (key != 'client_id' && key != 'client_name' && key != 'opportunity_id' && key != 'opportunity_title')) {
                hasErrors = true;
                return
            }
        })
        if (!file) return true;
        return hasErrors;
    }

    /**
     * Converts a number to a string and appends a leading zero if the number is less than 10.
     * @param {number} val - The number to be converted to a string.
     * @returns {string} - The resulting string representation of the number.
     */
    const numberToString = (val) => { // aux function to change from 1 to 01, etc.
        let res = val < 10 ? `0${val}` : val
        return res.toString();
    }

    /**
     * Deletes the current licitation data from the server.
     */
    const deleteLicitation = (client_id, opportunity_id) => {
        const payload = {
            client_rut: client_id,
            op_id: opportunity_id,
            date: new Date().toISOString().split("T")[0]
        }

        serviallAxios.delete(process.env.REACT_APP_ML_BACKEND_URL + "/licitations/delete_licitation", { params: payload }, {
            timeout: TIMEOUTS.long,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
    }


    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (loadOpportunity) {
                console.log("UNLOAD", loadOpportunity)
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            console.log('unmount')
        };
    }, [loadOpportunity]);

    /**
     * Saves the opportunity data by making a POST request to the server with the form values and file data.
     * Displays a dialog with status information during the process.
     */
    const saveOpportunity = () => {
        if (formHasErrors()) return;

        let deadline_string = `${numberToString(formValues.deadline.$y)}/${numberToString(formValues.deadline.$M + 1)}/${numberToString(formValues.deadline.$D)}`;
        setLoadOpportunity(true);
        setDialogOpen(true);
        setDialogStatus("loading");
        let payload = new FormData();
        payload.append("file", file);
        payload.append("file_lang", formValues.file_lang);
        //payload.append("client_id", formValues.client_id);
        //payload.append("client_name", formValues.client_name);
        //payload.append("opportunity_id", formValues.opportunity_id);
        //payload.append("opportunity_title", formValues.opportunity_title);
        payload.append("opportunity_type", formValues.opportunity_type);
        payload.append("assigned_executive", formValues.assigned_executive);
        payload.append("deadline", deadline_string); // formato año/mes/dia
        payload.append("family_json", JSON.stringify(selectedFamilies));
        serviallAxios.post("opportunities/upload-massive", payload, {
            timeout: TIMEOUTS.medium
        })
            .then((res) => {
                setLicitationIds(res.data.lic_ids)
                setS3Paths(res.data.s3_paths)
                setClientIds(res.data.client_ids)
                setOpportunityIds(res.data.opportunity_ids)
                setClientNames(res.data.client_names)
                setStartRequest(true)
                setFormValues({ ...formValues, file_lang: res.data.language })
                //formValues.file_lang = res.language
            })
            .catch((err) => {
                err.client_ids.forEach((e, i) => deleteLicitation(err.client_ids[i], err.opportunity_ids[i]))
                //deleteLicitation()
                setLoadOpportunity(false)
                /* Delete false-positive */
                if (err.code === "ECONNABORTED") {
                    console.log(" Timeout error")
                    setUploadErrorCode(err.code);
                    setUploadErrorMsg(err.message);
                }
                else {
                    if (err.response) {
                        setUploadErrorCode(err.response.status);
                        setUploadErrorMsg(err.response.data.detail);
                    } else {
                        setUploadErrorCode(err.code);
                    }
                }
                setDialogStatus("error");
            })
    }

    /**
     * Handles the closing of the dialog and resets error-related states.
     */
    const handleDialogClose = () => {
        setDialogOpen(false);
        setUploadErrorCode("");
        setUploadErrorMsg("");
    }

    /**
     * Handles the download of the opportunity template file.
     * Shows a snackbar notification when the download is initiated.
    */
    const handleDownloadTemplate = () => {
        setSnackbarOpen(true);
        serviallAxios.get("opportunities/template", {
            timeout: TIMEOUTS.small,
            responseType: "blob"
        })
            .then((res) => {
                const href = URL.createObjectURL(res.data);
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "new_opportunity_template.xlsx");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
    }

    // Helper components

    /**
     * Component for selecting the type of item opportunity.
     * @param {Object} props - The props object.
     * @param {string} props.primaryText - The primary text displayed as a label.
     * @param {string} props.secondaryText - The secondary text displayed as additional information.
     * @param {string} props.placeholder - The placeholder text for the select input.
     * @returns {JSX.Element} - The JSX element representing the item opportunity type selection.
     */
    const SelectItemOpType = ({ primaryText, secondaryText, placeholder }) => {

        const licitacion_text = t("NuevaOportunidadPage:OpTooltip1")
        const cotizacion_agil_text = t("NuevaOportunidadPage:OpTooltip2")
        const cotizacion_grande_text = t("NuevaOportunidadPage:OpTooltip3")

        return (
            <>
                {nuevaOportunidadPermissions.Box.selectContainer !== "hidden" &&
                    <Box
                        id="selectContainer"
                        className='nuevaOportunidad-Box-permi desc-selectInput_container'
                        sx={{ display: "flex", flexDirection: "column" }} width={{ xs: "100%", tablet: "45%" }} justifyContent={"space-between"} gap={1.5}>
                        <Box>
                            <Typography className='serviall-input-label'>
                                {primaryText}
                            </Typography>
                            <Typography className='serviall-input-caption'>
                                {secondaryText}
                            </Typography>
                        </Box>
                        <FormControl className='nuevaOportunidad-mui-input-container'>
                            <Select sx={{ padding: "10px" }} displayEmpty renderValue={value => value || <Typography sx={{ fontSize: "14px", color: "gray" }}>{placeholder}</Typography>} name='opportunity_type' value={formValues.opportunity_type} onChange={handleFormChange} variant="standard" fullWidth disableUnderline={true} placeholder={placeholder} >
                                <ListSubheader className='nuevaOportunidad-select-options-title'>
                                    <Typography className='serviall-h6'>{t("NuevaOportunidadPage:SelectPlaceholder")}</Typography>
                                </ListSubheader>
                                <MenuItem className='nuevaOportunidad-menuItem' divider value={"licitacion"}>
                                    <Box className="nuevaOportunidad-select-item-container">
                                        <Typography className='serviall-small'>
                                            {t("NuevaOportunidadPage:Licitation")}
                                        </Typography>
                                        <Tooltip placement='top-start' arrow title={<span style={{ whiteSpace: "pre-line", fontSize: "var(--serviall-h6)" }}>{licitacion_text}</span>}>
                                            <InfoOutlinedIcon fontSize='small' />
                                        </Tooltip>
                                    </Box>
                                </MenuItem>
                                <MenuItem className='nuevaOportunidad-menuItem' divider value={"cotizacion_agil"}>
                                    <Box className="nuevaOportunidad-select-item-container">
                                        <Typography className='serviall-small'>
                                            {t("NuevaOportunidadPage:AgileQuotation")}
                                        </Typography>
                                        <Tooltip placement='top-start' arrow title={<span style={{ whiteSpace: "pre-line", fontSize: "var(--serviall-h6)" }}>{cotizacion_agil_text}</span>}>
                                            <InfoOutlinedIcon fontSize='small' />
                                        </Tooltip>
                                    </Box>
                                </MenuItem>
                                <MenuItem divider value={"cotizacion_grande"}>
                                    <Box className="nuevaOportunidad-select-item-container">
                                        <Typography className='serviall-small'>
                                            {t("NuevaOportunidadPage:LargeQuotation")}
                                        </Typography>
                                        <Tooltip placement='top-start' arrow title={<span style={{ whiteSpace: "pre-line", fontSize: "var(--serviall-h6)" }}>{cotizacion_grande_text}</span>}>
                                            <InfoOutlinedIcon fontSize='small' />
                                        </Tooltip>
                                    </Box>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                }
            </>

        )
    }




    /**
     * Component for selecting an executive item.
     * @param {Object} props - The props object.
     * @param {string} props.primaryText - The primary text displayed as a label.
     * @param {string} props.secondaryText - The secondary text displayed as additional information.
     * @param {string} props.placeholder - The placeholder text for the select input.
     * @returns {JSX.Element} - The JSX element representing the executive item selection.
     */
    const SelectItemExecutive = ({ primaryText, secondaryText, placeholder }) => {
        return (
            <>
                {nuevaOportunidadPermissions.Box.selectContainer !== "hidden" &&
                    <Box
                        id="selectContainer"
                        className='nuevaOportunidad-Box-permi desc-selectInput_container'
                        sx={{ display: "flex", flexDirection: "column" }} width={{ xs: "100%", tablet: "45%" }} justifyContent={"space-between"} >
                        <Typography className='serviall-input-label'>
                            {primaryText}
                        </Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            <Typography className='serviall-input-caption'>
                                {secondaryText}
                                <br />&nbsp;
                            </Typography>
                        </Box>
                        <FormControl className='nuevaOportunidad-mui-input-container'>
                            <Select displayEmpty renderValue={value => (selectedExecutive) || <Typography sx={{ fontSize: "14px", color: "gray" }}>{placeholder}</Typography>} name='assigned_executive' value={formValues.assigned_executive} onChange={(e) => {
                                handleFormChange(e)
                                setSelectedExecutive(executives.find(x => x.id === e.target.value).name)
                            }} variant="standard" fullWidth disableUnderline={true} placeholder={placeholder}>
                                {
                                    executives.map((exec) => {
                                        return (
                                            <MenuItem divider value={exec.id}>
                                                <Typography className='serviall-small'>
                                                    {exec.name}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                }
            </>

        )
    }

    /**
     * Component for selecting family items.
     * @param {Object} props - The props object.
     * @param {string} props.primaryText - The primary text displayed as a label.
     * @param {string} props.secondaryText - The secondary text displayed as additional information.
     * @param {number} props.width - The width of the component.
     * @returns {JSX.Element} - The JSX element representing the family item selection.
     */
    const FamilyItemSelect = ({ primaryText, secondaryText, width }) => {
        return (
            <Stack direction={"column"} spacing={1} width={"100%"}>
                <Stack direction={"column"} gap={1.5}>
                    <Box>
                        <Box display={"flex"} gap={"1rem"} alignItems={"center"} flexWrap={"wrap"}>
                            <Typography className='serviall-input-label'>
                                {primaryText}
                            </Typography>
                            <Typography className='serviall-input-error'>
                                *{t("NuevaOportunidadPage:Optional")}
                            </Typography>

                        </Box>

                        <Typography className='serviall-input-caption'>
                            {secondaryText}
                        </Typography>
                    </Box>

                    <Stack direction={"row"} spacing={2} >
                        <Autocomplete
                            sx={{ width: "100%" }}
                            value={selectedFamily}
                            onChange={handleSelectFamily}
                            options={familyOptions}
                            className='nuevaOportunidad-mui-input-container'
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Stack>
                </Stack>
                <Box className="nuevaOportunidad-families-box">
                    {
                        selectedFamilies.length > 0 ?
                            selectedFamilies.map((family, idx) =>
                                <Chip
                                    key={`chip-${idx}`}
                                    sx={{ marginBottom: "8px" }}
                                    label={family}
                                    onDelete={() => { handleFamilyDelete(family) }}
                                />
                            )
                            :
                            <Typography>
                                {t("NuevaOportunidadPage:FamilyInputPlaceholder")}
                            </Typography>
                    }
                </Box>
            </Stack>
        )
    }

    /**
     * Component for selecting a date.
     * 
     * @component
     * @param {Object} props - The props object for the DateItem component.
     * @param {string} props.primaryText - The primary text displayed as a label.
     * @param {string} props.secondaryText - The secondary text displayed as additional information.
     * @param {string} props.placeholder - The placeholder text for the date picker.
     * @returns {JSX.Element} The JSX element representing the date selection.
     */
    const DateItem = ({ primaryText, secondaryText, placeholder }) => {
        return (
            <>
                {nuevaOportunidadPermissions.Box.selectContainer !== "hidden" &&
                    <Box
                        id="selectContainer"
                        className='nuevaOportunidad-Box-permi desc-selectInput_container'
                        sx={{ display: "flex", flexDirection: "column" }} width={{ xs: "100%", tablet: "45%" }} justifyContent={"space-between"} gap={1.5}>

                        <Box>
                            <Typography className='serviall-input-label'>
                                {primaryText}
                            </Typography>
                            <Typography className='serviall-input-caption'>
                                {secondaryText}
                            </Typography>
                        </Box>
                        <Box className="nuevaOportunidad-datepicker-container">
                            <DatePicker
                                id="deadline"
                                value={formValues.deadline}
                                onChange={handleDateChange}
                                disablePast
                                format='DD/MM/YYYY'
                                slotProps={{
                                    textField: {
                                        className: "nuevaOportunidad-date-input",
                                        disabled: true,
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                }
            </>
        )
    }

    /**
     * Perform actions when `selectedFamily` or `selectedFamilies` change.
     * This effect adds the `selectedFamily` to the list of `selectedFamilies`
     * if it is not already present, and then clears the value of `selectedFamily`.
     * 
     * @effect
     * @returns {void}
     */
    useEffect(() => {
        const handleFamilyAdd = () => {
            if (!selectedFamily) return;
            let newSelectedFamilies = [...selectedFamilies];
            if (!(newSelectedFamilies.includes(selectedFamily))) {
                newSelectedFamilies.push(selectedFamily);
            }
            setSelectedFamilies(newSelectedFamilies);
        }
        handleFamilyAdd();
        setSelectedFamily("")
    }, [selectedFamily, selectedFamilies]);

    /**
     * Perform actions when the component mounts.
     * This effect makes an API call to fetch client data and sets it in the state,
     * and sets up a cleanup function to clear an interval in case the user doesn't
     * wait for a navigation redirect.
     * 
     * @effect
     * @returns {void}
     */
    useEffect(() => {
        serviallAxios.get("/masters/get_clients_rut", {
            timeout: TIMEOUTS.small
        })
            .then(res => {
                const clients = []
                res.data.clients.forEach((client) => {
                    const clientObj = {
                        rut: client[0],
                        name: client[1]
                    }
                    clients.push(clientObj)
                })
                setClientsRut(clients)
            })
            .catch(e => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: e.response ? e.response.data.detail : CustomAlertMessage(e.code)
                }));
            })

        /* Clean interval in case user don't wait navigate redirect */
        return () => {
            console.log("Cleaning interval in Nueva Oportunidad...")
            clearInterval(intervalRef.current)
            intervalRef.current = null
        }
    }, [])

    const filterOptions = (options, inputValue) => {
        const substrings = inputValue.split(" ")
        if (substrings.length === 1 && substrings[0] === "") return options
        return options.filter(item => {
            if (substrings[substrings.length - 1] === "") substrings.pop()
            return (
                substrings.some(substring => item.rut.toString().toLowerCase().includes(substring.toLowerCase())) || substrings.some(substring => item.name.toString().toLowerCase().includes(substring.toLowerCase()))
            )
        })
    }
    /* ComponentWillUnmount */
    return (
        <Stack direction={"column"} spacing={4}>
            <Stack direction={"column"}>
                <Typography className="serviall-page-title1">{t("NuevaOportunidadPage:Title")}</Typography>
                <Box display={"flex"} flexDirection={{ xs: "column", md: "row" }}>
                    <Typography className="serviall-page-title2" display={{ xs: "inline" }}>
                        {t("NuevaOportunidadPage:Caption2")}
                    </Typography>
                </Box>
            </Stack>
            <Stack direction={"column"} spacing={4}>
                <Box display={"flex"} justifyContent={{ xs: "none", md: "space-between" }} gap={{ xs: "3rem", md: "1rem", lg: "0" }}>

                    <ServiallUploadButton
                        primaryText={t("NuevaOportunidadPage:InputFieldTitle8")}
                        secondaryText={t("NuevaOportunidadPage:InputFieldDesc8")}
                        tertiaryText={t("NuevaOportunidadPage:InputFieldSubDesc8")}
                        height="51.6px"
                        handleFileChange={handleFileChange}
                        filename={file && file.name}
                        resetFile={resetFile}
                        marginBottom={"19.5px"}
                    />
                    <Typography className='nuevaOportunidad-download-template serviall-small'>
                        {t("NuevaOportunidadPage:DownloadTemplateButton")}
                        &nbsp;
                        &nbsp;
                        <SystemUpdateAltIcon onClick={handleDownloadTemplate} style={{ cursor: "pointer" }} fontSize='small' />
                        <Tooltip placement='top-start' arrow title={<span style={{ whiteSpace: "pre-line", fontSize: "var(--serviall-h6)" }}>{t("NuevaOportunidadPage:TooltipDownloadTemplate")}</span>}>
                            <InfoOutlinedIcon fontSize='small' />
                        </Tooltip>
                    </Typography>
                    <DateItem primaryText={t("NuevaOportunidadPage:InputFieldTitle4")} secondaryText={t("NuevaOportunidadPage:InputFieldDesc4")} placeholder={t("NuevaOportunidadPage:Placeholder4")} />
                </Box>
                <Box display={"flex"} flexWrap={"wrap"} justifyContent={{ xs: "none", md: "space-between" }} gap={{ xs: "3rem", md: "0" }}>
                    <SelectItemOpType
                        id={"opportunity_type"}
                        primaryText={t("NuevaOportunidadPage:InputFieldTitle3")}
                        secondaryText={t("NuevaOportunidadPage:InputFieldDesc3")}
                        placeholder={t("NuevaOportunidadPage:Placeholder3")}
                    />
                    <SelectItemExecutive
                        id={"assigned_executive"}
                        primaryText={t("NuevaOportunidadPage:InputFieldTitle7")}
                        secondaryText={t("NuevaOportunidadPage:InputFieldDesc7")}
                        placeholder={t("NuevaOportunidadPage:Placeholder7")}
                    />
                    {/* <ServiallInput
                            id={"assigned_executive"}
                            label={t("NuevaOportunidadPage:InputFieldTitle7")}
                            caption={t("NuevaOportunidadPage:InputFieldDesc7")}
                            placeholder={t("NuevaOportunidadPage:Placeholder7")}
                            type={"text"}
                            width={colWidth}
                            value={formValues.assigned_executive}
                            onChange={handleFormChange}
                        /> */}
                </Box>
                <Box display={"flex"} flexWrap={"wrap"} justifyContent={{ xs: "none", md: "space-between" }} gap={{ xs: "3rem", md: "0" }}>
                    {/*<DateItem primaryText={t("NuevaOportunidadPage:InputFieldTitle4")} secondaryText={t("NuevaOportunidadPage:InputFieldDesc4")} placeholder={t("NuevaOportunidadPage:Placeholder4")} />*/}
                    <SelectLanguage primaryText={t("Common:FileLanguage")} secondaryText={t("Common:LanguageInputDescription")} formValues={formValues} handleFormChange={handleFormChange} />
                </Box>
                <Box display={"flex"} flexWrap={"wrap"} justifyContent={{ xs: "none", md: "space-between" }} gap={{ xs: "3rem", md: "0" }}>
                    {/*no se usa, se bloqueo para que no estorbe
                        <Box width={{ xs: "100%", tablet: "45%", lg: "45%" }}>
                        <FamilyItemSelect
                            primaryText={t("NuevaOportunidadPage:FamilyTitle")}
                            secondaryText={t("NuevaOportunidadPage:FamilyInputLabel")}
                        />
                    </Box>*/}
                    <Box width={{ xs: "100%", tablet: "45%", lg: "45%" }} position={"relative"}>
                        {/*<Typography className='nuevaOportunidad-download-template serviall-small' position={"absolute"} top={{ xs: "-30px", sm: "2px", tablet: "-30px", md: "2px" }}>
                            {t("NuevaOportunidadPage:DownloadTemplateButton")}
                            &nbsp;
                            &nbsp;
                            <SystemUpdateAltIcon onClick={handleDownloadTemplate} style={{ cursor: "pointer" }} fontSize='small' />
                        </Typography>
                        <ServiallUploadButton
                            primaryText={t("NuevaOportunidadPage:InputFieldTitle8")}
                            secondaryText={t("NuevaOportunidadPage:InputFieldDesc8")}
                            tertiaryText={t("NuevaOportunidadPage:InputFieldSubDesc8")}
                            height="51.6px"
                            handleFileChange={handleFileChange}
                            filename={file && file.name}
                            resetFile={resetFile}
                            marginBottom={"19.5px"}
                        />*/}

                        <Box display="flex" justifyContent={"flex-end"} marginTop={4}>
                            <Button disabled={formHasErrors()}
                                onClick={saveOpportunity} className='serviall-button'>
                                {t("NuevaOportunidadPage:SaveButton")}
                            </Button>
                        </Box>
                    </Box>

                </Box>
            </Stack>
            {/* Temporal button, might need to change the styling */}
            <Box sx={{ display: "flex", justifyContent: "flex-end", paddingRight: "10vw" }}>

            </Box>
            <ServiallDialog
                open={dialogOpen}
                status={dialogStatus}
                loading_msg={"Cargando archivo, este proceso puede tardar varios minutos..."}
                error_code={uploadErrorCode}
                error_msg={uploadErrorMsg}
                error_type={"file-error"}
                handleClose={handleDialogClose}
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => { setSnackbarOpen(false) }} severity="info" sx={{ width: '100%' }}>
                    {t("NuevaOportunidadPage:Downloading")}
                </Alert>
            </Snackbar>
        </Stack>
    )
}

NuevaOportunidadPage.displayName = "NuevaOportunidadPage";

export default NuevaOportunidadPage;