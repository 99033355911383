import { createSlice } from "@reduxjs/toolkit";

// displaySlice

// En este slice guardar guardar cosas relacionadas a la visualización de datagrids, por ejemplo las filas por página,
// los filtros de cada datagrid, etc.

const initialState = {

}
export const displaySlice = createSlice({
    name: "display",
    initialState: {
        itemsLicitacionModoEdicion: {
            filters: {},
            activeCols: [],
            widths: {}
        },
        itemsLicitacionModoAgregado: {
            filters: {},
            activeCols: [],
            widths: {},
            rowsPerPage: 25
        },
        itemsAdicionales: {
            filters: {},
            activeCols: [],
            widths: {}
        },
        gestorOportunidades: {
            filters: {},
            activeCols: [],
            widths: {},
            rowsPerPage: 25
        },
        detalleHomologoCliente: {
            filters: {},
            activeCols: [],
            widths: {},
            rowsPerPage: 10
        },
        detalleHomologoProveedor: {
            filters: {},
            activeCols: [],
            widths: {},
            rowsPerPage: 10
        },
        configuracionPermisos: {
            filters: {},
            activeCols: [],
            widths: {},
            rowsPerPage: 5
        },
        configuracionGrupos: {
            filters: {},
            activeCols: [],
            widths: {},
            rowsPerPage: 5
        },
        configuracionAuxItems: {
            filters: {},
            activeCols: [],
            widths: {},
            rowsPerPage: 10
        },
        configuracionVersionesCliente: {
            filters: {},
            activeCols: [],
            widths: {},
            rowsPerPage: 10,
            rowsPerPageDialog: 5
        },
        configuracionVersionesProveedor: {
            filters: {},
            activeCols: [],
            widths: {},
            rowsPerPage: 10,
            rowsPerPageDialog: 5
        },
        configuracionMaestrosDialog: {
            filters: {},
            activeCols: [],
            widths: {},
        },
        maestroClientes: {
            filters: {},
            activeCols: [],
            widths: {},
            rowsPerPage: 25,
        },
        maestroProveedores: {
            filters: {},
            activeCols: [],
            widths: {},
            rowsPerPage: 25
        }
    },
    reducers: {
        setDisplayState: (state, action) => {
            console.log(action.payload);
            Object.keys(action.payload).forEach((key) => {
                state[key] = action.payload[key];
            })
        },
        setRowsPerPageGestorOportunidades: (state, action) => {
            state.gestorOportunidades.rowsPerPage = action.payload;
        },
        setFilterGestorOportunidades: (state, action) => {
            state.gestorOportunidades.filters[action.payload.key] = action.payload.value;
        },
        setRowsPerPageDetalleHomologoCliente: (state, action) => {
            state.detalleHomologoCliente.rowsPerPage = action.payload;
        },
        setFilterDetalleHomologoCliente: (state, action) => {
            state.detalleHomologoCliente.filters[action.payload.key] = action.payload.value;
        },
        setRowsPerPageDetalleHomologoProveedor: (state, action) => {
            state.detalleHomologoProveedor.rowsPerPage = action.payload;
        },
        setRowsPerPageMaestroProveedores: (state, action) => {
            state.maestroProveedores.rowsPerPage = action.payload;
        },
        setRowsPerPageMaestroClientes: (state, action) => {
            state.maestroClientes.rowsPerPage = action.payload;
        },
        setFilterDetalleHomologoProveedor: (state, action) => {
            state.detalleHomologoProveedor.filters[action.payload.key] = action.payload.value;
        },
        setRowsPerPageConfiguracionPermisos: (state, action) => {
            state.configuracionPermisos.rowsPerPage = action.payload;
        },
        setFilterConfiguracionPermisos: (state, action) => {
            state.configuracionPermisos.filters[action.payload.key] = action.payload.value;
        },
        setRowsPerPageConfiguracionGrupos: (state, action) => {
            state.configuracionGrupos.rowsPerPage = action.payload;
        },
        setRowsPerPageItemsLicitacionModoAgregado: (state, action) => {
            state.itemsLicitacionModoAgregado.rowsPerPage = action.payload;
        },
        setRowsPerPageConfiguracionVersionesCliente: (state, action) => {
            state.configuracionVersionesCliente.rowsPerPage = action.payload
        },
        setRowsPerPageConfiguracionVersionesProveedor: (state, action) => {
            state.configuracionVersionesProveedor.rowsPerPage = action.payload
        },
        setRowsPerPageConfiguracionAuxItems: (state, action) => {
            state.configuracionAuxItems.rowsPerPage = action.payload
        },
        setFilterMaestroClientes: (state, action) => {
            state.maestroClientes.filters[action.payload.key] = action.payload.value;
        },
        setActiveCols: (state, action) => {
            state[action.payload.page].activeCols = action.payload.value;
        },
        addColWidth: (state, action) => {
            state[action.payload.page].widths[action.payload.id] = action.payload.width;
        },
        setFilter: (state, action) => {
            state[action.payload.page].filters[action.payload.key] = action.payload.value;
        },
        resetFilters: (state, action) => {
            Object.keys(state[action.payload.page].filters).forEach((key) => {
                state[action.payload.page].filters[key] = ""
            })
        }
    }
})

export const {
    setDisplayState,
    setRowsPerPageGestorOportunidades,
    setFilterGestorOportunidades,
    setRowsPerPageDetalleHomologoCliente,
    setFilterDetalleHomologoCliente,
    setRowsPerPageDetalleHomologoProveedor,
    setFilterDetalleHomologoProveedor,
    setRowsPerPageConfiguracionPermisos,
    setFilterConfiguracionPermisos,
    setRowsPerPageConfiguracionGrupos,
    setFilterMaestroClientes,
    setRowsPerPageMaestroClientes,
    setRowsPerPageMaestroProveedores,
    setRowsPerPageItemsLicitacionModoAgregado,
    setRowsPerPageConfiguracionVersionesCliente,
    setRowsPerPageConfiguracionVersionesProveedor,
    setRowsPerPageConfiguracionAuxItems,
    setActiveCols,
    addColWidth,
    setFilter,
    resetFilters,
} = displaySlice.actions;

export default displaySlice.reducer;
