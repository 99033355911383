import { Box, Button, CircularProgress, InputAdornment, TextField } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import pepeLogo from "../../assets/Images/Pepe-logo.png";
import { useEffect } from "react";
import "./LoginPage.css";
import serviallAxios from "../../axiosConfig";
import ServiallDialog from "../../components/ServiallDialog";
import { setAlert } from "../../features/navigationSlice";
import { TIMEOUTS } from "../../utils";
import { CustomAlertMessage } from "../../utils";

/**
 * A React component that renders the 'Login' page.
 * @typedef LoginPage
 * @returns The Login page component
 */


const LoginPage = () => {


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useSelector((state) => state.settings.theme);
    const lang = useSelector((state) => state.settings.language);
    const [email, setEmail] = useState("");

    const [dialogOpen, setDialogOpen] = useState(false);
    const [password, setPassword] = useState("");
    const [errorModalCode, setErrorModalCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [triggerRedirect, setTriggerRedirect] = useState(false);

    /**
     * useEffect hook to check if the user is already logged in.
     * This hook runs when the component mounts and makes a request to check if the user has an active session.
     * If the user is logged in, it triggers a redirect to the home page ("/dashboard/home").
     */
    useEffect(() => {
        setPageLoading(true);
        serviallAxios.get("/auth/session", {
            timeout: TIMEOUTS.small
        })
            .then(res => {
                setTriggerRedirect(true);
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                setPageLoading(false);
            })
    }, [])

    // Have to do it this way cuz navigate doesn't work if I put it in the initial useeffect
    /**
     * useEffect hook to handle the redirect after successful login check.
     * This hook runs when the "triggerRedirect" state changes.
     * If "triggerRedirect" is true, it navigates to the home page ("/dashboard/home").
     */
    useEffect(() => {
        if (triggerRedirect) {
            navigate("/dashboard/home");
        }
    }, [triggerRedirect])

    /**
     * Handle the change event for the email input field.
     * This function updates the "email" state with the new value entered in the email input field.
     *
     * @param {object} e - The event object of the input change event.
     */
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    /**
     * Handle the change event for the password input field.
     * This function updates the "password" state with the new value entered in the password input field.
     *
     * @param {object} e - The event object of the input change event.
     */
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    /**
     * Handle the normal login event.
     * This function is called when the user clicks the login button for normal login.
     * It validates the email and password fields and sends a login request to the server using the entered credentials.
     * If the login is successful, it stores the access token in a cookie and redirects to the home page ("/dashboard/home").
     * If the login fails, it displays an error modal.
     */
    const handleNormalLogin = () => {
        if (!email || !password) return;
        setLoading(true);

        /*if (window.localStorage.getItem("refresh_token")) {
            navigate("/dashboard/home");
        } else {*/
        const loginFormData = new FormData();
        loginFormData.append("username", email);
        loginFormData.append("password", password);
        serviallAxios.post("/auth/token", loginFormData, {
            timeout: TIMEOUTS.small
        })
            .then(res => {
                window.localStorage.setItem("access_token", res.data.access_token)
                window.localStorage.setItem("refresh_token", res.data.refresh_token)
                navigate("/dashboard/home");
            })
            .catch(e => {
                if (e.response) {
                    setErrorModalCode(e.response.status);
                } else {
                    setErrorModalCode(e.code);
                }
                setDialogOpen(true);
            }
            ).finally(() => {
                setLoading(false);
            })
        //}
        return
    }

    /**
     * Handle "Enter" key press event for login.
     * This function is called when the user presses the "Enter" key in the email or password input field.
     * If the "Enter" key is pressed, it calls the "handleNormalLogin" function to initiate the login process.
     *
     * @param {object} e - The event object of the key press event.
     */
    const handleEnterPress = (e) => {
        if (e.key === "Enter") {
            handleNormalLogin();
        }
    }

    /**
     * Handle the successful Google login event.
     * This function is called when the user successfully logs in using Google authentication.
     * It sends the Google token to the server for verification and, if valid, stores the access token in a cookie and redirects to the home page ("/dashboard/home").
     *
     * @param {object} credentialResponse - The response object containing the Google authentication credentials.
     */
    const googleLoginSuccess = (credentialResponse) => {
        console.log(credentialResponse);
        setLoading(true);
        let payload = {
            token: credentialResponse.credential
        }
        serviallAxios.post("/auth/google_token", payload, {
            timeout: TIMEOUTS.small
        })
            .then(res => {
                // Add interceptor to automatically redirect to login page when user is unauthorized (session expires)
                serviallAxios.interceptors.response.use(
                    res => res,
                    err => {
                        if (err.response) {
                            if (err.response.status === 401) {
                                serviallAxios.interceptors.response.clear();
                                navigate("/");
                                alert(t("Common:401"));
                            }

                            if (err.response.status > 401) {
                                dispatch(setAlert({
                                    open: true,
                                    severity: "error",
                                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                                }))
                            }
                        }
                        return Promise.reject(err);
                    }
                )

                let expire_time = new Date().getTime() + 1000 * 3600; // 1 hr expire time
                document.cookie = `access_token=${res.data.access_token};expires=${expire_time};path=/`;
                window.localStorage.setItem("access_token", res.data.access_token)
                window.localStorage.setItem("refresh_token", res.data.refresh_token)
                navigate("/dashboard/home");
            })
            .catch(e => {
                setDialogOpen(true);
                setErrorModalCode(e.code);
            }
            ).finally(() => {
                setLoading(false);
            })
    }

    /**
     * Handle the failed Google login event.
     * This function is called when there is an error during Google authentication.
     * Currently, it only logs the error message to the console.
     */
    const googleLoginError = () => {
        console.log('Google login Failed');
    }

    /**
     * Handle the close event for the error dialog.
     * This function is called when the user clicks the close button on the error modal dialog.
     * It updates the "dialogOpen" state to false, closing the dialog.
     */
    const handleDialogClose = () => {
        setDialogOpen(false);
    }

    return (
        pageLoading ?
            "Cargando..."
            :
            <Box className="login-container">
                <Box className="login-form-container">
                    <img
                        className="logo"
                        src={pepeLogo}
                        alt=""
                    />
                    <Box className="login-form-fields-container">
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                )
                            }}
                            className="login-text-field-username"
                            value={email}
                            onChange={handleEmailChange}
                            onKeyDown={(e) => handleEnterPress(e)}
                            label={t("LoginPage:MailField")}
                        />
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <KeyIcon />
                                    </InputAdornment>
                                )
                            }}
                            className="login-text-field-password"
                            type={"password"}
                            value={password}
                            onChange={handlePasswordChange}
                            onKeyDown={(e) => handleEnterPress(e)}
                            label={t("LoginPage:PasswordField")}
                        />
                    </Box>
                    {
                        loading ?
                            <Box className="login-loading-box">
                                <CircularProgress />
                            </Box>
                            :
                            <Button
                                className="serviall-button login-button"
                                disabled={!Boolean(email) || !Boolean(password)}
                                onClick={() => handleNormalLogin()}
                            >
                                {t("LoginPage:LoginBtn")} <span className="login-button-icon"><ChevronRightIcon /></span>
                            </Button>
                    }
                    <GoogleLogin
                        className="google-login"
                        size="medium"
                        theme={theme === "light" ? "filled_blue" : "outline"}
                        locale={lang}
                        onSuccess={googleLoginSuccess}
                        onFailure={googleLoginError}
                    />
                </Box>
                <ServiallDialog
                    open={dialogOpen}
                    status="error"
                    error_code={errorModalCode}
                    error_type={"login-error"}
                    handleClose={handleDialogClose}
                />
            </Box>
    )
}

LoginPage.displayName = "LoginPage";

export default LoginPage;