import { experimental_extendTheme } from "@mui/material";
import lightPalette from "./Light";
import darkPalette from "./Dark";

const cssTheme = experimental_extendTheme({
    colorSchemes: {
        light: {
            palette: lightPalette
        },
        dark: {
            palette: darkPalette
        }
    }
})

export default cssTheme;