import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./ConfiguracionPage.css";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useSelector } from "react-redux";
import { NavLink, Outlet, useLocation } from "react-router-dom";

/**
 * A React component that renders the 'configuración' page.
 * @typedef ConfiguracionPage
 * @returns A page with the configuración page components
 */

const ConfiguracionPage = () => {

    const { t } = useTranslation();

    const configuracionPagePermissions = useSelector((state) => state.permissions.componentPermissions.ConfiguracionPage);
    const { pathname } = useLocation();

    /**
     * A functional component that renders a sub-configuration page selector with an arrow icon.
     * @param {Object} props - Component props.
     * @param {string} props.text - The text to display for the selector.
     * @param {string} props.to - The link to redirect the user.
     * @param {string} props.type - the only available type is accordion, it sets an icon for styling.
     * @returns {JSX.Element} The sub-configuration page selector component.
     */
    const SubConfigPageSelector = ({ text, to, type }) => {
        return (
            <NavLink
                className={({ isActive }) =>
                    isActive ?
                        "configuracion-navlink active"
                        :
                        "configuracion-navlink"
                }
                to={to}
            >
                {({ isActive }) =>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginRight={"24px"}>
                        <Typography
                            sx={!isActive && { color: "gray", marginLeft: "24px" }}
                            className="configuracion-selector-text"
                        >
                            {isActive && <ArrowRightIcon className="configuracion-selected-icon" />}
                            {text}
                        </Typography>
                        {type === "accordion" ?
                            isActive ?
                                <ExpandLessIcon />
                                :
                                <ExpandMoreIcon />
                            :
                            ""
                        }
                    </Box>
                }
            </NavLink>
        )
    }

    /**
     * A functional component that renders a navlink with different styling than the other navlinks of the page.
     * These links are meant to appear when another parent link is clicked
     * @param {Object} props - Component props.
     * @param {string} props.text - The text to display for the selector.
     * @param {string} props.to - The link to redirect the user.
     * @returns {JSX.Element} The sublink page selector component.
     */
    const SubLink = ({ text, to }) => {
        return (
            <NavLink
                className={({ isActive }) =>
                    isActive ?
                        "configuracion-accordion-option-container configuracion-accordion-option-container-selected"
                        :
                        "configuracion-accordion-option-container"
                }
                to={to}
            >
                <Typography
                    className="configuracion-selector-text"
                >
                    {text}
                </Typography>
            </NavLink>
        )
    }

    return (
        <Box height={"100%"}>
            <Grid height={"inherit"} display="flex" flexDirection={{ xs: "column", md: "row" }}>
                <Grid item xs={2} md={4} className="configuracion-grid-separator">
                    <Box className="configuracion-options-container">
                        <SubConfigPageSelector
                            to={"cuenta"}
                            text={t("ConfiguracionPage:Option1")}
                        />
                        {configuracionPagePermissions.AccordionConfigSelector.optionMantenedores === "admin" &&
                            <SubConfigPageSelector
                                id="optionGrupos"
                                className='Configuracion-AccordionConfigSelector-permi 
                                    desc-Grupos-select-button'
                                to={"grupos"}
                                text={t("ConfiguracionPage:Option5")}
                            />
                        }
                        {configuracionPagePermissions.AccordionConfigSelector.optionMantenedores === "admin" &&
                            <SubConfigPageSelector
                                id="optionUsuarios"
                                className='Configuracion-AccordionConfigSelector-permi 
                                    desc-Grupos-select-button'
                                to={"usuarios"}
                                text={t("ConfiguracionPage:Option4")}
                            />
                        }
                        {configuracionPagePermissions.SubConfigPageSelector.optionPermisos === "admin" &&
                            <SubConfigPageSelector
                                id="optionPermisos"
                                className='Configuracion-SubConfigPageSelector-permi 
                                           desc-Permisos_select_button'
                                to={"permisos"}
                                text={t("ConfiguracionPage:Option2")}
                            />
                        }
                        {configuracionPagePermissions.AccordionConfigSelector.optionMantenedores === "admin" &&
                            <SubConfigPageSelector
                                id="optionMantenedores"
                                className='Configuracion-AccordionConfigSelector-permi 
                                    desc-Mantenedores-select-button'
                                to={"mantenedores"}
                                type="accordion"
                                text={t("ConfiguracionPage:Option3")}
                            />
                        }
                        {pathname.includes("mantenedores") &&
                            <>
                                {
                                    (configuracionPagePermissions.AccordionItem.optionMantenedoresAuxItems === "admin" ||
                                        configuracionPagePermissions.AccordionItem.optionMantenedoresAuxItems === "write") &&
                                    <SubLink
                                        id="optionMantenedoresAuxItems"
                                        className="Configuracion-AccordionItem-permi 
                                            desc-Mantenedores-items-auxiliares"
                                        text={t("ConfiguracionPage:Option3Select1")}
                                        to={"mantenedores/aux_items"}
                                    />
                                }
                                {
                                    (configuracionPagePermissions.AccordionItem.optionMantenedoresVersiones === "admin" ||
                                        configuracionPagePermissions.AccordionItem.optionMantenedoresVersiones === "write") &&
                                    <SubLink
                                        id="optionMantenedoresVersiones"
                                        className="Configuracion-AccordionItem-permi 
                                               desc-Mantenedores-versiones"
                                        text={t("ConfiguracionPage:Option3Select2")}
                                        to={"mantenedores/versiones"}
                                    />
                                }
                                {
                                    <SubLink
                                        id="optionMantenedoresModelos"
                                        className="Configuracion-AccordionItem-permi 
                                       desc-Mantenedores-modelos"
                                        text={t("ConfiguracionPage:Option3Select3")}
                                        to={"mantenedores/modelos"}
                                    />
                                }
                            </>
                        }
                    </Box>
                </Grid>
                <Grid item width={"100%"} className="configuracion-content">
                    <Outlet />
                </Grid>
            </Grid>
        </Box >
    )
}

ConfiguracionPage.displayName = "ConfiguracionPage";

export default ConfiguracionPage;