import React, { useState, useEffect } from 'react'
import { Stack, Typography, Box, Grid, Checkbox, Paper } from '@mui/material'
import ReplyIcon from '@mui/icons-material/Reply';
import { useNavigate } from 'react-router-dom';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import serviallAxios from "../../axiosConfig";
import { useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { TIMEOUTS, make_excel } from '../../utils';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../features/navigationSlice';
import { useTranslation } from 'react-i18next';
import ServiallLoading from '../../components/ServiallLoading';
import { useSelector } from 'react-redux';
import "./VistaAgregada.css"
import VistaAgregadaDatagrid from '../../components/VistaAgregadaDatagrid';
import ResetFiltersBtn from '../../components/ResetFiltersBtn';
import ActiveInfoDialog from '../../components/ActiveInfoDialog';
import ColumnSelector from '../../components/ColumnSelector';
import { CustomAlertMessage } from '../../utils';
import LicitationSummary from '../../components/LicitationSummary';



/**
 * A React component that renders the 'Vista Agregada' page.
 * @typedef VistaAgregada
 * @returns A page with the datagrid for 'Vista Agregada' page.
 */

const VistaAgregada = () => {

    const dispatch = useDispatch();
    const current_user_id = useSelector((state) => state.navigation.id);
    const navigate = useNavigate()
    const [userInfoDialogOpen, setUserInfoDialogOpen] = useState(false);
    const [activeUserLicitation, setActiveUserLicitation] = useState("");
    const [activeUserName, setActiveUserName] = useState("");
    const [activeUserEmail, setActiveUserEmail] = useState("");
    const location = useLocation()
    const licitation = location.state
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation()
    const kpiWidth = "90px"
    const [rows, setRows] = useState([])
    const [kpis, setKpis] = useState({});
    const [redirectRoute, setRedirectRoute] = useState("");
    const [redirectState, setRedirectState] = useState({});

    /**
     * Creates a formatted row object based on the provided data.
     *
     * @param {Object} data - An object containing various properties for the row.
     * @returns {Object} A formatted row object.
     */
    const makeRow = ({
        seleccion,
        index,
        redirect,
        itemsLicitationIndex,
        estado_revision,
        sku_cliente,
        desc_cliente,
        desc_complementaria,
        marca_cliente,
        pn_cliente,
        um,
        consumo_anual,
        num_alternativa,
        match_proveedor,
        tipo_match,
        no_match,
        item_match,
        sku_proveedor,
        desc_match,
        marca_match,
        pn_match,
        criticidad_sa,
        um_match,
        cantidad_empaque,
        factor_equivalencia_cte,
        plazo_entrega,
        costo_estimado,
        precio_venta_sugerido,
        oportunidad_valorizada
    }) => {
        let status_icon;
        let status_text = estado_revision === "OK" ? "OK" : estado_revision
        switch (estado_revision) {
            case "OK":
                status_icon = <CircleIcon className={"gestorOportunidades-visibility-icon-ok"} />
                break
            case "Pend":
                status_icon = <CircleIcon className={"gestorOportunidades-visibility-icon-pend"} />
                break
            default:
                status_icon = ""
        }
        return {
            seleccion,
            index,
            redirect,
            itemsLicitationIndex,
            estado_revision:
                estado_revision ?
                    <Grid container value={estado_revision} className="gestorOportunidades-datagrid-status-container" >
                        <Grid item xs={8}>
                            {status_text}
                        </Grid>
                        <Grid item xs={4}>
                            {status_icon}
                        </Grid>
                    </Grid> :
                    "",
            sku_cliente,
            desc_cliente,
            desc_complementaria,
            marca_cliente,
            pn_cliente,
            um,
            consumo_anual,
            num_alternativa,
            match_proveedor,
            tipo_match,
            no_match,
            item_match,
            sku_proveedor,
            desc_match,
            marca_match,
            pn_match,
            criticidad_sa,
            um_match,
            cantidad_empaque,
            factor_equivalencia_cte,
            plazo_entrega,
            costo_estimado,
            precio_venta_sugerido,
            oportunidad_valorizada
        }
    }


    /**
     * Handles the redirect to a specific location based on the provided index.
     *
     * @param {number} index - The index to be used for redirection.
     * @returns {void}
     */
    const handleRedirect = (index) => {
        const active_user_url = `opportunities/active_user/${licitation.lic_id}`;
        // Check if there is no active user in the licitation
        serviallAxios.get(active_user_url, {
            timeout: TIMEOUTS.small
        })
            .then((res) => {
                if (res.data.result) {
                    if (parseInt(res.data.result[0]) !== current_user_id) {
                        /* If there's an active user, check if it's inactive */
                        serviallAxios.get(`opportunities/get_last_session`, { params: { client_rut: licitation.rut_cliente, id_opportunity: licitation.op_id } }, {
                            timeout: TIMEOUTS.small
                        })
                            .then((sessionRes) => {
                                if (parseInt(sessionRes.data.minutes_difference) < (process.env.REACT_APP_ACTIVE_TOLERANCE ? process.env.REACT_APP_ACTIVE_TOLERANCE : 30)) {
                                    setUserInfoDialogOpen(true);
                                    setActiveUserLicitation(licitation.op_id);
                                    setActiveUserName(res.data.result[2]);
                                    setActiveUserEmail(res.data.result[1]);
                                }
                                else {
                                    /* If user is inactive, then change inactive user to current user  */
                                    serviallAxios.put(active_user_url, {
                                        timeout: TIMEOUTS.small
                                    })
                                        .then(() => {
                                            licitation["itemsLicitationIndex"] = index
                                            setRedirectState(licitation);
                                            setRedirectRoute("/dashboard/items_licitacion");
                                        })
                                        .catch((err) => {
                                            dispatch(setAlert({
                                                open: true,
                                                severity: "error",
                                                message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                                            }));
                                        })
                                }
                            })
                            .catch((e) => {

                            })
                    }
                    else {
                        // Current user is the same as active (this scenario should not happen but I put this here just in case)
                        serviallAxios.put(active_user_url, {
                            timeout: TIMEOUTS.small
                        })
                            .then(() => {
                                licitation["itemsLicitationIndex"] = index
                                setRedirectState(licitation);
                                setRedirectRoute("/dashboard/items_licitacion");
                            })
                            .catch((err) => {
                                dispatch(setAlert({
                                    open: true,
                                    severity: "error",
                                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                                }));
                            })
                    }
                } else {
                    // There is no user currently in this licitation, set the active user to the current user, then redirect
                    serviallAxios.put(active_user_url, {
                        timeout: TIMEOUTS.small
                    })
                        .then(() => {
                            licitation["itemsLicitationIndex"] = index
                            setRedirectState(licitation);
                            setRedirectRoute("/dashboard/items_licitacion");
                        
                        })
                        .catch((err) => {
                            dispatch(setAlert({
                                open: true,
                                severity: "error",
                                message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                            }));
                        })
                }
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
        // navigate("/dashboard/items_licitacion", {
        //     state: params
        // })
    }

    /** 
    * Useeffect for the the case where the user goes to this url manually.
    * @return {void}
    */
    useEffect(() => {
        if (!licitation) {
            navigate("/dashboard/home");
        }
    }, [licitation])

    /**
     * A React hook that performs a redirect when the 'redirectRoute' variable changes.
     * It uses the 'navigate' function from the 'react-router-dom' package to force redirect to the specified route.
     * @typedef RedirectEffect
     * @function
     * @returns {void}
     */
    useEffect(() => {
        if (redirectRoute !== "") {
            navigate(redirectRoute, {
                state: redirectState
            })
        }
    }, [redirectRoute])
    /**
     * Handles the closing of the user info dialog.
     * Closes the dialog and resets the active user's information.
     *
     * @returns {void}
     */
    const handleUserInfoDialogClose = () => {
        setUserInfoDialogOpen(false);
        setActiveUserLicitation("");
        setActiveUserName("");
        setActiveUserEmail("");
    }

    /* Vista agregada datagrid */

    /**
     * Function to format the redirect column in the data grid.
     *
     * @param {Object} row - The data row in the data grid.
     * @returns {React.ReactNode} The formatted content for the redirect column.
     */
    const redirectFormatter = (row) => {
        return (
            licitation.status !== "Done" ?
                <div>
                    <ReplyIcon onClick={() => handleRedirect(row.row.itemsLicitationIndex)} className='vistaAgregada-redirectIcon' />
                </div>
                :
                <div>
                    <ReplyIcon className='vistaAgregada-redirectIcon-disable' />
                </div>
        )
    }

    /**
     * Function to format the checkbox column in the data grid.
     *
     * @param {Object} row - The data row in the data grid.
     * @returns {React.ReactNode} The formatted content for the checkbox column.
     */
    const checkboxFormatter = (row) => {
        return (
            <div>
                <Checkbox />
            </div>
        )
    }


    /**
     * Function to format the estado (status) column in the data grid.
     *
     * @param {Object} row - The data row in the data grid.
     * @returns {React.ReactNode} The formatted content for the estado (status) column.
     */
    const estadoFormatter = (row) => {
        let estado_revision = row.row.estado_revision;
        let status_icon;
        let status_text = estado_revision === "OK" ? "OK" : estado_revision
        switch (estado_revision) {
            case "OK":
                status_icon = <CircleIcon className={"gestorOportunidades-visibility-icon-ok"} />
                break
            case "Pend":
                status_icon = <CircleIcon className={"gestorOportunidades-visibility-icon-pend"} />
                break
            default:
                status_icon = ""
        }

        return (
            <Grid container className="gestorOportunidades-datagrid-status-container" >
                <Grid item xs={8}>
                    {status_text}
                </Grid>
                <Grid item xs={4}>
                    {status_icon}
                </Grid>
            </Grid>
        )
    }

    const initialStateAgregada = {
        itemsLicitationIndex: "",
        sku_cliente: "",
        estado_revision: "",
        desc_cliente: "",
        desc_complementaria: "",
        marca_cliente: "",
        pn_cliente: "",
        um: "",
        consumo_anual: "",
        similitud: "",
        criticidad_sa: "",
        proveedor: "",
        sku_proveedor: "",
        match_proveedor: "",
        item_match: "",
        desc_match: "",
        marca_match: "",
        pn_match: "",
        um_match: "",
        cantidad_empaque: "",
        factor_equivalencia_cte: "",
        plazo_entrega: "",
        costo_estimado: "",
        moneda_costo: "",
        precio_venta_sugerido: "",
        moneda_precio_venta: "",
        oportunidad_valorizada: "",
        moneda_oportunidad_valorizada: ""
    }

    const initialColsAgregada = [
        {
            key: "itemsLicitationIndex",
            name: t("VistaAgregada:Header0"),
            width: 50,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            sortable: true,
            resizable: true,
        },
        {
            key: "redirect",
            no_filter: true,
            name: t("VistaAgregada:HeaderRedirect"),
            width: 50,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            formatter: redirectFormatter,
            resizable: true,

        },
        {
            key: 'estado_revision',
            name: t("VistaAgregada:Header2"),
            width: 150,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            formatter: estadoFormatter
        },
        {
            key: 'sku_cliente',
            name: t("VistaAgregada:Header3"),
            width: 100,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'desc_cliente',
            name: t("VistaAgregada:Header4"),
            width: 155,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'desc_complementaria',
            name: t("VistaAgregada:Header24"),
            width: 155,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'marca_cliente',
            name: t("VistaAgregada:Header5"),
            width: 120,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'pn_cliente',
            name: t("VistaAgregada:Header6"),
            width: 100,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'um',
            name: t("VistaAgregada:Header7"),
            width: 60,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'consumo_anual',
            name: t("VistaAgregada:Header8"),
            width: 130,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'num_alternativa',
            name: t("VistaAgregada:Header9"),
            width: 142,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'match_proveedor',
            name: t("VistaAgregada:Header23"),
            width: 100,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'tipo_match',
            name: t("VistaAgregada:Header10"),
            width: 100,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'no_match',
            name: t("VistaAgregada:Header11"),
            width: 100,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'item_match',
            name: t("VistaAgregada:Header12"),
            width: 100,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'sku_proveedor',
            name: t("VistaAgregada:Header25"),
            width: 100,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'desc_match',
            name: t("VistaAgregada:Header13"),
            width: 150,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'marca_match',
            name: t("VistaAgregada:Header14"),
            width: 100,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'pn_match',
            name: t("VistaAgregada:Header15"),
            width: 100,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'criticidad_sa',
            name: t("VistaAgregada:Header26"),
            width: 100,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'um_match',
            name: t("VistaAgregada:Header16"),
            width: 100,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'cantidad_empaque',
            name: t("VistaAgregada:Header17"),
            width: 200,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'factor_equivalencia_cte',
            name: t("VistaAgregada:Header18"),
            width: 210,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'plazo_entrega',
            name: t("VistaAgregada:Header19"),
            width: 130,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'costo_estimado',
            name: t("VistaAgregada:Header20"),
            width: 130,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'precio_venta_sugerido',
            name: t("VistaAgregada:Header21"),
            width: 200,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'oportunidad_valorizada',
            name: t("VistaAgregada:Header22"),
            width: 200,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'seleccion',
            reset: true,
            name: t("VistaAgregada:Header1"),
            width: 100,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            formatter: checkboxFormatter,
            resizable: true,
        },
    ]


    const [datagridColsAgregada, setDatagridColsAgregada] = useState(initialColsAgregada)

    useEffect(() => {
        if (licitation) {
            const params = {
                op_id: licitation.op_id
            }
            serviallAxios.get(`utilities/get_resume`, { params }, {
                timeout: TIMEOUTS.medium,
            }).then((res) => {
                setKpis(res.data.data)
            }).catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
        }
    }, [])

    useEffect(() => {
        if (licitation) {
            let year, month, day;
            if (licitation.fecha_creacion.includes("-")) {
                [year, month, day] = licitation.fecha_creacion.split("-")
            }
            else {
                [day, month, year] = licitation.fecha_creacion.split("/")
            }

            setLoading(true);
            serviallAxios.post("/opportunities/aggregate_view", {
                client_rut: licitation.rut_cliente,
                op_id: licitation.op_id,
                date: `${year}-${month}-${day}`
            }, {
                timeout: TIMEOUTS.medium
            })
                .then((res) => {
                    const parsed_data = res.data.dict_history;
                    const rows = []
                    let itemsLicitationIndex = 1
                    let index = 1;
                    /* Item page stores the  */
                    parsed_data.forEach(item => {
                        // Have to do for like this to use break keyword
                        let noMatch = true;
                        let num_alternativa = 1;
                        if (item.length) {
                            for (const matchItem of item) {
                                if ("no_offer" in matchItem && matchItem["no_offer"]) {
                                    const newRow = {
                                        estado_revision: "OK",
                                        itemsLicitationIndex: itemsLicitationIndex,
                                        index: index,
                                        sku_cliente: matchItem.sku_cliente,
                                        desc_cliente: matchItem.desc_cliente,
                                        desc_complementaria: matchItem.desc_complementaria,
                                        marca_cliente: matchItem.marca_cliente,
                                        pn_cliente: matchItem.pn_cliente,
                                        um: matchItem.um,
                                        consumo_anual: matchItem.consumo_anual,
                                        num_alternativa: "-",
                                        match_proveedor: "-",
                                        tipo_match: "No Oferta",
                                        no_match: "No Oferta",
                                        item_match: "-",
                                        sku_proveedor: "-",
                                        desc_match: "-",
                                        pn_match: "-",
                                        criticidad_sa: "-",
                                        um_match: "-",
                                        cantidad_empaque: "-",
                                        factor_equivalencia_cte: "-",
                                        plazo_entrega: "-",
                                        costo_estimado: "-",
                                        precio_venta_sugerido: "-",
                                        oportunidad_valorizada: "-",
                                    }

                                    noMatch = false;
                                    const formattedRow = makeRow(newRow)
                                    rows.push(formattedRow)
                                    index += 1
                                    break;
                                }

                                if (matchItem.alternativo || matchItem.igual) {
                                    const newRow = {
                                        estado_revision: "OK",
                                        itemsLicitationIndex: itemsLicitationIndex,
                                        index: index,
                                        sku_cliente: matchItem.sku_cliente,
                                        desc_cliente: matchItem.desc_cliente,
                                        desc_complementaria: matchItem.desc_complementaria,
                                        marca_cliente: matchItem.marca_cliente,
                                        pn_cliente: matchItem.pn_cliente,
                                        um: matchItem.um,
                                        consumo_anual: matchItem.consumo_anual,
                                        num_alternativa: num_alternativa,
                                        match_proveedor: matchItem.proveedor,
                                        tipo_match: matchItem.igual ? "Igual" : "Alternativo",
                                        no_match: matchItem.auxiliar ? matchItem.auxiliar : "BN",
                                        item_match: matchItem.item_match,
                                        sku_proveedor: matchItem.sku_proveedor,
                                        desc_match: matchItem.desc_match,
                                        marca_match: matchItem.marca_match,
                                        pn_match: matchItem.pn_match,
                                        criticidad_sa: matchItem.criticidad_sa,
                                        um_match: matchItem.um_match,
                                        cantidad_empaque: matchItem.cantidad_empaque,
                                        factor_equivalencia_cte: matchItem.factor_equivalencia_cte,
                                        plazo_entrega: matchItem.plazo_entrega,
                                        costo_estimado: matchItem.costo_estimado,
                                        precio_venta_sugerido: matchItem.precio_venta_sugerido,
                                        oportunidad_valorizada: matchItem.oportunidad_valorizada,
                                    }

                                    noMatch = false;
                                    const formattedRow = makeRow(newRow)
                                    rows.push(formattedRow)
                                    index += 1
                                    num_alternativa += 1

                                }
                                else {
                                    if (item[item.length - 1] === matchItem) {
                                        /* This means that this item has no match, equal nor alternative, so it's still pending */
                                        /* Only insert this if item has no previous match */
                                        if (noMatch) {
                                            const newRow = {
                                                estado_revision: "Pend",
                                                itemsLicitationIndex: itemsLicitationIndex,
                                                index: index,
                                                sku_cliente: matchItem.sku_cliente,
                                                desc_cliente: matchItem.desc_cliente,
                                                desc_complementaria: matchItem.desc_complementaria,
                                                marca_cliente: matchItem.marca_cliente,
                                                pn_cliente: matchItem.pn_cliente,
                                                um: matchItem.um,
                                                consumo_anual: matchItem.consumo_anual,
                                                num_alternativa: "-",
                                                match_proveedor: "-",
                                                tipo_match: "-",
                                                no_match: "-",
                                                item_match: "-",
                                                sku_proveedor: "-",
                                                desc_match: "-",
                                                pn_match: "-",
                                                criticidad_sa: "-",
                                                um_match: "-",
                                                cantidad_empaque: "-",
                                                factor_equivalencia_cte: "-",
                                                plazo_entrega: "-",
                                                costo_estimado: "-",
                                                precio_venta_sugerido: "-",
                                                oportunidad_valorizada: "-",
                                            }
                                            const formattedRow = makeRow(newRow)
                                            rows.push(formattedRow)
                                            index += 1
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            const newRow = {
                                estado_revision: "Pend",
                                itemsLicitationIndex: itemsLicitationIndex,
                                index: index,
                                sku_cliente: item["Sku Cliente"],
                                desc_cliente: item["Descripción Principal cliente"],
                                desc_complementaria: item["Descripción complementaria"],
                                marca_cliente: item["Marca/Fabricante"],
                                pn_cliente: item["Número de Parte"],
                                um: item["UM"],
                                consumo_anual: item["Consumo anual"],
                                num_alternativa: "-",
                                match_proveedor: "-",
                                tipo_match: "-",
                                no_match: "-",
                                item_match: "-",
                                sku_proveedor: "-",
                                desc_match: "-",
                                pn_match: "-",
                                criticidad_sa: "-",
                                um_match: "-",
                                cantidad_empaque: "-",
                                factor_equivalencia_cte: "-",
                                plazo_entrega: "-",
                                costo_estimado: "-",
                                precio_venta_sugerido: "-",
                                oportunidad_valorizada: "-",
                            }
                            const formattedRow = makeRow(newRow)
                            rows.push(formattedRow)
                            index += 1
                        }

                        itemsLicitationIndex += 1;
                    })
                    setRows(rows)
                }).catch((error) => {
                    const rows = []
                    serviallAxios.post("/opportunities/aggregate_view_no_state", {
                        client_rut: licitation.rut_cliente,
                        op_id: licitation.op_id,
                        date: `${year}-${month}-${day}`
                    }, {
                        timeout: TIMEOUTS.medium
                    }).then(res => {
                        let itemsLicitationIndex = 1
                        res.data.remaining.forEach((item) => {
                            const newRow = {
                                estado_revision: "Pend",
                                itemsLicitationIndex: itemsLicitationIndex,
                                index: itemsLicitationIndex,
                                sku_cliente: item["Sku Cliente"],
                                desc_cliente: item["Descripción Principal cliente"],
                                desc_complementaria: item["Descripción complementaria"],
                                marca_cliente: item["Marca/Fabricante"],
                                pn_cliente: item["Número de Parte"],
                                um: item["UM"],
                                consumo_anual: item["Consumo anual"],
                                num_alternativa: "-",
                                tipo_match: "-",
                                no_match: "-",
                                item_match: "-",
                                sku_proveedor: "-",
                                desc_match: "-",
                                pn_match: "-",
                                criticidad_sa: "-",
                                um_match: "-",
                                cantidad_empaque: "-",
                                factor_equivalencia_cte: "-",
                                plazo_entrega: "-",
                                costo_estimado: "-",
                                precio_venta_sugerido: "-",
                                oportunidad_valorizada: "-",
                            }
                            const formattedRow = makeRow(newRow)
                            rows.push(formattedRow)
                            itemsLicitationIndex += 1
                        })
                        setRows(rows)
                    }).catch((error) => {
                        dispatch(setAlert({
                            open: true,
                            severity: "error",
                            message: error.response ? error.response.data.detail : CustomAlertMessage(error.code)
                        }))
                    })
                })

                .finally(() => { setLoading(false) })
        }
    }, [])



    /**
     * Handles the export of data to an Excel file.
     * If the data is not loading and there are rows to export,
     * it dispatches an alert message and triggers the creation of an Excel file.
     * The Excel file will be generated based on the current rows in the component state,
     * after modifying the 'estado_revision' property and removing the 'seleccion' property.
     * The Excel file will be named as "vista_agregada_{licitation.nombre_cliente}".
     *
     * @returns {void}
     */
    const handleExport = () => {
        if (!loading && rows.length > 0) {
            dispatch(setAlert({
                open: true,
                severity: "info",
                message: t("Common:Download")
            }))
            let rowsToSave = JSON.parse(JSON.stringify(rows));
            rowsToSave = rowsToSave.map((row) => {
                return {
                    'índice': row.itemsLicitationIndex,
                    'Estado de revision': row.estado_revision.props.value,
                    'SKU Cliente': row.sku_cliente,
                    'Descripción Cliente': row.desc_cliente,
                    'Descripción complementaria': row.desc_complementaria,
                    'Marca Cliente': row.marca_cliente,
                    'P/N Cliente': row.pn_cliente,
                    'UM Cliente': row.um,
                    'Consumo Anual': row.consumo_anual,
                    'N° de alternativa': row.num_alternativa,
                    'Nombre Proveedor': row.match_proveedor,
                    'Tipo Match': row.tipo_match,
                    'Resultado BN': row.no_match,
                    'Item Match': row.item_match,
                    'SKU Proveedor': row.sku_proveedor,
                    'Descripción Match': row.desc_match,
                    'Marca Match': row.marca_match,
                    'P/N Match': row.pn_match,
                    'Criticidad SA': row.criticidad_sa,
                    'UM Match': row.um_match,
                    'Cantidad por empaque': row.cantidad_empaque,
                    'Factor equivalencia cte': row.factor_equivalencia_cte,
                    'Plazo de entrega': row.plazo_entrega,
                    'Costo estimado': row.costo_estimado,
                    'Precio Venta Sugerido': row.precio_venta_sugerido,
                    'Oportunidad Valorizada': row.oportunidad_valorizada,
                };
            });

            rowsToSave.forEach((row) => {
                delete row.seleccion;
            })
            make_excel(`vista_agregada_${licitation.nombre_cliente}`, rowsToSave);
        }
    }


    return (
        <Stack direction={"column"} spacing={4}>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Stack>
                    <Typography className="serviall-page-title1">
                        {t("VistaAgregada:Title")}
                    </Typography>
                    {licitation &&
                        <Stack direction={"column"} spacing={0.5}>
                            <Typography className="VerDetalleMaestros-property-title">
                                <ArrowRightIcon className="serviall-color-main" />{licitation.op_id}
                            </Typography>
                            <Typography className="VerDetalleMaestros-property-value">
                                {licitation.nombre_cliente}
                            </Typography>
                        </Stack>
                    }
                </Stack>

                {
                    (rows.length > 0 && licitation) ?
                        <LicitationSummary t={t} kpis={kpis} kpiWidth={kpiWidth} />
                        : <></>
                }
            </Box>

            <Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"} gap={4} >
                <Stack direction={"row"} alignItems={"flex-end"}>
                    <Typography
                                id="downloadExcelButton"
                                className="GestorOportunidadesPage-Button-permi
                                    desc-Download-excel-button
                                    serviall-page-caption
                                    gestorOportunidades-download-button"
                                onClick={() => handleExport()}
                            >
                                {t("VistaAgregada:ExportButton")} <SystemUpdateAltIcon />
                    </Typography>
                </Stack>
                <Box display={"flex"} gap={4} flexWrap={"wrap"}>
                    <ColumnSelector setDatagridCols={setDatagridColsAgregada} initialState={initialColsAgregada} page="itemsLicitacionModoAgregado"/>
                    <ResetFiltersBtn page={"itemsLicitacionModoAgregado"} />
                </Box>
            </Box>

            {
                loading ?
                    <ServiallLoading
                        text={t("VistaAgregada:LoadingStatus")}
                    />
                    :
                    (rows.length > 0 && licitation) ?
                        <>
                            <Box className="serviall-datagrid-container">
                                <VistaAgregadaDatagrid
                                    t={t}
                                    rows={rows}
                                    initialState={initialStateAgregada}
                                    datagridCols={datagridColsAgregada}
                                />
                            </Box>
                        </>
                        :
                        <Box className="serviall-datagrid-loading-container">
                            <Typography className="serviall-h4">{t("VistaAgregada:NoItems")}</Typography>
                        </Box>
            }
            <ActiveInfoDialog
                open={userInfoDialogOpen}
                field1={activeUserLicitation}
                field2={activeUserName}
                field3={activeUserEmail}
                handleClose={handleUserInfoDialogClose}
                type={"active_user"}
            />
            <Typography className="serviall-back-text" onClick={() => navigate(-1)}>
                <ArrowBackIcon /> {t("VistaAgregada:GoBackButton")}
            </Typography>
        </Stack>
    )
}

VistaAgregada.displayName = "VistaAgregada";

export default VistaAgregada
