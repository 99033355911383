import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl,MenuItem, Select, Stack, Tooltip, Typography} from "@mui/material";
import { useEffect, useState } from "react";
import "./ConfiguracionMantenedoresVersiones.css";
import serviallAxios from "../../axiosConfig";
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../features/navigationSlice";
import DraggableDataGrid from "../../components/DraggableDataGrid";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ServiallLoading from "../../components/ServiallLoading";
import { TIMEOUTS } from "../../utils";
import { setFilter } from "../../features/displaySlice";
import ResetFiltersBtn from "../../components/ResetFiltersBtn";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { setRowsPerPageConfiguracionVersionesCliente, setRowsPerPageConfiguracionVersionesProveedor,} from "../../features/displaySlice";
import { CustomAlertMessage } from "../../utils";

/**
 * A React component that renders the 'Versions' page of maintainers page.
 * @typedef ConfiguracionMantenedoresVersiones
 * @returns A page with the datagrid for versions.
 */

const ConfiguracionMantenedoresVersiones = () => {

    const displayStateCliente = useSelector((state) => state.display.configuracionVersionesCliente);
    const displayStateProveedor = useSelector((state) => state.display.configuracionVersionesProveedor);
    const displayStateMasterDialog = useSelector((state) => state.display.configuracionMaestrosDialog)
    const filtersCliente = displayStateCliente.filters;
    const filtersProveedor = displayStateProveedor.filters;
    const filtersDialog = displayStateMasterDialog.filters;
    const rowsPerPageCliente = displayStateCliente.rowsPerPage;
    const rowsPerPageProveedor = displayStateProveedor.rowsPerPage;

    const dispatch = useDispatch();

    const [type, setType] = useState("");
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [versionText, setVersionText] = useState("");
    const [selectedData, setSelectedData] = useState({
        rut: "",
        nombre: "",
        latest_version: "",
        current_version: ""
    });
    const { t } = useTranslation();
    const lang = useSelector((state) => state.settings.language);

    const [page, setPage] = useState(0);
    const handlePageChange = (e, newPage) => {
        setPage(newPage);
    
    }   

    /**
     * Handles the change of rows per page for the 'Cliente' section.
     *
     * This function dispatches an action to update the number of rows per page for the 'Cliente' section in the configuration.
     * It resets the current page to the first page to maintain consistency.
     *
     * @function handleChangeRowsPerPageCliente
     * @memberof ComponentName
     * @param {Object} e - The event object representing the change event.
     * @returns {void}
     */
    const handleChangeRowsPerPageCliente = (e) => {
        dispatch(setRowsPerPageConfiguracionVersionesCliente(parseInt(e.target.value)));
        setPage(0);
    }

    /**
     * Handles the change of rows per page for the 'Proveedor' section.
     *
     * This function dispatches an action to update the number of rows per page for the 'Proveedor' section in the configuration.
     * It resets the current page to the first page to maintain consistency.
     *
     * @function handleChangeRowsPerPageProveedor
     * @memberof ComponentName
     * @param {Object} e - The event object representing the change event.
     * @returns {void}
     */
    const handleChangeRowsPerPageProveedor = (e) => {
        dispatch(setRowsPerPageConfiguracionVersionesProveedor(parseInt(e.target.value)));
        setPage(0);
    }

    /**
     * Determines and retrieves the type of entity based on the selected language.
     *
     * This function returns the type of entity (either "Client" or "Provider") based on the selected language.
     * If the language is 'es', it returns the type as it is. Otherwise, it translates 'proveedor' to "Provider" and 'cliente' to "Client".
     *
     * @function getType
     * @memberof ComponentName
     * @returns {string} The type of entity based on the selected language.
     */
    const getType = () => {
        if (lang === 'es'){
            return type
        }
        else{
            if (type === "proveedor"){
                return "Provider"
            }
            else{
                return "Client"
            }
        }
    }
    const cols = [
        {
            key: 'rut',
            name: i18next.t("MantenedoresVersiones:Header1", { master: getType() }),
            sortable: true,
            resizable: true,
            headerCellClass: "serviall-datagrid-header1",
        },
        {
            key: 'name',
            name: i18next.t("MantenedoresVersiones:Header2", { master: getType() }),
            headerCellClass: "serviall-datagrid-header2",
            sortable: true,
            resizable: true,
        },
        {
            key: 'website',
            name: t("MantenedoresVersiones:Header3"),
            headerCellClass: "serviall-datagrid-header1",
            sortable: true,
            resizable: true,
        },
        {
            key: 'latest_version',
            name: t("MantenedoresVersiones:Header4"),
            headerCellClass: "serviall-datagrid-header2",
            sortable: true,
            resizable: true,
        },
        {
            key: 'current_version',
            headerCellClass: "serviall-datagrid-header1",
            name: t("MantenedoresVersiones:Header5"),
            sortable: true,
            resizable: true,
        },
        {
            key: "actions",
            name: t("MantenedoresVersiones:Header6"),
            headerCellClass: "serviall-datagrid-header2",
            reset: true,
            width: 20
        }
    ]

    const [dialogCols] = useState([
        {
            key: 'version',
            name: t("MantenedoresVersiones:DialogCol1"),
            sortable: true,
            resizable: true,
            headerCellClass: "serviall-datagrid-header1",
        },
        {
            key: 'actions',
            name: t("MantenedoresVersiones:DialogCol2"),
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
        },
        {
            key: 'creation_date',
            name: t("MantenedoresVersiones:DialogCol3"),
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
        },
        {
            key: 'last_update',
            name: t("MantenedoresVersiones:DialogCol4"),
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
        },

    ])

    const initialState = {
        rut: "",
        name: "",
        website: "",
        latest_version: "",
        current_version: "",
    }

    const [dialogRows, setDialogRows] = useState([]);

    const [versionsArray, setVersionsArray] = useState([])
    /**
     * Fetches rows of data from the server based on the selected entity type.
     *
     * This function initiates a GET request to retrieve rows of data from the server, based on the selected entity type
     * ("cliente" or "proveedor"). The function handles loading states, updates the component's rows state, and
     * updates column names accordingly. If successful, the retrieved data is used to update the rows and column information.
     * If an error occurs during the request, an error alert is displayed.
     *
     * @function fetchRows
     * @memberof ComponentName
     * @returns {void}
     */
    const fetchRows = () => {
        setLoading(true);
        let url = type === "cliente" ? "masters/clients" : "masters/providers";
        serviallAxios.get(url, {
            timeout: TIMEOUTS.medium
        })
            .then((res) => {
                let newRows = res.data.clients_data.map((dbRow, idx) => makeRows(idx, dbRow));
                setRows(newRows);
                const newCols = [...cols]
                newCols[0].name = type === "cliente" ? t("MantenedoresVersiones:RutClient") : t("MantenedoresVersiones:RutProvider")
                newCols[1].name = type === "cliente" ? t("MantenedoresVersiones:NameClient") : t("MantenedoresVersiones:NameProvider")

            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
            .finally(() => {
                setLoading(false);
            })
    }

    /** 
     * React useEffect hook to fetch data when the component mounts or 'type' changes.
     */

    useEffect(() => {
        if (!type) return;
        fetchRows()
        setPage(0)
    }, [type])


    /**
     * Function to transform server data to rows for the data grid.
     * @param {number} id - The unique id for the row.
     * @param {object} dbEntry - The data object retrieved from the server.
     * @returns {object} - An object representing the row for the data grid.
     */
    const makeRows = (id, dbEntry) => {
        return {
            id: id,
            rut: dbEntry[1],
            name: dbEntry[2],
            website: dbEntry[3],
            latest_version: dbEntry[4],
            current_version: dbEntry[5],
            created_at:dbEntry[6],
            updated_at:dbEntry[7],
            actions: makeChangeVersionAction({
                rut: dbEntry[1],
                name: dbEntry[2],
                latest_version: dbEntry[4],
                current_version: dbEntry[5],
                created_at:dbEntry[6],
                updated_at:dbEntry[7],
            })
        }
    }

    /**
     * Function to create a component representing the 'Cambiar version' action in the data grid.
     * @param {object} row_data - Data for the current row in the data grid.
     * @returns {JSX.Element} - A component representing the action icon.
     */
    const makeChangeVersionAction = (row_data) => {
        let component;

        component = row_data.latest_version <= 1 ?
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <EditIcon
                    className={"versions-icon-disabled"}
                />
            </Box>
            :
            <Tooltip
                title={<span style={{whiteSpace: "pre-line", fontSize: "var(--serviall-h6)"}}>t{"MantenedoresVersiones:Description"}</span>}
                placement="right"
            >
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <EditIcon
                        className={"versions-icon"}
                        onClick={() => {
                            handleEditVersion(row_data)
                        }}
                    />
                </Box>
            </Tooltip>

        return component
    }

    /**
     * Initiates a download of a version file based on the given parameters.
     *
     * This function triggers a GET request to download a version file associated with the specified `rut`, `op_id`, and `idx`.
     * The `master_type` is determined based on the selected entity type ("cliente" or "proveedor"). The function creates a temporary link
     * element, sets up the appropriate attributes for downloading, and then clicks the link to initiate the download.
     * After the download is completed, the temporary link is removed and the object URL is revoked.
     *
     * @function handleDownload
     * @memberof ComponentName
     * @param {string} rut - The RUT (unique identifier) of the entity.
     * @param {string} op_id - The identifier of the operation.
     * @param {number} idx - The index of the version.
     * @returns {void}
     */
    const handleDownload = (rut,op_id,idx) => {
        const master_type = type === "cliente" ? "clients" : "providers"
        serviallAxios.get(`masters/download_version/${rut}/${op_id}/${master_type}/${idx}`, {
            timeout: TIMEOUTS.medium,
            responseType: "blob"
        })
            .then((res) => {
                const href = URL.createObjectURL(res.data);
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", `${op_id}_v${idx}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }))
            })
    }
    // Handlers

    /**
     * Function to handle editing a version in the data grid.
     * It opens the edit dialog and sets the selected data for editing.
     * @param {object} data - Data for the selected row in the data grid.
     */
    const handleEditVersion = (data) => {

        const newDialogRows = []
        const arr = [...Array(parseInt(data.latest_version)).keys()].map(num => (num+1).toString())
        setVersionsArray(arr)
        for (const x of Array(parseInt(data.latest_version)).keys()){
            const newDialogRow = {
                version: x+1,
                actions: <SystemUpdateAltIcon onClick={() => handleDownload(data.rut, data.name, x+1)} fontSize="small" className="serviall-step-icon"/>,
                creation_date: new Date(data.created_at).toISOString().split("T")[0],
                last_update: new Date(data.updated_at).toISOString().split("T")[0]
            }
            newDialogRows.push(newDialogRow)
        }
        setDialogRows(newDialogRows)
        setDialogOpen(true);
        setVersionText(data.current_version);
        setSelectedData(data);

    }

     /**
     * Function to handle changes in the radio button for selecting 'cliente' or 'proveedor'.
     * @param {object} e - The event object from the radio button change.
     */
    const handleRadioChange = (e) => {
        setType(e.target.value);
    }

    /**
     * Function to handle changes in the version text input in the edit dialog.
     */
    const handleVersionChange = () => {
        let payload;
        if (type === "cliente") {
            payload = {
                client_rut: selectedData.rut,
                version: versionText,
            }
            serviallAxios.post(process.env.REACT_APP_ML_BACKEND_URL + "/maintainers/clients", payload,
                {
                    timeout: TIMEOUTS.huge
                })
                .then((res) => {
                    dispatch(setAlert({
                        open: true,
                        severity: "info",
                        message: t("MantenedoresVersiones:UpdateSuccesful")
                    }))
                    fetchRows()
                })
                .catch((err) => {
                    dispatch(setAlert({
                        open: true,
                        severity: "error",
                        message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)

                    }))
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            payload = {
                provider_rut: selectedData.rut,
                version: versionText,
            }
            serviallAxios.post(process.env.REACT_APP_ML_BACKEND_URL + "/maintainers/providers", payload,
                {
                    timeout: TIMEOUTS.huge
                })
                .then((res) => {
                    dispatch(setAlert({
                        open: true,
                        severity: "info",
                        message: t("MantenedoresVersiones:UpdateSuccesful")
                    }))
                    fetchRows()
                })
                .catch((err) => {
                    dispatch(setAlert({
                        open: true,
                        severity: "error",
                        message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                    }))
                })
        }

        setDialogOpen(false);
    }

    /**
     * Function to get the unique row key for the data grid.
     * @param {object} row - The row data object.
     * @returns {number|string} - The unique key for the row.
     */
    const rowKeyGetter = (row) => {
        return row.id;
    }

    return (
        <Stack direction={"column"} className="configuracionMantenedoresVersiones-container">
            <Typography className="serviall-page-title1">
                {t("MantenedoresVersiones:Title")}
            </Typography>
            <Typography className="serviall-page-title2" marginBottom={4}>
                {t("MantenedoresVersiones:Description")}
            </Typography>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginBottom={4} >
                    <FormControl sx={{width: "300px"}}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        displayEmpty
                        renderValue={value => value || <Typography sx={{ fontSize: "14px", color: "#ABABAB", fontFamily: "Poppins"}} padding={0}>{t("MantenedoresVersiones:SelectPlaceholder")}</Typography>}
                        className="serviall-input"
                        notched={false}
                        placeholder={t("MantenedoresVersiones:CardTitle")}
                        label="Age"
                        onChange={handleRadioChange}
                    >
                        <MenuItem value={"proveedor"}>{t("MantenedoresVersiones:CardRadio1")}</MenuItem>
                        <MenuItem value={"cliente"}>{t("MantenedoresVersiones:CardRadio2")}</MenuItem>
                    </Select>
                    </FormControl>
                
                <ResetFiltersBtn page={type === "cliente" ? "configuracionVersionesCliente" : "configuracionVersionesProveedor"} />
            </Box>
            {
                type &&
                    loading ?
                    <ServiallLoading
                        text={i18next.t("MantenedoresVersiones:LoadingStatus", { master: type })}
                    />
                    :
                    rows.length > 0 &&
                    <Box className="serviall-datagrid-container">
                        <DraggableDataGrid
                            headerRowHeight={90}
                            className="serviall-datagrid"
                            rowKeyGetter={rowKeyGetter}
                            cols={cols}
                            rows={rows}
                            initialState={initialState}
                            filters={type === "cliente" ? filtersCliente : filtersProveedor}
                            setFiltersAction={setFilter}
                            page={type === "cliente" ? "configuracionVersionesCliente" : "configuracionVersionesProveedor"}
                            pageNum={page}
                            rowsPerPage={type === "cliente" ? rowsPerPageCliente : rowsPerPageProveedor}
                            handlePageChange={handlePageChange}
                            handleChangeRowsPerPage={type === "cliente" ? handleChangeRowsPerPageCliente : handleChangeRowsPerPageProveedor}
                        />
                    </Box>
            }
            <Dialog
                open={dialogOpen}
                onClose={() => { setDialogOpen(false) }}
            >
                <DialogTitle>
                    <Typography className="versions-dialog-title">
                        {t("MantenedoresVersiones:ChangeText")}: {type}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Stack direction={"column"} marginBottom={4}>
                        <Typography>
                            Rut: {selectedData.rut}
                        </Typography>
                        <Typography>
                        {t("MantenedoresVersiones:Name")}: {selectedData.name}
                        </Typography>
                        <Typography>
                        {t("MantenedoresVersiones:LastVersion")}: {selectedData.latest_version}
                        </Typography>
                        <Typography>
                        {t("MantenedoresVersiones:CurrentVersion")}: {selectedData.current_version}
                        </Typography>
                    </Stack>
                    <DraggableDataGrid
                            headerRowHeight={90}
                            className="serviall-datagrid"
                            rowKeyGetter={rowKeyGetter}
                            cols={dialogCols}
                            rows={dialogRows}
                            initialState={initialState}
                            filters={filtersDialog}
                            setFiltersAction={setFilter}
                            page={"configuracionMaestrosDialog"}
                        />
                        
                    <Stack direction={"column"} spacing={1} className="versions-select-container" marginTop={4}>
                        <Typography>
                        {t("MantenedoresVersiones:SelectMessage")}:
                        </Typography>
                        <Box display={"flex"} justifyContent={"space-between"} className="MantenedoresItemsAuxiliares-form-container" marginBottom={4}>
                        <Select
                            sx={{width: "300px"}}
                            renderValue={value => value || <Typography sx={{ fontSize: "14px", color: "gray" }}>{t("MantenedoresVersiones:SelectPlaceholder")}</Typography>}
                            className="serviall-input"
                                value={versionText}
                                onChange={(e) => { setVersionText(e.target.value) }}
                            >
                                {
                                    versionsArray.map((version) => <MenuItem key={version} value={version}>{version}</MenuItem>)
                                }
                    </Select>
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={versionText === selectedData.current_version}
                        className="serviall-button-success"
                        onClick={handleVersionChange}
                    >
                       {t("MantenedoresVersiones:SaveBtn")}
                    </Button>
                    <Button className="serviall-button-error" onClick={() => { setDialogOpen(false) }}>
                        {t("MantenedoresVersiones:CancelBtn")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}

ConfiguracionMantenedoresVersiones.displayName = "ConfiguracionMantenedoresVersiones";

export default ConfiguracionMantenedoresVersiones;