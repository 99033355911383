import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ServiallInput from "../../components/ServiallInput";
import "./ConfiguracionCuenta.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect} from "react";
import { useSelector } from "react-redux";
import serviallAxios from "../../axiosConfig";
import { TIMEOUTS } from "../../utils";
import { setAlert } from "../../features/navigationSlice";
import { useDispatch } from "react-redux";
import { CustomAlertMessage } from "../../utils";

/**
 * A React component that renders the 'Cuenta' page.
 * @typedef ConfiguracionCuenta
 * @returns A page with the Cuenta components
 */
const ConfiguracionCuenta = () => {

    const { t } = useTranslation();
    const email = useSelector((state) => state.navigation.email)
    const dispatch = useDispatch()

    const [formValues, setFormValues] = useState({
        name: "",
        password: "",
        email: email,
        lastname: "",
        password_confirm: "",
    })

    const [wasFocused, setWasFocused] = useState({
        name: false,
        password: false,
        lastname: false,
        password_confirm: false,
    })

    const [createWasFocused, setCreateWasFocused] = useState({
        create_name: false,
        create_password: false,
        create_email: false,
        create_lastname: false,
        create_password_confirm: false,
    })


    const [newAccount, setNewAccount] = useState({
        create_name: "",
        create_lastname: "",
        create_password: "",
        create_password_confirm: "",
        create_email: ""
    })

    /**
     * Checks if the given email address is valid.
     * @param {string} email - The email address to validate.
     * @returns {boolean} True if the email is valid, otherwise false.
     */
    const isEmailValid = (email) => {
        const validateRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (validateRegex.test(email)) {
            return true
        } else {
            return false
        }

    }

 

    const [createErrors, setCreateErrors] = useState({
        create_password: t("ConfiguracionCuenta:EmptyFieldError"),
        create_email: t("ConfiguracionCuenta:EmptyFieldError"),
        create_password_confirm: t("ConfiguracionCuenta:EmptyFieldError"),
    })

    const group = useSelector((state) => state.permissions.group)

    /**
     * Handles changes in the input fields of the form.
     * Updates the formValues and error state accordingly.
     * @param {Object} e - The input change event.
     * @returns {void}
     */
    const handleFormInputChange = (e) => {
        let newFormValues = { ...formValues };
        newFormValues[e.target.id] = e.target.value;
        setFormValues(newFormValues);
    }

    /**
     * Handles the change event on the input fields of the create account form.
     * Updates the form values and error messages based on the user's input.
     *
     * @param {object} e - The change event object.
     * @returns {void}
     */
    const handleCreateFormInputChange = (e) => {
        let newFormValues = {...newAccount}
        let newErrors = { ...createErrors }
        newFormValues[e.target.id] = e.target.value;
        setNewAccount(newFormValues)
        if (!e.target.value) {
            newErrors[e.target.id] = t("ConfiguracionCuenta:EmptyFieldError");
        }
        else if (e.target.id === "create_email") {
            if (!isEmailValid(e.target.value)) {
                newErrors[e.target.id] = t("ConfiguracionCuenta:EmailError");
            } else {
                newErrors[e.target.id] = "";
            }
        }
        else {
            newErrors[e.target.id] = "";
        }
        setCreateErrors(newErrors);
    }

    /**
     * Handles input field focus events.
     * Updates the wasFocused state to indicate that an input field has been focused.
     * @param {Object} e - The input focus event.
     * @returns {void}
     */
    const handleFormInputFocus = (e) => {
        let newWasFocused = { ...wasFocused };
        newWasFocused[e.target.id] = true
        setWasFocused(newWasFocused);
    }

    /**
     * Handles input field focus events.
     * Updates the wasFocused state to indicate that an input field has been focused.
     * @param {Object} e - The input focus event.
     * @returns {void}
     */
    const handleCreateFormInputFocus = (e) => {
        let newWasFocused = { ...createWasFocused };
        newWasFocused[e.target.id] = true
        setCreateWasFocused(newWasFocused);
    }

    /**
     * Fetches user information from the server based on the provided email.
     * Updates the form values with the retrieved user data.
     *
     * @returns {void}
     */
    const fetchUser = () => {
        serviallAxios.get(`/auth/user_info/${email}`, {
            timeout: TIMEOUTS.small
        })
            .then(res => {
                const newFormValues = {...formValues}
                newFormValues.name = res.data.user[0][0]
                newFormValues.lastname = res.data.user[0][1]
                setFormValues(newFormValues)
            })
            .catch(e => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: e.response ? e.response.data.detail : CustomAlertMessage(e.code)
                }));
            })
    }

    // Fetch user information when component mounts
    useEffect(() => {
        fetchUser()
    }, [])


    /**
     * Handles the submission of user update data.
     * Sends a PUT request to update user information.
     * Updates user information and displays an alert on success.
     * Displays an alert in case of an error.
     *
     * @returns {void}
     */
    const handleSubmitUpdate = () => {
        const payload = {
            first_name: formValues.name,
            last_name: formValues.lastname,
            password: formValues.password,
            password_confirmation: formValues.password_confirm
        }


        serviallAxios.put("/auth/update", payload, {
            timeout: TIMEOUTS.medium    
        })
            .then(res => {
                fetchUser()
                dispatch(setAlert({
                    open: true,
                    severity: "success",
                    message: t("ConfiguracionCuenta:AlertSuccesful")
                }))
            })
            .catch(e => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: e.response ? e.response.data.detail : CustomAlertMessage(e.code)
                }))
            })
    }

    /**
     * Handles the submission of user creation data.
     * Sends a POST request to create a new user account.
     * Displays an alert on success.
     * Displays an alert in case of an error.
     *
     * @returns {void}
     */
    const handleSubmitCreate = () => {
        const payload = {
            email: newAccount.create_email,
            first_name: newAccount.create_name,
            last_name: newAccount.create_lastname,
            password: newAccount.create_password,
            password_confirmation: newAccount.create_password_confirm
        }

        serviallAxios.post("/auth/register", payload, {
            timeout: TIMEOUTS.medium    
        })
            .then(res => {
                dispatch(setAlert({
                    open: true,
                    severity: "success",
                    message: t("ConfiguracionCuenta:AlertSuccesful")
                }))
            })
            .catch(e => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: e.response ? e.response.data.detail : CustomAlertMessage(e.code)
                }))
            })
    }
    return (
        <>
            <Box>
                <Typography className="serviall-page-title1">{t("ConfiguracionCuenta:Title")}</Typography>
                <Typography className="serviall-page-title2">{t("ConfiguracionCuenta:Caption")}</Typography>
            </Box>
            <Grid  className="configuracion-cuenta-grid-container" gap={{xs: 2, tablet: 4}} marginBottom={4}>
                <Box display={"flex"} flexDirection={{xs: "column", tablet: "row"}} width={"100%"} gap={{xs:4}} marginBottom={4}>
                    <ServiallInput
                        id={"name"}
                        label={t("ConfiguracionCuenta:NameInputLabel")}
                        placeholder={t("ConfiguracionCuenta:NameInputPlaceholder")}
                        type="text"
                        value={formValues.name}
                        onFocus={handleFormInputFocus}
                        onChange={handleFormInputChange}
                        inputClassName="serviall-input"
                    />
                    <ServiallInput
                    id="lastname"
                    label={t("ConfiguracionCuenta:LastnameInputLabel")}
                    placeholder={t("ConfiguracionCuenta:LastnameInputPlaceholder")}
                    type="text"
                    value={formValues.lastname}
                    onFocus={handleFormInputFocus}
                    onChange={handleFormInputChange}
                    inputClassName="serviall-input"
                />
                    
                </Box>
                
                <Box display={"flex"} flexDirection={{xs: "column", tablet: "row"}} width={"100%"} gap={{xs:4}} marginBottom={4}>
                <ServiallInput
                        id="password"
                        label={t("ConfiguracionCuenta:PasswordInputLabel")}
                        placeholder={t("ConfiguracionCuenta:PasswordInputPlaceholder")}
                        type="password"
                        value={formValues.password}
                        onFocus={handleFormInputFocus}
                        errorText={formValues.password !== formValues.password_confirm && t("ConfiguracionCuenta:PasswordError") }
                        onChange={handleFormInputChange}
                        inputClassName="serviall-input"
                    />
                     <ServiallInput
                    id="password_confirm"
                    label={t("ConfiguracionCuenta:PasswordConfirmInputLabel")}
                    placeholder={t("ConfiguracionCuenta:PasswordConfirmInputPlaceholder")}
                    type="password"
                    value={formValues.password_confirm}
                    onFocus={handleFormInputFocus}
                    errorText={formValues.password !== formValues.password_confirm && t("ConfiguracionCuenta:PasswordError") }
                    onChange={handleFormInputChange}
                    inputClassName="serviall-input"
                />
                </Box>
                <Box display={"flex"} flexDirection={{xs: "column", tablet: "row"}} width={"100%"} gap={{xs:4}} marginBottom={4}>
                <ServiallInput
                    id="email"
                    label={t("ConfiguracionCuenta:MailInputLabel")}
                    placeholder={t("ConfiguracionCuenta:MailInputPlaceholder")}
                    type="email"
                    value={email}
                    disabled
                    inputClassName="serviall-input"
                />
                </Box>
                <Box display={"flex"} justifyContent={"flex-end"} marginTop={"2rem"} width={"100%"}>
                    <Button
                        className="serviall-button"
                        disabled= {formValues.password !== formValues.password_confirm}
                        onClick={handleSubmitUpdate}
                    >
                        {t("ConfiguracionCuenta:Save")}
                    </Button>
            </Box>
            </Grid>
            
            {
                group === "admin" &&
                <Grid container className="configuracion-cuenta-grid-container" gap={{xs: 2, tablet: 4}} >
            <Typography className="configuracion-grupos-section">{t("ConfiguracionCuenta:Caption2")}</Typography>
                <Box display={"flex"} flexDirection={{xs: "column", tablet: "row"}} width={"100%"} gap={{xs:4}} marginBottom={4}>
                    <ServiallInput
                        id={"create_name"}
                        label={t("ConfiguracionCuenta:NameInputLabel")}
                        placeholder={t("ConfiguracionCuenta:CreateNameInputPlaceholder")}
                        type="text"
                        value={newAccount.create_name}
                        onFocus={handleCreateFormInputFocus}
                        onChange={handleCreateFormInputChange}
                        inputClassName="serviall-input"
                    />
                    <ServiallInput
                    id="create_lastname"
                    label={t("ConfiguracionCuenta:LastnameInputLabel")}
                    placeholder={t("ConfiguracionCuenta:CreateLastnameInputPlaceholder")}
                    type="text"
                    value={newAccount.create_lastname}
                    onFocus={handleCreateFormInputFocus}
                    onChange={handleCreateFormInputChange}
                    inputClassName="serviall-input"
                />
                    
                </Box>
                
                <Box display={"flex"} flexDirection={{xs: "column", tablet: "row"}} width={"100%"} gap={{xs:4}} marginBottom={4}>
                <ServiallInput
                        id="create_password"
                        label={t("ConfiguracionCuenta:PasswordInputLabel")}
                        placeholder={t("ConfiguracionCuenta:CreatePasswordInputPlaceholder")}
                        errorText={(newAccount.create_password !== newAccount.create_password_confirm && t("ConfiguracionCuenta:PasswordError"))
                        || (createWasFocused.create_password && !newAccount.create_password && t("ConfiguracionCuenta:EmptyFieldError"))}   
                        type="password"
                        value={newAccount.create_password}
                        onFocus={handleCreateFormInputFocus}
                        onChange={handleCreateFormInputChange}
                        inputClassName="serviall-input"
                    />
                     <ServiallInput
                    id="create_password_confirm"
                    label={t("ConfiguracionCuenta:PasswordConfirmInputLabel")}
                    placeholder={t("ConfiguracionCuenta:CreatePasswordConfirmInputPlaceholder")}
                    errorText={(newAccount.create_password !== newAccount.create_password_confirm && t("ConfiguracionCuenta:PasswordError"))
                            || (createWasFocused.create_password_confirm && !newAccount.create_password_confirm && t("ConfiguracionCuenta:EmptyFieldError"))}
                    type="password"
                    value={newAccount.create_password_confirm}
                    onFocus={handleCreateFormInputFocus}
                    onChange={handleCreateFormInputChange}
                    inputClassName="serviall-input"
                />
                </Box>
                <Box display={"flex"} flexDirection={{xs: "column", tablet: "row"}} width={"100%"} gap={{xs:4}} marginBottom={4}>
                <ServiallInput
                    id="create_email"
                    label={t("ConfiguracionCuenta:MailInputLabel")}
                    placeholder={t("ConfiguracionCuenta:CreateMailInputPlaceholder")}
                    errorText={createErrors.create_email && createWasFocused.create_email ? createErrors.create_email : null}
                    type="email"
                    value={newAccount.create_email}
                    onFocus={handleCreateFormInputFocus}
                    onChange={handleCreateFormInputChange}
                    inputClassName="serviall-input"
                />
                </Box>
                <Box display={"flex"} justifyContent={"flex-end"} marginTop={"2rem"} width={"100%"}>
                    <Button
                        className="serviall-button configuracion-cuenta-save-button"
                        disabled= {newAccount.create_password !== newAccount.create_password_confirm
                         || !isEmailValid(newAccount.create_email) || !newAccount.create_password} 
                         onClick={handleSubmitCreate}
                    >
                        {t("ConfiguracionCuenta:Save")}
                    </Button>
            </Box>
            </Grid>
            }
            
            
            
        </>
    )
}

ConfiguracionCuenta.displayName = "ConfiguracionCuenta";

export default ConfiguracionCuenta;