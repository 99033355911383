import { Box, CircularProgress, Stack, Typography, Paper, Grid } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import "./VerDetalleHomologosProveedorPage.css";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import serviallAxios from "../../axiosConfig";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EditMastersItemDialog from "../../components/EditMastersItemDialog";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../features/navigationSlice";
import DeleteMastersItemDialog from "../../components/DeleteMastersItemDialog";
import DraggableDataGrid from "../../components/DraggableDataGrid";
import { TIMEOUTS, make_excel } from "../../utils";
import { useTranslation } from "react-i18next";
import { setFilter, setRowsPerPageDetalleHomologoProveedor } from "../../features/displaySlice";
import ResetFiltersBtn from "../../components/ResetFiltersBtn";
import ColumnSelector from "../../components/ColumnSelector";
import { CustomAlertMessage } from "../../utils";
import KpiBox from "../../components/KpiBox";
import ServiallInput from "../../components/ServiallInput";

/**
 * A React component that renders the 'Ver Detalle Homologos' page.
 * @typedef VerDetalleHomologosProveedorPage
 * @returns A page with the datagrid that renders the items.
 */

const VerDetalleHomologosProveedorPage = () => {

    /* Initial State */
    const initialState = {
        sku_proveedor: "",
        homologo_sa: "",
        es_desc: "",
        en_desc: "",
        marca: "",
        codigo_sap: "",
        pn: "",
        costo_sa: "",
        moneda: "",
        um: "",
        cantidad: "",
        plazo_entrega: "",
        sitio_web: "",
        apariciones_creacion: "",
        apariciones_ultimos_dias: "",
        fecha_creacion: "",
        fecha_edicion: "",
        formato_empaque: "",
        factor_importacion: ""
    }

    /* Constants */
    const dispatch = useDispatch();
    const displayData = useSelector((state) => state.display.detalleHomologoProveedor);
    const filters = displayData.filters;
    const rowsPerPage = displayData.rowsPerPage;
    const navigate = useNavigate();
    const { provider_id } = useParams();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editForm, setEditForm] = useState(initialState);
    const [dialogLoading, setDialogLoading] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [actualDeleteId, setActualDeleteId] = useState({});
    const [page, setPage] = useState(0);
    const { t } = useTranslation()
    const location = useLocation();
    const masterData = location.state;
    const [lines, setLines] = useState(0)
    const [filteredLines, setFilteredLines] = useState(0)
    const [query, setQuery] = useState("")
    const [filledPages, setFilledPages] = useState([0])
    const [redirectPage, setRedirectPage] = useState(false)
    const [lastPage, setLastPage] = useState(0)

    /* Delete Functions */
    /**
     * Open the delete dialog when the delete icon is clicked.
     * 
     * @param {string} sku_proveedor - SKU of the provider item.
     * @param {string} db_id - Database ID of the provider item.
     * @returns {void}
     */
    const handleClickOpen = (sku_proveedor, db_id) => {
        setDeleteDialogOpen(true);
        setActualDeleteId({ "sku_proveedor": sku_proveedor, "db_id": db_id })
    };

    /**
     * Close the delete dialog.
     * 
     * @returns {void}
     */
    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    /**
     * Handle the deletion of a provider item.
     * It sends a DELETE request to the server to remove the provider item based on the actualDeleteId.
     * After the deletion, it shows a success or error alert and updates the data by calling fetchData().
     * 
     * @returns {void}
     */
    const handleDelete = () => {
        let payload = {
            provider_item_id: actualDeleteId.db_id
        }
        serviallAxios.delete("/masters/provider_items", {
            timeout: TIMEOUTS.small,
            params: payload
        }).then((res) => {
            dispatch(setAlert({
                open: true,
                severity: "info",
                message: t("DetalleHomologoProveedorPage:DeleteSuccesful")
            }))
        })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }))
            })
            .finally(() => {
                setDialogLoading(false);
                setDeleteDialogOpen(false);
                fetchData(true, true);
                setPage(0)
                fetchLines()
            })

    }

    /* Edit Functions */

    /**
 * Open the edit dialog and set the editForm state with the provided parameters.
 * 
 * @param {Object} params - The parameters to populate the editForm.
 * @returns {void}
 */
    const handleEdit = (params) => {
        setEditDialogOpen(true);
        setEditForm(params);
    }

    /**
     * Update the editForm state when the form inputs change.
     * 
     * @param {Object} e - The event object representing the form input change.
     * @returns {void}
     */
    const handleEditFormChange = (e) => {
        let newFormValues = { ...editForm };
        newFormValues[e.target.id] = e.target.value;
        setEditForm(newFormValues);
    }

    /**
     * Handle the saving of the edited provider item.
     * It sends a PUT request to the server with the updated data from editForm.
     * After the update, it shows a success or error alert and updates the data by calling fetchData().
     * 
     * @returns {void}
     */
    const handleSaveEdit = () => {
        console.log("Editando item con id ", editForm.db_id);

        if (editForm.db_id === -1) {
            console.log("Edit id error");
            setEditDialogOpen(false);
            return
        }

        setDialogLoading(true);

        let item_data = {
            "UM": editForm.um ?  editForm.um : "",
            "Nombre": masterData.name ? masterData.name : "",
            "Codigo Sap": editForm.codigo_sap ? editForm.codigo_sap : "",
            "sku_serviall": editForm.homologo_sa ? editForm.homologo_sa : "",
            "Costo estimado": editForm.costo_sa ? editForm.costo_sa : "",
            "Marca/Fabricante": editForm.marca ? editForm.marca : "",
            "Moneda del costo": editForm.moneda ? editForm.moneda : "",
            "Número de Parte": editForm.pn ? editForm.pn : "",
            "Plazo entrega": editForm.plazo_entrega ? editForm.plazo_entrega : "",
            "URL del item-proveedor": editForm.sitio_web ? editForm.sitio_web : "",
            "Cantidad por empaque cerrado": editForm.cantidad ? editForm.cantidad : "",
            "Formato de empaque": editForm.formato_empaque ? editForm.formato_empaque : "",
            "Factor de importacion": editForm.factor_importacion ? editForm.factor_importacion : ""
        }

        let payload = {
            item_id: editForm.db_id,
            sku_provider: editForm.sku_proveedor,
            es_desc: editForm.es_desc,
            en_desc: editForm.en_desc,
            item_data: JSON.stringify(item_data)
        }

        serviallAxios.put("/masters/provider_items", payload, {
            timeout: TIMEOUTS.medium
        })
            .then((res) => {
                dispatch(setAlert({
                    open: true,
                    severity: "info",
                    message: t("DetalleHomologoProveedorPage:UpdateSuccesful")
                }))
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }))
            })
            .finally(() => {
                setDialogLoading(false);
                setEditDialogOpen(false);
                fetchData(false, false, true);
            })
    }

    /**
     * Close the edit dialog.
     * 
     * @returns {void}
     */
    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
    }

    
    /**
     * Fetches data from a server and updates the 'rows' state based on specific conditions.
     *
     * This function is responsible for fetching data from the server and updating the 'rows' state.
     * It checks if the 'initializeArray' flag is set, and if so, it initializes the 'rows' state with
     * the fetched data. If not, it updates the 'rows' state at the appropriate index. It also handles
     * loading states and error handling.
     *
     * @param {boolean} initializeArray - A flag indicating whether to initialize the 'rows' array.
     */
    const fetchData = (initializeArray, resetPages=false, handleEdit=false) => {
        setLoading(true)
        const rowsCpy = [...rows]
        let twoFetch = false
        if (filledPages.length === 2 && !initializeArray){
            if (Object.keys(rowsCpy[filledPages[0]]).length === 0 && Object.keys(rowsCpy[filledPages[1]]).length === 0){
                twoFetch = true
            }
        }
        const cpyFilledPages = resetPages ? [0] : [...filledPages]
        for(let k = 0; k < cpyFilledPages.length ; k ++){
            const getPage = (cpyFilledPages[k]/500)+1
            if (rowsCpy.length === 0 || Object.keys(rowsCpy[cpyFilledPages[k]]).length === 0 || initializeArray || handleEdit){
                serviallAxios.get("/masters/provider_items", {params:{provider_rut: provider_id, user_query:query, page:getPage}}, {
                    timeout: TIMEOUTS.medium
                })
                    .then((res) => {
                        const filteredRows = res.data.provider_items.map((db_row, idx) => {
                            let item_data = JSON.parse(db_row[5]);
                            // Because in db a clenup function is used so that database accepts ' and " characters, 
                            // another JSON parse is needed only when the first JSON parse returns a string
                            if (typeof item_data === "string") {
                                item_data = JSON.parse(item_data);
                            }
                            let creation_date = db_row[6] ? (new Date(db_row[6]).toLocaleDateString('es-GB', { year: "numeric", month: "2-digit", day: "2-digit" })).toString() : ""
                            let update_date = db_row[7] ? (new Date(db_row[7]).toLocaleDateString('es-GB', { year: "numeric", month: "2-digit", day: "2-digit" })).toString() : ""
                            return makeData(
                                idx, db_row[2], item_data["sku_serviall"], db_row[3], db_row[4], item_data["Marca/Fabricante"],
                                item_data["Número de Parte"], item_data["Costo estimado"], item_data["Moneda del costo"], item_data["UM"],
                                item_data["Cantidad por empaque cerrado"], item_data["Plazo entrega"], item_data["URL del item-proveedor"], db_row[9], db_row[10],
                                creation_date, update_date, item_data["Formato de empaque"],  db_row[0], item_data["Codigo Sap"], item_data["Factor de importacion"]);
                        })
                        setFilteredLines(res.data.total_filtered_items)
                        if (initializeArray){
                            const newArray = new Array(res.data.total_filtered_items).fill({})
                            Array.prototype.splice.apply(newArray, [0, filteredRows.length].concat(filteredRows))
                            setRows(newArray)
                            setFilledPages([0])
                            setLoading(false)
                        }
                        else{
                            if (getPage === 1){
                                Array.prototype.splice.apply(rowsCpy, [0, filteredRows.length].concat(filteredRows))
                            }
                            else{
                                Array.prototype.splice.apply(rowsCpy, [(getPage-1)*500, filteredRows.length].concat(filteredRows))
                            }
                            if(twoFetch){
                                if (k === 1){
                                    setRows(rowsCpy)
                                    setLoading(false)
                                }
                            }
                            else{
                                setRows(rowsCpy)
                                setLoading(false)
                            }
                        }
                        
                    })
                    .catch((error) => { 
                        dispatch(setAlert({
                            open: true,
                            severity: "error",
                            message: error.response ? error.response.data.detail : CustomAlertMessage(error.code)
                        }));
                        setLoading(false)
                     })
            }
            else{
                setTimeout(() => {
                    setLoading(false)
                  }, 2000);
            }
            
        }
        
    }

    /**
     * Fetches the total number of lines from a server and updates the 'lines' state.
     *
     * This function is responsible for fetching the total number of lines from the server and updating
     * the 'lines' state with the fetched value. It is typically used to retrieve the total number of
     * lines for a specific provider.
     */

    const fetchLines = () => {
        serviallAxios.get("/masters/total_lines_provider", {params:{provider_rut: provider_id}}, {
            timeout: TIMEOUTS.medium
        })
            .then((res) => {
                setLines(res.data.total)
            })
            .catch((error) => { 
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: error.response ? error.response.data.detail : CustomAlertMessage(error.code)
                }));
             })
    }
    
    /**
     * Get the key for a row in the data grid.
     * 
     * @function
     * @param {Object} row - The row object in the data grid.
     * @returns {string} - The key of the row.
     */
    const rowKeyGetter = (row) => {
        return row.id;
    }

    const colWidth = 30;
    const initialCols = [
        {
            key: 'sku_proveedor',
            name: t("DetalleHomologoProveedorPage:Header2"),
            width: 120,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'homologo_sa',
            name: t("DetalleHomologoProveedorPage:Header3"),
            width: 120,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'es_desc',
            name: t("DetalleHomologoProveedorPage:Header4"),
            width: 120,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'en_desc',
            name: t("DetalleHomologoProveedorPage:Header5"),
            width: 150,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'marca',
            name: t("DetalleHomologoProveedorPage:Header7"),
            width: 60,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'pn',
            name: t("DetalleHomologoProveedorPage:Header8"),
            width: 60,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'costo_sa',
            name: t("DetalleHomologoProveedorPage:Header9"),
            width: 100,
            headerCellClass: "serviall-datagrid-header1",
            type: "number",
            resizable: true,
            sortable: true,
        },
        {
            key: 'moneda',
            name: t("DetalleHomologoProveedorPage:Header10"),
            width: 60,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'um',
            name: t("DetalleHomologoProveedorPage:Header12"),
            width: 120,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'cantidad',
            name: t("DetalleHomologoProveedorPage:Header13"),
            width: 190,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'plazo_entrega',
            name: t("DetalleHomologoProveedorPage:Header14"),
            width: 150,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'sitio_web',
            name: t("DetalleHomologoProveedorPage:Header15"),
            width: 150,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'apariciones_creacion',
            name: t("DetalleHomologoProveedorPage:Header16"),
            width: 270,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'apariciones_ultimos_dias',
            name: t("DetalleHomologoProveedorPage:Header17"),
            width: 290,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'fecha_creacion',
            name: t("DetalleHomologoProveedorPage:Header18"),
            width: 160,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'fecha_edicion',
            name: t("DetalleHomologoProveedorPage:Header19"),
            width: 160,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'acciones',
            reset: true,
            name: t("DetalleHomologoProveedorPage:Header1"),
            width: colWidth + 20,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
        },
    ]

    /** 
    * Useeffect for the the case where the user goes to this url manually.
    * @return {void}
    */
    useEffect(() => {
        if (!masterData) {
            navigate("/dashboard/home");
        }
    }, [masterData])

    /**
     * Fetch provider item data from the server when the component mounts.
     */
    useEffect(() => {
        fetchData(true);
        fetchLines()
    }, [])

    /**
     * A React useEffect hook that monitors changes in the 'page' state variable and
     * triggers data fetching based on specific conditions.
     *
     * It checks if there are rows available and if the current page exceeds the available rows.
     * If so, it checks if the last row contains no data, and if filters are empty, it triggers data fetching.
     *
     * @param {number} page - The current page number.
     * @param {Array} rows - An array of data rows.
     * @param {number} rowsPerPage - The number of rows to display per page.
     * @param {object} filters - An object containing filter values.
     * @param {function} fetchData - A function to fetch data when needed.
     */
    useEffect(() => {
        if (rows.length > 0){
            if (!rows[rowsPerPage*(page+1)-1]){
                if (Object.keys(rows[rows.length - 1]).length === 0) {
                    if (!Object.keys(filters).some(x => filters[x] !== "")){
                        fetchData(false)
                    }
                }
            }
            else{
                if (Object.keys(rows[rowsPerPage*(page+1)-1]).length === 0) {
                    if (!Object.keys(filters).some(x => filters[x] !== "")){
                        fetchData(false)
                    }
                }
            }
        }
    }, [page])
    /**
    * A React useEffect hook that monitors changes in the 'filters' object
    * and performs actions based on the changes.
    *
    * If any value in the 'filters' object is not an empty string and the 'redirectPage'
    * state is not set to true, it sets 'page' to 0 and sets 'redirectPage' to true.
    * 
    * If all values in the 'filters' object are empty strings, it sets 'redirectPage' to false
    * and if 'redirectPage' was previously false, it also sets 'page' to 0 and fetches data.
    *
    * @param {object} filters - An object containing filter values.
    * @param {boolean} redirectPage - A state variable indicating whether to redirect the page.
    * @param {function} setPage - A function to update the 'page' state.
    * @param {function} setRedirectPage - A function to update the 'redirectPage' state.
    * @param {function} fetchData - A function to fetch data when needed.
    */
    useEffect(() => {
        if (rows.length > 0){

            if (Object.keys(filters).some(x => filters[x] !== "") && !redirectPage){
                setLastPage(page)
                setPage(0)
                setRedirectPage(true)
            }
            else{
                if(!Object.keys(filters).some(x => filters[x] !== "")){
                    setPage(lastPage)
                    fetchData(false)
                    setRedirectPage(false)

                }
            }
        }
    }, [filters])

    const [datagridCols, setDatagridCols] = useState(initialCols)
    /**
     * Generate JSX elements for row actions (edit and delete icons) in the data grid.
     * 
     * @param {Object} params - The parameters for the row actions.
     * @returns {JSX.Element} - The JSX elements representing the row actions.
     */
    const makeRowActions = (params) => {
        return (
            <Stack sx={{ justifyContent: "center", marginTop: "5px" }} direction={"row"} spacing={1}>
                <EditIcon
                    fontSize="small"
                    className="detalleHomologoProveedor-datagrid-header-icon"
                    onClick={() => { handleEdit(params) }}
                />
                <DeleteIcon
                    fontSize="small"
                    className="detalleHomologoProveedor-datagrid-header-icon"
                    onClick={() => { handleClickOpen(params.sku_proveedor, params.db_id) }}
                />
            </Stack>
        )
    }

    /**
     * Create a data object for a row in the data grid with the provided parameters.
     * 
     * @function
     * @param {number} id - The ID of the row.
     * @param {string} sku_proveedor - SKU of the provider item.
     * @param {string} homologo_sa - Homologo SA of the provider item.
     * @param {string} es_desc - Description in Spanish of the provider item.
     * @param {string} en_desc - Description in English of the provider item.
     * @param {string} marca - Brand of the provider item.
     * @param {string} pn - Part number of the provider item.
     * @param {number} costo_sa - Cost of the provider item.
     * @param {string} moneda - Currency of the cost of the provider item.
     * @param {string} um - Unit of measurement of the provider item.
     * @param {string} cantidad - Quantity of the provider item.
     * @param {string} plazo_entrega - Delivery time of the provider item.
     * @param {string} sitio_web - Website URL of the provider item.
     * @param {string} apariciones_creacion - Appearances on creation of the provider item.
     * @param {string} apariciones_ultimos_dias - Appearances in the last few days of the provider item.
     * @param {string} fecha_creacion - Creation date of the provider item.
     * @param {string} fecha_edicion - Edition date of the provider item.
     * @param {string} db_id - Database ID of the provider item.
     * @returns {Object} - The data object representing a row in the data grid.
     */
    const makeData = (
        id,
        sku_proveedor,
        homologo_sa,
        es_desc,
        en_desc,
        marca,
        pn,
        costo_sa,
        moneda,
        um,
        cantidad,
        plazo_entrega,
        sitio_web,
        apariciones_creacion,
        apariciones_ultimos_dias,
        fecha_creacion,
        fecha_edicion,
        formato_empaque,
        db_id,
        codigo_sap,
        factor_importacion
    ) => {
        return {
            id,
            acciones: makeRowActions({
                sku_proveedor,
                homologo_sa,
                es_desc,
                en_desc,
                marca,
                pn,
                costo_sa,
                moneda,
                um,
                cantidad,
                plazo_entrega,
                sitio_web,
                apariciones_creacion,
                apariciones_ultimos_dias,
                fecha_creacion,
                fecha_edicion,
                formato_empaque,
                db_id: db_id,
                codigo_sap: codigo_sap,
                factor_importacion: factor_importacion
            }),
            sku_proveedor,
            homologo_sa,
            es_desc,
            en_desc,
            marca,
            pn,
            costo_sa,
            moneda,
            um,
            cantidad,
            plazo_entrega,
            sitio_web,
            apariciones_creacion,
            apariciones_ultimos_dias,
            fecha_creacion,
            fecha_edicion,
            formato_empaque,
            codigo_sap,
            factor_importacion
        }
    }

    // Pagination handling

    /**
     * Resets the 'rows' state based on the given 'newPage' and 'filters'.
     *
     * This function is responsible for resetting the 'rows' state based on the provided 'newPage'
     * and the state of 'filters'. If all filter values are empty, it resets 'rows' by filling it
     * with empty objects, depending on the 'newPage' value and pagination settings. It also updates
     * the 'filledPages' state accordingly.
     *
     * @param {number} newPage - The new page number for which to reset the 'rows' state.
     */
    const resetRows = (newPage) => {
        if (!Object.keys(filters).some(x => filters[x] !== "")){
            const newFilledPages = []
            const newRows = [...rows]
            if (rowsPerPage * (newPage+1) <= 500){
                const newArray = new Array(newRows.length).fill({})
                const newLeftIndex = parseInt(parseInt(rowsPerPage * newPage / 500)*500)
                for (let k = 0; k < filledPages.length; k ++){
                    const leftIndex = parseInt(filledPages[k]/500)*500
                    if (Math.abs(leftIndex-newLeftIndex) === 500){
                        newFilledPages.push(leftIndex)
                        newFilledPages.push(newLeftIndex)
                    }
                    /* If its not consecutive, then remove from current array */
                    else{
                        const newArray = new Array( Math.min(500, Math.abs(newRows.length - leftIndex))).fill({})
                        Array.prototype.splice.apply(newRows, [leftIndex, Math.min(500, Math.abs(newRows.length - leftIndex))].concat(newArray))
                    }
                }
                if (newFilledPages.length === 0){
                    setFilledPages([0])
                    setRows(newArray)
                }
                else{
                    setFilledPages(newFilledPages)
                    setRows(newRows)
                }
            }
            else{
                const newLeftIndex = parseInt(parseInt(rowsPerPage * newPage / 500)*500)
                for (let k = 0; k < filledPages.length; k ++){
                    const leftIndex = parseInt(filledPages[k]/500)*500
                    if (Math.abs(leftIndex-newLeftIndex) === 500){
                        newFilledPages.push(leftIndex)
                        newFilledPages.push(newLeftIndex)
                }
                /* If its not consecutive, then remove from current array */
                else{
                    const newArray = new Array( Math.min(500, Math.abs(newRows.length - leftIndex))).fill({})
                    Array.prototype.splice.apply(newRows, [leftIndex, Math.min(500, Math.abs(newRows.length - leftIndex))].concat(newArray))
                }
            }
            setRows(newRows)
            if (newFilledPages.length === 0){
                newFilledPages.push(newLeftIndex - 500)
                newFilledPages.push(newLeftIndex)
                setFilledPages(newFilledPages)
            }
            else{
                setFilledPages(newFilledPages)
            }
            }
        }
       
    }
    /**
     * Handle the page change event in the data grid pagination.
     * 
     * @param {Object} e - The event object representing the page change event.
     * @param {number} newPage - The new page number to be set.
     * @returns {void}
     */
    const handlePageChange = (e, newPage) => {
        if (!rows[rowsPerPage*(newPage+1)-1]){
            if (Object.keys(rows[rows.length - 1]).length === 0){
                resetRows(newPage)
            }
        }
        else{ 
            if(Object.keys(rows[rowsPerPage*(newPage+1)-1]).length === 0){
            /* Remove First array from memory*/
                resetRows(newPage)

            }
        }
        setPage(newPage);
        }
        
    /**
     * Handle the change in the number of rows per page in the data grid.
     * It updates the rows per page in the state and resets the current page to the first page (page 0).
     * 
     * @param {Object} e - The event object representing the change in the rows per page.
     * @returns {void}
     */
    const handleChangeRowsPerPage = (e) => {
        dispatch(setRowsPerPageDetalleHomologoProveedor(parseInt(e.target.value, 10)));
        fetchData(true, true)
        setPage(0)
    }

    /**
     * Event handler for handling 'Enter' key presses.
     *
     * This function is an event handler that triggers a data fetch operation when the 'Enter' key is pressed.
     * It is typically used in input fields to initiate a data fetch when the user presses 'Enter'.
     *
     * @param {Event} e - The keyboard event object.
     */
    const onKeyDown = (e) => {
        if (e.key === "Enter"){
            setPage(0)
            setLastPage(0)
            fetchData(true, true)
        }
    }

    /**
     * Handle the export of the data in the data grid to an Excel file.
     * It checks if the data is loaded and the rows array is not empty.
     * If the conditions are met, it shows a success alert with a download message and
     * then exports the data using the make_excel function with a modified rows array
     * that doesn't contain the 'acciones' property.
     * 
     * @returns {void}
     */
    const handleExport = () => {
        if (!loading && rows.length > 0) {
            dispatch(setAlert({
                open: true,
                severity: "info",
                message: t("Common:Download")
            }))
            let rowsToSave = JSON.parse(JSON.stringify(rows));
            // Filtrar los registros que tienen el campo 'sku_proveedor'
            const filteredRows = rows.filter(row => row.sku_proveedor);

            rowsToSave = filteredRows.map((row) => {
                return {
                    'Rut': provider_id,
                    'Sku Proveedor': row.sku_proveedor,
                    'Homólogo SA': row.homologo_sa,
                    'Descripción Español': row.es_desc,
                    'Descripción Inglés': row.en_desc,
                    'Marca': row.marca,
                    'P/N': row.pn,
                    'Costo para SA': row.costo_sa,
                    'Moneda del costo': row.moneda,
                    'UM del costo': row.um,
                    'Cantidad por empaque': row.cantidad,
                    'Plazo de entrega': row.plazo_entrega,
                    'Sitio web': row.sitio_web,
                    'Cantidad apariciones desde creación': row.apariciones_creacion,
                    'Cantidad apariciones últimos 90 días': row.apariciones_ultimos_dias
                }
            })
            rowsToSave.forEach((row) => {
                delete row.acciones;
            })
            make_excel(`homologos_proveedor_${masterData.name}`, rowsToSave);
        }
    }

    return (
        <Stack direction={"column"} spacing={4}>
            <Box display={"flex"} flexWrap={"wrap"} gap={4} justifyContent={"space-between"}>
                <Box display={"flex"} flexDirection={"column"} gap={4}>
                    <Typography className="serviall-page-title1">
                        {t("DetalleHomologoProveedorPage:Title")}
                    </Typography>
                    {masterData &&
                    <Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"}>
                        <Stack direction={"column"} spacing={0.5}>
                            <Typography className="VerDetalleMaestros-property-title">
                                <ArrowRightIcon className="serviall-color-main" />{masterData.rut}
                            </Typography>
                            <Typography className="VerDetalleMaestros-property-value">
                                {masterData.name}
                            </Typography>
                        </Stack>

                        
                    </Box>
                    }
                </Box>

                <Paper className="verDetalleOportunidad-paper-container" elevation={6}>
                    <Grid display={"flex"}  flexWrap={"wrap"} width={"100%"} flexDirection={{ xs: "columnm", lg: "row" }} justifyContent={{ lg: "space-evenly" }} gap={{ xs: "1rem", lg: "0" }}>
                            <Stack display={"flex"} flexDirection={"column"} alignItems={"center"} marginRight={{ xs: 0, lg: "8px" }} marginBottom={{ xs: "1rem", lg: 0 }}>
                                <Typography className="verDetalleOportunidad-KpiBox-container-title">{t("DetalleHomologoProveedorPage:GridTitle")}</Typography>
                                <Grid display={"flex"} flexDirection={{ xs: "column", tablet: "row" }}>
                                        <KpiBox value={lines} size={100} text={t("DetalleHomologoProveedorPage:TotalLines")} tooltipText={"Info"}/>


                                </Grid>
                            </Stack>
                    </Grid>
                </Paper>
            </Box>
            <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"space-between"} gap={4}>
                <Typography
                            id="downloadExcelButton"
                            className="GestorOportunidadesPage-Button-permi
                                desc-Download-excel-button
                                serviall-page-caption
                                gestorOportunidades-download-button"
                            onClick={() => handleExport()}
                        >
                            {t("DetalleHomologoProveedorPage:ExportButton")} <SystemUpdateAltIcon />
                </Typography>
                <Box display={"flex"} flexDirection={"column"}>

                    <Box display={"flex"} gap={4} flexWrap={"wrap"}>
                        <ColumnSelector initialState={initialCols} setDatagridCols={setDatagridCols} page="detalleHomologoProveedor" />
                        <ResetFiltersBtn page={"detalleHomologoProveedor"} />
                    </Box>

                    <Box width={"100%"}>
                        <ServiallInput 
                            id={"user_query"} 
                            value= {query} 
                            onKeyDown={onKeyDown}
                            placeholder={t("DetalleHomologoProveedorPage:UserQueryPlaceholder")}
                            fullWidth={true}
                            onChange={(e) => setQuery(e.target.value)}
                            inputClassName="serviall-input"/>
                    </Box>
                </Box>
            </Stack>
            <Typography className="serviall-page-title2">{t("DetalleHomologoProveedorPage:Subtitle3")}</Typography>
            <Typography className="serviall-page-title2">{t("DetalleHomologoProveedorPage:Subtitle2")}{filteredLines}</Typography>
            {
                loading ?
                    <Box className="serviall-datagrid-loading-container">
                        <Stack direction={"column"} alignItems={"center"} spacing={2}>
                            <Typography className="serviall-h4">{t("DetalleHomologoProveedorPage:LoadingStatus")}</Typography>
                            <CircularProgress />
                        </Stack>
                    </Box>
                    :
                    rows.length > 0 ?
                        <Box className="serviall-datagrid-container">
                            {
                                datagridCols.length > 0 &&
                                <>
                                    <DraggableDataGrid
                                        className="serviall-datagrid"
                                        cols={datagridCols}
                                        rows={rows}
                                        headerRowHeight={90}
                                        rowKeyGetter={rowKeyGetter}
                                        initialState={initialState}
                                        count={filteredLines}
                                        filters={filters}
                                        setFiltersAction={setFilter}
                                        page={"detalleHomologoProveedor"}
                                        pageNum={page}
                                        rowsPerPage={rowsPerPage}
                                        handlePageChange={handlePageChange}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </>
                            }

                        </Box>
                        :
                        <Box className="serviall-datagrid-loading-container">
                            <Typography className="serviall-h4">{t("DetalleHomologoProveedorPage:EmptyStatus")}</Typography>
                        </Box>
            }
            <Typography className="serviall-back-text" onClick={() => navigate(-1)}>
                <ArrowBackIcon />{t("DetalleHomologoProveedorPage:GoBackButton")}
            </Typography>
            <EditMastersItemDialog
                dialogOpen={editDialogOpen}
                loading={dialogLoading}
                variant={"proveedores"}
                formValues={editForm}
                handleChange={handleEditFormChange}
                handleClose={handleEditDialogClose}
                handleSave={handleSaveEdit}
            />
            <DeleteMastersItemDialog
                dialogOpen={deleteDialogOpen}
                loading={dialogLoading}
                variant={"proveedores"}
                formValues={actualDeleteId}
                handleClose={handleDeleteDialogClose}
                handleSave={handleDelete} />

        </Stack>
    )
}

VerDetalleHomologosProveedorPage.displayName = "VerDetalleHomologosProveedorPage";

export default VerDetalleHomologosProveedorPage;
