import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import "./ServiallUploadButton.css";
import { useTranslation } from "react-i18next";

/**
 * A React component that renders the upload button for files.
 * @typedef ServiallUploadButton
 * @params {string} primaryText - The text to be displayed on the button.
 * @params {string} secondaryText - The secondary text to be displayed on the button.
 * @params {string} tertiaryText - The tertiary text to be displayed on the button.
 * @params {function} handleFileChange - The function to be called when the file is changed.
 * @params {function} resetFile - The function to be called when the file is reset.
 * @params {string} filename - The name of the file.
 * @params {boolean} disabled - The boolean to disable the button.
 * @params {string} height - The height of the button.
 * @params {string} marginBottom - The margin bottom of the button.
 * @returns A button component to upload files.
 */
const ServiallUploadButton = ({
    primaryText,
    secondaryText,
    tertiaryText,
    handleFileChange,
    resetFile,
    filename,
    disabled,
    height,
    marginBottom
}) => {
    const {t} = useTranslation()
    return (
        <Stack direction={"column"} spacing={1.5}>
            <Typography className='serviall-input-label' marginBottom={marginBottom ? marginBottom : 0}> 
                {primaryText}
            </Typography>
            <Box >
                <Box display={"flex"}  gap={2} alignItems={"center"} alignContent={"center"} marginBottom={"1rem"} height={height ? height : "fit-content"} >
                    <Button
                        variant="contained"
                        component="label"
                        disabled={disabled}
                        className="serviall-upload-button"
                    >
                        <Typography className="serviall-upload-secondary-text">
                            {secondaryText}
                        </Typography>
                        <input type="file" accept=".xlsx" hidden onChange={handleFileChange} />
                    </Button>
                {
                    filename ?
                        <Box sx={{ display: "flex", wordBreak:"break-all", maxHeight:"100px", overflowY: "auto"}} width={{xs :"60%"}}>
                            <Typography sx={{fontSize:"13px"}}>
                                {filename}
                            </Typography>
                            <Button onClick={resetFile}>X</Button>
                        </Box>
                        :
                        <Typography  sx={{fontSize:"13px", color: "red"}}>
                            {t("NuevaOportunidadPage:StatusText")}
                        </Typography>
                }
                </Box>
                <Typography className="serviall-upload-terciary-text">
                    {tertiaryText}
                </Typography>
            </Box>
        </Stack>
    )
}

ServiallUploadButton.displayName = "ServiallUploadButton";

export default ServiallUploadButton;