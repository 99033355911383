import {
    Box, Button, Checkbox, CircularProgress, Pagination, Stack, ToggleButton,
    ToggleButtonGroup, Typography, Select, FormControl, MenuItem, Grid, Paper
} from "@mui/material";
import KpiBox from '../../components/KpiBox';
import ReplyIcon from '@mui/icons-material/Reply';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import "./ItemsLicitacionPage.css";
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import serviallAxios from "../../axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { setCurrent, setHistory, setHistoryById, setPage, setRows } from "../../features/opportunitySlice";
import ServiallDialog from "../../components/ServiallDialog";
import AddAuxItemDialog from "../../components/AddAuxItemDialog";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { setAlert, setPage as setNavPage } from "../../features/navigationSlice";
import { useTranslation } from "react-i18next";
import VistaAgregadaDatagrid from "../../components/VistaAgregadaDatagrid";
import EdicionItemsDatagrid from "../../components/EdicionItemsDatagrid";
import { ITEM_DATA_FIELDS, TIMEOUTS, makeExtraItemRows, makeMlPayload, makeRowAggregatedMode, makeRowEditMode, makeS3FolderPath } from "../../utils";
import ResetFiltersBtn from "../../components/ResetFiltersBtn";
import ItemsAdicionalesDialog from "../../components/ItemsAdicionalesDialog";
import ItemsAdicionalesDatagrid from "../../components/ItemsAdicionalesDatagrid";
import ColumnSelector from "../../components/ColumnSelector";
import { CustomAlertMessage } from "../../utils";
import { setCurrentLicitation } from "../../features/opportunitySlice";
import LicitationSummary from "../../components/LicitationSummary";

/**
 * A React component that renders the licitation's items page.
 * @typedef ItemsLicitacionPage
 * @returns A page with the licitation's items
 */

const ItemsLicitacionPage = () => {

    // Consts
    const SAVE_EACH_N_PAGES = parseInt(process.env.REACT_APP_SAVE_EACH_N_PAGES);
    const MIN_COL_WIDTH = 30;
    const kpiWidth = "90px"

    const { t } = useTranslation()
    const navigate = useNavigate();
    const location = useLocation();
    const opportunity_data = location.state;
    const READ_ONLY = opportunity_data ? opportunity_data.read_only : true;
    const pageState = useSelector((state) => state.opportunity);
    const dispatch = useDispatch();
    const loggingOut = useSelector((state) => state.navigation.loggingOut)
    const [kpis, setKpis] = useState({});

    // Datagrid type states
    const [datagridType, setDatagridType] = useState(0); // 0 = edicion, 1 = agregados

    // Select states logic
    const [disableIgualSelect, setDisableIgualSelect] = useState(false);
    const [disableAlternativoSelect, setDisableAlternativoSelect] = useState(false);

    // No match states
    const [noOffer, setNoOffer] = useState(false);
    const [matchData, setMatchData] = useState({
        provider_sku: "",
        provider_name: ""
    })

    // Autosave states
    const [checkedPages, setCheckedPages] = useState(0);

    // Serviall Dialog states
    const [dialogMessage, setDialogMessage] = useState("");
    const [loading, setLoading] = useState(true);

    const [triggerPageChange, setTriggerPageChange] = useState(false);

    const intervalRef = useRef(null)
    /**
     * Set the "no_match" property for all rows in the page state to the specified value.
     * This function updates the "no_match" property of each row in the page state with the given value.
     *
     * @param {boolean} value - The new value to set for the "no_match" property.
     */
    const setNoMatchFoundRows = (value) => {
        let newRows = JSON.parse(JSON.stringify(pageState.rows));
        newRows.forEach((row) => {
            row["no_match"] = value;
        })
        dispatch(setRows(newRows));
    }

    const [pages, setPages] = useState()


    /** 
    * Useeffect for the the case where the user goes to this url manually.
    * @return {void}
    */
    useEffect(() => {
        if (!opportunity_data) {
            navigate("/dashboard/home");
        }
    }, [opportunity_data])

    /**
     * Unsets the active user from the opportunity and navigates to the opportunity list.
     *
     * This function sends an Axios PUT request to unset the active user associated with the current opportunity.
     * After the request is successful, it logs the response and navigates the user to the opportunity list.
     *
     * @function unsetActiveUser
     * @returns {void}
     */
    const unsetActiveUser = (redirect = false) => {
        /* Avoid duplicate unset_active_user*/
        if (!loggingOut) {
            serviallAxios.put(`opportunities/unset_active_user/${opportunity_data.lic_id}`, {}, {
                timeout: TIMEOUTS.small
            })
                .then((res) => {
                    if (redirect) navigate("/dashboard/gestor_oportunidades");
                })
                .catch((err) => {
                    console.log(err)
                });
        }
    }
    /**
     * Updates the session for the current opportunity.
     *
     * This function sends an Axios PUT request to update the session data for the current opportunity.
     * The request includes the client's RUT and opportunity ID as parameters.
     *
     * @function updateSession
     * @returns {void}
     */
    const updateSession = () => {
        serviallAxios.put("opportunities/update_session", {}, { params: { client_rut: opportunity_data.rut_cliente.toUpperCase(), id_opportunity: opportunity_data.op_id } }, {
            timeout: TIMEOUTS.small
        })
            .then((res) => {
                console.log(res)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    /**
     * Gets the last session data for the current opportunity and takes action based on the session's age.
     *
     * This function sends an Axios GET request to retrieve the last session data for the current opportunity.
     * The request includes the client's RUT and opportunity ID as parameters.
     * If the minutes difference in the session response is greater than or equal to 30, it calls 'unsetActiveUser'.
     * Otherwise, it logs that the user is still active.
     *
     * @function getLastSession
     * @returns {void}
     */
    const getLastSession = () => {
        serviallAxios.get(`opportunities/get_last_session`, { params: { client_rut: opportunity_data.rut_cliente.toUpperCase(), id_opportunity: opportunity_data.op_id } }, {
            timeout: TIMEOUTS.small
        })
            .then((sessionRes) => {
                console.log(sessionRes.data.minutes_difference)
                if (parseInt(sessionRes.data.minutes_difference) >= (process.env.REACT_APP_ACTIVE_TOLERANCE ? process.env.REACT_APP_ACTIVE_TOLERANCE : 30)) {
                    unsetActiveUser(true)
                }
                else {
                    console.log("USER IS ACTIVE")
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const enableCheckbox = () => {
        const newRows = JSON.parse(JSON.stringify(pageState.rows));
        return newRows.every((obj) => !obj.alternativo && !obj.igual)
    }
    /**
     * Effect hook to manage the user's session and page activity.
     *
     * If 'opportunity_data' is truthy, this effect sets up and maintains the user's session.
     * It calls 'updateSession' immediately and then starts an interval to call 'getLastSession' every minute.
     * It also adds a 'pagehide' event listener to call 'unsetActiveUser' when the page is hidden.
     * 
     * If the component unmounts or the effect dependencies change, the interval is cleared,
     * the 'pagehide' event listener is removed, and 'unsetActiveUser' is called.
     * 
     * @function useEffect
     * @memberof ComponentName
     * @returns {void}
     */

    const setActiveUser = () => {
        if (!loggingOut) {
            serviallAxios.get(`opportunities/active_user/${opportunity_data.lic_id}`, {
                timeout: TIMEOUTS.small
            })
                .then((res) => {
                    /* This case only happens when one user reloads the page and another enter this view at the exact same time */
                    if (res.data.result && parseInt(localStorage.getItem("id")) !== res.data.result[0]) {
                        navigate("/dashboard/gestor_oportunidades");
                    }
                    else {
                        serviallAxios.put(`opportunities/active_user/${opportunity_data.lic_id}`, {
                            timeout: TIMEOUTS.small
                        })
                            .catch((err) => {
                                dispatch(setAlert({
                                    open: true,
                                    severity: "error",
                                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                                }));
                                navigate("/dashboard/gestor_oportunidades");
                            })
                    }
                })
        }

    }
    const firstRender = useRef(true)
    useEffect(() => {
        console.log(loggingOut)
        if (opportunity_data) {
            dispatch(setCurrentLicitation(opportunity_data.lic_id))
            setActiveUser()
            updateSession()
            console.log("update session")
            intervalRef.current = setInterval(getLastSession, 60000)
            console.log("interval")
            window.addEventListener("pagehide", unsetActiveUser);
            console.log("Setting user");
            // Adding a return to useeffect is the same as a component will unmount

            return () => {
                console.log("clean")
                if (intervalRef.current) {
                    clearInterval(intervalRef.current)
                }
                console.log("Unsetting user");
                window.removeEventListener("pagehide", unsetActiveUser);
                unsetActiveUser();
                firstRender.current = false
            }
        }
    }, [loggingOut])



    /**
     * Handle data fetching depending on the datagrid type in "edit" mode.
     *
     * @param {number} page - The page number to load the data for. If provided, the function will redirect to this page after loading the data.
     */
    const handleFetchEditMode = (page) => {
        setLoading(true);
        // Get data from s3 if available
        let state_filename = `${opportunity_data.op_id}_state.json`;
        let s3_folder = makeS3FolderPath(opportunity_data);
        let state_payload = {
            path: s3_folder,
            filename: state_filename
        }
        serviallAxios.post("opportunities/fetch_state", state_payload, {
            timeout: TIMEOUTS.small
        })
            .then((res) => {
                if (res.data.data) {
                    console.log("Loading data from S3")
                    let res_data = JSON.parse(res.data.data);
                    let total_pages = JSON.parse(res_data.state_data).history.length;

                    if (total_pages <= 0) {
                        throw new Error('The state is empty');
                    }

                    setPages(total_pages);
                    let new_current_state = JSON.parse(res_data.state_data);

                    if ("no_offer" in new_current_state.history[0][0] && new_current_state.history[0][0]["no_offer"]) {
                        setNoOffer(true)
                    }
                    new_current_state.page = 1;

                    // Fill diff with empty arrays, this should not happen in new licitations
                    if (new_current_state.history.length < res_data.total_pages) {
                        let fetchedHistLen = new_current_state.history.length;
                        for (var i = 0; i < res_data.total_pages - fetchedHistLen; i++) {
                            new_current_state.history.push([]);
                        }
                    }
                    dispatch(setCurrent(new_current_state));
                    dispatch(setRows(new_current_state.history[0]));
                    // Redirect from agregados datagrid

                    if (page) {
                        setTriggerPageChange(page);
                    } else {
                        // Redirect from agregados route
                        if (opportunity_data.itemsLicitationIndex) {
                            setTriggerPageChange(opportunity_data.itemsLicitationIndex);
                        } else {
                            dispatch(setPage(1));
                        }
                    }

                    setLoading(false);
                }
            })
            .catch((err) => {
                // Do not make a match if the user is in read mode and no state was found
                dispatch(setAlert({
                    open: false,
                    severity: "info",
                    message: ""
                }))
                if (READ_ONLY) {
                    console.log("State not found in read mode");
                    dispatch(setHistory([[]]));
                    dispatch(setRows([]));
                    setLoading(false);
                    return
                };
                console.log("Data not found on S3, loading from backML");

                // Load data from backML
                let payload = makeMlPayload(opportunity_data);
                payload["page"] = 0;

                serviallAxios.post(process.env.REACT_APP_ML_BACKEND_URL + "/licitations/match", payload, {
                    timeout: TIMEOUTS.medium,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then((res) => {
                        setPages(res.data.lic_length);
                        let items = res.data.json_items;
                        items = JSON.parse(items);
                        let pageRows = items.map((item, idx) => makeRowEditMode(
                            idx,
                            item["SKU cliente"],
                            item["Desc Cliente"],
                            item["Descripción complementaria"],
                            item["Marca Cliente"],
                            item["P/N Cliente"],
                            item["Tipo"],
                            item["Item Match"],
                            item["Desc Match"],
                            item["Marca Match"],
                            item["P/N Match"],
                            item["Criticidad SA"],
                            item["Proveedor"],
                            item["SKU proveedor"],
                            item["Costo estimado"],
                            item["Moneda del costo"],
                            item["Precio Venta sugerido"],
                            item["Moneda del precio venta"],
                            item["Oportunidad valorizada"],
                            item["Moneda de oportunidad valorizada"],
                            false,
                            false,
                            item["UM"],
                            item["Consumo Anual"],
                            item["Cantidad por empaque"],
                            item["Factor de equivalencia cte"],
                            item["Plazo de entrega"],
                            item["score"],
                        )
                        )

                        serviallAxios.get(`opportunities/extra_items?client_id=${opportunity_data.rut_cliente.toUpperCase()}&sku_item=${items[0]["SKU cliente"]}`)
                            .then((extra_items_res) => {
                                const extraClientItems = extra_items_res.data.items;
                                const extraItemRows = extraClientItems.map((extraItem) => {
                                    let extraItemData = {}
                                    Object.keys(extraItem).forEach((key) => {
                                        if (ITEM_DATA_FIELDS.includes(key)) {
                                            extraItemData[key] = extraItem[key];
                                        }
                                    })
                                    let extra_client_item_obj = {
                                        extra_client_item: true,
                                        es_desc: extraItem.es_desc,
                                        en_desc: extraItem.en_desc,
                                        item_data: extraItemData,
                                        igual: false,
                                        alternativo: false
                                    }
                                    return makeExtraItemRows(extra_client_item_obj)
                                })
                                const finalPageRows = [...pageRows, ...extraItemRows]

                                let newHist = [];
                                // Fill history with empty arrays
                                for (var i = 0; i < res.data.lic_length; i++) {
                                    newHist.push([]);
                                }
                                newHist[0] = finalPageRows;
                                dispatch(setHistory(newHist));
                                dispatch(setRows(finalPageRows));


                                // Redirect from agregados datagrid
                                if (page) {
                                    setTriggerPageChange(page);
                                } else {
                                    // Redirect from agregados route
                                    if (opportunity_data.itemsLicitationIndex) {
                                        setTriggerPageChange(opportunity_data.itemsLicitationIndex);
                                    } else {
                                        dispatch(setPage(1));
                                    }
                                }
                            })
                            .catch((err) => {
                                let newHist = [];
                                // Fill history with empty arrays
                                for (var i = 0; i < res.data.lic_length; i++) {
                                    newHist.push([]);
                                }
                                newHist[0] = pageRows;

                                dispatch(setHistory(newHist));
                                dispatch(setRows(pageRows));

                                // Redirect from agregados datagrid
                                if (page) {
                                    setTriggerPageChange(page);
                                } else {
                                    // Redirect from agregados route
                                    if (opportunity_data.itemsLicitationIndex) {
                                        setTriggerPageChange(opportunity_data.itemsLicitationIndex);
                                    } else {
                                        dispatch(setPage(1));
                                    }
                                }
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                    })
                    .catch((err) => {
                        dispatch(setAlert({
                            open: true,
                            severity: "error",
                            message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                        }));
                    })
            })
    }

    /**
     * Handle data fetching in "aggregated" mode for the current opportunity.
     * This function fetches data related to the aggregated view of the opportunity.
     * It makes a POST request to retrieve aggregated data from the server based on the opportunity information.
     * If data is available, it processes the parsed data and updates the rows using the "setRows" dispatch function.
     * If data is not available, it falls back to another API call and updates the rows with pending state.
     * This function also sets the loading state while fetching the data and clears it when done.
     */
    const handleFetchAggregatedMode = () => {

        let year, month, day;
        if (opportunity_data.fecha_creacion.includes("-")) {
            [year, month, day] = opportunity_data.fecha_creacion.split("-")
        }
        else {
            [day, month, year] = opportunity_data.fecha_creacion.split("/")
        }

        setLoading(true);
        let payload = {
            client_rut: opportunity_data.rut_cliente.toUpperCase(),
            op_id: opportunity_data.op_id,
            date: `${year}-${month}-${day}`
        }

        serviallAxios.post("/opportunities/aggregate_view", payload, {
            timeout: TIMEOUTS.medium
        })
            .then((res) => {
                const parsed_data = res.data.dict_history;
                const aggregatedRows = []
                let itemsLicitationIndex = 1
                let index = 1
                /* Item page stores the  */
                parsed_data.forEach(item => {
                    // Have to do for like this to use break keyword
                    let noMatch = true;
                    let num_alternativa = 1;
                    if (item.length) {
                        for (const matchItem of item) {
                            // If no_match is false, add any alternativo or igual matchItems
                            if ("no_offer" in matchItem && matchItem["no_offer"]) {
                                const newRow = {
                                    estado_revision: "OK",
                                    itemsLicitationIndex: itemsLicitationIndex,
                                    index: index,
                                    sku_cliente: matchItem.sku_cliente,
                                    desc_cliente: matchItem.desc_cliente,
                                    desc_complementaria: matchItem.desc_complementaria,
                                    marca_cliente: matchItem.marca_cliente,
                                    pn_cliente: matchItem.pn_cliente,
                                    um: matchItem.um,
                                    consumo_anual: matchItem.consumo_anual,
                                    num_alternativa: "-",
                                    match_proveedor: "-",
                                    tipo_match: "No Oferta",
                                    no_match: "No Oferta",
                                    item_match: "-",
                                    sku_proveedor: "-",
                                    desc_match: "-",
                                    pn_match: "-",
                                    criticidad_sa: "-",
                                    um_match: "-",
                                    cantidad_empaque: "-",
                                    factor_equivalencia_cte: "-",
                                    plazo_entrega: "-",
                                    costo_estimado: "-",
                                    precio_venta_sugerido: "-",
                                    oportunidad_valorizada: "-",
                                }

                                noMatch = false;
                                const formattedRow = makeRowAggregatedMode(newRow)
                                aggregatedRows.push(formattedRow)
                                index += 1
                                break;
                            }
                            if (matchItem.alternativo || matchItem.igual) {
                                const newRow = {
                                    estado_revision: "OK",
                                    itemsLicitationIndex: itemsLicitationIndex,
                                    index: index,
                                    sku_cliente: matchItem.sku_cliente,
                                    desc_cliente: matchItem.desc_cliente,
                                    desc_complementaria: matchItem.desc_complementaria,
                                    marca_cliente: matchItem.marca_cliente,
                                    pn_cliente: matchItem.pn_cliente,
                                    um: matchItem.um,
                                    consumo_anual: matchItem.consumo_anual,
                                    num_alternativa: num_alternativa,
                                    match_proveedor: matchItem.proveedor,
                                    tipo_match: matchItem.igual ? "Igual" : "Alternativo",
                                    no_match: matchItem.auxiliar ? matchItem.auxiliar : "BN",
                                    item_match: matchItem.item_match,
                                    sku_proveedor: matchItem.sku_proveedor,
                                    desc_match: matchItem.desc_match,
                                    marca_match: matchItem.marca_match,
                                    pn_match: matchItem.pn_match,
                                    criticidad_sa: matchItem.criticidad_sa,
                                    um_match: matchItem.um_match,
                                    cantidad_empaque: matchItem.cantidad_empaque,
                                    factor_equivalencia_cte: matchItem.factor_equivalencia_cte,
                                    plazo_entrega: matchItem.plazo_entrega,
                                    costo_estimado: matchItem.costo_estimado,
                                    precio_venta_sugerido: matchItem.precio_venta_sugerido,
                                    oportunidad_valorizada: matchItem.oportunidad_valorizada,
                                }
                                noMatch = false;
                                const formattedRow = makeRowAggregatedMode(newRow)
                                aggregatedRows.push(formattedRow)
                                index += 1
                                num_alternativa += 1
                            }
                            else {
                                if (item[item.length - 1] === matchItem) {
                                    /* This means that this item has no match, equal nor alternative, so it's still pending */
                                    /* Only insert this if item has no previous match */
                                    if (noMatch) {
                                        const newRow = {
                                            estado_revision: "Pend",
                                            itemsLicitationIndex: itemsLicitationIndex,
                                            index: index,
                                            sku_cliente: matchItem.sku_cliente,
                                            desc_cliente: matchItem.desc_cliente,
                                            desc_complementaria: matchItem.desc_complementaria,
                                            marca_cliente: matchItem.marca_cliente,
                                            pn_cliente: matchItem.pn_cliente,
                                            um: matchItem.um,
                                            consumo_anual: matchItem.consumo_anual,
                                            num_alternativa: "-",
                                            match_proveedor: "-",
                                            tipo_match: "-",
                                            no_match: "-",
                                            item_match: "-",
                                            sku_proveedor: "-",
                                            desc_match: "-",
                                            marca_match: "-",
                                            pn_match: "-",
                                            criticidad_sa: "-",
                                            um_match: "-",
                                            cantidad_empaque: "-",
                                            factor_equivalencia_cte: "-",
                                            plazo_entrega: "-",
                                            costo_estimado: "-",
                                            precio_venta_sugerido: "-",
                                            oportunidad_valorizada: "-",
                                        }
                                        const formattedRow = makeRowAggregatedMode(newRow)
                                        aggregatedRows.push(formattedRow)
                                        index += 1
                                    }
                                }
                            }

                        }
                    }
                    else {
                        const newRow = {
                            estado_revision: "Pend",
                            itemsLicitationIndex: itemsLicitationIndex,
                            index: index,
                            sku_cliente: item["Sku Cliente"],
                            desc_cliente: item["Descripción Principal cliente"],
                            desc_complementaria: item["Descripción complementaria"],
                            marca_cliente: item["Marca/Fabricante"],
                            pn_cliente: item["Número de Parte"],
                            um: item["UM"],
                            consumo_anual: item["Consumo anual"],
                            num_alternativa: "-",
                            match_proveedor: "-",
                            tipo_match: "-",
                            no_match: "-",
                            item_match: "-",
                            desc_match: "-",
                            pn_match: "-",
                            um_match: "-",
                            cantidad_empaque: "-",
                            factor_equivalencia_cte: "-",
                            plazo_entrega: "-",
                            costo_estimado: "-",
                            precio_venta_sugerido: "-",
                            oportunidad_valorizada: "-",
                        }
                        const formattedRow = makeRowAggregatedMode(newRow)
                        aggregatedRows.push(formattedRow)
                        index += 1
                    }

                    itemsLicitationIndex += 1;
                })
                dispatch(setRows(aggregatedRows))
            }).catch((error) => {
                dispatch(setAlert({
                    open: false,
                    severity: "info",
                    message: ""
                }))
                const aggregatedRows = []

                let payload = {
                    client_rut: opportunity_data.rut_cliente.toUpperCase(),
                    op_id: opportunity_data.op_id,
                    date: `${year}-${month}-${day}`
                }

                serviallAxios.post("/opportunities/aggregate_view_no_state", payload, {
                    timeout: TIMEOUTS.small
                }).then(res => {
                    let itemsLicitationIndex = 1
                    res.data.remaining.forEach((item) => {
                        const newRow = {
                            estado_revision: "Pend",
                            itemsLicitationIndex: itemsLicitationIndex,
                            index: itemsLicitationIndex,
                            sku_cliente: item["Sku Cliente"],
                            desc_cliente: item["Descripción Principal cliente"],
                            desc_complementaria: item["Descripción complementaria"],
                            marca_cliente: item["Marca/Fabricante"],
                            pn_cliente: item["Número de Parte"],
                            um: item["UM"],
                            consumo_anual: item["Consumo anual"],
                            num_alternativa: "-",
                            match_proveedor: "-",
                            tipo_match: "-",
                            no_match: "-",
                            item_match: "-",
                            desc_match: "-",
                            pn_match: "-",
                            um_match: "-",
                            cantidad_empaque: "-",
                            factor_equivalencia_cte: "-",
                            plazo_entrega: "-",
                            costo_estimado: "-",
                            precio_venta_sugerido: "-",
                            oportunidad_valorizada: "-",
                        }
                        const formattedRow = makeRowAggregatedMode(newRow)
                        aggregatedRows.push(formattedRow)
                        itemsLicitationIndex += 1
                    })
                    dispatch(setRows(aggregatedRows))
                })
            })
            .finally(() => { setLoading(false) })
    }

    /**
     * Handles exporting the current view state data to a downloadable file.
     *
     * This function sends a POST request to the server with the current page state rows data
     * as payload, and expects a Blob response (binary data).
     * Once the response is received, it creates a downloadable link for the Blob data, sets the appropriate
     * attributes for download, triggers the click event on the link, and then cleans up by removing the link.
     * If an error occurs during the process, it displays an error alert.
     * 
     * @function handleExportView
     * @returns {void}
     */
    const handleExportView = () => {
        const payload = {
            data: JSON.stringify(pageState.rows)
        }
        serviallAxios.post("opportunities/export_state", payload, {
            timeout: TIMEOUTS.small,
            responseType: "blob"
        })
            .then((res) => {
                const href = URL.createObjectURL(res.data);
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", `${opportunity_data.op_id}_pag${pageState.current.page}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
    }

    /** 
    * Useeffect for the the case where the user goes to this url manually.
    * @return {void}
    */
    useEffect(() => {
        if (!opportunity_data) {
            navigate("/dashboard/home");
        }
    }, [opportunity_data])

    /**
     * Initial fetch useEffect hook.
     * This hook is called once when the component mounts to fetch and load data for the "edit" mode.
     * It calls the "handleFetchEditMode" function to fetch and update the data.
     */
    useEffect(() => {
        if (opportunity_data) {
            handleFetchEditMode();
        }
    }, [])

    /**
     * Force redirect useEffect hook.
     * This hook is used to handle page changes when the user comes from the aggregated view or the aggregated table tab.
     * If the "triggerPageChange" state is set, it calls the "handlePageChange" function to perform the redirect.
     * After the redirect is handled, it resets the "triggerPageChange" state.
     */
    useEffect(() => {
        if (triggerPageChange) {
            handlePageChange(undefined, triggerPageChange);
            setTriggerPageChange(0);
        }
    }, [triggerPageChange])



    /**
     * Autosave useEffect hook.
     * This hook is triggered whenever the "checkedPages" state changes.
     * It checks if the current page count (checkedPages) is divisible by SAVE_EACH_N_PAGES constant.
     * If it is, it calls the "handleSaveState" function to perform an autosave of the current state.
     */
    useEffect(() => {
        if (checkedPages > 0 && checkedPages % SAVE_EACH_N_PAGES === 0) {
            console.log("ACTIVAR AUTOGUARDADO");
            handleSaveState();
        }
    }, [checkedPages])


    useEffect(() => {
        if (datagridType === 1 && opportunity_data) {
            const params = {
                op_id: opportunity_data.op_id
            }
            serviallAxios.get(`utilities/get_resume`, { params }, {
                timeout: TIMEOUTS.medium,
            }).then((res) => {
                setKpis(res.data.data)
            }).catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
        }
    }, [datagridType, opportunity_data])

    /**
     * Handle if some checkboxes should be disabled useEffect hook.
     * This hook is triggered whenever the "pageState" state changes.
     * It calculates whether the checkboxes for "igual" and "alternativo" should be disabled based on the data in the "pageState.rows" array.
     * It updates the "disableIgualSelect" and "disableAlternativoSelect" states accordingly.
     */
    useEffect(() => {
        let newDisableIgualSelect = pageState.rows.reduce((count, row) => count + row.igual, 0) >= 1 ? true : false;
        let newDisableAlternativoSelect = pageState.rows.reduce((count, row) => count + row.alternativo, 0) >= 3 ? true : false;
        setDisableIgualSelect(newDisableIgualSelect)
        setDisableAlternativoSelect(newDisableAlternativoSelect)
    }, [pageState])


    /**
     * Handle checkbox change event.
     * This function is called when a checkbox in a row is toggled.
     * It updates the "colValue" property of the row at the specified index (rowIdx) to the new value of the checkbox (e.target.checked).
     * It then dispatches an action to update the rows in the Redux store and also updates the history for the current page.
     *
     * @param {number} rowIdx - The index of the row in "pageState.rows" array that needs to be updated.
     * @param {string} colValue - The property (column) of the row that corresponds to the checkbox being toggled.
     */
    const onCheckboxChange = (e, rowIdx, colValue) => {
        // Save current rows
        let newRows = [...pageState.rows];
        let newRow = { ...newRows[rowIdx] };
        newRow[colValue] = e.target.checked;
        newRows[rowIdx] = newRow;
        dispatch(setRows(newRows));
        // Update history on redux as well
        let updateHistoryPayload = {
            idx: pageState.current.page - 1,
            rows: newRows
        }
        dispatch(setHistoryById(updateHistoryPayload));
        setNoOffer(false)
    }

    /**
     * Handle datagrid type change event.
     * This function is called when the datagrid type (datagridType) changes.
     * It updates the datagridType state with the new value (newDatagridType).
     * If the new datagrid type is 0 (edit mode), it calls the "handleFetchEditMode" function.
     * If the new datagrid type is 1 (aggregated mode), it sets the "noMatch" state to false and calls the "handleFetchAggregatedMode" function.
     *
     * @param {object} e - The event object of the datagrid type change event.
     * @param {number} newDatagridType - The new datagrid type value to be set.
     */
    const handleDatagridTypeChange = (e, newDatagridType) => {
        if (newDatagridType === null) return;
        setDatagridType(newDatagridType);
        if (newDatagridType === 0) {
            handleFetchEditMode();
        } else {
            setNoOffer(false);
            handleFetchAggregatedMode();
        }
    }

    /**
     * Handle redirect to specific item.
     * This function is called to redirect the user to a specific item (page) in the edit mode.
     * It sets the datagridType to 0 (edit mode) and calls the "handleFetchEditMode" function with the provided index (idx).
     *
     * @param {number} idx - The index (page number) to which the user should be redirected.
     */
    const handleRedirectToSpecificItem = (idx) => {
        setDatagridType(0);
        handleFetchEditMode(idx);
    }

    /**
     * Handle page change event.
     * This function is called when the user selects a different page in the datagrid.
     * It first updates the history for the current page in the Redux store to the current rows.
     * If the selected page's history is empty, it fetches new data using the "makeMlPayload" and "handleFetchEditMode" functions.
     * If the selected page's history is not empty, it loads the data from the history for that page.
     * It then updates the rows, page state, and resets some related states.
     *
     * @param {object} e - The event object of the page change event.
     * @param {number} val - The new page number selected by the user.
     */
    const handlePageChange = (e, val) => {
        updateSession()
        // If read only do not allow for match searching, only allow reading the maximum match reached
        if (READ_ONLY) {
            if (val > pageState.current.history.length) return;
        }

        if (pageState.current.page !== val) {
            console.log("Checked pages:", checkedPages)
            setCheckedPages(checkedPages + 1);
        }

        const newRows = JSON.parse(JSON.stringify(pageState.rows));
        newRows.forEach((row) => {
            row.no_offer = noOffer

        })
        // Actualizar valores de la pagina en redux
        let updateHistoryPayload = {
            idx: pageState.current.page - 1,
            rows: newRows
        }

        dispatch(setHistoryById(updateHistoryPayload));

        if (pageState.current.history[val - 1].length === 0) {
            // Cargar nueva pagina utilizando back ml
            setLoading(true);
            setNoOffer(false)
            let payload = makeMlPayload(opportunity_data);
            payload["page"] = val - 1;

            serviallAxios.post(process.env.REACT_APP_ML_BACKEND_URL + "/licitations/match", payload,
                {
                    timeout: TIMEOUTS.medium
                })
                .then((res) => {
                    let items = res.data.json_items;
                    items = JSON.parse(items);
                    let pageRows = items.map((item, idx) => makeRowEditMode(
                        idx,
                        item["SKU cliente"],
                        item["Desc Cliente"],
                        item["Descripción complementaria"],
                        item["Marca Cliente"],
                        item["P/N Cliente"],
                        item["Tipo"],
                        item["Item Match"],
                        item["Desc Match"],
                        item["Marca Match"],
                        item["P/N Match"],
                        item["Criticidad SA"],
                        item["Proveedor"],
                        item["SKU proveedor"],
                        item["Costo estimado"],
                        item["Moneda del costo"],
                        item["Precio Venta sugerido"],
                        item["Moneda del precio venta"],
                        item["Oportunidad valorizada"],
                        item["Moneda de oportunidad valorizada"],
                        false,
                        false,
                        item["UM"],
                        item["Consumo Anual"],
                        item["Cantidad por empaque"],
                        item["Factor de equivalencia cte"],
                        item["Plazo de entrega"],
                        item["score"],
                    )
                    )

                    serviallAxios.get(`opportunities/extra_items?client_id=${opportunity_data.rut_cliente.toUpperCase()}&sku_item=${items[0]["SKU cliente"]}`)
                        .then((res) => {
                            const extraClientItems = res.data.items;
                            const extraItemRows = extraClientItems.map((extraItem) => {
                                let extraItemData = {}
                                Object.keys(extraItem).forEach((key) => {
                                    if (ITEM_DATA_FIELDS.includes(key)) {
                                        extraItemData[key] = extraItem[key];
                                    }
                                })
                                let extra_client_item_obj = {
                                    extra_client_item: true,
                                    es_desc: extraItem.es_desc,
                                    en_desc: extraItem.en_desc,
                                    item_data: extraItemData,
                                    igual: false,
                                    alternativo: false
                                }
                                return makeExtraItemRows(extra_client_item_obj);
                            })
                            const finalPageRows = [...pageRows, ...extraItemRows]

                            // Agregar history en la posicion actual
                            let updateHistoryPayload = {
                                idx: val - 1,
                                rows: finalPageRows
                            }
                            dispatch(setHistoryById(updateHistoryPayload));
                            dispatch(setRows(finalPageRows));
                        })
                        .catch((err) => {
                            let updateHistoryPayload = {
                                idx: val - 1,
                                rows: pageRows
                            }
                            dispatch(setHistoryById(updateHistoryPayload));
                            dispatch(setRows(pageRows));
                        })
                        .finally(() => {
                            setLoading(false);
                            setCheckedPages(checkedPages + 1);
                        });

                })
                .catch((err) => {
                    dispatch(setAlert({
                        open: true,
                        severity: "error",
                        message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                    }));
                })
        } else {
            console.log("Cargar datos de la historia, no sacar nuevos de la api");

            if (pageState.current.page !== val) {
                setCheckedPages(checkedPages + 1);
            }

            let rowsToSet = JSON.parse(JSON.stringify(pageState.current.history[val - 1]));
            rowsToSet.map((row) => {
                row.highlight_repeated = false;
                return row;
            })
            if ("no_offer" in rowsToSet[0] && rowsToSet[0]["no_offer"]) {
                setNoOffer(true);
            }
            else {
                setNoOffer(false)
            }
            dispatch(setRows(rowsToSet));
        }

        dispatch(setPage(val));

        // Reset states
        setMatchData({
            provider_sku: "",
            provider_name: ""
        })
    }

    /**
     * Handles the state-saving event (and optionally, closing).
     * This function is called to save the current state of the data grid and, if the "close" parameter is true, to close the bid.
     * Sets the dialog message to indicate the saving process.
     * Constructs the payload data with the necessary information for saving the state to the server.
     * If the size of the state event exceeds 6MB, it will use the S3 multipart upload method to upload it.
     * The multipart upload method involves an initialization of the file, an update of the part, and a completion of the part.
     * If the "close" parameter is true, it also prepares the payload data for closing the bid.
     * Makes a POST request to save the state using the "serviallAxios.post" function.
     * If the saving process is successful and the "close" parameter is true, it makes another POST request to close the bid.
     * Then dispatches the "setNavPage" action to navigate to the "gestor_oportunidades" page and resets some related states.
     *
     * @param {boolean} close - A boolean flag to indicate whether the bid should be closed after saving the state.
     */
    const handleSaveState = async (close = false) => {

        setDialogMessage("Guardando matches...");

        let filename = `${opportunity_data.op_id}_state.json`

        let s3_folder = makeS3FolderPath(opportunity_data);

        let payload_data = {
            total_pages: pages,
            state_data: JSON.stringify(pageState.current)
        }
        let payload = {
            path: s3_folder,
            filename: filename,
            data: JSON.stringify(payload_data)
        }
        const sizePayload = new Blob([payload.data]).size

        //el maximo permitido por lamda
        const maxSize = 6291456
        if (sizePayload > maxSize) {

            let responseInit = await serviallAxios.post('opportunities/state-init-parts', {
                path: payload.path,
                filename: payload.filename
            })
            responseInit = responseInit.data

            const totalParts = Math.ceil(sizePayload / maxSize);
            //const totalParts = 2

            const parts = [];
            let aux = payload.data.length / totalParts
            for (let i = 0; i < payload.data.length; i += aux) {
                parts.push(payload.data.slice(i, i + aux));
            }
            console.log("parts", parts)
            console.log("payload.data", payload.data)
            console.log("sizePayload", sizePayload)

            for (let part = 0; part < parts.length; part++) {
                await serviallAxios.post('opportunities/state-update-parts', {
                    path: payload.path,
                    filename: payload.filename,
                    uploadId: responseInit.upload_id,
                    partNumber: part + 1,
                    data: parts[part],
                })
            }
            await serviallAxios.post('opportunities/state-complete-parts', {
                path: payload.path,
                filename: payload.filename,
                uploadId: responseInit.upload_id,
            })
            setDialogMessage("");
            setLoading(false);
        } else {
            serviallAxios.post("opportunities/state", payload, {
                timeout: TIMEOUTS.small
            })
                .then((res) => {
                    if (close) {
                        setDialogMessage("Terminando licitación");
                        console.log("Closing licitation");

                        let payload = {
                            client_rut: opportunity_data.rut_cliente.toUpperCase(),
                            op_id: opportunity_data.op_id,
                            lic_id: opportunity_data.lic_id,
                            state: "Done"
                        }

                        if (opportunity_data.fecha_creacion.includes("/")) {
                            let [day, month, year] = opportunity_data.fecha_creacion.split("/");
                            payload.date = `${year}-${month}-${day}`;
                        } else {
                            let [year, month, day] = opportunity_data.fecha_creacion.split("-");
                            payload.date = `${year}-${month}-${day}`;
                        }


                        serviallAxios.post("opportunities/close_licitation", payload, {
                            timeout: TIMEOUTS.medium
                        })
                            .then(() => {
                                dispatch(setNavPage("gestor_oportunidades"));
                                navigate("/dashboard/gestor_oportunidades");
                            })
                            .catch((err) => {
                                dispatch(setAlert({
                                    open: true,
                                    severity: "error",
                                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                                }));
                            })
                            .finally(() => {
                                setDialogMessage("");
                                setLoading(false);
                            })
                    }
                })
                .catch((err) => {
                    dispatch(setAlert({
                        open: true,
                        severity: "error",
                        message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                    }));
                })
                .finally(() => {
                    if (!close) setDialogMessage("");
                })
        }


    }

    /**
     * Handle "no match" checkbox change event.
     * This function is called when the "no match" checkbox is toggled.
     * It sets the "noMatch" state to the new value of the checkbox (e.target.checked) and clears the "alternativeExists" state.
     *
     * @param {object} e - The event object of the checkbox change event.
     */
    const handleNoMatchCheckbox = (e) => {
        setNoOffer(e.target.checked);
    }

    /**
     * Reset "no match" and related states.
     *
     * @param {boolean} val - The new value for the "no match" state.
     */
    const noMatchReset = (val) => {
        setNoOffer(val);
        setMatchData({
            provider_sku: "",
            provider_name: ""
        });
    }


    /**
     * Create a checkbox element for the rows.
     *
     * @param {number} idx - The index of the row.
     * @param {string} colValue - The property (column) of the row that corresponds to the checkbox being created.
     * @param {boolean} value - The value of the checkbox (true or false).
     * @param {boolean} isOtherSelected - Whether the other checkbox in the row is selected.
     * @returns {JSX.Element} - The checkbox element.
     */
    const makeRowSelect = (idx, colValue, value, isOtherSelected) => {
        let disabled;
        if (value === true) {
            disabled = false
        } else {
            disabled = colValue === "igual" ? disableIgualSelect : colValue === "alternativo" ? disableAlternativoSelect : false
        }

        return (
            <Checkbox
                onChange={(e) => onCheckboxChange(e, idx, colValue)}
                checked={value}
                disabled={disabled || isOtherSelected || READ_ONLY}
            />
        )
    }

    /**
     * Create an icon to show some data when it is clicked.
     * @param {Object} itemData - The object with the item data.
     * @returns {JSX.Element} - The checkbox element.
     */
    const makeRowDetail = (itemData) => {
        return (
            <ItemsAdicionalesDialog
                item_data={itemData}
            />
        )
    }

    /**
     * Generate the rows for the datagrid.
     * This block of code maps the "pageState.rows" array to create new rows with checkboxes.
     * It also calls the "makeRowSelect" function to create checkboxes for each row.
     */
    const rows = [...pageState.rows].map((row, idx) => {
        let newRow = { ...row };
        newRow.igual_select = makeRowSelect(idx, "igual", row.igual, row.alternativo);
        newRow.alternativo_select = makeRowSelect(idx, "alternativo", row.alternativo, row.igual);
        if (newRow.item_data) {
            newRow.item_data = makeRowDetail(row.item_data);
        }
        return newRow;
    })

    /**
     * Finds the index of the closest pending page within the current history
     * and navigates to that page.
     * 
     * This function retrieves a sub-history starting from the current page to the end of the history.
     * It iterates through the sub-history and checks if all rows in each page are pending
     * (i.e., have no matching, equal, or alternate status).
     * If a pending page is found, it triggers the page change to that page.
     * 
     * @function getClosestPending
     * @returns {void}
     */
    const getClosestPending = () => {
        const subHistory = pageState.current.history.slice(pageState.current.page, pageState.current.history.length - 1)
        for (let i = 0; i < subHistory.length; i++) {
            const isPending = subHistory[i].every((row) => !row.alternativo && !row.igual && !row.no_match && !row.no_offer)
            if (isPending) {
                handlePageChange("", pageState.current.page + 1 + i)
                break
            }
        }

    }

    const normalRows = rows.filter((row) => row.extra_client_item !== true);
    const extraRows = rows.filter((row) => row.extra_client_item === true);
    const totalPages = pageState.current.history.length >= 0 ? [...Array(pageState.current.history.length).keys()] : [0];

    /* Edición datagrid */
    const initialCols = [
        {
            key: 'sku_cliente',
            name: t("ItemsLicitation:Header1"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'desc_cliente',
            name: t("ItemsLicitation:Header2"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'desc_complementaria',
            name: t("ItemsLicitation:Header27"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'marca_cliente',
            name: t("ItemsLicitation:Header3"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'pn_cliente',
            name: t("ItemsLicitation:Header4"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'um',
            name: t("ItemsLicitation:Header5"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'item_match',
            name: t("ItemsLicitation:Header11"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'desc_match',
            name: t("ItemsLicitation:Header12"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'marca_match',
            name: t("ItemsLicitation:Header13"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'pn_match',
            name: t("ItemsLicitation:Header14"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'criticidad_sa',
            name: t("ItemsLicitation:Header8"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'proveedor',
            name: t("ItemsLicitation:Header9"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'sku_proveedor',
            name: t("ItemsLicitation:Header10"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'costo_estimado',
            name: t("ItemsLicitation:Header19"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'moneda_costo',
            name: t("ItemsLicitation:Header20"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'precio_venta_sugerido',
            name: t("ItemsLicitation:Header21"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'moneda_precio_venta',
            name: t("ItemsLicitation:Header22"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'oportunidad_valorizada',
            name: t("ItemsLicitation:Header23"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'moneda_oportunidad_valorizada',
            name: t("ItemsLicitation:Header24"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'igual_select',
            reset: true, // Add a reset filters button
            name: t("ItemsLicitation:Header25"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            cellClass: "itemsLicitacion-datagrid-checkbox-cell",
            resizable: true,
        },
        {
            key: 'alternativo_select',
            no_filter: true,
            name: t("ItemsLicitation:Header26"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header2",
            cellClass: "itemsLicitacion-datagrid-checkbox-cell",
            resizable: true,
        },
        {
            key: 'um_match',
            name: t("ItemsLicitation:Header15"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },


        {
            key: 'consumo_anual',
            name: t("ItemsLicitation:Header6"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'cantidad_empaque',
            name: t("ItemsLicitation:Header16"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'factor_equivalencia_cte',
            name: t("ItemsLicitation:Header17"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'plazo_entrega',
            name: t("ItemsLicitation:Header18"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'similitud',
            name: t("ItemsLicitation:Header7"),
            width: MIN_COL_WIDTH,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        }
    ]

    const [datagridCols, setDatagridCols] = useState(initialCols)

    /* Vista agregada datagrid */

    /**
     * Function to format the redirect column in the data grid.
     *
     * @param {Object} row - The data row in the data grid.
     * @returns {React.ReactNode} The formatted content for the redirect column.
     */
    const redirectFormatter = (row) => {
        return (
            handleRedirectToSpecificItem ?
                <div>
                    <ReplyIcon onClick={() => handleRedirectToSpecificItem(row.row.itemsLicitationIndex)} className='vistaAgregada-redirectIcon' />
                </div>
                :
                <div>
                    <ReplyIcon className='vistaAgregada-redirectIcon-disable' />
                </div>
        )
    }

    /**
     * Function to format the checkbox column in the data grid.
     *
     * @param {Object} row - The data row in the data grid.
     * @returns {React.ReactNode} The formatted content for the checkbox column.
     */
    const checkboxFormatter = (row) => {
        return (
            <div>
                <Checkbox />
            </div>
        )
    }


    /**
     * Function to format the estado (status) column in the data grid.
     *
     * @param {Object} row - The data row in the data grid.
     * @returns {React.ReactNode} The formatted content for the estado (status) column.
     */
    const estadoFormatter = (row) => {
        let estado_revision = row.row.estado_revision;
        let status_icon;
        let status_text = estado_revision === "OK" ? "OK" : estado_revision
        switch (estado_revision) {
            case "OK":
                status_icon = <CircleIcon className={"gestorOportunidades-visibility-icon-ok"} />
                break
            case "Pend":
                status_icon = <CircleIcon className={"gestorOportunidades-visibility-icon-pend"} />
                break
            default:
                status_icon = ""
        }

        return (
            <Grid container className="gestorOportunidades-datagrid-status-container" >
                <Grid item xs={8}>
                    {status_text}
                </Grid>
                <Grid item xs={4}>
                    {status_icon}
                </Grid>
            </Grid>
        )
    }

    const initialStateAgregada = {
        itemsLicitationIndex: "",
        sku_cliente: "",
        estado_revision: "",
        desc_cliente: "",
        desc_complementaria: "",
        marca_cliente: "",
        pn_cliente: "",
        um: "",
        consumo_anual: "",
        similitud: "",
        criticidad_sa: "",
        proveedor: "",
        sku_proveedor: "",
        match_proveedor: "",
        item_match: "",
        desc_match: "",
        marca_match: "",
        pn_match: "",
        um_match: "",
        cantidad_empaque: "",
        //factor_equivalencia_cte: "",
        plazo_entrega: "",
        costo_estimado: "",
        moneda_costo: "",
        precio_venta_sugerido: "",
        moneda_precio_venta: "",
        oportunidad_valorizada: "",
        moneda_oportunidad_valorizada: ""
    }

    const initialColsAgregada = [
        {
            key: "itemsLicitationIndex",
            name: t("VistaAgregada:Header0"),
            width: 50,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            sortable: true,
            resizable: true,
        },
        {
            key: "redirect",
            no_filter: true,
            name: t("VistaAgregada:HeaderRedirect"),
            width: 50,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            formatter: redirectFormatter,
            resizable: true,
        },
        {
            key: 'estado_revision',
            name: t("VistaAgregada:Header2"),
            width: 150,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            formatter: estadoFormatter
        },
        {
            key: 'sku_cliente',
            name: t("VistaAgregada:Header3"),
            width: 100,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'desc_cliente',
            name: t("VistaAgregada:Header4"),
            width: 155,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'desc_complementaria',
            name: t("VistaAgregada:Header24"),
            width: 155,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'marca_cliente',
            name: t("VistaAgregada:Header5"),
            width: 120,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'pn_cliente',
            name: t("VistaAgregada:Header6"),
            width: 100,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'um',
            name: t("VistaAgregada:Header7"),
            width: 60,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'consumo_anual',
            name: t("VistaAgregada:Header8"),
            width: 130,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'num_alternativa',
            name: t("VistaAgregada:Header9"),
            width: 142,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'tipo_match',
            name: t("VistaAgregada:Header10"),
            width: 100,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'item_match',
            name: t("VistaAgregada:Header12"),
            width: 100,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'desc_match',
            name: t("VistaAgregada:Header13"),
            width: 150,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'marca_match',
            name: t("VistaAgregada:Header14"),
            width: 100,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'pn_match',
            name: t("VistaAgregada:Header15"),
            width: 100,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'criticidad_sa',
            name: t("VistaAgregada:Header26"),
            width: 100,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'um_match',
            name: t("VistaAgregada:Header16"),
            width: 100,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'cantidad_empaque',
            name: t("VistaAgregada:Header17"),
            width: 200,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        /*{
            key: 'factor_equivalencia_cte',
            name: t("VistaAgregada:Header18"),
            width: 210,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },*/
        {
            key: 'plazo_entrega',
            name: t("VistaAgregada:Header19"),
            width: 130,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'costo_estimado',
            name: t("VistaAgregada:Header20"),
            width: 130,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },
        {
            key: 'precio_venta_sugerido',
            name: t("VistaAgregada:Header21"),
            width: 200,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'oportunidad_valorizada',
            name: t("VistaAgregada:Header22"),
            width: 200,
            headerCellClass: "serviall-datagrid-header1",
            resizable: true,
            sortable: true,
        },

        {
            key: 'match_proveedor',
            name: t("VistaAgregada:Header23"),
            width: 100,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'sku_proveedor',
            name: t("VistaAgregada:Header25"),
            width: 100,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        {
            key: 'no_match',
            name: t("VistaAgregada:Header11"),
            width: 100,
            headerCellClass: "serviall-datagrid-header2",
            resizable: true,
            sortable: true,
        },
        /*{
            key: 'seleccion',
            reset: true,
            name: t("VistaAgregada:Header1"),
            width: 100,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            formatter: checkboxFormatter,
            resizable: true,
        },*/
    ]

    const [datagridColsAgregada, setDatagridColsAgregada] = useState(initialColsAgregada)

    useEffect(() => {
        if (!triggerPageChange) {
            const newRows = JSON.parse(JSON.stringify(pageState.rows));
            newRows.forEach((row) => {
                row.no_offer = noOffer

            })
            dispatch(setRows(newRows))
            // Update history on redux as well
            let updateHistoryPayload = {
                idx: pageState.current.page - 1,
                rows: newRows
            }
            dispatch(setHistoryById(updateHistoryPayload));
        }

    }, [noOffer])

    return (
        <Stack direction={"column"} spacing={4}>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Stack>
                    <Stack direction={"row"} spacing={2} alignItems={"center"} marginBottom={0}>
                        <Typography className="serviall-page-title1">
                            {t("ItemsLicitation:Title")}
                        </Typography>
                        {
                            READ_ONLY &&
                            <Typography className="itemsLicitacion-warning">
                                * {t(":ItemsLicitation:ReadMode")}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction={"column"} spacing={0.5}>
                        {opportunity_data &&
                            <Typography className="VerDetalleMaestros-property-title" display={"flex"} flexWrap={"wrap"} style={{ wordBreak: "break-word" }}>
                                <ArrowRightIcon className="serviall-color-main" />{opportunity_data.op_id}
                            </Typography>
                        }
                        {opportunity_data &&
                            <Typography className="VerDetalleMaestros-property-value">
                                {opportunity_data.nombre_cliente}
                            </Typography>
                        }
                    </Stack>



                </Stack>
                {
                    (datagridType !== 0 && !loading) && (<LicitationSummary t={t} kpis={kpis} kpiWidth={kpiWidth} />)
                }
            </Box>

            <Box display={"flex"} justifyContent={"space-between"} marginBottom={4}>
                <Stack direction={"row"} alignItems={"flex-end"}>
                    <Typography
                        id="downloadExcelButton"
                        className="GestorOportunidadesPage-Button-permi
                                    desc-Download-excel-button
                                    serviall-page-caption
                                    gestorOportunidades-download-button"
                        onClick={() => handleExportView()}
                    >
                        {t("ItemsLicitation:ExportButton")} <SystemUpdateAltIcon />
                    </Typography>
                </Stack>
            </Box>
            <Stack direction={"column"} marginBottom={4}>
                <Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"} marginBottom={4} gap={4}>
                    <ToggleButtonGroup
                        disabled={loading}
                        color="primary"
                        value={datagridType}
                        exclusive
                        onChange={handleDatagridTypeChange}
                    >
                        <ToggleButton className="itemsLicitacion-toggle-button" value={0}>{t("ItemsLicitation:TableMode1")}</ToggleButton>
                        <ToggleButton className="itemsLicitacion-toggle-button" value={1}>{t("ItemsLicitation:TableMode2")}</ToggleButton>
                    </ToggleButtonGroup>
                    <Box display={"flex"} gap={4} flexWrap={"wrap"}>
                        {
                            datagridType === 0 ?
                                <ColumnSelector setDatagridCols={setDatagridCols} initialState={initialCols} page="itemsLicitacionModoEdicion" />
                                :
                                <ColumnSelector setDatagridCols={setDatagridColsAgregada} initialState={initialColsAgregada} page="itemsLicitacionModoAgregado" render={true} />


                        }

                        <ResetFiltersBtn page={datagridType === 0 ? "itemsLicitacionModoEdicion" : "itemsLicitacionModoAgregado"} />

                    </Box>


                </Box>
                {
                    loading ?
                        <Box className="serviall-datagrid-loading-container">
                            <Stack direction={"column"} alignItems={"center"} spacing={2}>
                                <Typography className="serviall-h4"> {t("ItemsLicitation:LoadingStatus")}</Typography>
                                <CircularProgress />
                            </Stack>
                        </Box>
                        :
                        pageState.rows.length > 0 ?
                            <>
                                <Box className="serviall-datagrid-container" >
                                    {
                                        datagridType === 0 ?
                                            <EdicionItemsDatagrid
                                                initialCols={initialCols}
                                                setDatagridCols={setDatagridCols}
                                                datagridCols={datagridCols}
                                                t={t}
                                                rows={normalRows}
                                                loading={loading}
                                                enableCheckbox={enableCheckbox}
                                                noOffer={noOffer}
                                                handleNoMatchCheckbox={handleNoMatchCheckbox}
                                                datagridType={datagridType}
                                                READ_ONLY={READ_ONLY}
                                            />
                                            :
                                            <VistaAgregadaDatagrid
                                                t={t}
                                                handleRedirect={handleRedirectToSpecificItem}
                                                rows={normalRows}
                                                initialState={initialStateAgregada}
                                                initialCols={initialColsAgregada}
                                                setDatagridCols={setDatagridColsAgregada}
                                                datagridCols={datagridColsAgregada}

                                            />
                                    }
                                </Box>
                            </>
                            :
                            <Box className="serviall-datagrid-loading-container">
                                <Typography className="serviall-h4">{t("ItemsLicitation:NoItems")}</Typography>
                            </Box>
                }
            </Stack>
            {
                datagridType === 0 &&
                <Box className="itemsLicitacion-bottom-actions-container" sx={READ_ONLY && { justifyContent: "center" }} marginBottom={4}>
                    <Box display={"flex"} flexWrap={"wrap"} gap={4} alignItems={"center"}>

                        {!READ_ONLY &&
                            <AddAuxItemDialog
                                pageState={pageState}
                                makeRow={makeRowEditMode}
                                noMatchReset={noMatchReset}
                            />
                        }
                        <Button disabled={loading} className="serviall-button" onClick={getClosestPending}>{t("ItemsLicitation:GoToPending")}</Button>
                        <Pagination
                            variant="outlined"
                            color="primary"
                            disabled={loading}
                            value={pageState.current.page}
                            page={pageState.current.page}
                            count={READ_ONLY ? pageState.current.history.length : pages}
                            boundaryCount={0} siblingCount={0}
                            onChange={handlePageChange}
                            showLastButton
                            showFirstButton
                        />

                        <Box display="flex" alignItems={"center"} gap={4}>
                            <Box width={"2px"} py={"4"} style={{ height: "30px", borderLeft: "2px solid var(--mui-palette-serviall-currencyInputHover)" }}></Box>
                            <Select disabled={loading} value={pageState.current.page} variant="standard" onChange={(e) => handlePageChange("", e.target.value)} fullWidth disableUnderline={true}>
                                {
                                    totalPages.map((pageNumber) => {
                                        return (
                                            <MenuItem divider value={pageNumber + 1}>
                                                <Typography className='serviall-small'>
                                                    {pageNumber + 1}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Box>
                    </Box>
                    {
                        !READ_ONLY &&
                        <Button sx={{ alignSelf: "flex-start" }} disabled={loading} onClick={() => { handleSaveState(false) }} className="serviall-button">
                            {t("ItemsLicitation:SaveButton")}
                        </Button>
                    }
                </Box>
            }

            {(datagridType === 0 && !loading && extraRows.length > 0) &&
                <Stack direction={"column"}>
                    <Typography  >Homológos adicionales</Typography>
                    <ItemsAdicionalesDatagrid
                        t={t}
                        rows={extraRows}
                    />
                </Stack>
            }


            <Box display={"flex"} justifyContent={"space-between"}>
                <Typography className="serviall-back-text" onClick={() => navigate(-1)}>
                    <ArrowBackIcon /> {t("ItemsLicitation:GoBackButton")}
                </Typography>
                {
                    !READ_ONLY && datagridType === 0 &&
                    <Button disabled={loading} onClick={() => { handleSaveState(true) }} className="serviall-button-success">
                        {t("ItemsLicitation:CompleteButton")}
                    </Button>
                }
            </Box>
            <ServiallDialog
                open={Boolean(dialogMessage)}
                status={"loading"}
                loading_msg={dialogMessage}
            />

        </Stack>
    )
}

ItemsLicitacionPage.displayName = "ItemsLicitacionPage";

export default ItemsLicitacionPage;
