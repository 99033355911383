import { Box, Grid, Paper, Stack,Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import KpiBox from "../../components/KpiBox";
// Icons
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CircleIcon from '@mui/icons-material/Circle';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import serviallAxios from "../../axiosConfig";
// Styles
import "./VerDetalleOportunidadPage.css"
import { useTranslation } from 'react-i18next';
import { TIMEOUTS } from "../../utils";
import { useDispatch } from "react-redux";
import { setAlert } from "../../features/navigationSlice";
import { CustomAlertMessage } from "../../utils";
/**
 * A React component that renders the 'Ver Detalle' for the opportunity page.
 * @typedef VerDetalleOportunidadPage
 * @returns A page with the details for the opportunity items.
 */

const VerDetalleOportunidadPage = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const renderData = location.state;
    const { t } = useTranslation()
    const [kpis, setKpis] = useState({})
    

    const kpiWidth = "150px"

    /**
     * Handle the download of the original file for the opportunity with the given ID, RUT, and creation date.
     * It shows a success alert with a download message and then makes a GET request to the server
     * to download the original file associated with the opportunity in Excel format.
     * 
     * @function
     * @param {string} op_id - The opportunity ID.
     * @param {string} rut - The RUT of the client associated with the opportunity.
     * @param {string} creation_date - The creation date of the opportunity.
     * @returns {void}
     */
    const handleDownloadOriginal = (op_id, rut, creation_date) => {

        dispatch(setAlert({
            open: true,
            severity: "info",
            message: t("Common:Download")
        }))

        let [day, month, year] = creation_date.split("/");
        serviallAxios.get(`opportunities/get_licitation/${op_id}/${rut}/${year}-${month}-${day}`, {
            timeout: TIMEOUTS.small,
            responseType: "blob"
        }).then((res) => {
            const href = URL.createObjectURL(res.data);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", op_id + "_" + rut + "_" + creation_date + ".xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch((err) => {
            dispatch(setAlert({
                open: true,
                severity: "error",
                message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
            }))
        })

    }

    /** 
    * Useeffect for the the case when user goes to the link by changing the url manually to this page.
    * @return {void}
    */
    useEffect(() => {
        if (!renderData) {
            navigate("/dashboard/home");
        }
    }, [renderData])

    /**
     * Fetches key performance indicators (KPIs) for a specific licitation.
     *
     * This effect is triggered on initial component mount and when certain state values change.
     * It fetches KPI data for a specific licitation using the provided `renderData` object.
     * The KPIs are then set in the component's state.
     *
     * @effect
     * @returns {void}
     */
    useEffect(() => {
        if (renderData) {
            const params = {
                op_id: renderData.op_id
            }
            serviallAxios.get(`utilities/get_licitation_kpis`, { params }, {
                timeout: TIMEOUTS.medium,
            }).then((res) => {
                setKpis(res.data.data)
            }).catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
        }
    }, [])

    /**
     * Handle the download of the processed file for the opportunity with the given ID, RUT, and creation date.
     * It shows a success alert with a download message and then makes a GET request to the server
     * to download the processed file associated with the opportunity in Excel format.
     * 
     * @function
     * @param {string} op_id - The opportunity ID.
     * @param {string} rut - The RUT of the client associated with the opportunity.
     * @param {string} creation_date - The creation date of the opportunity.
     * @returns {void}
     */
    const handleDownloadProcessed = (op_id, rut, creation_date) => {

        dispatch(setAlert({
            open: true,
            severity: "info",
            message: t("Common:Download")
        }))

        let [day, month, year] = creation_date.includes("-") ? creation_date.split("-") : creation_date.split("/");
        serviallAxios.get(`opportunities/aggregate_view/sheet/${op_id}/${rut}/${year}-${month}-${day}`, {
            timeout: TIMEOUTS.small,
            responseType: "blob"
        }).then((res) => {
            const href = URL.createObjectURL(res.data);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", op_id + "_" + rut + "_" + creation_date + "Lista_Agregada.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch((err) => {
            dispatch(setAlert({
                open: true,
                severity: "error",
                message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
            }))
        })

    }

    let status_classname;
    switch (renderData ? renderData.status : null) {
        case "Done":
            status_classname = "serviall-color-success"
            break
        case "Pending":
            status_classname = "serviall-main-color"
            break
        case "CM":
            status_classname = "serviall-color-warning"
            break
        default:
            status_classname = ""
    }

    return (
        <Stack direction={"column"} spacing={4}>
            <Stack direction={"column"} spacing={1}>
                <Typography className="serviall-page-title1">
                    {t("VerDetallePage:Title")}
                </Typography>
            </Stack>
            {renderData &&
                <Paper className="verDetalleOportunidad-paper-container" elevation={6}>
                    <Grid display={"flex"} flexDirection={{ xs: "column", md: "row" }}>
                        <Grid width={{ xs: "100%", lg: "85%" }}>
                            <Stack direction={"column"} marginBottom={2} width={"100%"}>
                                <Typography className="verDetalleOportunidad-kpiTitle">
                                    <ArrowRightIcon className="serviall-main-color" /> {renderData.op_id}
                                </Typography>
                                <Typography>
                                    <b>{t("VerDetallePage:Client")}</b> {renderData.nombre_cliente}
                                </Typography>
                                <Typography className="verDetalleOportunidad-kpiDescription">
                                    {renderData.descripcion}
                                </Typography>
                            </Stack>
                            <Typography display={{ xs: "flex", md: "none" }} alignItems={"center"} marginBottom={"1rem"}>
                                <b>{t("VerDetallePage:Status")}</b> &nbsp; &nbsp; {renderData.status} &nbsp;
                                <CircleIcon fontSize="small" className={status_classname} />
                            </Typography>
                            <Box display={"flex"} flexDirection={{ xs: "column", sm: "row" }} flexWrap={"wrap"} gap={{ xs: "1rem", lg: "0" }}>
                                <Stack direction={"column"} width={{ xs: "100%", sm: "45%", md: "25%" }} justifyContent={"space-between"} marginBottom={{ xs: 0, lg: "1rem" }}>
                                    <Typography className="verDetalleOportunidad-kpiTitle">
                                        {t("VerDetallePage:OpportunityType")}
                                    </Typography>
                                    <Typography>
                                        {renderData.tipo}
                                    </Typography>
                                </Stack>
                                <Stack direction={"column"} width={{ xs: "100%", sm: "45%", md: "25%" }} justifyContent={"space-between"} marginBottom={{ xs: 0, lg: "1rem" }}>
                                    <Typography className="verDetalleOportunidad-kpiTitle">
                                        {t("VerDetallePage:AssignedExecutive")}
                                    </Typography>
                                    <Typography>
                                        {renderData.responsable}
                                    </Typography>
                                </Stack>
                                <Stack direction={"column"} width={{ xs: "100%", sm: "45%", md: "25%" }} justifyContent={"space-between"} marginBottom={{ xs: 0, lg: "1rem" }}>
                                    <Typography className="verDetalleOportunidad-kpiTitle">
                                        {t("VerDetallePage:CreationDate")}
                                    </Typography>
                                    <Typography>
                                        {renderData.fecha_creacion}
                                    </Typography>
                                </Stack>
                                <Stack direction={"column"} width={{ xs: "100%", sm: "45%", md: "25%" }} justifyContent={"space-between"} marginBottom={{ xs: 0, lg: "1rem" }}>
                                    <Typography className="verDetalleOportunidad-kpiTitle">
                                        {t("VerDetallePage:DueDate")}
                                    </Typography>
                                    <Typography>
                                        {renderData.fecha_cierre}
                                    </Typography>
                                </Stack>
                                <Stack direction={"column"} width={{ xs: "100%", sm: "45%", md: "25%" }} justifyContent={"space-between"} marginBottom={{ xs: 0, lg: "1rem" }}>
                                    <Typography className="verDetalleOportunidad-kpiTitle">
                                        {t("VerDetallePage:Lines")}
                                    </Typography>
                                    <Typography>
                                        {kpis.lines}
                                    </Typography>
                                </Stack>
                                <Stack direction={"column"} width={{ xs: "100%", sm: "45%", md: "25%" }} justifyContent={"space-between"} marginBottom={{ xs: 0, lg: "1rem" }}>
                                    <Typography className="verDetalleOportunidad-kpiTitle">
                                        {t("VerDetallePage:Units")}
                                    </Typography>
                                    <Typography>
                                        {kpis.units}
                                    </Typography>
                                </Stack>
                                <Stack direction={"column"} width={{ xs: "100%", sm: "45%", md: "25%" }} justifyContent={"space-between"} marginBottom={{ xs: 0, lg: "1rem" }}>
                                    <Typography className="verDetalleOportunidad-kpiTitle">
                                        {t("VerDetallePage:NegocioValorizado")}
                                    </Typography>
                                    <Typography>
                                        ${kpis && kpis.profit !== undefined ? kpis.profit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""}
                                    </Typography>
                                </Stack>
                            </Box>
                            <Box display={{ xs: "flex", md: "none" }} flexWrap={"wrap"} gap={{ xs: "1rem", lg: "0" }} marginTop={"1rem"}>
                                <Box display={"flex"} alignItems={"center"} width={{ xs: "100%", sm: "45%", md: "25%" }} gap={1} onClick={() => handleDownloadOriginal(renderData.op_id, renderData.rut_cliente, renderData.fecha_creacion)}>
                                    <SystemUpdateAltIcon className="verDetalleOportunidad-download-icon" />
                                    <Typography width={"100%"}> {t("VerDetallePage:DownloadButton")} </Typography>
                                </Box>
                                <Box display={"flex"} width={{ xs: "100%", sm: "45%", md: "25%" }} alignItems={"center"} gap={1} >
                                    <SystemUpdateAltIcon className="verDetalleOportunidad-download-icon" onClick={() => handleDownloadProcessed(renderData.op_id, renderData.rut_cliente, renderData.fecha_creacion)} />
                                    <Typography>  {t("VerDetallePage:DownloadButton2")} </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item display={{ xs: "none", md: "block" }}>
                            <Stack direction={"column"} spacing={6} >
                                <Typography display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
                                    <b>{t("VerDetallePage:Status")}</b> &nbsp; &nbsp; {renderData.status} &nbsp;
                                    <CircleIcon fontSize="small" className={status_classname} />
                                </Typography>
                                <Stack className="verDetalleOportunidad-actions-container" direction={"column"} spacing={2}>
                                    <Box gap={1} className="verDetalleOportunidad-download-container" onClick={() => handleDownloadOriginal(renderData.op_id, renderData.rut_cliente, renderData.fecha_creacion)}>
                                        <SystemUpdateAltIcon sx={{ marginLeft: "1rem" }} className="verDetalleOportunidad-download-icon" />
                                        <Typography> {t("VerDetallePage:DownloadButton")} </Typography>
                                    </Box>
                                    <Box gap={1} className="verDetalleOportunidad-download-container">
                                        <SystemUpdateAltIcon sx={{ marginLeft: "1rem" }} className="verDetalleOportunidad-download-icon" onClick={() => handleDownloadProcessed(renderData.op_id, renderData.rut_cliente, renderData.fecha_creacion)} />
                                        <Typography>  {t("VerDetallePage:DownloadButton2")} </Typography>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
            }
            <Paper className="verDetalleOportunidad-paper-container" elevation={6}>
                <Grid display={"flex"} flexWrap={"wrap"} width={"100%"} flexDirection={{ xs: "columnm", lg: "row" }} justifyContent={{ lg: "space-evenly" }} gap={{ xs: "1rem", lg: "0" }}>
                    <Grid item className="verDetalle-grid-separator" width={{ xs: "100%", lg: "fit-content" }}>
                        <Stack display={"flex"} flexDirection={"column"} alignItems={"center"} marginRight={{ xs: 0, lg: "8px" }} marginBottom={{ xs: "1rem", lg: 0 }}>
                            <Typography className="verDetalleOportunidad-KpiBox-container-title">{t("VerDetallePage:GridTitle")}</Typography>
                            <Grid display={"flex"} flexDirection={{ xs: "column", tablet: "row" }}>
                                <Grid item width={"150px"}>
                                    <KpiBox value={kpis.lines_reviewed} size={kpiWidth} text={t("VerDetallePage:ReviewedLines")} tooltipText={t("VerDetallePage:Tooltip1")} percentage={true} />
                                    <Stack direction={"row"} >
                                        <KpiBox value={kpis.precision_bn} size={kpiWidth} variant={"tiny"} text={t("VerDetallePage:Precision")} percentage={true} tooltipText={t("VerDetallePage:Tooltip6")}/>
                                        <KpiBox value={kpis.calibration} size={kpiWidth} variant={"tiny"} text={t("VerDetallePage:RequiresCalibration")} percentage={true} tooltipText={t("VerDetallePage:Tooltip7")}/>
                                    </Stack>
                                </Grid>
                                <Grid item height={"100%"}>
                                    <KpiBox value={kpis.efective_match} size={kpiWidth} text={t("VerDetallePage:EffectiveMatch")} tooltipText={t("VerDetallePage:Tooltip2")} percentage={true} />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                    <Grid item className="verDetalle-grid-separator" width={{ xs: "100%", lg: "fit-content" }}>
                        <Stack display={"flex"} flexDirection={"column"} alignItems={"center"} marginRight={{ xs: 0, lg: "8px" }} marginBottom={{ xs: "1rem", lg: 0 }}>
                            <Typography className="verDetalleOportunidad-KpiBox-container-title">{t("VerDetallePage:GridTitle2")}</Typography>
                            <Box display={"flex"} flexDirection={{ xs: "column", tablet: "row" }} >
                                <KpiBox value={kpis.cni_matches} size={kpiWidth} text={t("VerDetallePage:Match1")} tooltipText={t("VerDetallePage:Tooltip3")} percentage={true}/>
                                <KpiBox value={kpis.duplicates} size={kpiWidth} text={t("VerDetallePage:Match2")} tooltipText={t("VerDetallePage:Tooltip4")} percentage={true}/>
                                <KpiBox value={kpis.more_alternatives} size={kpiWidth} text={t("VerDetallePage:Match3")} tooltipText={t("VerDetallePage:Tooltip5")} percentage={true}/>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item width={{ xs: "100%", lg: "fit-content" }} >
                        <Stack display={"flex"} flexDirection={"column"} alignItems={"center"}>
                            <Typography className="verDetalleOportunidad-KpiBox-container-title">{t("VerDetallePage:GridTitle3")}</Typography>
                            <Box display={"flex"} flexDirection={{ xs: "column", tablet: "row" }}>
                                <KpiBox value={kpis.items_80_units} size={kpiWidth} text={t("VerDetallePage:Concentration1")} tooltipText={t("VerDetallePage:Tooltip8")} percentage={true}/>
                                <KpiBox value={kpis.items_80_profit} size={kpiWidth} text={t("VerDetallePage:Concentration2")} tooltipText={t("VerDetallePage:Tooltip9")} percentage={true}/>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Paper>
            <Typography className="serviall-back-text" onClick={() => navigate(-1)}>
                <ArrowBackIcon />{t("VerDetallePage:GoBackButton")}
            </Typography>
        </Stack>
    )
}

VerDetalleOportunidadPage.displayName = "VerDetalleOportunidadPage";

export default VerDetalleOportunidadPage;