import { Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import HomeCard from "../../components/HomeCard";
import { Box } from "@mui/system";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./GestorOportunidadesPage.css";
import GestorOportunidadesPageDatagrid from './GestorOportunidadesPageDatagrid';
import serviallAxios from "../../axiosConfig";
import { TIMEOUTS } from "../../utils";
import ResetFiltersBtn from "../../components/ResetFiltersBtn";
import { useDispatch } from "react-redux";
import { setAlert } from "../../features/navigationSlice";
import { CustomAlertMessage } from "../../utils";
import ColumnSelector from "../../components/ColumnSelector";
import { useEffect, useState } from "react";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

/**
 * A React component that renders the 'Gestor de Oportunidades' page.
 * @typedef GestorOportunidadesPage
 * @returns A page with the Gestor de Oportunidades components
 */


const GestorOportunidadesPage = () => {

    const COL_WIDTH = 100;
    const { t } = useTranslation();
    const theme = useSelector((state) => state.settings.theme);
    const themeVar = useTheme();
    const oportunityCardPrimaryColor = theme === "light" ? themeVar.colorSchemes.light.palette.serviall.main : themeVar.colorSchemes.dark.palette.serviall.main
    const oportunityCardSecondaryColor = theme === "light" ? themeVar.colorSchemes.light.palette.serviall.darkRed1 : themeVar.colorSchemes.dark.palette.serviall.darkRed1

    const remainingMatchesCardPrimaryColor = theme === "light" ? themeVar.colorSchemes.light.palette.serviall.gray1 : themeVar.colorSchemes.dark.palette.serviall.gray1
    const remainingMatchesCardSecondaryColor = theme === "light" ? themeVar.colorSchemes.light.palette.serviall.darkGray : themeVar.colorSchemes.dark.palette.serviall.darkGray
    const gestorOportunidadesPagePermissions = useSelector((state) => state.permissions.componentPermissions.GestorOportunidadesPage);

    const [openOps, setOpenOps] = useState(null)
    const [skuRevision, setSkuRevision] = useState(null)

    const dispatch = useDispatch()
    /**
     * Handles the download action for the 'all_licitations.xlsx' file.
     * Downloads the file via a GET request to the server and creates a downloadable link.
     * @returns {void}
     */
    const handleDownload = () => {
        serviallAxios.get("opportunities/all_licitations_sheet", { 
            timeout: TIMEOUTS.small,
            responseType: "blob" 
        }).then((res) => {
            const href = URL.createObjectURL(res.data);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", "all_licitations.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }));
            })
    }

    /**
     * A React hook that fetches the kpis for total open opportunities and
     * total pending lines.
     * @returns {void}
     */

    useEffect(() => {
        serviallAxios.get("opportunities/opportunities_kpis",{
            timeout: TIMEOUTS.small
        }).then((res) => {
            setOpenOps(res.data.open_ops)
            setSkuRevision(res.data.sku_revision)
        })
    }, [])

    const initialState = [
        {
            key: 'status',
            name: t("GestorOportunidadesPage:DataGridHeader1"),
            width: COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true
        },
        {
            key: 'op_id',
            name: t("GestorOportunidadesPage:DataGridHeader2"),
            width: COL_WIDTH - 40,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'nombre_cliente',
            name: t("GestorOportunidadesPage:DataGridHeader3"),
            width: COL_WIDTH + 50,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'descripcion',
            name: t("GestorOportunidadesPage:DataGridHeader4"),
            width: COL_WIDTH + 50,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'tipo',
            name: t("GestorOportunidadesPage:DataGridHeader5"),
            width: COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'fecha_creacion',
            name: t("GestorOportunidadesPage:DataGridHeader6"),
            width: COL_WIDTH + 50,
            headerCellClass: "serviall-datagrid-header2",
            type: "date",
            resizable: true,
            sortable: true,
        },
        {
            key: 'fecha_cierre',
            name: t("GestorOportunidadesPage:DataGridHeader7"),
            width: COL_WIDTH + 30,
            headerCellClass: "serviall-datagrid-header1",
            type: "date",
            resizable: true,
            sortable: true,
        },
        {
            key: 'lineas',
            name: t("GestorOportunidadesPage:DataGridHeader8"),
            width: COL_WIDTH,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'revisados',
            name: t("GestorOportunidadesPage:DataGridHeader9"),
            width: COL_WIDTH + 20,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'match_efectivo',
            name: t("GestorOportunidadesPage:DataGridHeader10"),
            width: COL_WIDTH + 50,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'negocio_valorizado',
            name: t("GestorOportunidadesPage:DataGridHeader11"),
            width: COL_WIDTH + 60,
            headerCellClass: "serviall-datagrid-header1",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'responsable',
            name: t("GestorOportunidadesPage:DataGridHeader12"),
            width: COL_WIDTH + 30,
            headerCellClass: "serviall-datagrid-header2",
            type: "text",
            resizable: true,
            sortable: true,
        },
        {
            key: 'actions',
            reset: true,
            name: t("GestorOportunidadesPage:DataGridHeader13"),
            width: COL_WIDTH,
            headerCellClass: "serviall-datagrid-header1",
        },
    ]
    const [datagridCols, setDatagridCols] = useState(initialState)

    return (
        <Stack direction={"column"} spacing={4}>
            <Grid className="gestorOportunidades-data-container" container>
                <Grid item width={{xs: "100%", lg: "40%"}} marginBottom={{xs: "2rem", md: 0}} >
                    <Box>
                        <Typography className="serviall-page-title1">{t("GestorOportunidadesPage:Title")}</Typography>
                        <Typography className="serviall-page-title2">{t("GestorOportunidadesPage:Caption")}</Typography>
                    </Box>
                </Grid>
                <Grid item width={{xs: "100%", lg: "60%"}}>
                    <Stack direction={"row"} spacing={{xs: 0, md: 4}} gap={{xs: 2}} flexWrap={{xs: "wrap", sm: "nowrap"}}>
                        <HomeCard width="100%" kpi={openOps} primaryText={t("GestorOportunidadesPage:Card1Title")} secondaryText={t("GestorOportunidadesPage:Card1Caption")} primaryColor={oportunityCardPrimaryColor} secondaryColor={oportunityCardSecondaryColor} iconColor="black" tooltipText={t("GestorOportunidadesPage:CardTooltip")}/>
                        <HomeCard width="100%" kpi={skuRevision} primaryText={t("GestorOportunidadesPage:Card2Title")} secondaryText={t("GestorOportunidadesPage:Card2Caption")} primaryColor={remainingMatchesCardPrimaryColor} secondaryColor={remainingMatchesCardSecondaryColor} iconColor="white" tooltipText={t("GestorOportunidadesPage:Card2Tooltip")}/>
                    </Stack>
                </Grid>
            </Grid>
            <Box className="gestorOportunidades-actions-container" gap={4} flexWrap={"wrap"}>
                <ColumnSelector initialState={initialState} setDatagridCols={setDatagridCols} page={"gestorOportunidades"} />
                <Box display={"flex"} gap={4} flexWrap={"wrap"}>
                    {
                            gestorOportunidadesPagePermissions.Button.downloadExcelButton !== "hidden" &&
                            <Typography
                                sx={{alignSelf:"flex-end"}}
                                id="downloadExcelButton"
                                className="GestorOportunidadesPage-Button-permi
                                    desc-Download-excel-button
                                    serviall-page-caption
                                    gestorOportunidades-download-button"
                                onClick={() => handleDownload()}
                            >
                                {t("GestorOportunidadesPage:DownloadButton")} <SystemUpdateAltIcon />
                            </Typography>
                        }
                    <ResetFiltersBtn page={"gestorOportunidades"}/>
                </Box>
            </Box>
            <GestorOportunidadesPageDatagrid datagridCols={datagridCols} />
        </Stack>
    )
}

GestorOportunidadesPage.displayName = "GestorOportunidadesPage";


export default GestorOportunidadesPage;
