import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Divider, Grid, Menu, MenuItem, Stack, SvgIcon, Typography } from '@mui/material'
import { Box } from '@mui/system';
//import serviallLogo from "../../assets/Images/Serviall-logo.png";
import serviallLogo from "../../assets/Images/Serviall-Logo-Sandia.png";
import HomeIcon from '@mui/icons-material/Home';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsIcon from '@mui/icons-material/Settings';
import account from "../../assets/icons/account.png"
import { setAlert, setEmail, setId, setLoggingOut } from '../../features/navigationSlice';
import ThemeSwitcher from "../../components/ThemeSwitcher";
import LangSwitcher from '../../components/LangSwitcher';
import { setComponentPermissions, setGroup } from '../../features/permissionsSlice';
import serviallAxios from '../../axiosConfig';
import "./Dashboard.css";
import preview from "../../assets/icons/preview.png"
import people from "../../assets/icons/people.png"
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { TIMEOUTS } from '../../utils';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { setSeenNotification, setCounter, setNotificationArray } from '../../features/notificationSlice';
import { setCurrentLicitation } from '../../features/opportunitySlice';
import { CustomAlertMessage } from '../../utils';

/**
 * A React component that renders the 'Dashboard' page.
 * @typedef Dashboard
 * @returns A page with the Dashboard page components
 */

function Dashboard() {

    // States, variables and hook calls
    const { t } = useTranslation();
    const navigate = useNavigate();
    const lang = useSelector((state) => state.settings.language);
    const dashboardPermissions = useSelector((state) => state.permissions.componentPermissions.Dashboard);
    const displayState = useSelector((state) => state.display);
    const notificationState = useSelector((state) => state.notification);
    const { pathname } = useLocation();
    /**
     * Event handler for saving the session state.
     * It sends a POST request to the server to save the current display state in the database.
     * @returns {void}
     */
    const handleSaveSession = () => {
        const payload = {
            display_state: JSON.stringify(displayState)
        }

        serviallAxios.post("auth/session", payload)
            .then((res) => {
                console.log("Respuesta de session: ", res);
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)

                }));
            })
    }

    // Save session only when page closes
    window.addEventListener("pagehide", handleSaveSession);

    window.onbeforeunload = handleSaveSession;

    const email = useSelector((state => state.navigation.email));
    const currentLicitation = useSelector((state) => state.opportunity.currentLicitation)
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [triggerRedirect, setTriggerRedirect] = useState({
        page: "",
        message: ""
    });
    const open = Boolean(anchorEl);

    /**
     * A useEffect hook to validate the user's token and handle session maintenance.
     * If the token is valid, the user is redirected to their last state within the application.
     * Also, in case of a page refresh, the user's session should be maintained.
     * @returns {void}
     */
    useEffect(() => {
        setLoading(true);
        serviallAxios.get("/auth/session", {
            timeout: TIMEOUTS.small
        })
            .then(res => {
                // Si el request es exitoso, guardar data de sesión en la redux store, no en cookies.
                // Agregar interceptor (el mismo del login, pero este se agrega en caso de que el usuario entre desde la url)
                // serviallAxios.interceptors.response.use(
                //     res => {
                //         return res
                //     },
                //     err => {
                //         if (err.response) {
                //             if (err.response.status === 401) {
                //                 serviallAxios.interceptors.response.clear();
                //                 setTriggerRedirect({
                //                     page: "/",
                //                     message: t("Dashboard:SessionExpired")
                //                 });
                //             }

                //             if (err.response.status > 401) {
                //                 dispatch(setAlert({
                //                     open: true,
                //                     severity: "error",
                //                     message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)

                //                 }))
                //             }
                //         }

                //         return Promise.reject(err);
                //     }
                // )
                console.log("session", res.data)
                const [user_data, permissions, display_state] = res.data.session;
                /* dispatch(setDisplayState(JSON.parse(display_state.replaceAll("'", '"')))); // Had to replace ' for " for the json to work */
                dispatch(setEmail(user_data.email));
                localStorage.setItem("id", user_data.id)
                dispatch(setId(user_data.id));
                dispatch(setGroup(user_data.group));
                console.log("Permisos: ", permissions);
                if (permissions) {
                    dispatch(setComponentPermissions(permissions));
                }

                // dispatch(setGroupPermissions(res.data.groupPermissions));
            })
            .catch(e => {
                // Si la request falla, redireccionar a página login y remover el token de acceso no funcional
                console.log("e", e)
                // dispatch(setAlert({
                //     open: true,
                //     severity: "error",
                //     message: e.response ? e.response.data.detail : CustomAlertMessage(e.code)

                // }));
                window.localStorage.removeItem("configPage");
                window.localStorage.removeItem('access_token');
                window.localStorage.removeItem('refresh_token');
                setTriggerRedirect({
                    page: "/",
                    message: ""
                });
            }).finally(() => {
                setLoading(false);
            })
    }, [])

    /**
     * A useEffect hook to handle redirection to the login page in case of triggerRedirect state change.
     * @returns {void}
     */
    useEffect(() => {
        if (triggerRedirect.message.length > 0) {
            alert(triggerRedirect.message);
        }

        if (triggerRedirect.page.length > 0) {
            navigate(triggerRedirect.page);
        }
    }, [triggerRedirect, navigate])

    /**
     * Event handler to dismiss the sidebar by removing a classname from it.
     * @returns {void}
     */
    const dismissSidebar = () => {
        const sidebar = document.querySelector(".dashboard-responsive-sidebar-container")
        if (sidebar) {
            sidebar.classList.remove("visible")
        }
    }

    /**
     * Event handler for opening the user menu.
     * It sets the anchor element to the current target to show the menu.
     * @param {Object} e - The event object.
     * @returns {void}
     */
    const handleMenuOpen = (e) => {
        setAnchorEl(e.currentTarget);
    }

    /**
     * Event handler for closing the user menu.
     * It sets the anchor element to null to close the menu.
     * @function handleMenuClose
     * @returns {void}
     */
    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    /**
     * Event handler for navigating to the 'Configuracion' page and 'cuenta' sub-page.
     * It sets the current page to 'configuracion' and configures the sub-page to 'cuenta'.
     * @returns {void}
     */
    const handleMenuConfiguracion = () => {
        setAnchorEl(null);
        navigate("/dashboard/configuracion/cuenta");
    }

    /**
     * Event handler for user logout.
     * It sends a GET request to the server to perform logout and redirects the user to the login page.
     * @returns {void}
     */
    const handleLogout = () => {
        dispatch(setLoggingOut(true))
        setAnchorEl(null);
        if (currentLicitation) {
            serviallAxios.get(`opportunities/active_user/${currentLicitation}`, {
                timeout: TIMEOUTS.small
            })
                .then((res) => {
                    /* This means an user wants to logout in ItemsLicitation view. So we should remove it as active user */
                    if (res.data.result && parseInt(localStorage.getItem("id")) === res.data.result[0]) {
                        serviallAxios.put(`opportunities/unset_active_user/${currentLicitation}`, {}, {
                            timeout: TIMEOUTS.small
                        })
                            .then((res) => dispatch(setCurrentLicitation("")))
                            .catch((err) => { console.log(err) })
                            .finally(() => {
                                /* After removing active user -> logout */
                                serviallAxios.get("auth/logout", {
                                    timeout: TIMEOUTS.small
                                })
                                    .then((res) => console.log(res))
                                    .catch((err) => console.log(err))
                                    .finally(() => {
                                        serviallAxios.interceptors.response.clear();
                                        localStorage.setItem("page", "home");
                                        localStorage.removeItem("configPage");
                                        localStorage.removeItem("access_token")
                                        localStorage.removeItem("refresh_token")
                                        setTriggerRedirect({
                                            page: "/",
                                            message: ""
                                        });
                                    })
                            })
                    }
                    else {
                        serviallAxios.get("auth/logout", {
                            timeout: TIMEOUTS.small
                        })
                            .then((res) => console.log(res))
                            .catch((err) => console.log(err))
                            .finally(() => {
                                serviallAxios.interceptors.response.clear();
                                localStorage.setItem("page", "home");
                                localStorage.removeItem("configPage");
                                localStorage.removeItem("access_token")
                                localStorage.removeItem("refresh_token")
                                setTriggerRedirect({
                                    page: "/",
                                    message: ""
                                });
                            })
                    }
                })
                .catch((err) => {
                    dispatch(setAlert({
                        open: true,
                        severity: "error",
                        message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)

                    }));
                    serviallAxios.get("auth/logout", {
                        timeout: TIMEOUTS.small
                    })
                        .then((res) => console.log(res))
                        .catch((err) => console.log(err))
                        .finally(() => {
                            serviallAxios.interceptors.response.clear();
                            localStorage.setItem("page", "home");
                            localStorage.removeItem("configPage");
                            localStorage.removeItem("access_token")
                            localStorage.removeItem("refresh_token")
                            setTriggerRedirect({
                                page: "/",
                                message: ""
                            });
                        })
                })
        }
        else {
            serviallAxios.get("auth/logout", {
                timeout: TIMEOUTS.small
            })
                .then((res) => console.log(res))
                .catch((err) => console.log(err))
                .finally(() => {
                    serviallAxios.interceptors.response.clear();
                    localStorage.setItem("page", "home");
                    localStorage.removeItem("configPage");
                    localStorage.removeItem("access_token")
                    localStorage.removeItem("refresh_token")
                    setTriggerRedirect({
                        page: "/",
                        message: ""
                    });
                })
        }



    }

    /**
     * Helper function to format the current date in the user's language.
     * @returns {string} The formatted date string.
     */
    const dateHelper = () => {
        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        var today = new Date();
        var todayString = today.toLocaleDateString(lang, options);
        return todayString.charAt(0).toUpperCase() + todayString.slice(1);
    }

    /**
     * A helper component that renders a toggle button with an icon and text.
     * @typedef {Object} Props
     * @property {JSX.Element} icon - The icon element to be displayed in the toggle button.
     * @property {string} text - The text to be displayed next to the icon in the toggle button.
     * @param {Props} props - The component props.
     * @returns {JSX.Element} A toggle button with the specified icon and text.
     */
    const ServiallToggleButton = ({ icon, text }) => {
        return (
            <Grid container sx={{ alignItems: "center", pointerEvents: "none" }}>
                <Grid item sx={{ display: "flex", justifyContent: "center" }} xs={4}>
                    {icon}
                </Grid>
                <Grid item xs={8} sx={{ display: "flex", textAlign: "left" }}>
                    <Typography className='dashboard-option-text'>
                        {text}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    /**
     * Function to toggle the visibility of the sidebar.
     * It finds the sidebar element by class name and toggles the 'visible' class.
     * @function toggleSidebar
     * @returns {void}
     */
    const toggleSidebar = () => {
        const sidebar = document.querySelector(".dashboard-responsive-sidebar-container")
        sidebar.classList.toggle("visible")
    }

    const toggleSidebarDesktop = () => {
        const sidebar = document.querySelector(".dashboard-sidebar-container")
        const container = document.querySelector('.dashboard-container')
        container.classList.toggle('dashboard-routes-container')
        container.classList.toggle('MuiGrid-grid-md-10')
        container.classList.toggle('MuiGrid-grid-md-12')
        container.classList.toggle('full-with-desktop-dashboard-routes-container')
        sidebar.classList.toggle("no-visible-main-desktop")
    }

    const [openList, setOpenList] = useState(false)

    /**
     * Handles the click event for the notifications icon or button, toggling the visibility of the notifications list.
     *
     * This function is called when the user interacts with the notifications icon or button.
     * It toggles the open state of the notifications list, showing or hiding it accordingly.
     *
     * @function handleNotificationsClick
     * @memberof ComponentName
     * @returns {void}
     */
    const handleNotificationsClick = () => {
        setOpenList(!openList)
    }

    /**
    * Handles the action when a user interacts with an active notification.
    *
    * This function is called when a user interacts with a notification, typically clicking on it.
    * It checks if the notification is already read, and if not, marks it as seen by dispatching a Redux action.
    * Additionally, it sends a PUT request to update the notification's status as "seen" on the server.
    *
    * @function handleActiveNotification
    * @memberof ComponentName
    * @param {number} idx - The index of the notification being interacted with.
    * @param {boolean} is_read - A boolean indicating if the notification has been read.
    * @returns {void}
    */
    const handleActiveNotification = (idx, is_read) => {
        if (is_read) return;
        dispatch(setSeenNotification(idx))

        serviallAxios.put(`utilities/update_notifications/${idx}`, {
            timeout: TIMEOUTS.small
        })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)

                }));
            })


    }
    const intervalRef = useRef(null)

    /**
     * Fetches notifications from the server and updates the component's state.
     *
     * This function sends an Axios GET request to retrieve notifications from the server.
     * Upon a successful response, it processes the notifications and updates the state
     * with the relevant information, including unread notification count and the notification array.
     * It also calculates the number of notifications that have not been read.
     *
     * @function getNotifications
     * @memberof ComponentName
     * @returns {void}
     */
    const getNotifications = () => {
        serviallAxios.get('utilities/get_notifications', {
            timeout: TIMEOUTS.medium
        })
            .then((res) => {
                const notifications = []
                let notSeenNotifications = 0
                res.data.notifications.forEach((notification) => {
                    const notificationObj = {
                        id: notification[0],
                        description: notification[1],
                        userId: notification[2],
                        isDone: notification[3],
                        createdAt: new Date(notification[4]).toLocaleDateString('es-GB', { year: "numeric", month: "2-digit", day: "2-digit" }),
                        updatedAt: new Date(notification[5]).toLocaleDateString('es-GB', { year: "numeric", month: "2-digit", day: "2-digit" }),
                        is_read: notification[6],
                    }

                    if (!notificationObj.is_read) {
                        notSeenNotifications += 1
                    }
                    notifications.push(notificationObj)
                })
                dispatch(setCounter(notSeenNotifications))
                dispatch(setNotificationArray(notifications))

            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)

                }));
            })
    }

    /**
     * Effect hook for fetching notifications and setting up polling.
     *
     * This effect hook fetches notifications on component mount and sets up an interval to fetch
     * notifications periodically (every 5 minutes). It also clears the interval when the component unmounts.
     *
     * @function useEffect
     * @memberof ComponentName
     * @returns {void}
     */
    useEffect(() => {
        getNotifications()
        dispatch(setLoggingOut(false))
        intervalRef.current = setInterval(getNotifications, 300000)
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
    }, [])

    let navigationLayout =
        <Stack direction={"column"}>
            <img
                className="dashboard-serviall-logo"
                src={serviallLogo}
                alt=""
            />
            <Stack direction={"column"} className='dashboard-options-container'>
                {dashboardPermissions.ToggleButton.optionInicio !== "hidden" &&
                    <NavLink
                        id="optionInicio"
                        to="home"
                        onClick={(e) => { dismissSidebar() }}
                        className={({ isActive }) =>
                            isActive
                                ?
                                "dashboard-navlink active  Dashboard-ToggleButton-permi desc-HomePage_select_button"
                                :
                                "dashboard-navlink  Dashboard-ToggleButton-permi desc-HomePage_select_button"
                        }
                    >
                        <ServiallToggleButton
                            icon={<HomeIcon fontSize='large' />}
                            text={t("Dashboard:Options1")}
                        />
                    </NavLink>
                }
                {dashboardPermissions.ToggleButton.optionNuevaOportunidad !== "hidden" &&
                    <NavLink
                        id="optionNuevaOportunidad"
                        to="nueva_oportunidad"
                        onClick={(e) => { dismissSidebar() }}
                        className={({ isActive }) =>
                            isActive
                                ?
                                "dashboard-navlink active Dashboard-ToggleButton-permi desc-NuevaOportunidad_select_button"
                                :
                                "dashboard-navlink Dashboard-ToggleButton-permi desc-NuevaOportunidad_select_button"
                        }
                    >
                        <ServiallToggleButton
                            icon={
                                <SvgIcon>
                                    {/* Had to edit the svg in the assets folder using this tool https://aydos.com/svgedit/ */}
                                    <path
                                        d="m14.294 5.967c-.581-.159-1.185-.24-1.794-.24-3.735 0-6.773 3.038-6.773 6.773s3.038 6.773 6.773 6.773 6.773-3.038 6.773-6.773c0-.609-.081-1.213-.24-1.794-.11-.399.126-.812.525-.922.399-.109.812 .126.922 .525.195 .711.294 1.448.294 2.191 0 4.562-3.711 8.273-8.273 8.273s-8.273-3.711-8.273-8.273 3.711-8.273 8.273-8.273c.743 0 1.48.099 2.191.294 .333.091 .552.393 .552.723 0 .066-.009.132-.027.199-.109.399-.522.635-.922.525zm8.782 2.616c-.397.118-.624.535-.506.932 .286.965 .43 1.969.43 2.985 0 5.79-4.71 10.5-10.5 10.5s-10.5-4.71-10.5-10.5 4.71-10.5 10.5-10.5c1.016 0 2.02.145 2.985.43 .397.118 .814-.109.932-.506.021-.071.031-.143.031-.213 0-.324-.211-.622-.537-.719-1.103-.327-2.25-.492-3.41-.492-3.205 0-6.219 1.248-8.485 3.515-2.266 2.267-3.515 5.28-3.515 8.485s1.248 6.219 3.515 8.485 5.28 3.515 8.485 3.515 6.219-1.248 8.485-3.515c2.266-2.267 3.515-5.28 3.515-8.485 0-1.16-.166-2.308-.492-3.411-.118-.397-.535-.624-.932-.506zm-10.576.917c.11 0 .222.006 .331.018 .412.045 .782-.252.827-.664.045-.412-.252-.782-.664-.827-.164-.018-.33-.027-.495-.027-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3zm3.829-1.89.374-3.748c.017-.172.093-.333.216-.456l2.687-2.687c.207-.207.515-.275.79-.173.275 .101.465 .353.488 .645l.213 2.712 2.712.213c.292.023 .544.214 .645.488 .101.275 .034.583-.173.79l-2.687 2.687c-.122.122-.284.199-.456.216l-3.747.374-4.36 4.36c-.146.146-.338.22-.53.22-.192 0-.384-.073-.53-.22-.293-.293-.293-.768 0-1.061zm1.557-.496 2.836-.283 1.347-1.347-1.728-.136c-.368-.029-.66-.321-.689-.689l-.136-1.728-1.347 1.347z"
                                    />
                                </SvgIcon>
                            }
                            text={t("Dashboard:Options2")}
                        />
                    </NavLink>
                }
                {dashboardPermissions.ToggleButton.optionNuevaOportunidadMasiva !== "hidden" &&
                    <NavLink
                        id="optionNuevaOportunidadMasiva"
                        to="nueva_oportunidad_masiva"
                        onClick={(e) => { dismissSidebar() }}
                        className={({ isActive }) =>
                            isActive
                                ?
                                "dashboard-navlink active Dashboard-ToggleButton-permi desc-NuevaOportunidad_select_button"
                                :
                                "dashboard-navlink Dashboard-ToggleButton-permi desc-NuevaOportunidad_select_button"
                        }
                    >
                        <ServiallToggleButton
                            icon={
                                <SvgIcon>
                                    {/* Had to edit the svg in the assets folder using this tool https://aydos.com/svgedit/ */}
                                    <path
                                        d="m14.294 5.967c-.581-.159-1.185-.24-1.794-.24-3.735 0-6.773 3.038-6.773 6.773s3.038 6.773 6.773 6.773 6.773-3.038 6.773-6.773c0-.609-.081-1.213-.24-1.794-.11-.399.126-.812.525-.922.399-.109.812 .126.922 .525.195 .711.294 1.448.294 2.191 0 4.562-3.711 8.273-8.273 8.273s-8.273-3.711-8.273-8.273 3.711-8.273 8.273-8.273c.743 0 1.48.099 2.191.294 .333.091 .552.393 .552.723 0 .066-.009.132-.027.199-.109.399-.522.635-.922.525zm8.782 2.616c-.397.118-.624.535-.506.932 .286.965 .43 1.969.43 2.985 0 5.79-4.71 10.5-10.5 10.5s-10.5-4.71-10.5-10.5 4.71-10.5 10.5-10.5c1.016 0 2.02.145 2.985.43 .397.118 .814-.109.932-.506.021-.071.031-.143.031-.213 0-.324-.211-.622-.537-.719-1.103-.327-2.25-.492-3.41-.492-3.205 0-6.219 1.248-8.485 3.515-2.266 2.267-3.515 5.28-3.515 8.485s1.248 6.219 3.515 8.485 5.28 3.515 8.485 3.515 6.219-1.248 8.485-3.515c2.266-2.267 3.515-5.28 3.515-8.485 0-1.16-.166-2.308-.492-3.411-.118-.397-.535-.624-.932-.506zm-10.576.917c.11 0 .222.006 .331.018 .412.045 .782-.252.827-.664.045-.412-.252-.782-.664-.827-.164-.018-.33-.027-.495-.027-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3zm3.829-1.89.374-3.748c.017-.172.093-.333.216-.456l2.687-2.687c.207-.207.515-.275.79-.173.275 .101.465 .353.488 .645l.213 2.712 2.712.213c.292.023 .544.214 .645.488 .101.275 .034.583-.173.79l-2.687 2.687c-.122.122-.284.199-.456.216l-3.747.374-4.36 4.36c-.146.146-.338.22-.53.22-.192 0-.384-.073-.53-.22-.293-.293-.293-.768 0-1.061zm1.557-.496 2.836-.283 1.347-1.347-1.728-.136c-.368-.029-.66-.321-.689-.689l-.136-1.728-1.347 1.347z"
                                    />
                                </SvgIcon>
                            }
                            text={t("Dashboard:Options10")}
                        />
                    </NavLink>
                }
                {dashboardPermissions.ToggleButton.optionGestorOportunidades !== "hidden" &&
                    <NavLink
                        id="optionGestorOportunidades"
                        to="gestor_oportunidades"
                        onClick={(e) => { dismissSidebar() }}
                        className={({ isActive }) =>
                            (isActive ||
                                pathname.includes("detalle_oportunidad") ||
                                pathname.includes("items_licitacion") ||
                                pathname.includes("vista_agregada"))
                                ?
                                "dashboard-navlink active Dashboard-ToggleButton-permi desc-Gestor_Oportunidades_select_button"
                                :
                                "dashboard-navlink Dashboard-ToggleButton-permi desc-Gestor_Oportunidades_select_button"
                        }
                    >
                        <ServiallToggleButton
                            icon={<img src={preview} alt="" className='dashboard-toggle-icon-img' style={{ marginLeft: "4px" }}></img>}
                            text={t("Dashboard:Options3")}
                        />
                    </NavLink>
                }
                {dashboardPermissions.ToggleButton.optionCalendario !== "hidden" &&
                    <NavLink
                        id="optionCalendario"
                        to="calendario"
                        onClick={(e) => { dismissSidebar() }}
                        className={({ isActive }) =>
                            isActive
                                ?
                                "dashboard-navlink active Dashboard-ToggleButton-permi desc-Calendario_select_button"
                                :
                                "dashboard-navlink Dashboard-ToggleButton-permi desc-Calendario_select_button"
                        }
                    >
                        <ServiallToggleButton
                            icon={<DateRangeIcon fontSize='large' />}
                            text={t("Dashboard:Options4")}
                        />
                    </NavLink>
                }
                {dashboardPermissions.ToggleButton.optionMaestroProveedores !== "hidden" &&
                    <NavLink
                        id="optionMaestroProveedores"
                        to="maestro_proveedores"
                        onClick={(e) => { dismissSidebar() }}
                        className={({ isActive }) =>
                            (isActive ||
                                pathname.includes("nuevo_proveedor") ||
                                pathname.includes("detalle_maestros_proveedor") ||
                                pathname.includes("detalle_homologo_proveedor")
                            )
                                ?
                                "dashboard-navlink active Dashboard-ToggleButton-permi desc-MaestroProveedores_select_button"
                                :
                                "dashboard-navlink Dashboard-ToggleButton-permi desc-MaestroProveedores_select_button"
                        }
                    >
                        <ServiallToggleButton
                            icon={<img src={people} alt="" className='dashboard-toggle-icon-img'></img>}
                            text={t("Dashboard:Options5")}
                        />
                    </NavLink>
                }
                {dashboardPermissions.ToggleButton.optionMaestroClientes !== "hidden" &&
                    <NavLink
                        id="optionMaestroClientes"
                        to="maestro_clientes"
                        onClick={(e) => { dismissSidebar() }}
                        className={({ isActive }) =>
                            (isActive ||
                                pathname.includes("nuevo_cliente") ||
                                pathname.includes("detalle_maestros_cliente") ||
                                pathname.includes("detalle_homologo_cliente")
                            )
                                ?
                                "dashboard-navlink active Dashboard-ToggleButton-permi desc-MaestroClientes_select_button"
                                :
                                "dashboard-navlink Dashboard-ToggleButton-permi desc-MaestroClientes_select_button"
                        }
                    >
                        <ServiallToggleButton
                            icon={<img src={people} alt="" className='dashboard-toggle-icon-img'></img>}
                            text={t("Dashboard:Options6")}
                        />
                    </NavLink>
                }
                <NavLink
                    to="configuracion/cuenta"
                    onClick={(e) => { dismissSidebar() }}
                    className={({ isActive }) =>
                        (isActive || pathname.includes("configuracion"))
                            ?
                            "dashboard-navlink active"
                            :
                            "dashboard-navlink"
                    }
                >
                    <ServiallToggleButton
                        icon={<SettingsIcon fontSize='large' />}
                        text={t("Dashboard:Options7")}
                    />
                </NavLink>
            </Stack>
        </Stack>

    let toRender;
    toRender = loading ?
        // TODO: crear una pantalla de cargando
        <Box>{t("Common:Loading")}</Box>
        :
        <Grid className="dashboard-page-container" container maxWidth={"100vw"} >
            <Grid className='dashboard-sidebar-container' item xs={2} display={{ xs: "none", md: "block" }}>
                {navigationLayout}
            </Grid>
            <Grid className='dashboard-responsive-sidebar-container' item display={{ md: "none" }}>
                <CloseIcon className='dashboard-close-icon' fontSize='large' onClick={toggleSidebar} />
                {navigationLayout}
            </Grid>
            <Grid className='dashboard-routes-container dashboard-container' item md={10} width={"100%"} alignContent={"center"}>
                {/* Test lang and theme changers */}
                <Box className="dashboard-avatar-group-container" justifyContent={{ xs: "space-between" }}>
                    <Box display={{ md: "none" }} >
                        <MenuIcon className='dashboard-menu-icon' onClick={toggleSidebar} />
                    </Box>
                    <Box display={{ xs: "none", md: "block" }} >
                        <MenuIcon className='dashboard-menu-icon' onClick={toggleSidebarDesktop} />
                    </Box>
                    <Stack gap={{ xs: 0, md: 3 }} flexDirection={{ xs: "column-reverse", md: "row" }} >
                        <Box display={"flex"} gap={{ xs: 3, md: 0 }} justifyContent={"flex-end"} alignItems={"center"}>
                            <ThemeSwitcher />
                            <LangSwitcher />
                        </Box>
                        <Box className="dashboard-account-container" onClick={handleMenuOpen}>
                            <Typography className='dashboard-avatar-text'>
                                {t("Dashboard:Greeting")}, {email}
                            </Typography>
                            <div className='dashboard-avatar-icon' variant="rounded">
                                <img src={account} alt="" />
                                {
                                    notificationState.notSeenCounter > 0 &&
                                    <div className='dashboard-account-notification'>
                                        <p>{notificationState.notSeenCounter}</p>
                                    </div>
                                }

                            </div>

                        </Box>
                    </Stack>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    slotProps={{
                        paper: {
                            sx: {
                                width: "400px",
                                maxWidth: "90%"
                            }
                        }
                    }}
                >
                    <MenuItem className='dashboard-list-container' key={"notifications"}>
                        <List
                            sx={{ width: '100%', maxWidth: "100%", bgcolor: 'background.paper', paddingY: 0 }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >
                            <ListItemButton onClick={handleNotificationsClick}>
                                <ListItemText primary={t("Dashboard:Options9")} />
                                {openList ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openList} timeout="auto" unmountOnExit >
                                <List component="div" disablePadding >
                                    {
                                        notificationState.notificationArray.map((notification, idx) => {
                                            return (
                                                <>
                                                    <ListItemButton className='dashboard-list-button' key={idx} sx={{ display: "flex", flexDirection: "column", padding: "8rem", color: notification.is_read ? "var(--mui-palette-serviall-currencyInputHover)" : "var(--mui-palette-serviall-selectDate)", backgroundColor: notification.is_read ? "background-color: var(--mui-palette-serviall-menuBackground);" : "#f03a3a", "&:hover": { color: "var(--mui-palette-serviall-currencyInputHover) !important" } }} onClick={() => handleActiveNotification(notification.id, notification.is_read)}>
                                                        <Box display={"flex"} width={"100%"} gap={1} >
                                                            <ListItemText className='dashboard-list-text' sx={{ width: "55%" }}>{notification.description}</ListItemText>
                                                            <ListItemText className='dashboard-list-text' sx={{ display: "flex", justifyContent: "flex-end" }}>{notification.isDone === 0 ? t("Dashboard:InProgress") : t("Dashboard:Done")}</ListItemText>
                                                        </Box>

                                                        <Box sx={{ marginLeft: "auto", marginRight: 0 }}>
                                                            <ListItemText className='dashboard-list-text'>{notification.updatedAt}</ListItemText>
                                                        </Box>

                                                    </ListItemButton>
                                                    <Divider sx={{ borderColor: "gray" }} />
                                                </>
                                            )
                                        })
                                    }
                                </List>
                            </Collapse>
                        </List>
                    </MenuItem>

                    <Divider sx={{ borderColor: "gray" }} />
                    <MenuItem onClick={handleMenuConfiguracion} key={"configuration"}>
                        <Typography className='dashboard-user-menu-option'>
                            {t("Dashboard:Options7")}
                        </Typography>
                    </MenuItem>
                    <Divider sx={{ borderColor: "gray" }} />
                    <MenuItem onClick={handleLogout} key={"logout"}>
                        <Typography className='dashboard-user-menu-option'>
                            {t("Dashboard:Options8")}
                        </Typography>
                    </MenuItem>
                </Menu>
                <Typography className='dashboard-date'>{dateHelper()}</Typography>
                <Box className="dashboard-outlet-container">
                    <Outlet />
                </Box>
            </Grid>
        </Grid>

    return toRender
}

Dashboard.displayName = "Dashboard"

export default Dashboard
