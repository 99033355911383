import { configureStore } from "@reduxjs/toolkit";
import navigationReducer from "../features/navigationSlice";
import permissionsSlice from "../features/permissionsSlice";
import settingsReducer from "../features/settingsSlice";
import opportunitySlice from "../features/opportunitySlice";
import displaySlice from "../features/displaySlice";
import notificationSlice from "../features/notificationSlice";

export default configureStore({
    reducer: {
        navigation: navigationReducer,
        opportunity: opportunitySlice,
        permissions: permissionsSlice,
        settings: settingsReducer,
        display: displaySlice,
        notification: notificationSlice,
    },
})