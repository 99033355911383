const darkPalette = {
    serviall: {
        /* main: "#c56860", */ // in css --mui-palette-serviall-main (Pantone 485 C)
        main: "#c21f11", // in css --mui-palette-serviall-main (Pantone 485 C)
        primary: "#e2271d",
        secondary: "#6995c2", // (Pantone Black 6 C)
        background: "#2b2b2b",
        gridRow: "#8f8f8f",
        darkGray: "#969696",
        gray1: "#cccccc",
        gray2: "#8c8c8c",
        lightGray: "#5e5e5e",
        darkRed1: "#a31c15",
        darkRed2: "#c9231a",
        midRed1: "#f02a1f",
        midRed2: "#ff544b",
        gradient1: "linear-gradient(310deg, rgba(247,138,213,1) 0%, rgba(254,91,146,1) 100%)",
        currencyInputHover: "#ffffff",
        currencyInputFocus: "#90CAF9",
        currencyInputBackground: "#383838",
        selectDate: "#000000",
        datagridGroupBackground: "#1E1E1E",
        menuBackground: "3f3f3f",
    }
}

export default darkPalette;