import { createSlice } from "@reduxjs/toolkit";

// opportunity slice

// En este slice guardar información relacionada a la busqueda de objetos recomendados segun la lógica redux para
// renderizado y uso del datagrid

export const opportunitySlice = createSlice({
    name: "opportunity",
    initialState: {
        current: {
            page: 1,
            history: []
        },
        rows: [],
        currentLicitation:"",
    },
    reducers: {
        setCurrent: (state, action) => {
            state.current = action.payload;
        },
        setRows: (state, action) => {
            state.rows = action.payload;
        },
        setHistory: (state, action) => {
            state.current.history = action.payload;
        },
        setHistoryById: (state, action) => {
            let newHist = [...state.current.history];
            newHist[action.payload.idx] = action.payload.rows;
            state.current.history = newHist;
        },
        setMove: (state, action) => {
            state.move = action.payload;
        },
        setPage: (state, action) => {
            state.current.page = action.payload;
        },
        priorPage: (state) => {
            state.current.page -= 1;
        },
        nextPage: (state) => {
            state.current.page += 1;
        },
        addHistory: (state, action) => {
            state.current.history = [...state.current.history, action.payload];
        },
        setCurrentLicitation: (state,action) => {
            state.currentLicitation = action.payload
        }
    }
})

export const { setCurrent, setRows, setHistory, setHistoryById, setMove, setPage, priorPage, nextPage, addHistory, setCurrentLicitation } = opportunitySlice.actions;

export default opportunitySlice.reducer;