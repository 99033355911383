import { Typography, Box, Grid } from "@mui/material";
import "./GestorOportunidadesPage.css";
import { ReactComponent as ArchivoLogo } from "../../assets/icons/archivo.svg";
import TableRowsIcon from '@mui/icons-material/TableRows';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ListIcon from '@mui/icons-material/List';
import ServiallActionsMenu from "../../components/ServiallActionsMenu";
import { useEffect, useState } from "react";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import serviallAxios from "../../axiosConfig";
import ServiallLoading from "../../components/ServiallLoading";
import CircleIcon from '@mui/icons-material/Circle';
import DraggableDataGrid from "../../components/DraggableDataGrid";
import ServiallDialog from "../../components/ServiallDialog";
import { setAlert } from "../../features/navigationSlice";
import ActiveInfoDialog from "../../components/ActiveInfoDialog";
import { TIMEOUTS, makeS3FolderPath } from "../../utils";
import { setFilter, setRowsPerPageGestorOportunidades } from "../../features/displaySlice";
import { CustomAlertMessage } from "../../utils";

/**
 * A React component that renders the 'Gestor de Oportunidades' datagrid.
 * @typedef GestorOportunidadesPageDatagrid
 * @returns The Datagrid component.
 */

const GestorOportunidadesPageDatagrid = ({datagridCols}) => {


    // const maestroClientesPermissions = useSelector((state) => state.permissions.componentPermissions.MaestroClientesPage);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const current_user_id = useSelector((state) => state.navigation.id);
    const current_user_group = useSelector((state) => state.permissions.group);
    const gestorOportunidadesDatagridPermissions = useSelector((state) => state.permissions.componentPermissions.GestorOportunidadesPageDatagrid);
    const displayState = useSelector((state) => state.display.gestorOportunidades);
    const rowsPerPage = displayState.rowsPerPage;
    const filters = displayState.filters;

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [dialogMsg, setDialogMsg] = useState("");
    const [dialogStatus, setDialogStatus] = useState("");
    const [responsibles, setResponsibles] = useState();
    const [redirectRoute, setRedirectRoute] = useState("");
    const [redirectState, setRedirectState] = useState({});

    const [userInfoDialogOpen, setUserInfoDialogOpen] = useState(false);
    const [activeUserLicitation, setActiveUserLicitation] = useState("");
    const [activeUserName, setActiveUserName] = useState("");
    const [activeUserEmail, setActiveUserEmail] = useState("");
    const lang = useSelector((state) => state.settings.language);


    /**
     * A React hook that fetches the list of responsible users from the server.
     * @returns {void}
     */
    useEffect(() => {
        localStorage.removeItem("firstRender")
        serviallAxios.get("opportunities/get_responsibles", {
            timeout: TIMEOUTS.small
        })
            .then((res) => {
                setResponsibles(res.data.responsibles)
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)

                }));
            })
    }, [])

    /**
     * A React hook that performs a redirect when the 'redirectRoute' variable changes.
     * It uses the 'navigate' function from the 'react-router-dom' package to force redirect to the specified route.
     * @typedef RedirectEffect
     * @function
     * @returns {void}
     */
    useEffect(() => {
        if (redirectRoute !== "") {
            navigate(redirectRoute, {
                state: redirectState
            })
        }
    }, [redirectRoute])


    /**
     * Creates a formatted row object from the provided data.
     * @param {Object} data - The data object containing various properties of the row.
     * @param {string} data.id - The unique identifier of the row.
     * @param {string} data.status - The status of the row.
     * @param {string} data.op_id - The opportunity ID associated with the row.
     * @param {string} data.nombre_cliente - The name of the client associated with the row.
     * @param {string} data.descripcion - The description of the row.
     * @param {string} data.tipo - The type of the row.
     * @param {string} data.fecha_creacion - The creation date of the row.
     * @param {string} data.fecha_cierre - The closing date of the row.
     * @param {string} data.lineas - Information about the lines.
     * @param {string} data.revisados - Information about revisions.
     * @param {string} data.match_efectivo - Information about effective matches.
     * @param {string} data.negocio_valorizado - Information about valued business.
     * @param {string} data.responsable - The responsible person associated with the row.
     * @param {ReactNode} data.actions - ReactNode representing the actions available for the row.
     * @returns {Object} The formatted row object containing various properties.
     */
    const makeRow = ({
        id,
        status,
        op_id,
        nombre_cliente,
        descripcion,
        tipo,
        fecha_creacion,
        fecha_cierre,
        lineas,
        revisados,
        match_efectivo,
        negocio_valorizado,
        responsable,
        actions
    }) => {
        let status_text;
        let status_icon;
        switch (status) {
            case "Done":
                status_text = t("GestorOportunidadesPage:DataGridStatusDone");
                status_icon = <CircleIcon className={"gestorOportunidades-visibility-icon-ok"} />;
                break
            case "Pending":
                status_text = t("GestorOportunidadesPage:DataGridStatusPending");
                status_icon = <CircleIcon className={"gestorOportunidades-visibility-icon-pend"} />;
                break
            case "CM":
                status_text = t("GestorOportunidadesPage:DataGridStatusCM");
                status_icon = <CircleIcon className={"gestorOportunidades-visibility-icon-cm"} />;
                break
            default:
                status_icon = ""
        }

        return {
            id,
            status_str: status,
            status:
                status ?
                    <Grid container value={status} className="gestorOportunidades-datagrid-status-container" >
                        <Grid item xs={10}>
                            {status_text}
                        </Grid>
                        <Grid item xs={2}>
                            {status_icon}
                        </Grid>
                    </Grid> :
                    "",
            op_id,
            nombre_cliente,
            descripcion,
            tipo,
            fecha_creacion,
            fecha_cierre,
            lineas,
            revisados,
            match_efectivo,
            negocio_valorizado,
            responsable,
            actions
        }
    }

    /**
     * A React hook that loads data from the database when 'loading' state is set to true.
     * It fetches opportunities data along with user permissions and processes the data to create rows for the data grid.
     * @returns {void}
     */
    useEffect(() => {
        if (loading === true) {
            serviallAxios.get("opportunities", {
                timeout: TIMEOUTS.small
            })
                .then((res) => {
                    serviallAxios.get("permissions/get_users_from_group_user_permission_with_access_level", {
                        timeout: TIMEOUTS.small
                    })
                        .then((user_permissions_res) => {
                            let user_permissions_with_write_access = user_permissions_res.data.res.map((row) => {
                                if (row[2] === 1 || row[2] === 3) {
                                    return row[0];
                                }
                            });
                            let db_entries = res.data.opportunities_data;
                            let newRows = db_entries.map((entry, idx) => {
                                // Read only when
                                let read_only = false;
                                if (current_user_group !== "admin" && entry[7] !== current_user_id && !user_permissions_with_write_access.includes(entry[7])) {
                                    read_only = true;
                                }
                                const fecha_creacion = entry[14] ? (new Date(entry[14]).toLocaleDateString('es-GB', { year: "numeric", month: "2-digit", day: "2-digit" })).toString() : "";
                                const fecha_cierre = entry[4] ? (new Date(entry[4]).toLocaleDateString('es-GB', { year: "numeric", month: "2-digit", day: "2-digit" })).toString() : ""
                                // const [day, month, year] = fecha_creacion.split("/");
                                // const s3_path = `licitations/dev/${entry[1]}/${entry[2]}_${year}_${month}_${day}/licitation_latest.xlsx`;
                                let data = {
                                    id: idx,
                                    status: entry[9],
                                    lic_id: entry[0], // Not rendered, just forwarded
                                    op_id: entry[2],
                                    nombre_cliente: entry[5],
                                    descripcion: entry[6],
                                    tipo: entry[3],
                                    fecha_creacion: fecha_creacion,
                                    fecha_cierre: fecha_cierre,
                                    lineas: entry[12],
                                    revisados: entry[20],
                                    match_efectivo: entry[21],
                                    negocio_valorizado: entry[22].toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
                                    responsable: entry[19],
                                    assigned_exec_id: entry[7], // Not rendered, just forwarded
                                    read_only: read_only, // Not rendered, just forwarded
                                    families: JSON.parse(entry[10]), // Not rendered, just forwarded
                                    lang: entry[8], // Not rendered, just forwarded
                                    rut_cliente: entry[1], // Not rendered, just forwarded
                                    // s3_path: s3_path
                                }
                                let actions = MakeRowActions(data);

                                return makeRow({ ...data, actions: actions })

                                // return makeRow(idx, entry[9], entry[2], entry[5], entry[6], entry[3], entry[10], entry[4], "", "", "", "", entry[7],
                                //     dataGridRowActions({
                                //         op_id: entry[2],
                                //         rut_cliente: entry[1],
                                //         nombre_cliente: entry[5],
                                //         descripcion: entry[6],
                                //         tipo: entry[3],
                                //         lang: entry[8],
                                //         fecha_creacion: entry[10],
                                //         fecha_cierre: entry[4],
                                //         responsable: entry[7],
                                //     }))
                            })
                            setRows(newRows);
                        })
                        .catch((err) => {
                            dispatch(setAlert({
                                open: true,
                                severity: "error",
                                message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                            }));
                        })
                        .finally(() => setLoading(false));
                })
                .catch((err) => {
                    dispatch(setAlert({
                        open: true,
                        severity: "error",
                        message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                    }));
                })
        }
    }, [loading])

    /**
     * A React hook that sets the 'loading' state to true whenever 'lang' state changes.
     * This effect is used to trigger loading data when the language is changed.
     * @returns {void}
     */
    useEffect(() => {
        setLoading(true)
    }, [lang])

    /**
     * Handles the download of the original file associated with the opportunity.
     * Downloads the file from the server and triggers the download link.
     *
     * @param {string} op_id - The opportunity ID.
     * @param {string} rut - The client's RUT (Rol Único Tributario).
     * @param {string} creation_date - The creation date of the opportunity.
     * @returns {void}
     */
    const handleDownloadOriginal = (op_id, rut, creation_date) => {
        let [day, month, year] = creation_date.split("/");

        serviallAxios.get(`opportunities/get_licitation/${op_id}/${rut}/${year}-${month}-${day}`, {
            timeout: TIMEOUTS.medium,
            responseType: "blob"
        }).then((res) => {
            const href = URL.createObjectURL(res.data);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", op_id + "_" + rut + "_" + creation_date + ".xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })

    }

    /**
     * Handles the download of the processed matches file associated with the opportunity.
     * Downloads the file from the server and triggers the download link.
     *
     * @param {string} op_id - The opportunity ID.
     * @param {string} rut - The client's RUT (Rol Único Tributario).
     * @param {string} creation_date - The creation date of the opportunity.
     * @returns {void}
     */
    const handleDownloadBN = (op_id, rut, creation_date) => {
        let [day, month, year] = creation_date.split("/");
        serviallAxios.get(`opportunities/processed_matches/${op_id}/${rut}/${year}-${month}-${day}`, {
            timeout: TIMEOUTS.medium,
            responseType: "blob"
        }).then((res) => {
            const href = URL.createObjectURL(res.data);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", op_id + "_" + rut + "_" + creation_date + "_matches.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })

    }


    /**
     * Handles the download of the processed file associated with the opportunity.
     * Downloads the file from the server and triggers the download link.
     *
     * @param {string} op_id - The opportunity ID.
     * @param {string} rut - The client's RUT (Rol Único Tributario).
     * @param {string} creation_date - The creation date of the opportunity.
     * @returns {void}
     */
    const handleDownloadProcessed = (op_id, rut, creation_date) => {
        let [day, month, year] = creation_date.includes("-") ? creation_date.split("-") : creation_date.split("/");
        serviallAxios.get(`opportunities/aggregate_view/sheet/${op_id}/${rut}/${year}-${month}-${day}`, {
            responseType: "blob",
            timeout: TIMEOUTS.medium
        }).then((res) => {
            const href = URL.createObjectURL(res.data);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", op_id + "_" + rut + "_" + creation_date + "_processed.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })

    }
    /** 
    * Update licitation comparison bases using a backend ml route.
    * op_id: String - the opportunity id
    * @param {Object} data - The data object containing various properties of the row.
    * @param {string} data.op_id - The opportunity ID associated with the row.
    * @param {string} data.rut_cliente - The client identifier.
    * @param {string} data.fecha_creacion - Licitation creation date.
    * @param {string} data.lang - List of languages.
    * @param {string} data.families - Family list.
    * @param {string} data.nombre_cliente - Name of the client.
    * @returns {void}
    */
    const updateLicitationBases = (data) => {
        setDialogMsg(t("GestorOportunidadesPage:UpdatingBases"));
        setDialogStatus("loading");
        let payload = {
            user_id: current_user_id,
            s3_path: makeS3FolderPath(data) + "/licitation_latest.xlsx",
            model_lg: [],
            client_rut: data.rut_cliente,
            id_opportunity: data.op_id,
            client_name: data.nombre_cliente
        }

        serviallAxios.post(process.env.REACT_APP_ML_BACKEND_URL + "/licitations/user_encode", payload, {
            timeout: TIMEOUTS.huge,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(setAlert({
                    open: true,
                    severity: "info",
                    message: t("GestorOportunidadesPage:UpdateSuccesful")
                }))
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }))
            })
            .finally(() => {
                setDialogMsg("");
                setDialogStatus("");
            })
    }

    /** 
    * Update licitation matches using a backend ml route.
    * @param {Object} data - The data object containing various properties of the row.
    * @param {string} data.op_id - The opportunity ID associated with the row.
    * @param {string} data.rut_cliente - The client identifier.
    * @param {string} data.fecha_creacion - Licitation creation date.
    * @param {string} data.lang - List of languages.
    * @param {string} data.families - Family list.
    * @param {string} data.nombre_cliente - Name of the client.
    * @returns {void}
    */
    const executeNeuralSearchAndRewrite = (data) => {
        setDialogMsg(t("GestorOportunidadesPage:ExecuteNeuralSearch"));
        setDialogStatus("loading");
        let payload = {
            user_id: current_user_id,
            s3_path: makeS3FolderPath(data) + "/licitation_latest.xlsx",
            model_lg: [],
            client_rut: data.rut_cliente,
            id_opportunity: data.op_id,
            client_name: data.nombre_cliente
        }

        serviallAxios.post(process.env.REACT_APP_ML_BACKEND_URL + "/licitations/overwrite_licitation", payload, {
            timeout: TIMEOUTS.huge,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(setAlert({
                    open: true,
                    severity: "info",
                    message: "GestorOportunidadesPage:SearchSuccesful"
                }))
                setRedirectRoute("/dashboard/items_licitacion");
                setRedirectState(res.data.data);
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }))
            })
            .finally(() => {
                setDialogMsg("");
                setDialogStatus("");
            })
    }

    /** 
    * Update licitation matches using a backend ml route, also downloads a file with the new matches.
    * @param {Object} data - The data object containing various properties of the row.
    * @param {string} data.op_id - The opportunity ID associated with the row.
    * @param {string} data.rut_cliente - The client identifier.
    * @param {string} data.fecha_creacion - Licitation creation date.
    * @param {string} data.lang - List of languages.
    * @param {string} data.families - Family list.
    * @param {string} data.nombre_cliente - Name of the client.
    * @returns {void}
    */

    const executeNeuralSearchAndSaveAs = (data) => {
        setDialogMsg(t("GestorOportunidadesPage:ExecuteNeuralSearch"));
        setDialogStatus("loading");
        let payload = {
            user_id: current_user_id,
            s3_path: makeS3FolderPath(data) + "/licitation_latest.xlsx",
            model_lg: [],
            client_rut: data.rut_cliente,
            id_opportunity: data.op_id,
            client_name: data.nombre_cliente
        }

        serviallAxios.post(process.env.REACT_APP_ML_BACKEND_URL + "/licitations/save_as_new_licitation", payload, {
            timeout: TIMEOUTS.huge,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(setAlert({
                    open: true,
                    severity: "info",
                    message: t("GestorOportunidadesPage:SearchSuccesful")
                }))
                setRedirectRoute("/dashboard/items_licitacion");
                setRedirectState(res.data.data);
            })
            .catch((err) => {
                dispatch(setAlert({
                    open: true,
                    severity: "error",
                    message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                }))
            })
            .finally(() => {
                setDialogMsg("");
                setDialogStatus("");
            })
    }

    /**
     * Initiates the process of manually closing an opportunity by sending a request to the server.
     * Closes the opportunity with the provided parameters and updates the state accordingly.
     *
     * @param {Object} params - The parameters required to close the opportunity.
     * @param {string} params.rut_cliente - The client's RUT (Rol Único Tributario).
     * @param {string} params.op_id - The opportunity ID.
     * @param {string} params.lic_id - The license ID associated with the opportunity.
     * @param {string} params.fecha_creacion - The creation date of the opportunity.
     * @returns {void}
     */
    const manualCloseOportunity = (params) => {
        setDialogMsg(t("GestorOportunidadesPage:ClosingLicitation"));
        setDialogStatus("loading");
        let payload = {
            client_rut: params.rut_cliente,
            op_id: params.op_id,
            lic_id: params.lic_id,
            state: "CM",
        }

        if (params.fecha_creacion.includes("/")) {
            let [day, month, year] = params.fecha_creacion.split("/");
            payload.date = `${year}-${month}-${day}`;
        } else {
            let [year, month, day] = params.fecha_creacion.split("-");
            payload.date = `${year}-${month}-${day}`;
        }

        serviallAxios.post("opportunities/close_licitation", payload, {
            timeout: TIMEOUTS.medium
        })
            .then((res) => {
                dispatch(setAlert({
                    open: true,
                    severity: "info",
                    message: t("GestorOportunidadesPage:CloseSuccesful")
                }))
                setDialogMsg("");
                setDialogStatus("");
                setLoading(true);
            })
            .catch((err) => {
                if (err.response) {
                    setDialogStatus("error");
                    setDialogMsg(err.response.data.detail);
                } else {
                    dispatch(setAlert({
                        open: true,
                        severity: "error",
                        message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                    }))
                    setDialogMsg("");
                    setDialogStatus("");
                }
            })
    }


        /**
     * Deletes the current licitation data from the server.
     */
        const deleteLicitationAdmin = (params) => {
            const payload = {
                client_rut: params.rut_cliente,
                op_id: params.op_id,
                date: new Date().toISOString().split("T")[0]
            }
            serviallAxios.delete(process.env.REACT_APP_ML_BACKEND_URL + "/licitations/delete_licitation", { params: payload }, {
                timeout: TIMEOUTS.long,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    console.log(res)
                    setLoading(true);
                })
                .catch((err) => {
                    dispatch(setAlert({
                        open: true,
                        severity: "error",
                        message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                    }));
                })
        }


    /**
     * Generates and returns a unique key for a given row.
     *
     * @param {Object} row - The row for which the key needs to be generated.
     * @returns {string} The unique key for the given row.
     */
    const rowKeyGetter = (row) => {
        return row.id;
    }

    /**
     * Handles the closing of the user info dialog.
     * Closes the dialog and resets the active user's information.
     *
     * @returns {void}
     */
    const handleUserInfoDialogClose = () => {
        setUserInfoDialogOpen(false);
        setActiveUserLicitation("");
        setActiveUserName("");
        setActiveUserEmail("");
    }

    /**
     * Creates an array of primary actions available for the opportunity in the data grid.
     * Each action object contains an icon, text, and an onClick handler to handle the action.
     *
     * @param {Object} params - The parameters associated with the opportunity.
     * @param {string} params.op_id - The opportunity ID.
     * @param {string} params.rut_cliente - The client's RUT (Rol Único Tributario).
     * @param {string} params.fecha_creacion - The creation date of the opportunity.
     * @param {string} params.status - The status of the opportunity.
     * @returns {Array<Object>} An array of primary actions for the opportunity.
     */
    const MakeGestorOportunidadesPrimaryActions = (params) => {
        return [
            {
                key: "gestorOportunidadesVerDetalle",
                icon: <ArchivoLogo fill="currentColor" className="gestorOportunidades-action-icon" />,
                text: t("GestorOportunidadesPage:DataGridAction1"),
                onClick: () => {
                    setRedirectState(params)
                    setRedirectRoute("/dashboard/detalle_oportunidad");
                    // navigate("/dashboard/detalle_oportunidad", {
                    //     state: params
                    // })
                }
            },

            {
                key: "gestorOportunidadesVerVistaAgregada",
                icon: <OpenInFullIcon fill="currentColor" className="gestorOportunidades-action-icon" />,
                text: t("GestorOportunidadesPage:DataGridAction8"),
                onClick: () => {
                    setRedirectState(params)
                    setRedirectRoute(`/dashboard/vista_agregada/${params.id}`);
                    // navigate(`/dashboard/vista_agregada/${params.id}`, {
                    //     state: params,
                    // })
                },
                status: params.status
            },
            params.status === "Pending" &&
            {
                key: "gestorOportunidadesVerDetalleProductos",
                icon: <TableRowsIcon fill="currentColor" className="gestorOportunidades-action-icon" />,
                text: t("GestorOportunidadesPage:DataGridAction9"),
                onClick: () => {
                    const active_user_url = `opportunities/active_user/${params.lic_id}`;
                    // Check if there is no active user in the licitation
                    serviallAxios.get(active_user_url, {
                        timeout: TIMEOUTS.small
                    })
                        .then((res) => {
                            if (res.data.result) {
                                // There is an active user
                                if (parseInt(res.data.result[0]) !== current_user_id) {
                                    /* If there's an active user, check if it's inactive */
                                    serviallAxios.get(`opportunities/get_last_session`, {params: {client_rut: params.rut_cliente, id_opportunity: params.op_id }}, {
                                        timeout: TIMEOUTS.small
                                    })
                                    .then((sessionRes) => {
                                        if (parseInt(sessionRes.data.minutes_difference) < (process.env.REACT_APP_ACTIVE_TOLERANCE ? process.env.REACT_APP_ACTIVE_TOLERANCE : 30)){
                                            setUserInfoDialogOpen(true);
                                            setActiveUserLicitation(params.op_id);
                                            setActiveUserName(res.data.result[2]);
                                            setActiveUserEmail(res.data.result[1]);
                                        }
                                        else{
                                            /* If user is inactive, then change inactive user to current user  */
                                            serviallAxios.put(active_user_url, {
                                                timeout: TIMEOUTS.small
                                            })
                                                .then(() => {
                                                    setRedirectState(params);
                                                    setRedirectRoute("/dashboard/items_licitacion");
                                                })
                                                .catch((err) => {
                                                    dispatch(setAlert({
                                                        open: true,
                                                        severity: "error",
                                                        message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                                                    }));
                                                })
                                        }
                                    })
                                    .catch((err) => {
                                        dispatch(setAlert({
                                            open: true,
                                            severity: "error",
                                            message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                                        }));
                                    })
                                } else {
                                    // Current user is the same as active (this scenario should not happen but I put this here just in case)
                                    serviallAxios.put(active_user_url, {
                                        timeout: TIMEOUTS.small
                                    })
                                        .then(() => {
                                            setRedirectState(params);
                                            setRedirectRoute("/dashboard/items_licitacion");
                                        })
                                        .catch((err) => {
                                            dispatch(setAlert({
                                                open: true,
                                                severity: "error",
                                                message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                                            }));
                                        })
                                }
                            } else {
                                // There is no user currently in this licitation, set the active user to the current user, then redirect
                                serviallAxios.put(active_user_url, {
                                    timeout: TIMEOUTS.small
                                })
                                    .then(() => {
                                        setRedirectState(params);
                                        setRedirectRoute("/dashboard/items_licitacion");
                                    })
                                    .catch((err) => {
                                        dispatch(setAlert({
                                            open: true,
                                            severity: "error",
                                            message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                                        }));
                                    })
                            }
                        })
                        .catch((err) => {
                            dispatch(setAlert({
                                open: true,
                                severity: "error",
                                message: err.response ? err.response.data.detail : CustomAlertMessage(err.code)
                            }));
                        })
                    // navigate("/dashboard/items_licitacion", {
                    //     state: params
                    // })
                }
            },
            {
                key: "gestorOportunidadesDescargarOriginal",
                icon: <SystemUpdateAltIcon />,
                text: t("GestorOportunidadesPage:DataGridAction2"),
                onClick: () => { handleDownloadOriginal(params.op_id, params.rut_cliente, params.fecha_creacion) }
            },
            {
                key: "gestorOportunidadesDescargarBN",
                icon: <SystemUpdateAltIcon />,
                text: t("GestorOportunidadesPage:DataGridAction3"),
                onClick: () => { handleDownloadBN(params.op_id, params.rut_cliente, params.fecha_creacion) }
            },
            (params.status === "Done" || params.status === "CM") &&
            {
                key: "gestorOportunidadesDescargarProcesado",
                icon: <SystemUpdateAltIcon />,
                text: t("GestorOportunidadesPage:DataGridAction4"),
                onClick: () => { handleDownloadProcessed(params.op_id, params.rut_cliente, params.fecha_creacion) }
            },
        ]
    }

    /**
     * Creates an array of secondary actions available for the opportunity in the data grid.
     * The secondary actions are conditional based on the status of the opportunity.
     * Each action object contains an icon, text, and an onClick handler to handle the action.
     *
     * @param {Object} params - The parameters associated with the opportunity.
     * @param {string} params.status - The status of the opportunity.
     * @returns {Array<Object>|null} An array of secondary actions for the opportunity or null if none.
     */
    console.log('ACCESS', current_user_group)
    const makeSecondaryActions = (params) => {
        let GestorOportunidadesSecondaryActions =
            params.status === "Done" || params.status === "CM" ?
            [{
                key: "gestorOportunidadesBusquedaGuardarNuevo",
                icon: <AutorenewIcon />,
                text: t("GestorOportunidadesPage:DataGridAction6"),
                onClick: () => { executeNeuralSearchAndSaveAs(params) }
            }]
                :
                [
                    {
                        key: "gestorOportunidadesActualizarBases",
                        icon: <CompareArrowsIcon />,
                        text: t("GestorOportunidadesPage:DataGridAction10"),
                        onClick: () => { updateLicitationBases(params) }
                    },
                    {
                        key: "gestorOportunidadesBusquedaSobreescribir",
                        icon: <AutorenewIcon />,
                        text: t("GestorOportunidadesPage:DataGridAction5"),
                        onClick: () => { executeNeuralSearchAndRewrite(params) }
                    },
                    {
                        key: "gestorOportunidadesBusquedaGuardarNuevo",
                        icon: <AutorenewIcon />,
                        text: t("GestorOportunidadesPage:DataGridAction6"),
                        onClick: () => { executeNeuralSearchAndSaveAs(params) }
                    },
                ]

        if (current_user_group === 'admin'){
            GestorOportunidadesSecondaryActions.push({
                key: "gestorOportunidadesEliminarLicitacion",
                        icon: <DeleteIcon />,
                        text: t("GestorOportunidadesPage:DataGridAction11"),
                        onClick: () => { deleteLicitationAdmin(params) }
            })
        }
        return GestorOportunidadesSecondaryActions;
    }

    /**
     * Creates an array of tertiary actions available for the opportunity in the data grid.
     * The tertiary actions are conditional based on the status of the opportunity.
     * Each action object contains an icon, text, and an onClick handler to handle the action.
     *
     * @param {Object} params - The parameters associated with the opportunity.
     * @param {string} params.status - The status of the opportunity.
     * @returns {Array<Object>|null} An array of tertiary actions for the opportunity or null if none.
     */
    const makeTertiaryActions = (params) => {
        const GestorOportunidadesTertiaryActions =
            params.status === "Done" || params.status === "CM" ?
                null
                :
                [
                    {
                        key: "gestorOportunidadesCierreManual",
                        icon: <DoNotDisturbAltIcon />,
                        text: t("GestorOportunidadesPage:DataGridAction7"),
                        onClick: () => { manualCloseOportunity(params) }
                    }
                ]
        return GestorOportunidadesTertiaryActions;
    }

    // Aux functions to transform db data to datagrid rows

    /**
     * Creates a React element representing the actions menu for a given opportunity row.
     * The actions menu contains primary, secondary, and tertiary actions based on permissions and opportunity status.
     *
     * @param {Object} params - The parameters associated with the opportunity row.
     * @param {string} params.op_id - The opportunity ID.
     * @param {string} params.rut_cliente - The client's RUT (Rol Único Tributario).
     * @param {string} params.fecha_creacion - The creation date of the opportunity.
     * @param {string} params.status - The status of the opportunity.
     * @returns {ReactElement|string} A React element representing the actions menu or an empty string if no data.
     */
    const MakeRowActions = (params) => {
        if (params) {
            const primaryActions = MakeGestorOportunidadesPrimaryActions(params);
            const secondaryActions = makeSecondaryActions(params);
            const tertiaryActions = makeTertiaryActions(params);
            return <ServiallActionsMenu
                id="writeActions"
                className='GestorOportunidades-ServiallActionsMenu-permi 
                           desc-GestorOportunidades_write_actions'
                menu={<ListIcon className='maestroClientes-datagrid-action-icon' />}
                actions={primaryActions}
                actionsSecondary={
                    (gestorOportunidadesDatagridPermissions.ServiallActionsMenu.writeActions === "admin")
                    && secondaryActions
                }
                actionsTertiary={
                    (gestorOportunidadesDatagridPermissions.ServiallActionsMenu.writeActions === "write" ||
                        gestorOportunidadesDatagridPermissions.ServiallActionsMenu.writeActions === "admin")
                    && tertiaryActions
                }
            />
        }
        else {
            return ""
        }
    }





    /**
     * Handles the page change event in the data grid.
     * Updates the current page number with the new page number.
     *
     * @param {Event} e - The event object representing the page change event.
     * @param {number} newPage - The new page number selected by the user.
     * @returns {void}
     */
    const handlePageChange = (e, newPage) => {
        setPage(newPage);
    }

    /**
     * Handles the change of rows per page in the data grid.
     * Updates the number of rows displayed per page and resets the current page to the first page.
     *
     * @param {Event} e - The event object representing the rows per page change event.
     * @returns {void}
     */
    const handleChangeRowsPerPage = (e) => {
        dispatch(setRowsPerPageGestorOportunidades(parseInt(e.target.value)));
        setPage(0);
    }

    // const pageRows = [makeRow({
    //     id: 1,
    //     status: "Done",
    //     op_id: "FAKE",
    //     nombre_cliente: "FAKE",
    //     descripcion: "FAKE",
    //     tipo: "FAKE",
    //     fecha_creacion: "",
    //     fecha_cierre: "",
    //     lineas: "FAKE",
    //     revisados: "FAKE",
    //     match_efectivo: "FAKE",
    //     negocio_valorizado: "FAKE",
    //     responsable: "FAKE",
    //     actions: ""
    // })]

    const handleCloseDialog = () => {
        setDialogMsg("");
        setDialogStatus("");
    }


    return (
        <>
            {
                loading ?
                    <ServiallLoading text={t("GestorOportunidadesPage:LoadingStatus")} />
                    :
                    rows.length > 0 ?
                        <Box className="serviall-datagrid-container">
                            {gestorOportunidadesDatagridPermissions.DraggableDataGrid.serviallDataGrid !== "hidden" && datagridCols.length > 0 &&
                                <>
                                    <DraggableDataGrid
                                        id="serviallDataGrid"
                                        className="GestorOportunidades-DataGrid-permi
                                           desc-GestorOportunidades_datagrid
                                           serviall-datagrid"
                                        cols={datagridCols}
                                        rows={rows}
                                        rowKeyGetter={rowKeyGetter}
                                        headerRowHeight={90}
                                        responsibles={responsibles}
                                        filters={filters}
                                        setFiltersAction={setFilter}
                                        page={"gestorOportunidades"}
                                        pageNum={page}
                                        rowsPerPage={rowsPerPage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        handlePageChange={handlePageChange}
                                    />
                                                        </>
                            }
                        </Box>
                        :
                        <>
                            <Box className="serviall-datagrid-loading-container">
                                <Typography className="serviall-h4">No hay oportunidades para mostrar</Typography>
                            </Box>
                        </>
            }
            <ServiallDialog
                open={Boolean(dialogMsg)}
                status={dialogStatus}
                loading_msg={dialogMsg}
                error_msg={dialogMsg}
                handleClose={handleCloseDialog}
            />
            <ActiveInfoDialog
                open={userInfoDialogOpen}
                field1={activeUserLicitation}
                field2={activeUserName}
                field3={activeUserEmail}
                handleClose={handleUserInfoDialogClose}
                type={"active_user"}
            />
        </>

    )
}

GestorOportunidadesPageDatagrid.displayName = "GestorOportunidadesPageDatagrid";

export default GestorOportunidadesPageDatagrid;