import { useRouteError } from "react-router-dom";

/**
 * A React component that renders the Error Page.
 * @typedef ErrorPage
 * @returns The Error Page component
 */


const ErrorPage = () => {
    const error = useRouteError();
    console.log(error);

    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has ocurred.</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}

ErrorPage.displayName = "ErrorPage";

export default ErrorPage;