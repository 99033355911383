import { Switch } from "@mui/material";
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useDispatch, useSelector } from "react-redux";
import { changeTheme } from "../features/settingsSlice";

/**
 * A React component that renders the theme switcher.
 * @typedef ThemeSwitcher
 * @returns The Theme Switcher component.
 */

const ThemeSwitcher = () => {

    const theme = useSelector((state) => state.settings.theme);
    const dispatch = useDispatch();

    /**
     * Function to handle the theme change when the switch is toggled.
     * It dispatches an action to update the theme in the Redux store.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e - The change event of the switch.
     */
    const handleThemeChange = (e) => {
        dispatch(changeTheme());
    }

    return (
        <Switch
            checked={theme === "light"}
            onChange={handleThemeChange}
            color="warning"
            icon={<DarkModeIcon />}
            checkedIcon={<LightModeIcon />}
        />
    )
}

ThemeSwitcher.displayName = "ThemeSwitcher";

export default ThemeSwitcher;